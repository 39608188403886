import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const AlertDialog = ({
  isOpen,
  handleConfirm,
  handleCancel,
  title,
  description,
  confirm = 'Yes',
  cancel = 'No',
}) => {
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {description && (
            <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            {cancel}
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            {confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AlertDialog;
