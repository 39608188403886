/* eslint-disable operator-linebreak */
/* eslint-disable arrow-body-style */
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import qs from 'qs';
import { getAccessToken } from './firebase';
import { removeNullFromData } from './util';
import { showMessage } from 'redux/actions';
import isEmpty from 'lodash.isempty';

const jobsService = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    // Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

jobsService.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.data.errors &&
      error.response.data.errors[0].code === 'INVALID_TOKEN'
    ) {
      // return AuthService.updateAccessToken().then((accessToken) => {
      //   error.config.headers.Authorization = `Bearer ${accessToken}`;
      //   return apiClient.request(error.config);
      // });
    }
    return Promise.reject(error);
  },
);

const listJobs = async ({ page = 0, size = 10, search = '' }) => {
  const accessToken = await getAccessToken();

  try {
    const apiURL = `/jobs`;
    const result = await jobsService.get(apiURL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      params: {
        page,
        size,
        search,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });

    return result.data;
  } catch (error) {
    return { error: true };
  }
};

// use React Query
export const useGetListJobs = (params) => {
  const dispatch = useDispatch();

  return useQuery(['JOBS', params], () => listJobs(params), {
    onError: (error) => {
      dispatch(
        showMessage({ type: 'error', message: 'There was an error fetching the jobs list.' }),
      );
    },
  });
};

const getJobDetails = async (id) => {
  const accessToken = await getAccessToken();

  try {
    const apiURL = `/jobs/${id}/details`;
    const result = await jobsService.get(apiURL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return result.data;
  } catch (error) {
    return { error: true };
  }
};

const submitJob = async (name, description, spec, oneOffRun = false) => {
  const accessToken = await getAccessToken();

  const data = {
    name,
    description,
    spec,
    one_off_run: oneOffRun,
  };

  try {
    const apiURL = `/jobs`;
    const result = await jobsService.post(apiURL, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return result.data;
  } catch (error) {
    console.log('error', error.response);
    const errorMessage = buildErrorMessage(error?.response?.data);

    return { error: true, errorMessage: errorMessage };
  }
};

const patchSpec = async (spec_id, data) => {
  const accessToken = await getAccessToken();

  try {
    const apiURL = `/specs/${spec_id}`;
    const result = await jobsService.patch(apiURL, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return result.data;
  } catch (error) {
    console.log('error', error.response);
    const errorMessage = buildErrorMessage(error?.response?.data);

    return { error: true, errorMessage: errorMessage };
  }
};

const buildErrorMessage = (error) => {
  const errorBody = error?.detail?.reduce((acc, curr) => {
    const errorLoction = curr.loc?.toString();
    return `${acc} ${errorLoction} - ${curr.msg} <br />`;
  }, '');
  return errorBody;
};

export const terminateJob = async (jobID) => {
  // https://parala-api.35-246-17-43.nip.io/api/v1/beta/jobs/{job_id}/terminate
  const accessToken = await getAccessToken();

  try {
    const apiURL = `/jobs/${jobID}/terminate`;
    const result = await jobsService.post(
      apiURL,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    return result.data;
  } catch (error) {
    return { error: error };
  }
};

export const deleteJob = async (jobID) => {
  // https://parala-api.35-246-17-43.nip.io/api/v1/beta/jobs
  const accessToken = await getAccessToken();

  try {
    const apiURL = `/jobs`;
    const result = await jobsService.delete(apiURL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      params: {
        job_id: [jobID],
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return result.data;
  } catch (error) {
    return { error: error };
  }
};

export const retryJob = async (jobID) => {
  // https://parala-api.35-246-17-43.nip.io/api/v1/beta/jobs/{job_id}/retry
  const accessToken = await getAccessToken();

  try {
    const apiURL = `/jobs/${jobID}/retry`;
    const result = await jobsService.post(
      apiURL,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    return result.data;
  } catch (error) {
    return { error: error };
  }
};

export const listArtifacts = async (jobID) => {
  const accessToken = await getAccessToken();
  try {
    const apiURL = `/jobs/${jobID}/artifacts`;
    const result = await jobsService.get(apiURL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return result.data;
  } catch (error) {
    return error;
  }
};

// /api/v1/beta/jobs/{job_id}/artifacts-by-category
export const listArtifactsByCategory = async (jobID) => {
  const accessToken = await getAccessToken();
  try {
    const apiURL = `/jobs/${jobID}/artifacts-by-category`;
    const result = await jobsService.get(apiURL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return result.data;
  } catch (error) {
    return error;
  }
};

export const listComponents = async (jobID) => {
  const accessToken = await getAccessToken();
  try {
    const apiURL = `/jobs/${jobID}/components`;
    const result = await jobsService.get(apiURL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return result.data;
  } catch (error) {
    return error;
  }
};

export const downloadArtifacts = async (jobID, artifacts) => {
  const accessToken = await getAccessToken();

  const body = {
    artifactNames: [...artifacts],
  };
  try {
    // https://parala-api.35-246-17-43.nip.io/api/v1/beta/jobs/{job_id}/artifacts/download
    // artifact_name
    const apiURL = `/jobs/${jobID}/artifacts/download`;
    const result = await jobsService.post(apiURL, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
    });

    return result.data;
  } catch (error) {
    return error;
  }
};

export const downloadLog = async (jobID, log_path) => {
  const accessToken = await getAccessToken();
  try {
    // https://parala-api.35-246-17-43.nip.io/api/v1/beta/jobs/{job_id}/components/logs/{log_path}
    const apiURL = `/jobs/${jobID}/components/logs/${log_path}`;
    const result = await jobsService.get(apiURL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    let logContent = '';
    result.data?.data?.forEach((line) => {
      logContent = logContent + line + '<br />';
    });
    return logContent;
  } catch (error) {
    return error;
  }
};

const loadSpec = async (id) => {
  const accessToken = await getAccessToken();
  try {
    const apiURL = `/specs/${id}`;
    const result = await jobsService.get(apiURL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return result.data;
  } catch (error) {
    return error;
  }
};

const validateSpec = async (data) => {
  const accessToken = await getAccessToken();
  try {
    // https://parala-api.35-246-17-43.nip.io/api/v1/beta/specs/pipeline/validate?type=pipelineSpec
    const apiURL = `/specs/pipeline/validate?type=pipelineSpec`;
    const result = await jobsService.post(
      apiURL,
      { ...data },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    return result.data;
  } catch (error) {
    return error;
  }
};

const buildSpec = async (
  id,
  reporting,
  reportOnPortfolioModels,
  useHistoricalLearning,
  specUpdate,
) => {
  try {
    let dataForAnalysis, estimator, optimization;
    let refSpecId;

    if (reportOnPortfolioModels) {
      optimization = await loadSpec(id);
      refSpecId = optimization?.ref_spec_id;
    } else refSpecId = id;

    if (refSpecId) {
      estimator = await loadSpec(refSpecId);
    } else {
      return { error: 'Optimization not verified' };
    }
    const dataForAnalysisId = estimator?.ref_spec_id;

    if (dataForAnalysisId) {
      dataForAnalysis = await loadSpec(dataForAnalysisId);
    } else {
      return { error: 'Data For Analysis not verified' };
    }

    const dataForAnalysisObject = {
      ...dataForAnalysis.spec,
      ...(specUpdate?.enableSpecUpdate &&
        moment(specUpdate.lastDataDate).isValid() && {
          endDate:
            moment(specUpdate.lastDataDate).format('YYYY-MM-DD') || dataForAnalysis.spec?.endDate,
        }),
    };

    const estimatorObject = {
      ...estimator.spec,
      vintage: {
        ...estimator.spec.vintage,
        ...(specUpdate?.enableSpecUpdate &&
          moment(specUpdate.lastVintageDate).isValid() && {
            endDate:
              moment(specUpdate.lastVintageDate).format('YYYY-MM-DD') ||
              estimator.spec?.vintage?.endDate,
          }),
      },
    };

    const data = {
      dataForAnalysis: dataForAnalysisObject,
      estimator: estimatorObject,
      ...(reportOnPortfolioModels && { optimization: optimization.spec }),
      ...(!isEmpty(reporting) && { reporting }),
      pipeline: {
        useHistoricalData: useHistoricalLearning === false ? false : true,
      },
    };
    const result = removeNullFromData(data);

    return {
      data: result,
      dataForAnalysisObject: {
        ...dataForAnalysis,
        spec: {
          ...dataForAnalysisObject,
        },
      },
      estimatorObject: {
        ...estimator,
        spec: {
          ...estimatorObject,
        },
      },
    };
  } catch (error) {
    return error;
  }
};

// /api/v1/beta/jobs/artifact-version-selector
const artifactVersionSelector = async (specID, type) => {
  const accessToken = await getAccessToken();
  try {
    const spec = await loadSpec(specID);

    const apiURL = `/jobs/artifact-version-selector?type=${type}`;
    const result = await jobsService.post(
      apiURL,
      { ...spec },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );

    return result.data;
  } catch (error) {
    return error;
  }
};

const getJobVisualReports = async (id, type = 'asset') => {
  const accessToken = await getAccessToken();

  const apiURL = `/jobs/${id}/reports/heatmap/${type}`;
  const result = await jobsService.get(apiURL, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  return result.data;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  artifactVersionSelector,
  loadSpec,
  patchSpec,
  buildSpec,
  listJobs,
  submitJob,
  terminateJob,
  deleteJob,
  retryJob,
  listArtifacts,
  listArtifactsByCategory,
  downloadArtifacts,
  downloadLog,
  listComponents,
  getJobDetails,
  getJobVisualReports,
};
