import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import SettingsIcon from '@material-ui/icons/Settings';

import RiskFactorDialog from './RiskFactorDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: 100,
    maxWidth: 510,
    backgroundColor: theme.palette.background.paper,
  },
  rootBox: {
    width: '100%',
    maxWidth: 510,
  },
}));

const SelectNeutralRiskFactor = ({
  name,
  btnTitle,
  fieldOptions,
  modalTitle,
  listItems,
  listValues,
  handleSaveData,
}) => {
  const classes = useStyles();
  const [showSetWeights, SetShowSetWeights] = useState(true);
  const [checked, setChecked] = useState([]);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  useEffect(() => {
    checked?.length > 0 ? SetShowSetWeights(false) : SetShowSetWeights(true);
  }, [checked]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleSave = (value, factors) => {
    handleSaveData(value, factors);
  };

  const openDialog = (status) => {
    setDialogIsOpen(status);
  };

  return (
    <>
      {dialogIsOpen ? (
        <RiskFactorDialog
          isOpen={dialogIsOpen}
          close={openDialog}
          items={checked}
          handleDelete={handleToggle}
          handleSave={handleSave}
          modalTitle={modalTitle}
          fieldOptions={fieldOptions}
        />
      ) : null}
      <Box mb={2} textAlign="right" className={classes.rootBox}>
        <Button
          variant="contained"
          size="small"
          startIcon={<SettingsIcon />}
          disabled={showSetWeights}
          onClick={() => openDialog(true)}
        >
          {btnTitle}
        </Button>
      </Box>
      <Paper className={classes.root}>
        <List>
          {listItems.map((item) => {
            const minMaxValues = listValues?.find((value) => value.id === item.id);
            const secondaryText = minMaxValues
              ? `Min:${minMaxValues?.min}, Max:${minMaxValues?.max}`
              : ``;

            return (
              <ListItem button key={item.id}>
                <ListItemText primary={item.name} secondary={secondaryText} />
                <ListItemSecondaryAction>
                  <Checkbox
                    edge="end"
                    onChange={handleToggle(item)}
                    checked={checked.indexOf(item) !== -1}
                    inputProps={{ 'aria-labelledby': item.id }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </Paper>
    </>
  );
};

export default SelectNeutralRiskFactor;
