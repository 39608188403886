/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Grid } from '@material-ui/core';
import InlineInput from '../InlineInput';

const MultiStatWeight = () => {
  const { control } = useFormContext();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <InlineInput
          control={control}
          text="Mean"
          type="number"
          name="MultiStatWeightsMean"
          leftGrid={6}
          rightGrid={6}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InlineInput
          control={control}
          text="Alpha"
          type="number"
          name="MultiStatWeightsAlpha"
          leftGrid={6}
          rightGrid={6}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InlineInput
          control={control}
          text="Variance"
          type="number"
          name="MultiStatWeightsVariance"
          leftGrid={6}
          rightGrid={6}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InlineInput
          control={control}
          text="Tracking Error"
          type="number"
          name="MultiStatWeightsTrackingError"
          leftGrid={6}
          rightGrid={6}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InlineInput
          control={control}
          text="Utility"
          type="number"
          name="MultiStatWeightsUtility"
          leftGrid={6}
          rightGrid={6}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InlineInput
          control={control}
          text="Sharpe ratio"
          type="number"
          name="MultiStatWeightsSharpeRatio"
          leftGrid={6}
          rightGrid={6}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InlineInput
          control={control}
          text="Info ratio"
          type="number"
          name="MultiStatWeightsInfoRatio"
          leftGrid={6}
          rightGrid={6}
        />
      </Grid>
    </Grid>
  );
};

export default MultiStatWeight;
