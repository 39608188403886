import { useQuery } from 'react-query';
import { getReportsStreamData } from 'services/DashboardReportService';

export const useGetReportsStreamData = (queryKey, reportId, fileName) => {
  return useQuery([queryKey, reportId, 'stream'], () => getReportsStreamData(reportId, fileName), {
    staleTime: Infinity,
    cacheTime: Infinity,
    retry: 0,
  });
};
