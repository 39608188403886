import { useTheme } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import Tooltip from '@material-ui/core/Tooltip';

const FormStatus = ({ status }) => {
  const theme = useTheme();

  return (
    <>
      {status !== null && (
        <Tooltip title={status ? `Form data not saved` : `Form data saved`}>
          <SaveIcon
            style={{
              color: status ? theme.palette.error.main : theme.palette.success.main,
              marginLeft: 5,
            }}
          />
        </Tooltip>
      )}
    </>
  );
};

export default FormStatus;
