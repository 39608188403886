import includes from 'lodash.includes';
import * as types from '../../types';
import {
  showMessage,
  loadDataForAnalysis,
  validateEstimatorModel,
  isDirtyEstimatorModel,
} from '../../actions';

import universeService from '../../../services/UniverseService';
import estimatorService from '../../../services/EstimatorService';
import config from '../../../config';

// set Estimator Model form rules
export const setEstimatorModelRules =
  (id, modelId = null) =>
  async (dispatch) => {
    const isModelEnabled = includes(
      config.estimatorModelRestrictions[id].modelEstimationStrategy,
      parseInt(modelId),
    );
    const rules = isModelEnabled
      ? config.estimatorModelRestrictions[id][modelId] || []
      : config.estimatorModelRestrictions[id] || [];
    dispatch({
      type: types.ESTIMATOR_MODEL_SET_RULES,
      payload: rules,
    });
  };

// Load Data
export const loadEstimatorModel = (id) => async (dispatch) => {
  const result = await estimatorService.loadSpec(id);

  const dataForAnalysisID = result?.ref_spec_id;

  if (dataForAnalysisID) {
    dispatch(loadDataForAnalysis(dataForAnalysisID));
  }
  if (result?.spec?.isSimulatedModelCombination) {
    dispatch({
      type: types.ESTIMATOR_MODEL_SMC_LOAD_DATA,
      payload: result,
    });
  } else {
    dispatch({
      type: types.ESTIMATOR_MODEL_LOAD_DATA,
      payload: result,
    });
  }
  dispatch(validateEstimatorModel(result));
  dispatch(isDirtyEstimatorModel(false));
  dispatch(showMessage({ type: 'success', message: 'Data loaded successfully.' }));
};

// Estimator Model save
export const saveEstimatorModel = (data, id) => async (dispatch) => {
  try {
    const type = 'estimator';

    const result = await estimatorService.saveEstimatorModelSpec(data, type, id);

    if (result?.error) {
      dispatch(showMessage({ type: 'error', message: result.error?.detail }));
    } else {
      dispatch(showMessage({ type: 'success', message: 'Data saved successfully.' }));

      dispatch({
        type: types.ESTIMATOR_MODEL_SAVE_DATA,
        payload: result,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

// Simulated Estimator Model save
export const saveSimulatedModelCombinations = (data, id) => async (dispatch) => {
  try {
    const type = 'estimator';

    const result = await estimatorService.saveSimulatedModelCombinations(data, type, id);

    if (result?.error) {
      dispatch(showMessage({ type: 'error', message: result.error?.detail }));
    } else {
      dispatch(showMessage({ type: 'success', message: 'Data saved successfully.' }));

      dispatch({
        type: types.ESTIMATOR_MODEL_SAVE_DATA,
        payload: result,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

// Estimator Model save in state

export const saveEstimatorModelInState = (data) => async (dispatch) => {
  dispatch({
    type: types.ESTIMATOR_MODEL_SAVE_DATA_IN_STATE,
    payload: data,
  });
};

export const resetEstimatorModel = () => (dispatch) => {
  dispatch({
    type: types.ESTIMATOR_MODEL_RESET,
    payload: '',
  });
  dispatch({
    type: types.APP_ESTIMATOR_MODEL_RESET,
    payload: '',
  });
};

export const deleteEstimatorModel = (id) => async (dispatch) => {
  try {
    await universeService.deleteUniverse(id);
    dispatch(resetEstimatorModel());
    dispatch({
      type: types.APP_ESTIMATOR_MODEL_RESET,
      payload: '',
    });
    dispatch(showMessage({ type: 'success', message: 'Data deleted successfully.' }));
  } catch (error) {}
};

// Complete Subset Regression
export const updateSubsetRegression = (data) => (dispatch) => {
  dispatch({
    type: types.UPDATE_SUBSET_REGRESSION,
    payload: data,
  });
};

export const updateSubsetRegressionMacrofactors = (data) => (dispatch) => {
  const arrayOfIDs = data.map((item) => item.id);
  const macroeconomicVariables = data.reduce(
    (o, cur) => ({ ...o, [cur.id]: { ...cur, id: `${cur.id}` } }),
    {},
  );

  dispatch({
    type: types.UPDATE_SUBSET_REGRESSION_MACROFACTORS,
    payload: {
      variables: macroeconomicVariables,
      macrofactors: arrayOfIDs,
    },
  });
  // TODO
  // dispatch(validateEstimatorModel(formData, estimatorRules));
};

export const refreshSubsetRegressionMacrofactors = (data) => (dispatch) => {
  const arrayOfIDs = data.map((item) => item.id);
  const macroeconomicVariables = data.reduce(
    (o, cur) => ({ ...o, [cur.id]: { id: `${cur.id}`, name: cur.name } }),
    {},
  );

  dispatch({
    type: types.REFRESH_SUBSET_REGRESSION_MACROFACTORS,
    payload: {
      variables: macroeconomicVariables,
      arrayOfIDs,
    },
  });
  // TODO
  // dispatch(validateEstimatorModel(formData, estimatorRules));
};

export const updateSelectedSimulatedModels = (models) => (dispatch) => {
  dispatch({
    type: types.ESTIMATOR_MODEL_UPDATE_SELECTED_SIMULATED_MODELS,
    payload: models,
  });
};

export const updateSimulatedModels = (specModel, models) => (dispatch) => {
  dispatch({
    type: types.ESTIMATOR_MODEL_UPDATE_SIMULATED_MODELS,
    payload: { specModel, models },
  });
};

export const updateIsModelCombination = (data) => (dispatch) => {
  dispatch({
    type: types.ESTIMATOR_MODEL_IS_SIMULATED_MODEL_COMBINATION,
    payload: data,
  });
};

export const updateIsSegmentedModelCombination = (data) => (dispatch) => {
  dispatch({
    type: types.ESTIMATOR_MODEL_IS_SEGMENTED_SIMULATED_MODEL_COMBINATION,
    payload: data,
  });
};

export const updateSelectedSegmentedModels = (models) => (dispatch) => {
  dispatch({
    type: types.ESTIMATOR_MODEL_UPDATE_SELECTED_SEGMENTED_MODELS,
    payload: models,
  });
};

export const updateSegmentedModels = (specModel, models) => (dispatch) => {
  dispatch({
    type: types.ESTIMATOR_MODEL_UPDATE_SEGMENTED_MODELS,
    payload: { specModel, models },
  });
};
