import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },

  title: {
    fontSize: 16,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function CardWithAction({ title, description, actions }) {
  const classes = useStyles();

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body2" component="p">
          {description}
        </Typography>
      </CardContent>
      <CardActions>
        {actions.map((action, i) => {
          return (
            <Button
              key={action.label}
              autoFocus
              size="small"
              color="primary"
              variant={i === 0 ? `contained` : `outlined`}
              onClick={action.callback}
            >
              {action.label}
            </Button>
          );
        })}
      </CardActions>
    </Card>
  );
}
