import * as React from 'react';
import moment from 'moment';
import { Box, Button, Container, IconButton, Popover, Grid, Typography } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import DateRangeIcon from '@material-ui/icons/DateRange';
import CloseIcon from '@material-ui/icons/Close';

const findDate = (index, availableDates) => {
  if (index < 0 || index >= availableDates.length) {
    return null;
  }
  return moment(availableDates[index], 'DD/MM/YYYY').format();
};

const findDateIndex = (date, availableDates) => {
  return availableDates.findIndex((item) => item === date);
};

export const GlobalDatePicker = ({ availableDates, handleUpdateGlobalDate, dateIndex }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [globalDateState, setGlobalDateState] = React.useState({
    startDate: findDate(dateIndex.startIndex, availableDates),
    endDate: findDate(dateIndex.endIndex, availableDates),
  });

  const onChangeStartDate = (date) => {
    setGlobalDateState({ ...globalDateState, startDate: date });
  };

  const onChangeEndDate = (date) => {
    setGlobalDateState({ ...globalDateState, endDate: date });
  };

  const onReset = () => {
    setGlobalDateState({
      startDate: findDate(0, availableDates),
      endDate: findDate(11, availableDates),
    });
  };

  const onSubmit = () => {
    const startDate = moment(globalDateState.startDate).format('DD/MM/YYYY');
    const endDate = moment(globalDateState.endDate).format('DD/MM/YYYY');

    const startIndex = findDateIndex(startDate, availableDates);
    const endIndex = findDateIndex(endDate, availableDates);

    if (startIndex > -1 && endIndex > -1 && startIndex <= endIndex) {
      handleUpdateGlobalDate({
        startIndex,
        endIndex,
        startDate,
        endDate,
      });
      handleClose();
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isDateAllowed = (date) => {
    if (!availableDates) {
      return true;
    }

    return availableDates.includes(date);
  };

  return (
    <Box>
      <IconButton aria-controls="simple-popover" aria-haspopup="true" onClick={handleClick}>
        <DateRangeIcon fontSize="24" color="primary" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Container maxWidth="xs" disableGutters>
          <Box p={2} position="relative">
            <IconButton onClick={handleClose} style={{ position: 'absolute', right: 5, top: 5 }}>
              <CloseIcon fontSize="24" color="primary" />
            </IconButton>

            <Typography variant="h6">Global Date</Typography>
            <Typography variant="body1">
              Select the start and end date to be used for all reports.
            </Typography>
            <Box mt={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                  <Typography
                    variant="body2"
                    as="span"
                    style={{
                      marginTop: '10px',
                    }}
                  >
                    Start Date
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <KeyboardDatePicker
                    value={globalDateState.startDate}
                    clearable
                    autoOk
                    placeholder="MM-DD-YYYY"
                    onChange={(date) => onChangeStartDate(date ? date.format() : null)}
                    format="MM-DD-YYYY"
                    shouldDisableDate={(date) => !isDateAllowed(date.format('DD/MM/YYYY'))}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography
                    variant="body2"
                    as="span"
                    style={{
                      marginTop: '10px',
                    }}
                  >
                    End Date
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <KeyboardDatePicker
                    value={globalDateState.endDate}
                    clearable
                    autoOk
                    placeholder="MM-DD-YYYY"
                    onChange={(date) => onChangeEndDate(date ? date.format() : null)}
                    format="MM-DD-YYYY"
                    shouldDisableDate={(date) => !isDateAllowed(date.format('DD/MM/YYYY'))}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button onClick={onReset}>
                      <Typography variant="body1">Reset</Typography>
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      disabled={!globalDateState.startDate || !globalDateState.endDate}
                      onClick={onSubmit}
                      style={{
                        marginLeft: '10px',
                      }}
                    >
                      <Typography variant="body1">Save</Typography>
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </Popover>
    </Box>
  );
};
