import chroma from 'chroma-js';

const dynamicColors = [
  '#D43F00', // Dark Red
  '#008C40', // Dark Green
  '#0042D4', // Dark Blue
  '#D4008C', // Dark Magenta
  '#8CD400', // Olive Green
  '#D47A00', // Deep Orange
  '#008C8C', // Dark Turquoise
  '#6A00D4', // Deep Purple
  '#0066A1', // Denim Blue
  '#1e4669', // Dark Blue
  '#66A100', // Moss Green
  '#838383', // Mustard Yellow
  '#A1008C', // Dark Violet
  '#00806A', // Ocean Green
  '#A18200', // Sienna
  '#6A008C', // Plum
  '#0066A1', // Steel Blue
  '#D4006A', // Berry Pink
  '#00826A', // Teal
  '#67B730',
  '#3115F0',
  '#A0E283',
  '#A701EA',
  '#562C73',
  '#6FA3E9',
  '#9F07AE',
  '#F2F7F0',
  '#6EE2BD',
  '#AB2A1D',
  '#DE9FD7',
  '#612025',
  '#3061F5',
  '#824606',
  '#89EF32',
];

const dynamicAssetColors = [
  '#8CA100', // Olive Drab
  '#A1006A', // Wine
  '#5C5C00', // Dark Olive
  '#8C0040', // Burgundy
  '#00428C', // Navy Blue
  '#408C00', // Forest Green
  '#8C0042', // Brick Red
  '#DB581A', // Slate Blue
  '#DD5748', // Dusty Rose
  '#40708C', // Storm Blue
  '#00408C', // Prussian Blue
  '#8C0066', // Mulberry
  '#002E8C', // Deep Sky Blue
  '#8C2E00', // Burnt Sienna
  '#008C5C', // Jade
  '#8C5555', // Copper
  '#55558C', // Cobalt Blue
  '#8C002E', // Cherry Red
  '#8C8C00', // Brass
  '#40558C', // Midnight Blue
  '#8C7040', // Brown
  '#0F43A1',
  '#EC4EC8',
  '#5EBDF5',
  '#1187EE',
  '#76F446',
  '#28C77A',
  '#8DF985',
  '#0439E4',
  '#C1AB4D',
  '#FFDF37',
  '#CAD3FF',
  '#339FEB',
  '#EB6FA7',
  '#8D50B9',
];

const idToColorMap = new Map();

function hashId(id) {
  let hash = 0;
  const idStr = id.toString();
  for (let i = 0; i < idStr.length; i++) {
    const char = idStr.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export function getColorById(id) {
  if (!id) {
    return 'black';
  }

  const colorID = +id;

  const colorArray = colorID > 200 ? dynamicAssetColors : dynamicColors;

  // If we've seen the ID before, return its color
  if (idToColorMap.has(id)) {
    return idToColorMap.get(id);
  }

  // Find the next available color
  let index = Math.abs(hashId(id)) % colorArray.length;
  while ([...idToColorMap.values()].includes(colorArray[index])) {
    index = (index + 1) % colorArray.length;
  }

  // Assign the color to the ID and store it in the map
  if (index && colorArray[index]) {
    idToColorMap.set(id, colorArray[index]);
  } else {
    const randomColor = [...idToColorMap.values()][Math.floor(Math.random() * idToColorMap.size)];

    const newColor = chroma(randomColor).darken().hex();

    // make sure the new color is not already in the map
    if ([...idToColorMap.values()].includes(newColor)) {
      idToColorMap.set(id, chroma(newColor).darken().hex());
    } else {
      idToColorMap.set(id, newColor);
    }
  }

  return idToColorMap.get(id);
}
