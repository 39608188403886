import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Chip from '@material-ui/core/Chip';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';

import SpecValidationErrors from '../SpecValidationErrors';
import CardWithAction from './CardWithActions';
import universeService from '../../services/UniverseService';
import { showMessage } from '../../redux/actions';
import { VERIFIED, NOT_VERIFIED } from '../../config/const';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  pending: {
    backgroundColor: '#ffeb3b',
    color: 'black',
  },
  invalid: {
    backgroundColor: '#ff3535',
    color: 'white',
    marginBottom: 5,
    marginRight: 5,
  },
  danger: {
    color: '#ff3535',
  },
  valid: {
    backgroundColor: 'green',
    color: 'white',
    marginBottom: 5,
    marginRight: 5,
  },
  loaded: {
    backgroundColor: '#1E4669',
    color: 'white',
  },
});

const useTableStyles = makeStyles({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

function Row({ row, type, loaded, isReporting, handleSpecDelete }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleDelete = async (jobID) => {
    const result = await universeService.deleteUniverse(jobID);
    if (result?.error) {
      dispatch(
        showMessage({ type: 'error', message: 'There was an error processing your request' }),
      );
    } else {
      handleSpecDelete();
      dispatch(showMessage({ type: 'success', message: 'Specification deleted successfully.' }));
    }
    handleClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'simple-popover' : undefined;

  // listArtifacts

  const status = row.status === VERIFIED ? `Verified` : `Not Verified`;

  return (
    <React.Fragment>
      <TableRow className={classes.root} selected={loaded}>
        <TableCell>
          {row.status === NOT_VERIFIED && type !== 'rankingReport' && type !== 'reporting' ? (
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          ) : null}
        </TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell>{row.description}</TableCell>
        <TableCell>{moment(row.created_at).format('MMMM Do YYYY, h:mm:ss a')}</TableCell>
        <TableCell>
          {row?.last_modified ? moment(row.last_modified).format('MMMM Do YYYY, h:mm:ss a') : ''}
        </TableCell>
        {!isReporting ? (
          <TableCell>
            <Chip
              size="small"
              label={status}
              classes={{
                root: classes[row.status === VERIFIED ? 'valid' : 'invalid'],
              }}
            />
            {loaded ? (
              <Chip
                size="small"
                label="Loaded"
                classes={{
                  root: classes.loaded,
                }}
              />
            ) : null}
          </TableCell>
        ) : null}
        <TableCell>
          {!loaded ? (
            <>
              <Tooltip title="Delete" aria-label="Delete">
                <IconButton aria-label="Delete" className={classes.danger} onClick={handleClick}>
                  <HighlightOffIcon />
                </IconButton>
              </Tooltip>
              <Popover
                id={id}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <CardWithAction
                  title={`Delete - ${row.name}`}
                  description={`Are you sure you want to delete the spec`}
                  actions={[
                    {
                      label: 'Yes',
                      callback: () => handleDelete(row.id),
                    },
                    {
                      label: 'No',
                      callback: () => handleClose(),
                    },
                  ]}
                />
              </Popover>
            </>
          ) : null}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <SpecValidationErrors id={row.id} type={type} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function CollapsibleTableSpec({ rows, type, loadedSpecId, handleSpecDelete }) {
  const classes = useTableStyles();

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('created_at');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const isReporting = type === 'rankingReport' || type === 'reporting';

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const headCells = [
    { id: 'name', disablePadding: false, label: 'Name' },
    { id: 'description', disablePadding: false, label: 'Description' },
    { id: 'created_at', disablePadding: false, label: 'Created' },
    { id: 'last_modified', disablePadding: false, label: 'Last Modified' },
    ...(!isReporting ? [{ id: 'status', disablePadding: false, label: 'Status' }] : []),
  ];

  return (
    <div>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell?.numeric ? 'right' : 'left'}
                  padding={headCell.disablePadding ? 'none' : 'default'}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <Row
                  key={row.id}
                  row={row}
                  type={type}
                  loaded={loadedSpecId === row.id}
                  handleSpecDelete={handleSpecDelete}
                  isReporting={isReporting}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}
