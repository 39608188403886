import { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import Chart from '../Chart';
import ChartInvalid from '../Chart//ChartInvalid';
import dfaService from 'services/DfaService';
import Error from '../Error';

const useStyles = makeStyles((theme) => ({
  chartPaper: {
    width: '100%',
    height: 400,
    display: 'inline-block',
    marginTop: 20,
  },
}));

const AssetPerformance = ({
  universe,
  riskFreeRate,
  startDate,
  endDate,
  investorCurrency,
  returnFrequency,
  benchmarks,
  macrofactors,
  handleClose,
}) => {
  const classes = useStyles();

  const [assets, setAssets] = useState([]);
  const [invalidAssetData, setInvalidAssetData] = useState([]);
  const [loadingUniverse, setLoadingUniverse] = useState(null);
  const [loadingAssets, setLoadingAssets] = useState(null);
  const [universeError, setUniverseError] = useState(null);
  const [assetsError, setAssetsError] = useState(null);

  const fetchUniversePerformanceData = async (data, returnFrequency) => {
    const result = await dfaService.getUniversePerformance(data, returnFrequency);
    if (!result?.error) {
      const { assets_with_returns } = result?.data;

      const returnData = [
        {
          id: 'assets',
          color: 'hsl(90, 70%, 50%)',
          data: assets_with_returns?.map((assets) => {
            return {
              x: assets.date,
              y: assets.count,
              count: assets.count,
            };
          }),
        },
      ];
      setUniverseError(null);
      setAssets(returnData);
    } else {
      const error = result?.data?.detail;
      setUniverseError(error);
    }
    setLoadingUniverse(false);
  };

  useEffect(() => {
    let isSubscribed = true;
    const data = {
      universe_id: universe?.id,
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).format('YYYY-MM-DD'),
      currency_asset_id: investorCurrency?.id,
    };
    // TODO validate DATA
    if (
      isSubscribed &&
      returnFrequency &&
      data?.universe_id &&
      data.start_date &&
      data.end_date &&
      data.currency_asset_id
    ) {
      setLoadingUniverse(true);
      fetchUniversePerformanceData(data, returnFrequency);
    } else setAssets([]);
    return () => {
      isSubscribed = false;
    };
  }, [universe?.id, startDate, endDate, investorCurrency?.id, returnFrequency]);

  const fetchAssetPerformanceData = async (data, returnFrequency) => {
    const result = await dfaService.getAssetPerformance(data, returnFrequency);

    if (!result?.error) {
      const { invalid_performance } = result?.data;
      const returnData = invalid_performance?.map((assets) => {
        const assetsSum =
          assets.invalid_benchmark_count +
          assets.invalid_macrofactor_count +
          assets.invalid_risk_free_rate_count;

        return {
          day: assets.date,
          value: assetsSum,
          info: assets,
        };
      });
      setAssetsError(null);
      setInvalidAssetData(returnData);
    } else {
      const error = result?.data?.detail;
      setAssetsError(error);
    }
    setLoadingAssets(false);
  };

  useEffect(() => {
    let isSubscribed = true;

    const macrofactor_ids = macrofactors.map((macrofactor) => macrofactor.id);
    const benchmark_ids = benchmarks.map((benchmark) => benchmark.id);

    const data = {
      macrofactor_ids,
      benchmark_ids,
      risk_free_rate_id: riskFreeRate?.id,
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).format('YYYY-MM-DD'),
      // currency_asset_id: investorCurrency?.id || preferenceData?.investorCurrency?.id,
    };
    // TODO validate DATA
    if (
      isSubscribed &&
      returnFrequency &&
      data?.macrofactor_ids?.length > 0 &&
      data?.benchmark_ids?.length > 0 &&
      data.risk_free_rate_id &&
      data.start_date &&
      data.end_date
    ) {
      setLoadingAssets(true);
      fetchAssetPerformanceData(data, returnFrequency);
    } else setInvalidAssetData([]);
    return () => {
      isSubscribed = false;
    };
  }, [riskFreeRate?.id, startDate, endDate, macrofactors, benchmarks, returnFrequency]);

  const years = moment(endDate).diff(startDate, 'years', false);

  return (
    <>
      <div style={{ position: 'fixed', right: 20, top: 10, zIndex: 9999 }}>
        <IconButton
          color="primary"
          variant="outlined"
          aria-label="upload picture"
          component="span"
          style={{ backgroundColor: '#FAFCFE' }}
          onClick={() => handleClose(false)}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h6" as="h5">
              Asset Performance Availability
            </Typography>
          </Box>
          <Box mb={5}>
            {loadingUniverse ? (
              <Skeleton variant="rect" height={400} />
            ) : (
              <>
                {universeError ? (
                  <Error error={universeError} />
                ) : (
                  <>
                    {assets.length > 0 ? (
                      <Paper elevation={4} className={classes.chartPaper}>
                        <Chart data={assets} />
                      </Paper>
                    ) : null}
                  </>
                )}
              </>
            )}
          </Box>
          <Box mb={5}>
            <Typography variant="h6" as="h5">
              Missing Benchmark and Macro Factor Data
            </Typography>
            {loadingAssets ? (
              <Skeleton variant="rect" height={400} />
            ) : (
              <>
                {assetsError ? (
                  <Error error={assetsError} />
                ) : (
                  <>
                    {invalidAssetData?.length > 0 ? (
                      <Paper
                        elevation={4}
                        className={classes.chartPaper}
                        style={{ height: years ? years * 200 : 200 }}
                      >
                        <ChartInvalid
                          data={invalidAssetData}
                          startDate={moment(startDate).format('YYYY-MM-DD')}
                          endDate={moment(endDate).format('YYYY-MM-DD')}
                        />
                      </Paper>
                    ) : null}
                  </>
                )}
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default AssetPerformance;
