import * as React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Chip from '@material-ui/core/Chip';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ReplayIcon from '@material-ui/icons/Replay';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

import CardWithActions from 'components/CardWithActions';
import jobsService from 'services/JobsService';
import { updatePagination, showMessage } from 'redux/actions';
import ArtifactsByCategory from 'components/ArtifactsByCategory';
import ComponentsInfo from 'components/ComponentsInfo';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  pending: {
    backgroundColor: '#ffeb3b',
    color: 'black',
  },
  Failed: {
    backgroundColor: '#ff3535',
    color: 'white',
  },
  danger: {
    color: '#ff3535',
  },
  Succeeded: {
    backgroundColor: 'green',
    color: 'white',
  },
});

const useTableStyles = makeStyles({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  rootPaper: {
    padding: '2px 4px',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
});

function Row(props) {
  const dispatch = useDispatch();
  const { row } = props;
  const [collapse, setCollapse] = React.useState({ open: false, info: null });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElRetry, setAnchorElRetry] = React.useState(null);
  const [anchorElDelete, setAnchorElDelete] = React.useState(null);

  const [isDeleted, setIsDeleted] = React.useState(false);
  const classes = useRowStyles();

  const handleCollapse = (info) => {
    setCollapse((prevState) => {
      return {
        open: prevState.info === info ? !collapse.open : true,
        info,
      };
    });
  };

  const handleTerminateJob = async (jobID) => {
    const result = await jobsService.terminateJob(jobID);
    if (result?.error) {
      dispatch(
        showMessage({ type: 'error', message: 'There was an error processing your request' }),
      );
    } else dispatch(showMessage({ type: 'success', message: 'Job was terminated successfully.' }));
    handleClose();
  };

  const handleDeleteJob = async (jobID) => {
    const result = await jobsService.deleteJob(jobID);
    if (result?.error) {
      dispatch(
        showMessage({ type: 'error', message: 'There was an error processing your request' }),
      );
    } else {
      setIsDeleted(true);
      dispatch(showMessage({ type: 'success', message: 'Job was deleted successfully.' }));
    }
    handleCloseDelete();
  };

  const handleRetryJob = async (jobID) => {
    const result = await jobsService.retryJob(jobID);
    if (result?.error) {
      dispatch(
        showMessage({ type: 'error', message: 'There was an error processing your request' }),
      );
    } else dispatch(showMessage({ type: 'success', message: 'Job was submitted successfully.' }));
    handleCloseRetry();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'terminate-popover' : undefined;

  const handleClickRetry = (event) => {
    setAnchorElRetry(event.currentTarget);
  };

  const handleCloseRetry = () => {
    setAnchorElRetry(null);
  };

  const openPopoverRetry = Boolean(anchorElRetry);
  const idRetry = openPopoverRetry ? 'retry-popover' : undefined;

  const handleClickDelete = (event) => {
    setAnchorElDelete(event.currentTarget);
  };

  const handleCloseDelete = () => {
    setAnchorElDelete(null);
  };

  const openPopoverDelete = Boolean(anchorElDelete);
  const idDelete = openPopoverDelete ? 'delete-popover' : undefined;

  return isDeleted ? null : (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleCollapse('artifacts')}
          >
            {collapse.open && collapse.info === 'artifacts' ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell
          style={{
            verticalAlign: 'top',
            wordWrap: 'anywhere',
          }}
        >
          <Link component={RouterLink} to={`/reports/${row.id}`}>
            {row.name}
          </Link>
        </TableCell>
        <TableCell
          style={{
            verticalAlign: 'top',
            wordWrap: 'anywhere',
          }}
        >
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            dangerouslySetInnerHTML={{ __html: row.description }}
            style={{
              wordWrap: 'anywhere',
            }}
          />
        </TableCell>
        <TableCell>{row.one_off_run === true ? 'Yes' : 'No'}</TableCell>
        <TableCell>{moment(row.created_at).format('MMMM Do YYYY, h:mm:ss a')}</TableCell>
        <TableCell>
          {row?.finished_at ? moment(row.finished_at).format('MMMM Do YYYY, h:mm:ss a') : ''}
        </TableCell>
        <TableCell>
          <Chip
            size="small"
            label={row.status || 'Pending'}
            classes={{
              root: classes[row?.status ? row.status : 'pending'],
            }}
          />
          {row.status === 'Failed' || row.status === 'Error' ? (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => handleCollapse('components')}
            >
              {collapse.open && collapse.info === 'components' ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          ) : null}
        </TableCell>
        <TableCell>
          <Box display="flex">
            {row?.status === 'Running' ? (
              <>
                <Tooltip title="Kill Job" aria-label="Kill Job">
                  <IconButton
                    aria-label="Kill Job"
                    className={classes.danger}
                    onClick={handleClick}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Tooltip>
                <Popover
                  id={id}
                  open={openPopover}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <CardWithActions
                    title={`Kill - ${row.name}`}
                    description={`Are you sure you want to kill the job`}
                    actions={[
                      {
                        label: 'Yes',
                        callback: () => handleTerminateJob(row.id),
                      },
                      {
                        label: 'No',
                        callback: () => handleClose(),
                      },
                    ]}
                  />
                </Popover>
              </>
            ) : null}

            {row.status !== 'Running' && row.status ? (
              <>
                <Tooltip title="Retry Job" aria-label="Retry Job">
                  <IconButton aria-label="Retry Job" color="primary" onClick={handleClickRetry}>
                    <ReplayIcon />
                  </IconButton>
                </Tooltip>
                <Popover
                  id={idRetry}
                  open={openPopoverRetry}
                  anchorEl={anchorElRetry}
                  onClose={handleCloseRetry}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <CardWithActions
                    title={`Retry - ${row.name}`}
                    description={`Are you sure you want to retry the job`}
                    actions={[
                      {
                        label: 'Yes',
                        callback: () => handleRetryJob(row.id),
                      },
                      {
                        label: 'No',
                        callback: () => handleCloseRetry(),
                      },
                    ]}
                  />
                </Popover>
              </>
            ) : null}
            {/* Delete */}
            <Tooltip title="Delete Job" aria-label="Delete Job">
              <IconButton
                aria-label="Delete Job"
                className={classes.danger}
                onClick={handleClickDelete}
              >
                <DeleteForeverIcon />
              </IconButton>
            </Tooltip>
            <Popover
              id={idDelete}
              open={openPopoverDelete}
              anchorEl={anchorElDelete}
              onClose={handleCloseDelete}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <CardWithActions
                title={`Delete - ${row.name}`}
                description={`Are you sure you want to Delete the job`}
                actions={[
                  {
                    label: 'Yes',
                    callback: () => handleDeleteJob(row.id),
                  },
                  {
                    label: 'No',
                    callback: () => handleCloseDelete(),
                  },
                ]}
              />
            </Popover>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingTop: 0 }} colSpan={7}>
          <Collapse in={collapse.open} timeout="auto" unmountOnExit>
            {collapse.info === 'artifacts' ? (
              <ArtifactsByCategory id={row.id} open={collapse.open} />
            ) : null}
            {collapse.info === 'components' ? (
              <ComponentsInfo id={row.id} open={collapse.open} />
            ) : null}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function CollapsibleTable({ jobs, total, page, pagination, isLoading }) {
  const classes = useTableStyles();
  const dispatch = useDispatch();

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('created_at');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    // setPage(newPage);
    const newPagination = {
      ...pagination,
      page: newPage,
    };
    dispatch(updatePagination(newPagination));
  };

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
    const newPagination = {
      ...pagination,
      size: event.target.value,
      page: 0,
    };
    dispatch(updatePagination(newPagination));
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const searchInput = React.useRef(null);

  const handleSearch = () => {
    const newPagination = {
      ...pagination,
      search: searchInput.current.value,
      page: 0,
    };
    dispatch(updatePagination(newPagination));
  };

  const resetSearch = () => {
    const newPagination = {
      ...pagination,
      search: '',
      page: 0,
    };
    dispatch(updatePagination(newPagination));
    searchInput.current.value = '';
  };

  const headCells = [
    { id: 'name', disablePadding: false, label: 'Name', width: '250px' },
    { id: 'description', disablePadding: false, label: 'Description', width: '300px' },
    { id: 'one-off-run', disablePadding: false, label: 'One-off run' },
    { id: 'created_at', disablePadding: false, label: 'Created' },
    { id: 'finished_at', disablePadding: false, label: 'Finished' },
    { id: 'status', disablePadding: false, label: 'Status' },
    { id: 'actions', disablePadding: false, label: '' },
  ];

  return (
    <Box>
      <Box
        display="flex"
        flex="1"
        justifyContent="space-between"
        alignItems="center"
        style={{ marginBottom: 20 }}
      >
        <Box display="flex" flex="1">
          <Paper classes={{ root: classes.rootPaper }}>
            <InputBase
              className={classes.input}
              placeholder={`Search ${total} jobs`}
              inputProps={{ 'aria-label': 'Search' }}
              inputRef={searchInput}
              style={{ width: '100%', padding: '5px 10px' }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  handleSearch();
                }
              }}
            />
            <IconButton onClick={handleSearch} className={classes.iconButton} aria-label="search">
              <SearchIcon />
            </IconButton>
            <Divider className={classes.divider} orientation="vertical" />
            <IconButton
              onClick={resetSearch}
              color="primary"
              className={classes.iconButton}
              aria-label="clear"
            >
              <ClearIcon />
            </IconButton>
          </Paper>
        </Box>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={total}
          rowsPerPage={pagination.size}
          page={pagination.page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Box>

      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  variant="head"
                  align={headCell?.numeric ? 'right' : 'left'}
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                  sortDirection={orderBy === headCell.id ? order : false}
                  style={{
                    width: headCell?.width || 'auto', // Set a fixed width if provided
                    ...(headCell?.width
                      ? { maxWidth: headCell?.width, minWidth: headCell?.width }
                      : {}),
                  }}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow className={classes.root}>
                <TableCell colSpan={7}>
                  {[...Array(pagination.size)].map((e, i) => (
                    <Skeleton
                      key={i}
                      variant="rect"
                      width="100%"
                      height={50}
                      style={{ marginBottom: 10 }}
                    />
                  ))}
                </TableCell>
              </TableRow>
            ) : (
              <>
                {stableSort(jobs, getComparator(order, orderBy)).map((job) => (
                  <Row key={job.id} row={job} />
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={total}
        rowsPerPage={pagination.size}
        page={pagination.page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Box>
  );
}
