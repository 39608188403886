/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { HelpInfo } from 'components/HelpInfo';
import Switch from '@material-ui/core/Switch';
import MUISwitch from 'components/MUISwitch';
import CardWithActions from 'components/CardWithActions';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import { KeyboardDatePicker } from '@material-ui/pickers';

const ReportingPreferences = ({
  showOverWrite = false,
  showUseHistoricalLearning = false,
  showOneOffRun = false,
}) => {
  const { control, register, watch } = useFormContext();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const enableSpecUpdateValue = watch('enableSpecUpdate');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'terminate-popover' : undefined;

  const popupContent = (
    <Box>
      <Typography variant="body1">
        Are you sure you don't want to use historical learning?
      </Typography>
      <Typography variant="body1">
        Clicking '<strong>YES</strong>' may greatly increase the time to completion for large
        simulations.
      </Typography>
    </Box>
  );

  return (
    <Grid container spacing={3}>
      {showUseHistoricalLearning ? (
        <>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Box display="flex" justifyContent="start" alignItems="center">
                  <Typography variant="h6" component="span">
                    Use historical learning
                  </Typography>
                  <HelpInfo name="useHistoricalLearning" />
                </Box>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Controller
                  name="useHistoricalLearning"
                  control={control}
                  render={(props) => {
                    return (
                      <>
                        <Switch
                          onChange={(e) => {
                            if (props?.value === false) {
                              props.onChange(e.target.checked);
                            } else {
                              handleClick(e);
                            }
                          }}
                          checked={props?.value === false ? false : true}
                          value={props?.value === false ? false : true}
                          color="primary"
                          defaultCheck={true}
                        />
                        <Popover
                          id={id}
                          open={openPopover}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                        >
                          <CardWithActions
                            title={`Historical learning`}
                            description={popupContent}
                            actions={[
                              {
                                label: 'Yes',
                                callback: () => {
                                  props.onChange(false);
                                  handleClose();
                                },
                              },
                              {
                                label: 'No',
                                callback: () => {
                                  props.onChange(true);
                                  handleClose();
                                },
                              },
                            ]}
                          />
                        </Popover>
                      </>
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : null}
      {showOneOffRun ? (
        <>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Box display="flex" justifyContent="start" alignItems="center">
                  <Typography variant="h6" component="span">
                    One-off run
                  </Typography>
                  <HelpInfo name="oneOffRun" />
                </Box>
              </Grid>
              <Grid item xs={12} sm={8}>
                <MUISwitch register={register} name="oneOffRun" />
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : null}

      {showOverWrite ? (
        <>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Typography variant="h6" component="span">
                  Enable Spec Update
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <MUISwitch
                  register={register}
                  defaultCheck={enableSpecUpdateValue ? true : false}
                  name="enableSpecUpdate"
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : null}

      {enableSpecUpdateValue ? (
        <>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
                <Typography variant="h6" as="span">
                  Last Data Date
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Controller
                  control={control}
                  name="lastDataDate"
                  render={({ onChange, onBlur, name, value }) => (
                    <KeyboardDatePicker
                      // className={classes.datePicker}
                      name={name}
                      value={value}
                      clearable
                      autoOk
                      placeholder="MM-DD-YYYY"
                      onChange={(date) => onChange(date ? date.format() : null)}
                      onBlur={(value) => onBlur(value ? value : null)}
                      format="MM-DD-YYYY"
                      disabled={!enableSpecUpdateValue}
                    />
                  )}
                />
                <FormControlLabel
                  style={{ marginLeft: 20 }}
                  control={
                    <Controller
                      name="overwriteLastDataDate"
                      defaultValue={false}
                      disabled={!enableSpecUpdateValue}
                      control={control}
                      render={(props) => (
                        <Checkbox
                          disabled={!enableSpecUpdateValue}
                          onChange={(e) => {
                            props.onChange(e.target.checked);
                          }}
                          checked={props.value && enableSpecUpdateValue}
                        />
                      )}
                    />
                  }
                  label={`Overwrite Specification`}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
                <Typography variant="h6" as="span">
                  Last Vintage Date
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Controller
                  control={control}
                  name="lastVintageDate"
                  render={({ onChange, onBlur, name, value }) => (
                    <KeyboardDatePicker
                      // className={classes.datePicker}
                      name={name}
                      value={value}
                      clearable
                      autoOk
                      placeholder="MM-DD-YYYY"
                      onChange={(date) => onChange(date ? date.format() : null)}
                      onBlur={(value) => onBlur(value ? value : null)}
                      format="MM-DD-YYYY"
                      disabled={!enableSpecUpdateValue}
                    />
                  )}
                />
                <FormControlLabel
                  style={{ marginLeft: 20 }}
                  control={
                    <Controller
                      name="overwriteLastVintageDate"
                      defaultValue={false}
                      disabled={!enableSpecUpdateValue}
                      control={control}
                      render={(props) => (
                        <Checkbox
                          disabled={!enableSpecUpdateValue}
                          onChange={(e) => {
                            props.onChange(e.target.checked);
                          }}
                          checked={props.value}
                        />
                      )}
                    />
                  }
                  label={`Overwrite Specification`}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};

export default ReportingPreferences;
