import * as React from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import CachedIcon from '@material-ui/icons/Cached';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ReplayIcon from '@material-ui/icons/Replay';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DescriptionIcon from '@material-ui/icons/Description';

import CardWithActions from 'components/CardWithActions';
import { SpecDialog } from 'components/SpecDialog';
import jobsService from 'services/JobsService';
import { showMessage } from 'redux/actions';

const useStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  pending: {
    backgroundColor: '#ffeb3b',
    color: 'black',
  },
  Failed: {
    backgroundColor: '#ff3535',
    color: 'white',
  },
  danger: {
    color: '#ff3535',
  },
  Succeeded: {
    backgroundColor: 'green',
    color: 'white',
  },
});

const ReportStatus = ({ report, handleRefresh }) => {
  const classes = useStyles();
  const [openSpec, setOpenSpec] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElRetry, setAnchorElRetry] = React.useState(null);
  const [anchorElDelete, setAnchorElDelete] = React.useState(null);
  const [isDeleted, setIsDeleted] = React.useState(false);
  const dispatch = useDispatch();

  const handleRefreshClick = () => {
    handleRefresh(report.id);
    handleCloseMenu();
  };

  const handleTerminateJob = async (jobID) => {
    const result = await jobsService.terminateJob(jobID);
    if (result?.error) {
      dispatch(
        showMessage({ type: 'error', message: 'There was an error processing your request' }),
      );
    } else dispatch(showMessage({ type: 'success', message: 'Job was terminated successfully.' }));
    handleCloseKillJob();
  };

  const handleDeleteJob = async (jobID) => {
    const result = await jobsService.deleteJob(jobID);
    if (result?.error) {
      dispatch(
        showMessage({ type: 'error', message: 'There was an error processing your request' }),
      );
    } else {
      setIsDeleted(true);
      dispatch(showMessage({ type: 'success', message: 'Job was deleted successfully.' }));
    }
    handleCloseDelete();
  };

  const handleRetryJob = async (jobID) => {
    const result = await jobsService.retryJob(jobID);
    if (result?.error) {
      dispatch(
        showMessage({ type: 'error', message: 'There was an error processing your request' }),
      );
    } else dispatch(showMessage({ type: 'success', message: 'Job was submitted successfully.' }));
    handleCloseRetry();
  };

  const [anchorElMenu, setAnchorElMenu] = React.useState(null);

  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const handleClickKillJob = (event) => {
    setAnchorEl(event.currentTarget);
    handleCloseMenu();
  };
  const handleCloseKillJob = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const idPopover = openPopover ? 'terminate-popover' : undefined;

  const handleClickRetry = (event) => {
    setAnchorElRetry(event.currentTarget);
    handleCloseMenu();
  };

  const handleCloseRetry = () => {
    setAnchorElRetry(null);
  };

  const openPopoverRetry = Boolean(anchorElRetry);
  const idRetry = openPopoverRetry ? 'retry-popover' : undefined;

  const handleClickDelete = (event) => {
    setAnchorElDelete(event.currentTarget);
  };

  const handleCloseDelete = () => {
    setAnchorElDelete(null);
  };

  const openPopoverDelete = Boolean(anchorElDelete);
  const idDelete = openPopoverDelete ? 'delete-popover' : undefined;

  const handleClickOpenSpec = () => {
    setOpenSpec(true);
    handleCloseMenu();
  };

  const handleCloseSpec = () => {
    setOpenSpec(false);
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box>
        <Chip
          size="small"
          label={report?.status || 'Pending'}
          classes={{
            root: classes[report?.status ? report.status : 'pending'],
          }}
        />
      </Box>
      <Box>
        <IconButton
          aria-label="more"
          aria-controls="report-menu"
          aria-haspopup="true"
          size="small"
          onClick={handleClickMenu}
        >
          <MoreVertIcon />
        </IconButton>

        <Menu
          id="simple-menu"
          anchorEl={anchorElMenu}
          keepMounted
          open={Boolean(anchorElMenu)}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={handleRefreshClick}>
            <CachedIcon fontSize="small" style={{ marginRight: 8 }} />
            Refresh
          </MenuItem>
          <MenuItem onClick={handleClickOpenSpec}>
            <DescriptionIcon fontSize="small" style={{ marginRight: 8 }} />
            Spec details
          </MenuItem>
          {report?.status === 'Running' ? (
            <MenuItem onClick={handleClickKillJob} className={classes.danger}>
              <HighlightOffIcon fontSize="small" style={{ marginRight: 8 }} />
              Kill Job
            </MenuItem>
          ) : null}
          {report?.status !== 'Running' && report?.status ? (
            <MenuItem onClick={handleClickRetry}>
              <ReplayIcon fontSize="small" style={{ marginRight: 8 }} />
              Retry Job
            </MenuItem>
          ) : null}
          <MenuItem onClick={handleClickDelete} className={classes.danger}>
            <DeleteForeverIcon fontSize="small" style={{ marginRight: 8 }} />
            Delete Job
          </MenuItem>
        </Menu>

        {report?.status === 'Running' ? (
          <>
            <Popover
              id={idPopover}
              open={openPopover}
              anchorEl={anchorEl}
              onClose={handleCloseKillJob}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <CardWithActions
                title={`Kill - ${report?.name}`}
                description={`Are you sure you want to kill the job`}
                actions={[
                  {
                    label: 'Yes',
                    callback: () => handleTerminateJob(report?.id),
                  },
                  {
                    label: 'No',
                    callback: () => handleCloseKillJob(),
                  },
                ]}
              />
            </Popover>
          </>
        ) : null}

        {report?.status !== 'Running' && report?.status ? (
          <>
            <Popover
              id={idRetry}
              open={openPopoverRetry}
              anchorEl={anchorElRetry}
              onClose={handleCloseRetry}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <CardWithActions
                title={`Retry - ${report?.name}`}
                description={`Are you sure you want to retry the job`}
                actions={[
                  {
                    label: 'Yes',
                    callback: () => handleRetryJob(report?.id),
                  },
                  {
                    label: 'No',
                    callback: () => handleCloseRetry(),
                  },
                ]}
              />
            </Popover>
          </>
        ) : null}
        {/* Delete */}

        <Popover
          id={idDelete}
          open={openPopoverDelete}
          anchorEl={anchorElDelete}
          onClose={handleCloseDelete}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <CardWithActions
            title={`Delete - ${report?.name}`}
            description={`Are you sure you want to Delete the job`}
            actions={[
              {
                label: 'Yes',
                callback: () => handleDeleteJob(report?.id),
              },
              {
                label: 'No',
                callback: () => handleCloseDelete(),
              },
            ]}
          />
        </Popover>
      </Box>
      <SpecDialog open={openSpec} onClose={handleCloseSpec} data={report?.spec} id={null} />
    </Box>
  );
};

export default ReportStatus;
