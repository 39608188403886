import { ResponsiveCalendar } from '@nivo/calendar';

const ChartInvalid = ({ data, startDate, endDate }) => {
  const customColorScale = (value) => {
    const colorScale = (value) => {
      return value > 0 ? '#ff1744' : '#4caf50';
    };

    return colorScale(value);
  };

  return (
    <ResponsiveCalendar
      data={data}
      from={startDate}
      to={endDate}
      emptyColor="#eeeeee"
      colorScale={customColorScale}
      margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
      yearSpacing={40}
      monthBorderColor="#ffffff"
      monthSpacing={25}
      dayBorderWidth={2}
      dayBorderColor="#ffffff"
      tooltip={(input) => {
        return (
          <div
            style={{
              padding: 15,
              backgroundColor: 'white',
              boxShadow:
                ' 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
            }}
          >
            <strong>{input.data.info.date}</strong>
            <div style={{ fontWeight: 500 }}>Missing data count</div>
            <div>Benchmark: {input.data.info.invalid_benchmark_count}</div>
            <div>Macro Factor: {input.data.info.invalid_macrofactor_count}</div>
            <div>Risk Free Rate: {input.data.info.invalid_risk_free_rate_count}</div>
          </div>
        );
      }}
    />
  );
};

export default ChartInvalid;
