import React from 'react';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Grid, TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormAutoComplete from '../FormAutoComplete';
import MUISwitch from '../MUISwitch';
import { HelpInfo } from '../HelpInfo';

const useStyles = makeStyles((theme) => ({
  input: {
    width: 100,
    backgroundColor: theme.palette.background.default,
    borderRadius: 4,
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      border: 0,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 0,
      },
      '&:hover fieldset': {
        border: 0,
      },
      '&.Mui-focused fieldset': {
        border: 0,
      },
    },
  },
}));

const AssetUniverse = ({ universeList }) => {
  const { control, register, watch } = useFormContext();

  const classes = useStyles();
  const allowShortSalesValue = watch('allowShortSales', true);
  const { overrideFeeWithDefault } = useSelector((state) => state.dataForAnalysis);
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" as="h5">
            Select Universe
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormAutoComplete options={universeList} name="universe" />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="span">
            Allow short sales?
          </Typography>
          <MUISwitch
            register={register}
            defaultCheck={allowShortSalesValue || false}
            name="allowShortSales"
          />
        </Grid>
        {allowShortSalesValue && (
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" as="h5">
                  Select Short Universe
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormAutoComplete options={universeList} name="shortUniverse" />
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Typography variant="h6" component="span">
              Override fee with default
            </Typography>
            <HelpInfo name="overrideFeeWithDefault" />

            <MUISwitch
              register={register}
              defaultCheck={overrideFeeWithDefault || false}
              name="overrideFeeWithDefault"
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="body1" component="span">
                Default fee (prior)
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                id="standard-basic"
                defaultValue="1.25"
                render={(props) => (
                  <TextField
                    {...props}
                    className={classes.input}
                    elevation={4}
                    variant="outlined"
                    inputProps={{
                      type: 'number',
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                  />
                )}
                name="defaultFeePrior"
                control={control}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AssetUniverse;
