const styles = (theme) => ({
  root: {
    width: '100%',
    padding: 0,
  },
  heading: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightRegular,
  },
  button: {
    margin: theme.spacing(1),
  },
});

export default styles;
