import React from 'react';
import { PieChart, Pie, Sector, Cell, Legend, Tooltip } from 'recharts';
import { Box, Button, Typography } from '@material-ui/core';
import { SelectBox } from './SelectBox';
import GetAppIcon from '@material-ui/icons/GetApp';

export const COLORS = [
  '#4986e8',
  '#71ac47',
  '#ec7d30',
  '#fec100',
  '#cc0000',
  '#f0f',
  '#664fa7',
  '#a5a5a5',
  '#b55f06',
  '#10505b',
  '#063763',
];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  const value = Math.round(percent * 100);

  return value > 1 ? (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${value}%`}
    </text>
  ) : null;
};

const renderLegend = (props) => {
  const { payload } = props;

  return (
    <ul
      style={{
        height: 'auto',
        paddingBottom: 10,
        maxHeight: '100%',
      }}
    >
      {payload.map((entry, index) => (
        <li key={`item-${index}`} style={{ color: entry.color }}>
          {entry.value} ({entry.payload.value.toFixed(2)}%)
        </li>
      ))}
    </ul>
  );
};

const CustomTooltip = (props) => {
  const { active, payload } = props;

  if (active && payload && payload.length) {
    return (
      <Box className="custom-tooltip" style={{ background: 'white', borderRadius: '5px' }}>
        <Box style={{ padding: 10, border: '1px solid #e6e6e6' }}>
          {payload.map((item) => (
            <Typography key={item.id} variant="body1" style={{ fontSize: 14, fontWeight: 600 }}>
              {item?.id ? item.id : item.name}:{' '}
              <span style={{ fontWeight: 700 }}>
                {typeof item.value === 'number' ? Math.round(item.value) : item.value}
                {item?.unit ? item.unit : '%'}
                {`  `}(
                {typeof item.formattedValue === 'number' ? item.value.toFixed(2) : item.value}
                {item?.unit ? item.unit : '%'})
              </span>
            </Typography>
          ))}
        </Box>
      </Box>
    );
  }

  return null;
};

export const ParalaPieChart = ({
  title,
  description,
  pieCharData,
  onDownloadClick,
  isLoading,
  downloadView,
  selectBox = null,
}) => {
  const pieData = pieCharData || [];

  return (
    <Box display="flex" flex="1" flexDirection="column">
      <Box display="flex" flex="1" alignItems="center" justifyContent="space-between">
        {selectBox && !downloadView ? (
          <Box>
            <SelectBox {...selectBox} />
          </Box>
        ) : null}
        <Box>
          <Typography variant="h3" style={{ fontSize: 21, marginBottom: 10 }}>
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {description}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          {downloadView ? null : (
            <Button
              variant="contained"
              color="secondary"
              startIcon={<GetAppIcon />}
              onClick={onDownloadClick}
              disabled={isLoading}
              style={{
                marginLeft: 10,
              }}
            >
              {isLoading ? 'Saving...' : 'PNG'}
            </Button>
          )}
        </Box>
      </Box>
      <Box display="flex" flex="1" alignItems="center">
        <PieChart width={1000} height={500}>
          <Pie
            data={pieData}
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={200}
            minAngle={1}
            dataKey="value"
            nameKey="name"
          >
            {pieData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.fill} />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
          <Legend
            verticalAlign="top"
            layout="vertical"
            align="right"
            iconType="circle"
            height="auto"
            content={renderLegend}
            wrapperStyle={{
              height: 'auto',
              maxHeight: '100%',
              ...(downloadView ? {} : { overflow: 'auto', overflowY: 'scroll' }),
            }}
          />
        </PieChart>
      </Box>
    </Box>
  );
};
