import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import jobsService from '../../services/JobsService';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
  },
  listText: {
    wordWrap: 'break-word',
  },
}));

const ArtifactList = ({ artifacts, jobID, section }) => {
  const classes = useStyles();
  const [selectedArtifacts, setSelectedArtifacts] = React.useState([]);
  const [downloadStarted, setDownloadStarted] = React.useState(false);

  const handleToggle = (value) => () => {
    const currentIndex = selectedArtifacts.indexOf(value);
    const newArtifacts = [...selectedArtifacts];

    if (currentIndex === -1) {
      newArtifacts.push(value);
    } else {
      newArtifacts.splice(currentIndex, 1);
    }

    setSelectedArtifacts(newArtifacts);
  };

  const handleSelectAll = () => {
    artifacts?.length === selectedArtifacts?.length
      ? setSelectedArtifacts([])
      : setSelectedArtifacts(artifacts);
  };

  const download = (blob, filename) => {
    if (!blob || !filename) return;

    const url = window.URL.createObjectURL(blob);
    const link = window.document.createElement('a');

    link.href = url;
    link.download = filename;
    window.document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    setDownloadStarted(false);
  };
  const handleDownloadReports = async () => {
    const saveFileName = section ? `${section}_${jobID}_reports.zip` : `reports_${jobID}.zip`;
    setDownloadStarted(true);
    const result = await jobsService.downloadArtifacts(jobID, selectedArtifacts);
    const blob = new Blob([result]);
    download(blob, saveFileName);
  };

  return (
    <Box>
      <Grid container spacing={3} className={classes.root}>
        {artifacts.map((artifact) => {
          const labelId = `checkbox-download-${artifact}`;

          return (
            <Grid item xs={6} sm={6} md={4} lg={4}>
              <ListItem
                className={classes.listItem}
                key={artifact}
                role={undefined}
                dense
                button
                onClick={handleToggle(artifact)}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={selectedArtifacts.indexOf(artifact) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText className={classes.listText} id={labelId} primary={artifact} />
              </ListItem>
            </Grid>
          );
        })}
      </Grid>
      {artifacts.length > 0 ? (
        <Grid container spacing={3} className={classes.root}>
          <Grid item xs={3}>
            <ListItem
              className={classes.listItem}
              role={undefined}
              dense
              button
              onClick={() => handleSelectAll()}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={artifacts?.length === selectedArtifacts?.length}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': 'Select All' }}
                />
              </ListItemIcon>
              <ListItemText className={classes.listText} id={`select-all`} primary={`Select All`} />
            </ListItem>
          </Grid>
          <Grid item xs={9}>
            <Button
              mt={2}
              onClick={() => handleDownloadReports()}
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<CloudDownloadIcon />}
              disabled={selectedArtifacts?.length === 0 || downloadStarted}
            >
              {downloadStarted
                ? `Downloading Reports`
                : `Download ${selectedArtifacts?.length ? selectedArtifacts?.length : ``}
            ${selectedArtifacts?.length > 1 ? `Reports` : `Report`}`}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={3} className={classes.root}>
          <Grid item xs={12}>
            <Typography variant="body2" style={{ paddingTop: 10 }}>
              No artifacts found.
            </Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ArtifactList;
