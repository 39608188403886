import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import SettingsIcon from '@material-ui/icons/Settings';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import MultiAssetConstraintDialog from './MultiAssetConstraintDialog';
import { addMultiAssetConstraintWeight, removeMultiAssetConstraint } from '../../redux/actions';

// TODO check https://react-window.now.sh/#/examples/list/memoized-list-items

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: 100,
    maxWidth: 510,
    backgroundColor: theme.palette.background.paper,
  },
  rootBox: {
    width: '100%',
    maxWidth: 510,
  },
}));

const SelectedMultiAssetConstraint = ({ listItems }) => {
  const classes = useStyles();
  const [showSetWeights, SetShowSetWeights] = useState(true);
  const [checked, setChecked] = useState([]);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    checked?.length > 0 ? SetShowSetWeights(false) : SetShowSetWeights(true);
  }, [checked]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleSubmit = (minMaxValues, items) => {
    dispatch(addMultiAssetConstraintWeight(minMaxValues, items));
    setChecked([]);
  };

  const handleRemove = (item) => {
    dispatch(removeMultiAssetConstraint(item));
    handleToggle(item);
  };

  const openDialog = (status) => {
    setDialogIsOpen(status);
  };

  return (
    <>
      <MultiAssetConstraintDialog
        isOpen={dialogIsOpen}
        close={openDialog}
        items={checked}
        handleDelete={handleToggle}
        handleSave={handleSubmit}
      />
      <Box mb={2} textAlign="right" className={classes.rootBox}>
        <Button
          variant="contained"
          size="small"
          startIcon={<SettingsIcon />}
          disabled={showSetWeights}
          onClick={() => openDialog(true)}
        >
          set weights
        </Button>
      </Box>
      {listItems?.length > 0 ? (
        <Paper className={classes.root}>
          <List>
            {listItems.map((item) => {
              const secondaryText = item?.weight
                ? `Apply Net Constraint:${
                    item?.weight?.applyNetConstraint ? 'True' : 'False'
                  }, Min:${item?.weight?.minimumClassWeight}%, Max:${
                    item?.weight?.maximumClassWeight
                  }%`
                : ``;

              return (
                <ListItem button key={item.displayValue}>
                  <ListItemText primary={item.displayValue} secondary={secondaryText} />
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge="end"
                      onChange={handleToggle(item)}
                      checked={checked.indexOf(item) !== -1}
                      inputProps={{ 'aria-labelledby': item.id }}
                    />
                  </ListItemSecondaryAction>
                  <ListItemIcon>
                    <HighlightOffIcon onClick={() => handleRemove(item)} />
                  </ListItemIcon>
                </ListItem>
              );
            })}
          </List>
        </Paper>
      ) : null}
    </>
  );
};

export default SelectedMultiAssetConstraint;
