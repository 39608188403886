import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles({
  root: {
    width: 'calc(100% - 16px)',
    backgroundColor: (props) => {
      return props.isDraggingOver ? 'skyblue' : 'red';
    },
    margin: 8,
    padding: 0,
    height: 75,
    paddingBottom: '0px!important',
  },
  categoryCard: {
    width: '100%',
    backgroundColor: (props) => {
      return props.isDraggingOver ? 'skyblue' : 'red';
    },
    height: 'auto',
    margin: 0,
    boxShadow: 'none',
    padding: 0,
    borderRadius: 0,
  },
  cardContentMacro: { padding: 10, height: 75, paddingBottom: '10px!important' },
  cardContent: { padding: 10, paddingBottom: '10px!important' },
  title: {
    fontSize: 13,
  },
  subtitle: {
    fontSize: 11,
  },
});

const Macrofactor = (props) => {
  const classes = useStyles(props);

  return (
    <Draggable draggableId={props.task.id} index={props.index}>
      {(provided, snapshot) => {
        const style = {
          background: snapshot.isDragging ? 'lightgray' : 'white',
        };

        return (
          <div style={style}>
            <div
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              isDragging={snapshot.isDragging}
            >
              <Card
                style={style}
                className={props.column === 'macrofactors' ? classes.root : classes.categoryCard}
              >
                <CardContent
                  className={
                    props.column === 'macrofactors' ? classes.cardContent : classes.cardContent
                  }
                >
                  <Typography className={classes.title}>{props.task.name}</Typography>
                  <Typography className={classes.subtitle} color="textSecondary">
                    {props.task?.timeSeriesFiltration?.name}
                  </Typography>
                </CardContent>
                {props.column !== 'macrofactors' && <Divider />}
              </Card>
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};

export default Macrofactor;
