import * as types from '../../types';
import config from '../../../config';
import userService from 'services/UserService';
import isEmpty from 'lodash.isempty';

const isValidateValue = (value) => {
  if (!isEmpty(value) || value || value === 0) {
    return true;
  }
  return false;
};

// Load Data
export const validateDataForAnalysis = (data) => (dispatch) => {
  const {
    universe,
    allowShortSales,
    shortUniverse,
    defaultFeePrior,
    investorCurrency,
    returnFrequency,
    startDate,
    benchmarks,
    macrofactors,
    riskFree,
    riskFreeRate,
  } = data;

  const dataToValidate = {
    universe,
    ...(allowShortSales && { shortUniverse }),
    defaultFeePrior,
    investorCurrency,
    returnFrequency,
    startDate,
    benchmarks,
    riskFree,
    riskFreeRate,
    macrofactors,
  };

  const numberOfFields = Object.keys(dataToValidate).length;

  const numberOfFieldsWithValue = Object.keys(dataToValidate).filter((key) =>
    isValidateValue(dataToValidate[key]),
  ).length;

  const validPercentage = Math.round((numberOfFieldsWithValue / numberOfFields) * 100);

  dispatch({
    type: types.VALIDATE_DATA_FOR_ANALYSIS,
    payload: validPercentage,
  });
};

// validate Estimator Model
export const validateEstimatorModel = (data, estimatorRulesState) => (dispatch) => {
  const {
    coreModelSpecification,
    modelEstimationStrategy,
    sigmaAlpha,
    simulationBlocks,
    drawsPerBlock,
    adjustmentSpeed,
    startDate,
    endDate,
    generationFrequency,
    minNumberOfObservations,
    rollingWindow,
    vintageWindowLength,
    customizeVintageCalendar,
    vintageMonthEndDay,
    enableCompleteSubsetRegression,
    subsetRegressionData,
    modelWeightRule,
    variableCount,
    gammaCSR,
    consolidationRule,
  } = {
    ...data,
  };

  const rules =
    modelEstimationStrategy && coreModelSpecification
      ? config.estimatorModelRestrictions[coreModelSpecification][modelEstimationStrategy] || []
      : coreModelSpecification
      ? config.estimatorModelRestrictions[coreModelSpecification]
      : [];

  const estimatorRules = estimatorRulesState ? estimatorRulesState : rules;

  // TODO check subsetRegressionData macrofactors array to be empty
  const dataToValidate = {
    coreModelSpecification,
    modelEstimationStrategy,
    ...(!estimatorRules?.sigmaAlpha && { sigmaAlpha }),
    ...(!estimatorRules?.simulationBlocks && { simulationBlocks }),
    ...(!estimatorRules?.drawsPerBlock && { drawsPerBlock }),
    ...(!estimatorRules?.adjustmentSpeed && { adjustmentSpeed }),
    startDate,
    endDate,
    generationFrequency,
    minNumberOfObservations,
    ...(rollingWindow && { vintageWindowLength }),
    ...(customizeVintageCalendar && { vintageMonthEndDay }),
    ...(!estimatorRules?.enableCompleteSubsetRegression &&
      enableCompleteSubsetRegression && { modelWeightRule }),
    ...(!estimatorRules?.enableCompleteSubsetRegression &&
      enableCompleteSubsetRegression && { variableCount }),
    ...(!estimatorRules?.enableCompleteSubsetRegression &&
      enableCompleteSubsetRegression && { gammaCSR }),
    ...(!estimatorRules?.enableCompleteSubsetRegression &&
      enableCompleteSubsetRegression && { consolidationRule }),
  };

  const numberOfFields = Object.keys(dataToValidate).length;

  const validFieldsNumber = Object.keys(dataToValidate).filter((key) =>
    isValidateValue(dataToValidate[key]),
  ).length;

  const numberOfFieldsWithValue = estimatorRules?.enableCompleteSubsetRegression
    ? validFieldsNumber
    : enableCompleteSubsetRegression &&
      subsetRegressionData?.columns?.macrofactors?.arrayOfIDs.length === 0
    ? validFieldsNumber + 1
    : validFieldsNumber;

  const validPercentage = Math.round((numberOfFieldsWithValue / numberOfFields) * 100);

  dispatch({
    type: types.VALIDATE_ESTIMATOR_MODEL,
    payload: validPercentage,
  });
};

export const validatePortfolioModel = (data) => (dispatch) => {
  // TODO: validate state values
  const { formData } = data;

  const {
    numberOrPercentage,
    numberAssets,
    assetsRankingCriteria,
    sortingOrder,
    portfolioWeights,
    MultiStatWeightsMean,
    MultiStatWeightsAlpha,
    MultiStatWeightsVariance,
    MultiStatWeightsTrackingError,
    MultiStatWeightsUtility,
    MultiStatWeightsSharpeRatio,
    MultiStatWeightsInfoRatio,
    referenceBenchmark,
    riskAversionCoEfficient,
    longAssetWeightIndividualMinimum,
    longAssetWeightIndividualMaximum,
    longAssetWeightTotalMinimum,
    longAssetWeightTotalMaximum,
    maximumAssetTurnover,
    showShortAssetWeight,
    shortAssetWeightIndividualMinimum,
    shortAssetWeightIndividualMaximum,
    shortAssetWeightTotalMinimum,
    shortAssetWeightTotalMaximum,
    totalNetWeightMinimum,
    totalNetWeightMaximum,
    totalGrossWeightMinimum,
    totalGrossWeightMaximum,
  } = { ...formData };

  const dataToValidate = {
    numberOrPercentage,
    numberAssets,
    assetsRankingCriteria,
    sortingOrder,
    portfolioWeights,
    referenceBenchmark,

    longAssetWeightIndividualMinimum,
    longAssetWeightIndividualMaximum,
    longAssetWeightTotalMinimum,
    longAssetWeightTotalMaximum,
    maximumAssetTurnover,
    ...(((portfolioWeights?.id === config.multiStateWeightID && MultiStatWeightsUtility > 0) ||
      portfolioWeights?.id === config.showRiskAversionID) && {
      riskAversionCoEfficient,
    }),
    ...(portfolioWeights?.id === config.multiStateWeightID && {
      MultiStatWeightsMean,
      MultiStatWeightsAlpha,
      MultiStatWeightsVariance,
      MultiStatWeightsTrackingError,
      MultiStatWeightsUtility,
      MultiStatWeightsSharpeRatio,
      MultiStatWeightsInfoRatio,
    }),
    ...(showShortAssetWeight && {
      shortAssetWeightIndividualMinimum,
      shortAssetWeightIndividualMaximum,
      shortAssetWeightTotalMinimum,
      shortAssetWeightTotalMaximum,
      totalNetWeightMinimum,
      totalNetWeightMaximum,
      totalGrossWeightMinimum,
      totalGrossWeightMaximum,
    }),
  };

  const numberOfFields = Object.keys(dataToValidate).length;

  const numberOfFieldsWithValue = Object.keys(dataToValidate).filter((key) =>
    isValidateValue(dataToValidate[key]),
  ).length;

  const validPercentage = Math.round((numberOfFieldsWithValue / numberOfFields) * 100);

  dispatch({
    type: types.VALIDATE_PORTFOLIO_MODEL,
    payload: validPercentage,
  });
};

export const getUserPreferences = () => async (dispatch) => {
  try {
    const result = await userService.getUserPreferences();
    if (result && !result?.error) {
      const payload = result.data.map((item) => {
        const v = item.property;
        let obj = {};
        obj[v] = item.value;
        return obj;
      });
      const reducer = (accumulator, currentValue) => ({ ...accumulator, ...currentValue });
      const obj = payload.reduce(reducer);

      dispatch({
        type: types.APP_LOAD_USER_PREFERENCES,
        payload: obj,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const savePreference = (data) => async (dispatch) => {
  try {
    const result = await userService.saveUserPreferences(data);

    if (result) {
      dispatch({
        type: types.APP_SAVE_PREFERENCE,
        payload: data,
      });
      getUserPreferences();
    }
  } catch (error) {
    console.log(error);
  }
};

export const isDirtyDataForAnalysis = (isDirty) => ({
  type: types.IS_DIRTY_DATA_FOR_ANALYSIS,
  payload: isDirty,
});

export const isDirtyEstimatorModel = (isDirty) => ({
  type: types.IS_DIRTY_ESTIMATOR_MODEL,
  payload: isDirty,
});

export const isDirtyPortfolioModel = (isDirty) => ({
  type: types.IS_DIRTY_PORTFOLIO_MODEL,
  payload: isDirty,
});

export const isDirtyReportingOutput = (isDirty) => ({
  type: types.IS_DIRTY_REPORTING_OUTPUT,
  payload: isDirty,
});

export const isDirtyRankingReportingOutput = (isDirty) => ({
  type: types.IS_DIRTY_RANKING_REPORTING_OUTPUT,
  payload: isDirty,
});

export const openPreference = () => ({
  type: types.APP_OPEN_PREFERENCE,
  payload: true,
});

export const closePreference = () => ({
  type: types.APP_CLOSE_PREFERENCE,
  payload: false,
});

export const showMessage = (data) => (dispatch) => {
  data &&
    dispatch({
      type: types.APP_SHOW_MESSAGE,
      payload: { ...data, date: Date.now() },
    });
};

export const updatePagination = (data) => (dispatch) => {
  data &&
    dispatch({
      type: types.APP_UPDATE_PAGINATION,
      payload: data,
    });
};
