import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';

export function ErrorItem({ isError, error, title }) {
  const errorMessage = error?.message?.split(' at ')[0] ?? '';

  return (
    <>
      <ListItem alignItems="center">
        <ListItemIcon>
          {isError ? (
            <ErrorIcon
              size={24}
              style={{
                color: 'red',
              }}
            />
          ) : (
            <CheckIcon
              style={{
                color: 'green',
              }}
            />
          )}
        </ListItemIcon>
        <ListItemText primary={title} secondary={errorMessage} />
      </ListItem>
    </>
  );
}
