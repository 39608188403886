/* eslint-disable operator-linebreak */
/* eslint-disable arrow-body-style */
import axios from 'axios';
import { getAccessToken } from './firebase';
import omit from 'lodash.omit';
import qs from 'qs';

const universeService = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

universeService.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.data.errors &&
      error.response.data.errors[0].code === 'INVALID_TOKEN'
    ) {
      // return AuthService.updateAccessToken().then((accessToken) => {
      //   error.config.headers.Authorization = `Bearer ${accessToken}`;
      //   return apiClient.request(error.config);
      // });
    }
    return Promise.reject(error);
  },
);

const getUniverse = async () => {
  const accessToken = await getAccessToken();
  try {
    const universeList = await universeService.get('/universes', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return universeList?.data;
  } catch (error) {
    return error;
  }
};

const saveUniverse = async (formData, type = 'dataForAnalysis', spec_id) => {
  const accessToken = await getAccessToken();
  try {
    const { name, description } = formData;

    const omitList = [
      'id',
      'name',
      'description',
      'benchmarkUniverse',
      'macrofactorUniverse',
      'created_at',
      'last_modified',
    ];
    const formattedFormData = omit(formData, omitList);
    const spec = {};

    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(formattedFormData)) {
      spec[key] = omit(formattedFormData[key], omitList);
    }

    const data = {
      name,
      description,
      spec,
      type,
    };

    const specData = spec_id
      ? await universeService.patch(`/specs/${spec_id}`, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
      : await universeService.post('/specs', data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        });
    return specData;
  } catch (error) {
    if (error.response.data) return { error: error.response.data };
    throw new Error(error);
  }
};

const deleteUniverse = async (id) => {
  const accessToken = await getAccessToken();
  try {
    const data = await universeService.delete(`/specs/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return data;
  } catch (error) {
    return error;
  }
};

const searchSpec = async ({ page = 0, size = 200, search = '', type = 'dataForAnalysis' }) => {
  const accessToken = await getAccessToken();
  try {
    const apiURL = `/specs`;
    const result = await universeService.get(apiURL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      params: {
        type,
        page,
        size,
        search,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });

    return result.data;
  } catch (error) {
    return { error: true, errorMessage: error };
  }
};

const loadSpec = async (id) => {
  const accessToken = await getAccessToken();
  try {
    const apiURL = `/specs/${id}`;
    const result = await universeService.get(apiURL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return result;
  } catch (error) {
    return error;
  }
};

const getAssets = async (id) => {
  const accessToken = await getAccessToken();
  try {
    const apiURL = `/universes/${id}/assets`;
    const assetsList = await universeService.get(apiURL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return assetsList.data;
  } catch (error) {
    return { error: true, message: error };
  }
};

const getAssetsList = async (assetIDArray) => {
  const accessToken = await getAccessToken();

  try {
    if (assetIDArray?.length) {
      const apiURL = `/assets`;
      const assetsList = await universeService.get(apiURL, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        params: {
          asset_id: [...assetIDArray],
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      });

      return assetsList.data;
    } else return [];
  } catch (error) {
    return { error: true, message: error };
  }
};

const validateSpecByType = async (id, type) => {
  const accessToken = await getAccessToken();
  try {
    // https://parala-api.35-246-17-43.nip.io/api/v1/beta/specs/pipeline/validate
    const apiURL = `/specs/pipeline/validate`;

    const specResult = await loadSpec(id);
    const data = specResult?.data?.spec;

    const result = await universeService.post(apiURL, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      params: {
        type: type,
      },
    });

    return result.data;
  } catch (error) {
    return { error: true, message: error };
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getUniverse,
  saveUniverse,
  deleteUniverse,
  loadSpec,
  searchSpec,
  getAssets,
  getAssetsList,
  validateSpecByType,
};
