import * as types from '../../types';

const initialData = {
  reportingData: {
    name: '',
    reportOnPortfolioModels: false,
    overwriteLastDataDate: false,
    overwriteLastVintageDate: false,
    enableSpecUpdate: false,
    oneOffRun: false,
    useHistoricalLearning: true,
    selectedModels: [],
    spec: {
      useHistoricalLearning: true,
    },
  },
  models: {},
};

const reportingReducer = (state = initialData, action) => {
  switch (action.type) {
    case types.USER_LOGOUT:
      return initialData;
    case types.REPORTING_OUTPUT_SAVE_DATA: {
      return {
        ...state,
        reportingData: {
          ...action.payload,
        },
      };
    }
    case types.REPORTING_OUTPUT_SAVE_DATA_IN_STATE: {
      return {
        ...state,
        reportingData: {
          ...action.payload,
        },
      };
    }
    case types.REPORTING_OUTPUT_LOAD_DATA: {
      return {
        ...state,
        reportingData: {
          ...action.payload,
        },
      };
    }
    case types.REPORTING_OUTPUT_RESET: {
      return {
        ...state,
        reportingData: {
          ...initialData.reportingData,
        },
      };
    }
    case types.REPORTING_OUTPUT_UPDATE_SELECTED_MODELS: {
      return {
        ...state,
        reportingData: {
          ...state.reportingData,
          selectedModels: [...action.payload],
        },
      };
    }
    case types.REPORTING_OUTPUT_UPDATE_MODELS: {
      let models = {};
      models[action.payload.specModel] = action.payload.models;
      return {
        ...state,
        models: {
          ...state.models,
          ...models,
        },
      };
    }

    default:
      return state;
  }
};

export default reportingReducer;
