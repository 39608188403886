import { useState } from 'react';
import { useDispatch } from 'react-redux';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { APP_HIDE_MESSAGE } from '../../redux/types';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CustomizedSnackbar({ message, variant = 'info', show }) {
  const [open, setOpen] = useState(show);
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    dispatch({
      type: APP_HIDE_MESSAGE,
      payload: null,
    });
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={variant}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
