import { useState, useMemo } from 'react';
import groupBy from 'lodash.groupby';

// MUI

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Popover from '@material-ui/core/Popover';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import Layout from 'components/Layout';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import BugReportIcon from '@material-ui/icons/BugReport';
import SettingsIcon from '@material-ui/icons/Settings';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ErrorIcon from '@material-ui/icons/Error';

import styles, { Accordion, AccordionSummary, AccordionDetails } from './ReportsDashboard.styles';
import { GlobalDatePicker } from './components/GlobalDatePicker';

import { UniverseLevelAttributionReports } from './UniverseLevelAttributionReports';
import { PortfolioLevelAttributionReports } from './PortfolioLevelAttributionReports';
import { AssetLevelAttributionReports } from './AssetLevelAttributionReports';
import { RiskReports } from './RiskReports';
import { MacroFactoLevelAttributionReports } from './MacroFactoLevelAttributionReports';
import { AssetAllocationReports } from './AssetAllocationReports';
import { PerformanceReports } from './PerformanceReports';

import {
  formatNumber,
  formatData,
  getLineAttributes,
  groupById,
  timeVaryingAlphaRegex,
  riskFactorRegex,
  macrofactorZRegex,
  macrofactorZRegexWithUnderscore,
  macrofactorZStarRegex,
  decompositionAlphaRegex,
  decompositionAlphaRegexWithUnderscore,
  portfolioAlphaDecompositionRegex,
  portfolioAlphaDecompositionRegexWithUnderscore,
  unconditionalAlphaDecompositionRegex,
  unconditionalAlphaDecompositionRegexWithUnderscore,
  unconditionalAlphaDecompositionRegexWithUnderscoreNew,
  conditionalRiskFactorExpectedReturnRegex,
  conditionalRiskFactorExpectedReturnRegexWithUnderscore,
  conditionalRiskFactorExpectedReturnRegexWithUnderscoreNew,
  unconditionalRiskFactorExpectedReturnRegex,
  unconditionalRiskFactorExpectedReturnRegexWithUnderscore,
  unconditionalRiskFactorExpectedReturnRegexWithUnderscoreNew,
  splitNameAndId,
  findNameWithId,
} from './attributesData';
import moment from 'moment';
import { getColorById } from 'lib/colors';
import { formatDataToPieChart, formatDataToLineChart } from 'lib/chart';

// import { useGetIdsNames } from 'lib/DashboardReport';
import { data } from 'lib/chartData/data';
// const { idsAndNames, pyChartData, CPitPct, pyPortfolioReturns, pyLongBetaT, BetaPT } = data;
// {
//   idsAndNames,
//   pyChartData,
//   CPitPct,
//   pyPortfolioReturns,
//   pyLongBetaT,
//   BetaPT,
// }

// regex for AssetID_
const assetRegex = /^AssetID_\d/;

export const ReportsDashboardContent = ({
  title,
  idsAndNames,
  pyChartData,
  CPitPct = [],
  pyPortfolioReturns,
  pyLongBetaT = [],
  BetaPT = [],
}) => {
  const classes = makeStyles(styles, { name: 'ReportsDashboards' })();
  const [showKeys, setShowKeys] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [expandedAccordion, setExpandedAccordion] = useState([]);
  const [showReports, setShowReports] = useState(() => ({
    totalReturnChart: {
      id: 'totalReturnChart',
      title: 'Cross-sectional average expected return',
      visible: true,
    },
    crossSectionAverageAlphaChart: {
      id: 'crossSectionAverageAlphaChart',
      title: 'Cross-sectional average alpha decomposition',
      visible: true,
    },
    expectedReturnForEachRiskFactor: {
      id: 'expectedReturnForEachRiskFactor',
      title: 'Expected return for each risk factor',
      visible: true,
    },
    extractMacroFactorStandardisedDeviations: {
      id: 'extractMacroFactorStandardisedDeviations',
      title: 'Macro-factor standardised deviations',
      visible: true,
    },
    // change
    extractMacroFactorChanges: {
      id: 'extractMacroFactorChanges',
      title: 'Macro-factor changes',
      visible: true,
    },
    // change
    expectationsAndPortfolioWeights: {
      id: 'expectationsAndPortfolioWeights',
      title: 'Expectations and portfolio weights',
      visible: true,
    },
    returnsFromTrackingTheModelWeights: {
      id: 'returnsFromTrackingTheModelWeights',
      title: 'Returns from tracking the model weights',
      visible: true,
    },

    portfolioReturnsFromTrackingTheModelWeights: {
      id: 'portfolioReturnsFromTrackingTheModelWeights',
      title: 'Portfolio returns from tracking the model weights',
      visible: true,
    },
    decompositionOfAlphaForTheAssets: {
      id: 'decompositionOfAlphaForTheAssets',
      title: 'Decomposition of alpha for the assets',
      visible: true,
    },
    portfolioAlphaDecomposition: {
      id: 'portfolioAlphaDecomposition',
      title: 'Portfolio alpha decomposition',
      visible: true,
    },
    extractUnconditionalAlphaDecomposition: {
      id: 'extractUnconditionalAlphaDecomposition',
      title: 'Unconditional alpha decomposition',
      visible: true,
    },
    extractConditionalRiskFactorExpectedReturn: {
      id: 'extractConditionalRiskFactorExpectedReturn',
      title: 'Conditional risk factor expected returns',
      visible: true,
    },
    extractUnConditionalRiskFactorExpectedReturn: {
      id: 'extractUnConditionalRiskFactorExpectedReturn',
      title: 'Unconditional risk factor expected returns',
      visible: true,
    },
    alphaContribution: {
      id: 'alphaContribution',
      title: 'Alpha contribution',
      visible: true,
    },
    individualAssetPercentContributions: {
      id: 'individualAssetPercentContributions',
      title: 'Individual Asset Percent Contributions',
      visible: true,
    },
    assetAllocation: {
      id: 'assetAllocation',
      title: 'Asset Allocation',
      visible: true,
    },
  }));

  const [dateIndex, setDateIndex] = useState({
    startIndex: 0,
    endIndex: 11,
  });

  const handleUpdateDateIndex = ({ startIndex, endIndex, startDate, endDate }) => {
    setDateIndex({ startIndex, endIndex, startDate, endDate });
  };

  //  expanded={checkIfExpanded('Universe Level Attribution Reports')}
  // onChange={handleAccordionChange('Universe Level Attribution Reports')}

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    // add panel to expandedAccordion if isExpanded is true else remove it
    if (isExpanded) {
      setExpandedAccordion([...expandedAccordion, panel]);
    } else {
      setExpandedAccordion(expandedAccordion.filter((item) => item !== panel));
    }
  };

  const checkIfExpanded = (panel) => expandedAccordion.includes(panel);

  const handleShowKeys = () => setShowKeys(!showKeys);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // refactor
  const lineCharData = formatData(pyChartData, idsAndNames);
  const [firstObject] = lineCharData;
  const firstObjectKeys = Object.keys(firstObject);

  const availableDates = [...new Set(lineCharData.map((item) => item.dateFormatted))];

  // const CPitPctFormatted = formatDataToPieChart(CPitPct, idsAndNames, assetRegex);
  const CPitPctFormatted = useMemo(() => {
    return formatDataToPieChart(CPitPct, idsAndNames, assetRegex);
  }, [CPitPct, idsAndNames]);

  const CPitPctFormattedLineChart = formatDataToLineChart(CPitPct, idsAndNames, assetRegex).sort(
    (a, b) => a.date - b.date,
  );

  // BetaPT

  const BetaPTFormatted = BetaPT.map((item) => {
    const date = item.ReturnDate;
    const dateFormatted = moment(date).format('YYYY-MM-DD');

    const data = Object.keys(item).reduce((acc, key) => {
      let returnObj = {};

      if (key !== 'ReturnDate') {
        const value = item[key];
        const [_, id] = key.split('_');
        const name = findNameWithId(id, idsAndNames);

        returnObj[id] = {
          id,
          name,
          value: parseFloat(value),
        };
        acc.push(returnObj);
      }

      return acc;
    }, []);

    return {
      date,
      dateFormatted,
      ReturnDate: item.ReturnDate,
      ...Object.assign({}, ...data),
      data: Object.assign({}, ...data),
    };
  }).sort((a, b) => a.date - b.date);

  const firstObjectBetaPT = BetaPTFormatted[0]?.data ?? null;

  const BetaPTAreaChart = firstObjectBetaPT
    ? Object.keys(firstObjectBetaPT).map((key, index) => {
        const item = firstObjectBetaPT[key];
        const color = getColorById(item.id);

        return {
          id: item.id,
          type: 'area',
          activeDot: false,
          color: color,
          dataKey: `${item.id}.value`,
          dot: false,
          fill: color,
          longName: item.name,
          name: item.name,
          stroke: color,
          strokeWidth: 3,
          stackId: 1,
          unit: '',
          yAxisId: 'y-axis-1',
        };
      })
    : [];

  const BetaPTLineChart = firstObjectBetaPT
    ? Object.keys(firstObjectBetaPT).map((key, index) => {
        const item = firstObjectBetaPT[key];
        const color = getColorById(item.id);

        return {
          id: item.id,
          type: 'line',
          activeDot: false,
          color: color,
          dataKey: `${item.id}.value`,
          dot: false,
          fill: color,
          longName: item.name,
          name: item.name,
          stroke: color,
          strokeWidth: 3,
          stackId: 1,
          unit: '',
          yAxisId: 'y-axis-1',
        };
      })
    : [];

  // BetaPT

  // format pyLongBeta

  const pyLongBetaTFormatted =
    pyLongBetaT?.map((item) => {
      const assetId = item.iAssetID?.toString() ?? null;
      const assetName = findNameWithId(assetId, idsAndNames);

      const benchmarkId = item.jBenchmarkID?.toString() ?? null;
      const benchmarkName = findNameWithId(benchmarkId, idsAndNames);

      const value = item.ijBetaT ?? null;

      const vintageDate = moment(item.VintageDate?.toString()).format('YYYY-MM-DD');

      return {
        assetId,
        assetName,
        benchmarkId,
        name: benchmarkName,
        benchmarkName,
        value: parseFloat(value),
        VintageDate: vintageDate,
        date: item.VintageDate?.toString(),

        [benchmarkId]: {
          value,
          name: benchmarkName,
          benchmarkName,
          id: benchmarkId,
        },
      };
    }) ?? [];

  const pyLongBetaTGroupedByVintage = groupBy(pyLongBetaTFormatted, 'VintageDate');
  const pyLongBetaTGroupedByAsset = groupBy(pyLongBetaTFormatted, 'assetId');
  // need array with id, name, data - id, name is used for the chart selector

  const pyLongBetaTFormattedForChart = Object.keys(pyLongBetaTGroupedByAsset).map((key) => {
    const data = pyLongBetaTGroupedByAsset[key];

    const newData = data.reduce((acc, item) => {
      const { date, benchmarkId, benchmarkName, assetName, assetId, value } = item;
      const asset = acc.find((i) => i.date === date || i.date?.toString() === date?.toString());

      if (asset) {
        asset[benchmarkId] = {
          id: benchmarkId,
          name: benchmarkName,
          value,
        };
      } else {
        acc.push({
          id: assetId,
          name: assetName,
          date,
          [benchmarkId]: {
            id: benchmarkId,
            name: benchmarkName,
            value: value,
          },
        });
      }
      return acc;
    }, []);
    return {
      id: key,
      name: findNameWithId(key, idsAndNames),
      data: newData,
    };
  });

  const pyLongBetaTGroupedByVintageBarChartData = Object.keys(pyLongBetaTGroupedByVintage).map(
    (key) => {
      const data = pyLongBetaTGroupedByVintage[key];

      const newData = data
        .reduce((acc, item) => {
          const { assetId, assetName, benchmarkId, name, value } = item;
          const asset = acc.find(
            (i) => i.id === assetId || i.id?.toString() === assetId?.toString(),
          );
          if (asset) {
            asset[benchmarkId] = {
              id: benchmarkId,
              name: name,
              value,
            };
          } else {
            acc.push({
              id: assetId,
              name: assetName,
              [benchmarkId]: {
                id: benchmarkId,
                name: name,
                value: value,
              },
            });
          }
          return acc;
        }, [])
        .sort((a, b) => a?.name?.localeCompare(b?.name))
        .map((item) => {
          const { id, name, ...rest } = item;

          return {
            id,
            name,
            ...rest,
          };
        });

      return {
        id: key,
        name: moment(key).format('YYYY-MM-DD'),
        data: [...newData],
      };
    },
  );

  const firstPyLongBetaT = pyLongBetaTGroupedByVintageBarChartData[0]?.data || [];

  const pyLongBetaTChart = firstPyLongBetaT.map((item) => {
    const { name, id, ...rest } = item;

    const result = Object.keys(rest).map((key, index) => {
      const { id, name } = rest[key];
      const color = getColorById(id);

      return {
        id: key,
        type: 'bar',
        activeDot: false,
        color: color,
        dataKey: `${key}.value`,
        dot: false,
        fill: color,
        longName: name,
        name: name,
        stroke: color,
        strokeWidth: 3,
        stackId: 1,
        unit: '',
        yAxisId: 'y-axis-1',
      };
    });

    return result;
  })[0];

  // TODO: check for a better way to do this

  const firstPyLongBetaTLineObject = pyLongBetaTFormatted.reduce((acc, item) => {
    const { benchmarkId } = item;
    if (!acc.find((i) => i.benchmarkId === benchmarkId)) {
      acc.push(item);
    }
    return acc;
  }, []);

  const pyLongBetaTLineChart = firstPyLongBetaTLineObject.map((item, index) => {
    const { benchmarkId, name } = item;
    const color = getColorById(benchmarkId);

    return {
      id: benchmarkId,
      type: 'line',
      activeDot: false,
      color: color,
      dataKey: `${benchmarkId}.value`,
      dot: false,
      fill: color,
      longName: name,
      name: name,
      stroke: color,
      strokeWidth: 3,
      stackId: 1,
      unit: '',
      yAxisId: 'y-axis-1',
    };
  });

  const pyLongBetaTBarChart = firstPyLongBetaTLineObject.map((item, index) => {
    const { benchmarkId, name } = item;
    const color = getColorById(benchmarkId);

    return {
      id: benchmarkId,
      type: 'bar',
      activeDot: false,
      color: color,
      dataKey: `${benchmarkId}.value`,
      dot: false,
      fill: color,
      longName: name,
      name: name,
      stroke: color,
      strokeWidth: 3,
      stackId: 1,
      unit: '',
      yAxisId: 'y-axis-1',
      tickFormatter: (value) => value,
    };
  });
  // end format pyLongBetaT

  const firstCPitPctObject =
    CPitPct?.length > 0
      ? Object.keys(CPitPct[0]).filter((item) => item.match(assetRegex) || item === 'dateFormatted')
      : [];

  const CPitPctFormattedAreaChart =
    firstCPitPctObject
      ?.map((item, index) => {
        const [name, id] = item.split('_');

        const riskFactorName = findNameWithId(id, idsAndNames);
        const color = getColorById(id);

        return {
          id: id,
          activeDot: false,
          color: color,
          dataKey: `${id}.value`,
          dot: false,
          fill: color,
          longName: riskFactorName,
          name: riskFactorName,
          stroke: color,
          strokeWidth: 3,
          type: 'area',
          stackId: 1,
          unit: '%',
          yAxisId: 'y-axis-1',
        };
      })
      .filter((item) => item.id)
      .sort((a, b) => a?.name?.localeCompare(b?.name)) ?? [];

  const pieChartDataObject = groupBy(CPitPctFormatted, 'dateFormatted');

  const pieChartData = pieChartDataObject
    ? Object.keys(pieChartDataObject).map((key) => {
        return {
          id: key,
          name: moment(key, 'DD/MM/YYYY').format('MMMM YYYY'),
          data: pieChartDataObject[key][0].data,
        };
      })
    : [];

  const extractVaryingAlphaKeys = firstObjectKeys.filter((key) => key.match(timeVaryingAlphaRegex));
  const extractRiskFactorKeys = firstObjectKeys.filter((key) => key.match(riskFactorRegex));
  const extractMacroFactorStandardisedDeviationsKeys = firstObjectKeys.filter((key) =>
    key.match(macrofactorZStarRegex),
  );

  const extractMacroFactorChangesKeys = firstObjectKeys.filter(
    (key) => key.match(macrofactorZRegex) || key.match(macrofactorZRegexWithUnderscore),
  );

  const decompositionOfAlphaForTheAssetsKeys = firstObjectKeys.filter(
    (key) => key.match(decompositionAlphaRegex) || key.match(decompositionAlphaRegexWithUnderscore),
  );

  const extractPortfolioAlphaDecompositionKeys = firstObjectKeys.filter(
    (key) =>
      key.match(portfolioAlphaDecompositionRegex) ||
      key.match(portfolioAlphaDecompositionRegexWithUnderscore),
  );

  const extractUnconditionalAlphaDecompositionKeys = firstObjectKeys.filter(
    (key) =>
      key.match(unconditionalAlphaDecompositionRegex) ||
      key.match(unconditionalAlphaDecompositionRegexWithUnderscore) ||
      key.match(unconditionalAlphaDecompositionRegexWithUnderscoreNew),
  );

  // 11.06.2023 - feedback
  // 'bit*Bmk_mu',
  const extractConditionalRiskFactorExpectedReturnKeys = firstObjectKeys.filter(
    (key) =>
      key.match(conditionalRiskFactorExpectedReturnRegex) ||
      key.match(conditionalRiskFactorExpectedReturnRegexWithUnderscore) ||
      key.match(conditionalRiskFactorExpectedReturnRegexWithUnderscoreNew),
  );

  const extractUnConditionalRiskFactorExpectedReturnKeys = firstObjectKeys.filter(
    (key) =>
      key.match(unconditionalRiskFactorExpectedReturnRegex) ||
      key.match(unconditionalRiskFactorExpectedReturnRegexWithUnderscore) ||
      key.match(unconditionalRiskFactorExpectedReturnRegexWithUnderscoreNew),
  );

  const totalReturnData = lineCharData.filter(
    (value, index, self) => self.findIndex((v) => v.date === value.date) === index,
  );

  const chartDataGroupedById = groupById(lineCharData, idsAndNames);

  const totalReturnChart = [
    {
      key: 'Et_bar',
      type: 'line',
    },
    {
      key: 'a0_bar+a1_bar*z',
    },
    {
      key: 'a0_bar',
    },
    {
      key: 'a1_bar*z',
    },
    {
      key: 'b0_bar*mu',
    },
    {
      key: 'b0_bar*mu+a1_bar*z',
    },
  ].map((value) => getLineAttributes(value?.key));

  //   firstObject, extractVaryingAlphaKeys
  const alphaArray = extractVaryingAlphaKeys.map((key, index) =>
    getLineAttributes(key, firstObject[key], index),
  );
  const crossSectionAverageAlphaData = [
    {
      key: 'a0_bar+a1_bar*z',
      type: 'area',
      // color: getColorById(1),
    },
    {
      key: 'a1_bar*z',
    },
    {
      key: 'a0_bar',
    },
  ].map((value, index) => {
    const { key, ...rest } = value;

    return getLineAttributes(key, rest, index);
  });
  // .splice(1, 0, ...alphaArray);

  const crossSectionAverageAlphaChart = [
    crossSectionAverageAlphaData[0],
    ...alphaArray,
    crossSectionAverageAlphaData[1],
    crossSectionAverageAlphaData[2],
  ];

  const expectedReturnForEachRiskFactor = extractRiskFactorKeys.map((key, index) =>
    getLineAttributes(key, firstObject[key], index),
  );

  const extractMacroFactorStandardisedDeviations = extractMacroFactorStandardisedDeviationsKeys.map(
    (key) => getLineAttributes(key, firstObject[key]),
  );

  const extractMacroFactorChanges = extractMacroFactorChangesKeys.map((key) =>
    getLineAttributes(key, firstObject[key]),
  );

  const expectationsAndPortfolioWeights = [
    {
      key: 'Eit',
      fill: '#1E4669',
      type: 'area',
    },
    {
      key: 'ai0',
    },
    {
      key: 'ai1*z',
    },
    {
      key: 'bi0*Bmk_mu',
    },
    {
      key: 'Vit',
    },
    {
      key: 'Et_bar',
      name: 'Average total return for the universe',
      longName: 'Average total return for the universe',
      type: 'line',
    },
    {
      key: 'w',
      yAxisId: 'y-axis-2',
      // type: 'bar',
    },
  ].map((value, index) => {
    const { key, ...rest } = value;

    return getLineAttributes(key, rest, index);
  });

  const returnsFromTrackingTheModelWeights = [
    {
      key: '(wit-wibar)*rit',
    },
    {
      key: '(wit-wibar)*(rit-bi*rmt)',
    },
    {
      key: 'wit*rit+',
    },
    {
      key: 'wit*(rit-bit*rmt)',
    },
    {
      key: '(wit-wibar)',
      yAxisId: 'y-axis-2',
    },
  ].map((value, index) => {
    const { key, ...rest } = value;

    return getLineAttributes(key, rest, index);
  });

  const portfolioReturnsFromTrackingTheModelWeights = [
    { key: '(wt-wbar)*rt' },
    { key: '(wt-wbar)*(rt-b*rmt)' },
    { key: 'wt*rt+' },
    { key: 'wt*(rt-bt*rmt)' },
  ].map((value, index) => {
    const { key, ...rest } = value;

    return getLineAttributes(key, rest, index);
  });

  const decompositionOfAlphaForTheAssetsDynamicValues = decompositionOfAlphaForTheAssetsKeys.map(
    (key, index) => getLineAttributes(key, firstObject[key], index),
  );

  const decompositionOfAlphaForTheAssetsTemp = [
    {
      key: 'ai0+ai1*z',
      type: 'area',
      color: '#1E4669',
    },
    {
      key: 'a0_bar+a1_bar*z',
      type: 'line',
      color: getColorById(1),
    },
    {
      key: 'ai1*z',
    },
    {
      key: 'ai0',
    },
    {
      key: 'w',
      yAxisId: 'y-axis-2',
    },
  ].map((value, index) => {
    const { key, ...rest } = value;

    return getLineAttributes(key, rest, index);
  });

  const decompositionOfAlphaForTheAssets = [
    decompositionOfAlphaForTheAssetsTemp[0],
    decompositionOfAlphaForTheAssetsTemp[1],
    ...decompositionOfAlphaForTheAssetsDynamicValues,
    decompositionOfAlphaForTheAssetsTemp[2],
    decompositionOfAlphaForTheAssetsTemp[3],
  ];
  const portfolioAlphaDecompositionDynamicValues = extractPortfolioAlphaDecompositionKeys.map(
    (key, index) => getLineAttributes(key, firstObject[key], index),
  );

  const portfolioAlphaDecompositionTemp = [
    {
      key: 'wt*(a0+a1*z)',
      name: 'Total portfolio alpha',
      longName: 'Total portfolio alpha',
      type: 'area',
      color: getColorById(1),
    },

    {
      key: 'a0_bar+a1_bar*z',
      type: 'line',
    },
    {
      // Missing from the data namespace
      key: 'wt*a1*z',
      name: 'Total time-varying alpha',
      longName: 'Total time-varying alpha',
      strokeWidth: 5,
    },
    {
      // Missing from the data namespace
      key: 'wt*a0',
      strokeWidth: 5,
    },
  ].map((value, index) => {
    const { key, ...rest } = value;

    return getLineAttributes(key, rest, index);
  });

  const portfolioAlphaDecomposition = [
    portfolioAlphaDecompositionTemp[0],
    portfolioAlphaDecompositionTemp[1],
    ...portfolioAlphaDecompositionDynamicValues,
    portfolioAlphaDecompositionTemp[2],
    portfolioAlphaDecompositionTemp[3],
  ];

  const extractUnconditionalAlphaDecomposition = extractUnconditionalAlphaDecompositionKeys.map(
    (key, index) => getLineAttributes(key, firstObject[key], index),
  );

  const extractConditionalRiskFactorExpectedReturn = [
    'bit*Bmk_mu',
    ...extractConditionalRiskFactorExpectedReturnKeys,
  ].map((key, index) => getLineAttributes(key, firstObject[key], index));

  const extractUnConditionalRiskFactorExpectedReturn =
    extractUnConditionalRiskFactorExpectedReturnKeys.map((key, index) =>
      getLineAttributes(key, firstObject[key], index),
    );

  const macrofactorZIds = firstObjectKeys
    .filter((key) => key.match(macrofactorZRegex) || key.match(macrofactorZRegexWithUnderscore))
    .map((key) => {
      const { name: index, id } = splitNameAndId(key);

      return {
        index: parseInt(index.replace('z', ''), 10),
        zIndex: index,
        id,
        name: findNameWithId(id, idsAndNames) || id,
        splitByUnderscore: key.match(macrofactorZRegexWithUnderscore) ? true : false,
        zStar: false,
      };
    });

  // macrofactorZStarRegex
  const macrofactorZStarIndexIds = firstObjectKeys
    .filter((key) => key.match(macrofactorZStarRegex))
    .map((key) => {
      const { name: index, id } = splitNameAndId(key);

      return {
        index: parseInt(index.replace('zStar', ''), 10),
        zIndex: index,
        id,
        name: findNameWithId(id, idsAndNames) || id,
        splitByUnderscore: true,
        zStar: true,
      };
    });

  const macrofactorIds = macrofactorZStarIndexIds?.length
    ? macrofactorZStarIndexIds
    : macrofactorZIds;

  const alphaContribution = macrofactorIds.map((macrofactor) => {
    const { index, id, splitByUnderscore, zStar } = macrofactor;
    const splitChar = splitByUnderscore ? '_' : ', ';
    // determine if we are using ', ' or '_' in the data

    const name = findNameWithId(id, idsAndNames) || id;

    // const key = zStar ? `zStar${index}${splitChar}${id}` : `z${index}${splitChar}${id}`;
    const key = `z${index}${splitChar}${id}`;

    return [
      {
        key: `${key}`,
        dataKey: `${key}.value`,
        color: getColorById(1),
        name: name,
        yAxisId: 'y-axis-3',
        strokeDasharray: '5 5',
      },
      {
        key: `ai1${index}*z${index}${splitChar}${id}`,
        dataKey: `ai1${index}*z${index}${splitChar}${id}.value`,
        color: getColorById(3),
        name: 'Time-varying alpha',
        yAxisId: 'y-axis-1',
      },
      {
        // TODO: check if this is correct
        key: `ai1${index}*zbar${index}${splitChar}${id}`,
        dataKey: `ai1${index}*zbar${index}${splitChar}${id}.value`,
        //   /^ai1\d{1,2}(?:\*z)bar\d{1,2}_\d/;
        color: getColorById(4),
        name: 'Time-averaged time-varying alpha',
        yAxisId: 'y-axis-1',
      },
      {
        key: `a1${index}_bar*z${index}, ${id}`,
        dataKey: `a1${index}_bar*z${index}${splitChar}${id}.value`,
        color: getColorById(5),
        name: 'Time-varying alpha across all assets',
        yAxisId: 'y-axis-1',
      },
      {
        key: `ai1${index}, ${id}`,
        dataKey: `ai1${index}${splitChar}${id}.value`,
        color: getColorById(2),
        name: `Sensitivity to ${name}`,
        yAxisId: 'y-axis-2',
      },
    ].map((value, index) => {
      const { key, ...rest } = value;

      return getLineAttributes(key, rest, index);
    });
  });
  //

  // return (
  //   <Layout>
  //     <Container maxWidth="lg" style={{ margin: 0 }}>
  //       <Box display="flex" alignItems="center" justifyContent="space-between">
  //         <Box display="flex" alignItems="center">
  //           <h1>Testing 2a</h1>
  //           <Box style={{ marginLeft: 10 }}>
  //             <IconButton aria-label="show key" onClick={handleShowKeys}>
  //               <BugReportIcon fontSize="24" color={showKeys ? 'primary' : ''} />
  //             </IconButton>
  //             {isError ? (
  //               <IconButton aria-label="show error">
  //                 <Tooltip title={`Data loaded from local file.`}>
  //                   <ErrorIcon fontSize="24" color="error" />
  //                 </Tooltip>
  //               </IconButton>
  //             ) : null}
  //           </Box>
  //         </Box>
  //       </Box>
  //     </Container>
  //   </Layout>
  // );

  return (
    <Layout>
      <Container maxWidth="lg" style={{ margin: 0 }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Typography variant="h6" as="h3">
              {title ?? 'Reports Dashboard'}
            </Typography>
            <Box style={{ marginLeft: 10 }}>
              <IconButton aria-label="show key" onClick={handleShowKeys}>
                <BugReportIcon fontSize="24" color={showKeys ? 'primary' : ''} />
              </IconButton>
            </Box>
          </Box>
          <Box display="flex">
            <IconButton aria-controls="simple-popover" aria-haspopup="true" onClick={handleClick}>
              <SettingsIcon fontSize="24" color="primary" />
            </IconButton>

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <List>
                {Object.values(showReports).map((report) => (
                  <ListItem
                    key={report.id}
                    role={undefined}
                    dense
                    button
                    onClick={() =>
                      setShowReports((prev) => ({
                        ...prev,
                        [report.id]: {
                          ...report,
                          visible: !report.visible,
                        },
                      }))
                    }
                  >
                    <ListItemIcon minWidth="auto" style={{ minWidth: 'auto' }}>
                      <Checkbox
                        edge="start"
                        checked={report.visible}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': report.id }}
                      />
                    </ListItemIcon>
                    <ListItemText id={report.id} primary={report.title} />
                  </ListItem>
                ))}
              </List>
            </Popover>
            <GlobalDatePicker
              availableDates={availableDates}
              handleUpdateGlobalDate={handleUpdateDateIndex}
              dateIndex={dateIndex}
            />
          </Box>
        </Box>
        <Container className={classes.root} style={{ margin: 0 }}>
          <Accordion elevation={4}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Universe Level Attribution Reports
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <UniverseLevelAttributionReports
                showKeys={showKeys}
                dateIndex={dateIndex}
                showReports={showReports}
                totalReturnData={totalReturnData}
                totalReturnChart={totalReturnChart}
                crossSectionAverageAlphaChart={crossSectionAverageAlphaChart}
                expectedReturnForEachRiskFactor={expectedReturnForEachRiskFactor}
                extractMacroFactorStandardisedDeviations={extractMacroFactorStandardisedDeviations}
                extractMacroFactorChanges={extractMacroFactorChanges}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={4}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Portfolio Level Attribution Reports
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PortfolioLevelAttributionReports
                showKeys={showKeys}
                dateIndex={dateIndex}
                showReports={showReports}
                chartDataGroupedById={chartDataGroupedById}
                expectationsAndPortfolioWeights={expectationsAndPortfolioWeights}
                returnsFromTrackingTheModelWeights={returnsFromTrackingTheModelWeights}
                portfolioReturnsFromTrackingTheModelWeights={
                  portfolioReturnsFromTrackingTheModelWeights
                }
                portfolioAlphaDecomposition={portfolioAlphaDecomposition}
                totalReturnData={totalReturnData}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={4}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Asset Level Attribution Reports</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AssetLevelAttributionReports
                showKeys={showKeys}
                dateIndex={dateIndex}
                showReports={showReports}
                chartDataGroupedById={chartDataGroupedById}
                decompositionOfAlphaForTheAssets={decompositionOfAlphaForTheAssets}
                extractUnconditionalAlphaDecomposition={extractUnconditionalAlphaDecomposition}
                extractConditionalRiskFactorExpectedReturn={
                  extractConditionalRiskFactorExpectedReturn
                }
                extractUnConditionalRiskFactorExpectedReturn={
                  extractUnConditionalRiskFactorExpectedReturn
                }
              />
            </AccordionDetails>
          </Accordion>

          <Accordion elevation={4}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Macro Factor Level Attribution Reports
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <MacroFactoLevelAttributionReports
                showKeys={showKeys}
                dateIndex={dateIndex}
                showReports={showReports}
                chartDataGroupedById={chartDataGroupedById}
                macrofactorIds={macrofactorIds}
                alphaContribution={alphaContribution}
                pieChartData={pieChartData}
                CPitPctFormattedAreaChart={CPitPctFormattedAreaChart}
                CPitPctFormattedLineChart={CPitPctFormattedLineChart}
              />
            </AccordionDetails>
          </Accordion>
          {/* TODO check other props too? */}
          {pieChartData?.length > 0 ? (
            <Accordion elevation={4}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>Risk Reports</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <RiskReports
                  showKeys={showKeys}
                  dateIndex={dateIndex}
                  showReports={showReports}
                  chartDataGroupedById={chartDataGroupedById}
                  macrofactorIds={macrofactorIds}
                  alphaContribution={alphaContribution}
                  pieChartData={pieChartData}
                  CPitPctFormattedAreaChart={CPitPctFormattedAreaChart}
                  CPitPctFormattedLineChart={CPitPctFormattedLineChart}
                  pyLongBetaTFormatted={pyLongBetaTFormatted}
                  pyLongBetaTGroupedByVintage={pyLongBetaTGroupedByVintage}
                  pyLongBetaTGroupedByVintageBarChartData={pyLongBetaTGroupedByVintageBarChartData}
                  pyLongBetaTChart={pyLongBetaTChart}
                  pyLongBetaTLineChart={pyLongBetaTLineChart}
                  pyLongBetaTFormattedForChart={pyLongBetaTFormattedForChart}
                  pyLongBetaTBarChart={pyLongBetaTBarChart}
                  BetaPTFormatted={BetaPTFormatted}
                  BetaPTAreaChart={BetaPTAreaChart}
                  BetaPTLineChart={BetaPTLineChart}
                />
              </AccordionDetails>
            </Accordion>
          ) : null}
          <Accordion elevation={4}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Asset Allocation Reports</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AssetAllocationReports
                showKeys={showKeys}
                dateIndex={dateIndex}
                showReports={showReports}
                idsAndNames={idsAndNames}
                pyPortfolioReturns={pyPortfolioReturns}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion elevation={4}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Performance</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PerformanceReports pyPortfolioReturns={pyPortfolioReturns} dateIndex={dateIndex} />
            </AccordionDetails>
          </Accordion>
        </Container>
      </Container>
    </Layout>
  );
};
