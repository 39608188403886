import axios from 'axios';
import { auth, getAccessToken } from './firebase';

const authClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    // Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

authClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.data.errors &&
      error.response.data.errors[0].code === 'INVALID_TOKEN'
    ) {
      // return AuthService.updateAccessToken().then((accessToken) => {
      //   error.config.headers.Authorization = `Bearer ${accessToken}`;
      //   return apiClient.request(error.config);
      // });
    }
    return Promise.reject(error);
  },
);

const userSignUp = ({ email, password }) => {
  auth
    .createUserWithEmailAndPassword(email, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      return user;
      // ...
    })
    .catch((error) => {
      console.log('Error', error);
    });
};
const userLogin = async ({ email, password }) => {
  auth.signInWithEmailAndPassword(email, password);
};

const getCurrencies = async () => {
  const accessToken = await getAccessToken();
  try {
    const apiURL = `/currencies`;
    const result = await authClient.get(apiURL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return result.data;
  } catch (error) {
    return error;
  }
};

const getUserPreferences = async () => {
  const accessToken = await getAccessToken();
  try {
    if (accessToken) {
      const apiURL = `/user_properties`;
      const result = await authClient.get(apiURL, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      return result.data;
    }
    return false;
  } catch (error) {
    return error;
  }
};

const saveUserPreferences = async (data) => {
  const accessToken = await getAccessToken();
  try {
    const apiURL = `/user_properties`;
    const result = await authClient.post(apiURL, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return result.data;
  } catch (error) {
    return error;
  }
};

const userService = {
  userSignUp,
  userLogin,
  getAccessToken,
  getCurrencies,
  getUserPreferences,
  saveUserPreferences,
};

export default userService;
