import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import includes from 'lodash.includes';

import { Box, Typography, Grid } from '@material-ui/core';

import { HelpInfo } from 'components/HelpInfo';
import InlineInput from 'components/InlineInput';
import InlineSwitch from 'components/InlineInput/InlineSwitch';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { setEstimatorModelRules } from 'redux/actions';
import config from 'config';

const ModelSpecification = () => {
  const { estimatorRules } = useSelector((state) => state.estimatorModel);
  const dispatch = useDispatch();
  const { control, watch, setValue, errors, register } = useFormContext();

  const coreModelSpecification = watch('coreModelSpecification');
  const modelEstimationStrategy = watch('modelEstimationStrategy');

  useEffect(() => {
    if (coreModelSpecification && modelEstimationStrategy) {
      const isModelEnabled = includes(
        config.estimatorModelRestrictions[coreModelSpecification].modelEstimationStrategy,
        parseInt(modelEstimationStrategy),
      );

      !isModelEnabled && setValue('modelEstimationStrategy', config.modelEstimationStrategy[0]?.id);
    }
    coreModelSpecification &&
      dispatch(setEstimatorModelRules(coreModelSpecification, modelEstimationStrategy));
  }, [coreModelSpecification, modelEstimationStrategy, dispatch, setValue]);

  useEffect(() => {
    coreModelSpecification &&
      modelEstimationStrategy &&
      dispatch(setEstimatorModelRules(coreModelSpecification, modelEstimationStrategy));
  }, [coreModelSpecification, modelEstimationStrategy, dispatch]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center">
            <Typography variant="h6">Alpha Model Specification</Typography>
            <HelpInfo name="alphaModelSpecification" isHtml />
          </Box>
          <FormControl component="fieldset">
            <Controller
              id="model-core"
              name="coreModelSpecification"
              control={control}
              render={(props) => {
                return (
                  <RadioGroup aria-label=" Select Core Model Specification" {...props}>
                    {config.coreModelSpecification.map((item) => (
                      <FormControlLabel
                        key={item.id}
                        value={item.id}
                        checked={parseInt(props.value) === item.id}
                        control={<Radio />}
                        label={item.name}
                      />
                    ))}
                  </RadioGroup>
                );
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center">
            <Typography variant="h6" as="h5">
              Model Estimation Strategy
            </Typography>
            <HelpInfo name="modelEstimationStrategy" isHtml />
          </Box>

          <FormControl component="fieldset">
            <Controller
              id="model-estimation"
              name="modelEstimationStrategy"
              control={control}
              render={(props) => (
                <RadioGroup aria-label=" Model Estimation Strategy" {...props}>
                  {config.modelEstimationStrategy.map((item) => (
                    <FormControlLabel
                      key={item.id}
                      value={item.id}
                      checked={parseInt(props.value) === item.id}
                      disabled={
                        estimatorRules?.modelEstimationStrategy
                          ? includes(estimatorRules?.modelEstimationStrategy, item.id)
                            ? false
                            : true
                          : false
                      }
                      control={<Radio />}
                      label={item.name}
                    />
                  ))}
                </RadioGroup>
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <InlineInput
            control={control}
            text="Maximum Forecast Horizon"
            type="number"
            step="1"
            helperText="Please enter as a integer. "
            name="maxH"
            defaultValue="1"
            showHelpIcon
            disabled={estimatorRules?.maxH}
            error={errors.maxH?.message}
          />
        </Grid>

        <Grid item xs={12}>
          <InlineInput
            control={control}
            text="Sigma Alpha"
            type="number"
            step="0.1"
            helperText="Please enter as a decimal. "
            name="sigmaAlpha"
            showHelpIcon
            disabled={estimatorRules?.sigmaAlpha}
            error={errors.sigmaAlpha?.message}
          />
        </Grid>

        <Grid item xs={12}>
          <InlineSwitch
            register={register}
            setValue={setValue}
            text="Save Predictive Densities"
            name="savePredDensity"
            showHelpIcon
            disabled={estimatorRules?.savePredDensity ? true : false}
            error={errors.savePredDensity?.message}
          />
        </Grid>

        <Grid item xs={12}>
          <InlineInput
            control={control}
            text="Simulation Blocks"
            name="simulationBlocks"
            showHelpIcon
            disabled={estimatorRules?.simulationBlocks}
            error={errors.simulationBlocks?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <InlineInput
            control={control}
            text="Draws per Block"
            name="drawsPerBlock"
            showHelpIcon
            disabled={estimatorRules?.drawsPerBlock}
            error={errors.drawsPerBlock?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <InlineInput
            control={control}
            step="0.1"
            text="Adjustment Speed"
            helperText="Please enter as a decimal. "
            name="adjustmentSpeed"
            showHelpIcon
            disabled={estimatorRules?.adjustmentSpeed}
            error={errors.adjustmentSpeed?.message}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ModelSpecification;
