import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import AvailableBenchmarkForm from '../AvailableBenchmarkForm';
import FormAutoComplete from '../FormAutoComplete';
import OrderList from '../OrderList';
import CustomizedSnackbar from '../CustomizedSnackbar';

import { Box, Typography, Grid } from '@material-ui/core';

import Paper from '@material-ui/core/Paper';

import { removeBenchmark, reorderBenchmark, updateBenchmark } from '../../redux/actions';
import universeService from '../../services/UniverseService';
import { HelpInfo } from '../HelpInfo';

const Benchmarks = ({ universeList }) => {
  const [availableBenchmarkList, setAvailableBenchmarkList] = useState([]);

  const [benchmarkListError, setBenchmarkListError] = useState(null);
  const { benchmarks } = useSelector((state) => state.dataForAnalysis);
  const dispatch = useDispatch();
  const { watch } = useFormContext();

  const watchBenchmarkUniverse = watch('benchmarkUniverse', false);

  useEffect(() => {
    const fetchData = async () => {
      const result = await universeService.getAssets(watchBenchmarkUniverse.id);
      if (result.error) {
        setAvailableBenchmarkList([]);
        setBenchmarkListError('Error fetching available benchmark list.');
      } else {
        setAvailableBenchmarkList(result.data);
        setBenchmarkListError(null);
      }
    };
    watchBenchmarkUniverse?.id && fetchData();
  }, [watchBenchmarkUniverse?.id]);

  const removeSelectedBenchmark = (index) => {
    dispatch(removeBenchmark(index));
  };

  const reorderSelectedList = (list) => {
    dispatch(reorderBenchmark(list));
  };

  const handleUpdateBenchmark = (benchmark) => {
    dispatch(updateBenchmark(benchmark));
  };

  return (
    <>
      {benchmarkListError && (
        <CustomizedSnackbar
          message={benchmarkListError}
          variant="error"
          show={!!benchmarkListError}
        />
      )}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" as="h5">
            Benchmark Universe
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormAutoComplete name="benchmarkUniverse" options={universeList} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" as="h5">
            Available Benchmarks
          </Typography>
        </Grid>
        {/* Start availableBenchmark Form */}
        <AvailableBenchmarkForm
          availableBenchmarkList={availableBenchmarkList}
          benchMarkPlaceholder="Benchmark"
          timeFiltration={false}
          addButtonLabel="Add Benchmark"
        />
        {/* End availableBenchmark Form */}
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Typography variant="h6" as="h5">
              Selected Benchmarks
            </Typography>
            <HelpInfo name="selectedBenchmarks" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            {benchmarks?.length ? (
              <OrderList
                initialList={benchmarks}
                reorderSelectedList={reorderSelectedList}
                handleClick={removeSelectedBenchmark}
                handleSwitch={handleUpdateBenchmark}
              />
            ) : (
              <Typography variant="body2" as="span" m={2} style={{ padding: 20 }}>
                No Benchmark selected.
              </Typography>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Benchmarks;
