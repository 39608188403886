import { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MUISwitch from 'components/MUISwitch';
import Typography from '@material-ui/core/Typography';
import { HelpInfo } from 'components/HelpInfo';

const InlineSwitch = ({
  disabled,
  register,
  setValue,
  text,
  name,
  defaultValue = false,
  leftGrid = 4,
  rightGrid = 8,
  textClass,
  showHelpIcon,
}) => {
  useEffect(() => {
    if (disabled) setValue(name, false);
  }, [disabled, name]);

  return (
    <Grid container spacing={3} alignItems="flex-start">
      <Grid item xs={12} md={leftGrid}>
        <Box display="flex" alignItems="center">
          <Typography className={textClass} variant="subtitle1">
            {text}
          </Typography>
          {showHelpIcon ? <HelpInfo name={name} /> : null}
        </Box>
      </Grid>
      <Grid item xs={12} sm={rightGrid}>
        <MUISwitch
          disabled={disabled}
          register={register}
          defaultCheck={defaultValue || false}
          name={name}
        />
      </Grid>
    </Grid>
  );
};

export default InlineSwitch;
