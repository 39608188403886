export const help = {
  overrideFeeWithDefault:
    'Turn on to add a default management fee where this information is missing for an investment fund in the database',

  selectedBenchmarks: 'Turn Self-Financing on for zero weight benchmarks like risk premiums',
  alphaModelSpecification: [
    {
      title: 'Dogmatic CAPM',
      description: 'Highly restrictive view of alpha skills and predictability',
    },
    {
      title: 'Bayesian CAPM',
      description: 'Fairly restrictive view of alpha skills and predictability',
    },
    {
      title: 'Bayesian Macro Alpha',
      description: 'Informative data-driven view of alpha skills and predictability',
    },
    {
      title: 'Skeptic Macro Alpha',
      description: 'Sceptical albeit data-driven view of alpha skills and predictability',
    },
  ],
  modelEstimationStrategy: [
    {
      title: 'Analytical Core Model',
      description: 'Core estimation model which does not require sampling',
    },
    {
      title: 'Simulated Core Model',
      description:
        'Core estimation model which uses sampling to generate full distribution forecasts',
    },
    {
      title: 'Stochastic Volatility',
      description: 'Simulation-based estimation model with adaptive, time-varying volatility',
    },
    {
      title: 'Time-Varying Parameters',
      description:
        'Simulation-based estimation model allowing for time-varying estimates of model parameters',
    },
    {
      title: 'Stochastic Volatility + Time-Varying Parameters',
      description:
        'Simulation-based estimation model with time-varying volatility forecasts and time-varying estimation of model parameters',
    },
    {
      title: 'Markov Switching',
      description:
        'Simulation-based estimation model with regime switching capability which aims to capture large, discrete shifts in model parameters',
    },
  ],
  sigmaAlpha:
    'An investor prior view about a strategy’s expected alpha which is used in combination with time-series data to model investment behaviour. An input of 0.0025 corresponds to an expected alpha of 3% per annum (3% divided by 12 months).',
  simulationBlocks:
    'The number of simulations to compute the entire predictive distribution of all individual assets (10,000 is a reasonable size)',
  drawsPerBlock:
    'A ‘short-cut’ used to robustize the sampling process by increasing the number of draws per simulation block. Set to the number of macro factors up to a maximum value of 10',
  adjustmentSpeed:
    'The adjustment speed for time-varying volatility and time-varying model parameters. Values between 0.025 and 0.10 are reasonable.',
  premiumAll: 'Tick to assume risk factors are predictable across the business cycle',
  loadingsAll:
    'Tick to assume that individual asset risk factor loadings are predictable across the business cycle. Leave unticked unless time-series history is sufficiently long.',
  completeSubsetRegression:
    'Complete subset regression provides the ability to use more macroeconomic variables while reducing estimation errors by averaging forecasts over every subset combination',
  modelWeightingRule: [
    {
      title: 'Equal Weighted Combinations',
      description: 'All subset model forecasts are equally weighted',
    },
    {
      title: 'Posterior Odds Weighting',
      description: 'All subset model forecasts are weighted using Bayesian model averaging',
    },
    {
      title: 'Optimal Predictive Weighting',
      description: 'All subset model forecasts are weighted based on their log predictive score',
    },
    {
      title: 'Variable Count',
      description: 'The number of macroeconomic variables to use in each subset model',
    },
    {
      title: 'Gamma - CSR',
      description:
        'A scalar allowing subset model forecasts to be weighted using a blend of equal weighting and another specified weighting method. Set at 0 for equal weighting and 1 or higher to place most of the weight on another specified weighting method.',
    },
  ],
  riskAversionCoEfficient:
    'Risk aversion is a parameter used to customize the Mean-Variance optimization objective for different risk profiles. A risk aversion level of 1 is aligned with wealth maximization whereas a level of 10 is aligned with safety and capital protection.',
  includeConstrainedAssets:
    'Switch on to force constrained assets into the portfolio optimization where they might otherwise be excluded if a ranking (i.e. top 50% ranked by expected return) is used to determine eligible assets',
  simulatedModelCombinations:
    'Provides the ability to combine different estimator models for the same investment universe with the aim of greater robustness',
  simulatedSegmentedModelCombinations: 'simulatedSegmentedModelCombinations',
  maxH: 'This input corresponds to the number of months when using monthly data (12 = 12 months).  A lower number is more computationally efficient especially for simulated estimation models',
  savePredDensity:
    'Saving the distribution of potential outcomes is helpful if you plan to run reports showing the full distribution of returns or simulated model combinations otherwise leaving it switched to ‘off’ will make the report generation faster',
  useHistoricalLearning:
    'Using historical learning is more efficient and will speed the calculation time. Default should be ‘on’.',
  oneOffRun:
    'Turn on to perform a one-off analysis which does not use or delete historical learning data',
};
