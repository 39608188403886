import * as React from 'react';
import { ErrorBoundary, useErrorBoundary } from 'react-error-boundary';

import {
  Area,
  Bar,
  ComposedChart,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Brush,
  ResponsiveContainer,
  Label,
} from 'recharts';
import { Box, Button, Typography } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import FileSaver from 'file-saver';
import { useCurrentPng } from 'recharts-to-png';

import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

// TODO: move to utils file
import moment from 'moment';

import { SelectBox } from './SelectBox';

const CustomTooltip = (props) => {
  const { active, payload, label } = props;

  if (active && payload && payload.length) {
    const itemDateValue = payload[0]?.payload?.date ? `${payload[0]?.payload?.date}` : label;
    const displayLabel = payload[0]?.payload?.date ? false : true;

    const vintageDate = payload[0]?.payload?.WeightVintageDate
      ? `${payload[0]?.payload?.WeightVintageDate}`
      : null;

    return (
      <Box
        className="custom-tooltip"
        style={{
          background: 'white',
          borderRadius: '25px',
          zIndex: 10000,
          position: 'relative',
        }}
      >
        <Typography
          variant="h6"
          style={{
            padding: 10,
            fontSize: 16,
            fontWeight: 700,
            background: '#1E4669',
            color: 'white',
          }}
        >
          {displayLabel ? itemDateValue : moment(itemDateValue).format('DD MMMM YYYY')}
        </Typography>
        <Box style={{ padding: 10, border: '1px solid #e6e6e6' }}>
          {vintageDate ? (
            <Typography variant="h6" style={{ fontSize: 16, fontWeight: 700 }}>
              {`Vintage Date`}:{' '}
              <span style={{ fontWeight: 700 }}>{moment(vintageDate).format('DD MMMM YYYY')}</span>
            </Typography>
          ) : null}
          {payload.map((item) => (
            <Typography key={item.id} variant="body1" style={{ fontSize: 14, fontWeight: 400 }}>
              {item?.id ? item.id : item.name}:{' '}
              <span style={{ fontWeight: 700 }}>
                {typeof item.value === 'number' ? item.value.toFixed(2) : item.value}
                {item?.unit ? item.unit : ''}
              </span>
            </Typography>
          ))}
        </Box>
      </Box>
    );
  }

  return null;
};

const renderLegend = (props) => {
  const { payload, onClick } = props;

  return (
    <ul
      style={{
        listStyle: 'none',
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        maxHeight: 100,
        overflowY: 'scroll',
      }}
    >
      {payload.map((entry, index) => (
        <li
          key={`item-${index}`}
          onClick={() => onClick(entry)}
          style={{
            color: entry.color,
            display: 'inline-block',
            padding: '0px 5px 5px 5px',
          }}
        >
          {entry.value}
        </li>
      ))}
    </ul>
  );
};

const CustomizedAxisTick = (props) => {
  const { x, y, stroke, payload } = props;

  const displayValue = props?.tickFormatter ? props.tickFormatter(payload.value) : payload.value;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#656565"
        transform="rotate(-35)"
        fontSize={11}
      >
        {displayValue}
      </text>
    </g>
  );
};

export const ParalaLineChart = ({
  chart,
  chartId,
  lineCharData,
  title,
  description,
  yAxisLabels,
  chartProps,
  dateIndex,
  isBiAxis = false,
  isTripleAxis = false,
  selectBox = null,
  showKeys = false,
}) => {
  const chartRef = React.useRef(null);
  const [chartState, setChartState] = React.useState(
    () =>
      chart?.reduce(
        (o, key) =>
          Object.assign(o, {
            [key.dataKey]: {
              visible: true,
            },
          }),
        {},
      ) ?? {},
  );
  const [downloadView, setDownloadView] = React.useState(false);
  const [brushData, setBrushData] = React.useState({
    startIndex: 0,
    endIndex: 11,
  });

  React.useEffect(() => {
    try {
      const startDate = moment(dateIndex.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
      const endDate = moment(dateIndex.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');

      let startIndexValue = 0;
      let endIndexValue = 11;

      const startIndex = lineCharData.findIndex((item) =>
        moment(moment(item.date, 'YYYYMMDD').format('YYYY-MM-DD')).isSame(startDate),
      );

      const endIndex = lineCharData.findIndex((item) =>
        moment(moment(item.date, 'YYYYMMDD').format('YYYY-MM-DD')).isSame(endDate),
      );

      if (startIndex > -1 && startIndex <= endIndex) {
        startIndexValue = startIndex;
      }

      if (endIndex > -1 && startIndex <= endIndex) {
        endIndexValue = endIndex;
      }

      if (endIndexValue > lineCharData?.length) {
        endIndexValue = lineCharData.length;
      }

      setBrushData({
        startIndex: startIndexValue,
        endIndex: endIndexValue,
      });
    } catch (error) {
      setBrushData({
        startIndex: 0,
        endIndex: 11,
      });
      console.error('error', error);
    }
  }, [dateIndex, lineCharData]);

  const [getPng, { ref, isLoading }] = useCurrentPng({ margin: '20px' });

  const handleDownload = React.useCallback(async () => {
    // wait for state to update
    setDownloadView(true);
    await new Promise((resolve) => setTimeout(resolve, 0));
    const png = await getPng();

    // Verify that png is not undefined
    if (png) {
      const fileName = title.replace(/ /g, '_').toLowerCase();
      // Download with FileSaver
      FileSaver.saveAs(png, `${fileName}.png`);
    }
    setDownloadView(false);
  }, [getPng, title]);

  // test new download
  const onDownloadClick = React.useCallback(() => {
    if (chartRef.current === null) {
      return;
    }
    setDownloadView(true);
    const fileName = title.replace(/ /g, '_').toLowerCase();

    toPng(chartRef.current, {
      cacheBust: true,
      backgroundColor: 'white',
      style: { margin: '10px' },
    })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = `${fileName}.png`;
        link.href = dataUrl;
        link.click();
        setDownloadView(false);
      })
      .then(() => {
        setDownloadView(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [chartRef, title]);
  // end test

  const handleLegendClick = (event) => {
    const { dataKey } = event;
    if (chartState[dataKey]) {
      setChartState((prevState) => {
        return {
          ...prevState,
          [dataKey]: {
            ...prevState[dataKey],
            visible: !prevState[dataKey].visible,
          },
        };
      });
    }
  };

  const formatTickValue = (value) => {
    return moment(value).isValid() ? moment(value, 'YYYYMMDD').format('MMM YYYY') : value;
  };

  const handleBrushChange = (event) => {
    setBrushData(event);
  };
  const gradientOffset = (data, key) => {
    const dataMax = Math.max(...data.map((i) => i[key]));
    const dataMin = Math.min(...data.map((i) => i[key]));

    if (dataMax <= 0) {
      return 0;
    }
    if (dataMin >= 0) {
      return 1;
    }

    return dataMax / (dataMax - dataMin);
  };

  const renderColorfulLegendText = (value, entry) => {
    const { color, dataKey } = entry;
    const [key] = dataKey?.split('.') || [dataKey];

    return (
      <span style={{ color }}>
        {value}
        {showKeys ? <span style={{ fontWeight: 700 }}>{` (${key}, ${color})`}</span> : null}
      </span>
    );
  };

  const customLabel = (props) => {
    const dataKey = chartProps?.xAxisDataKey || 'date';

    // display label with values from brush
    const startIndex = brushData?.startIndex || 0;
    const endIndex = brushData?.endIndex || 12;
    const brushDataValue =
      formatTickValue(lineCharData[startIndex]?.[dataKey]) +
      ' - ' +
      formatTickValue(lineCharData[endIndex]?.[dataKey]);

    // center text
    const xPosition = 0;
    const yPosition = props.viewBox.y + 20;

    return (
      <text
        x={xPosition}
        y={yPosition}
        fill="#000"
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={14}
        fontWeight={500}
        width={props.viewBox.width}
        transform={`translate(${props.viewBox.width / 2}, 0)`}
      >
        {brushDataValue}
      </text>
    );
  };

  const logError = (error, info) => {
    console.error(error, info);
  };

  if (
    brushData.endIndex > lineCharData?.length ||
    brushData.startIndex < 0 ||
    brushData.endIndex < 0
  ) {
    return <div>No data</div>;
  }

  if (!lineCharData || lineCharData?.length === 0) {
    return <div>No data</div>;
  }

  return (
    <ErrorBoundary fallbackRender={fallbackRender} onError={logError}>
      <div style={{ width: '100%' }} ref={chartRef}>
        <Box
          display="flex"
          flex="1"
          alignItems="cenflex-startter"
          justifyContent="space-between"
          mb={2}
        >
          <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
            {selectBox && !downloadView ? (
              <Box>
                <SelectBox {...selectBox} />
              </Box>
            ) : null}
            <Box>
              <Typography
                variant="h3"
                style={{ fontSize: 21, marginBottom: 10 }}
                dangerouslySetInnerHTML={{ __html: title }}
              />

              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </Box>
          </Box>
          {downloadView ? null : (
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="secondary"
                startIcon={<GetAppIcon />}
                onClick={onDownloadClick}
                disabled={isLoading}
                style={{
                  marginLeft: 10,
                }}
              >
                {isLoading ? 'Saving...' : 'PNG'}
              </Button>
            </Box>
          )}
        </Box>
        <ResponsiveContainer width="100%" height={600}>
          <ComposedChart
            ref={ref}
            data={lineCharData}
            syncId={chartId}
            margin={
              chartProps?.chartMargin
                ? chartProps.chartMargin
                : {
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 50,
                  }
            }
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey={chartProps?.xAxisDataKey || 'date'}
              // TODO: use name from props
              name="Portfolio Return"
              tick={chartProps?.xAxisTick || <CustomizedAxisTick tickFormatter={formatTickValue} />}
              tickFormatter={chartProps?.xAxisFormatter || formatTickValue}
              interval={chartProps?.xInterval ?? `preserveStartEnd`}
            >
              {/* <Label content={customLabel} toffset={0} position="insideBottom" /> */}
            </XAxis>
            <YAxis
              name={chartProps?.yAxis1?.name ? chartProps.yAxis1.name : 'Portfolio Return'}
              // TODO: use name from props
              tickFormatter={(value) =>
                chartProps?.yAxis1?.tickFormatter
                  ? chartProps.yAxis1?.tickFormatter(value)
                  : `${value}%`
              }
              yAxisId="y-axis-1"
              type="number"
              domain={chartProps?.yAxis1?.domain ? chartProps.yAxis1.domain : ['auto', 'auto']}
              label={{
                value: yAxisLabels?.left ? yAxisLabels.left : 'Return',
                angle: -90,
                position: 'insideLeft',
                textAnchor: 'middle',
              }}
            />
            {isBiAxis ? (
              <YAxis
                yAxisId="y-axis-2"
                orientation="right"
                tickFormatter={(value) => `${value}%`}
                type="number"
                width={isTripleAxis ? 80 : 60}
                domain={chartProps?.yAxis2?.domain ? chartProps.yAxis2.domain : ['auto', 'auto']}
                label={{
                  value: yAxisLabels?.right ? yAxisLabels.right : '',
                  angle: 90,
                  position: 'insideRight',
                  textAnchor: 'middle',
                  offset: isTripleAxis ? 20 : 0,
                }}
              />
            ) : null}
            {isTripleAxis ? (
              <YAxis
                yAxisId="y-axis-3"
                orientation="right"
                tickFormatter={(value) =>
                  chartProps?.yAxis3?.tickFormatter
                    ? chartProps.yAxis3?.tickFormatter(value)
                    : `${value}%`
                }
                type="number"
                width={isTripleAxis ? 80 : 60}
                domain={chartProps?.yAxis3?.domain ? chartProps.yAxis3.domain : ['auto', 'auto']}
                label={{
                  value: yAxisLabels?.right2 ? yAxisLabels.right2 : '',
                  angle: 90,
                  position: 'insideRight',
                  textAnchor: 'middle',
                }}
              />
            ) : null}
            <Tooltip content={<CustomTooltip />} />
            {/* <Tooltip /> */}
            <Legend
              verticalAlign="top"
              margin={{
                top: 10,
                right: 0,
                left: 0,
                bottom: 10,
              }}
              wrapperStyle={{
                paddingBottom: 20,
              }}
              height="auto"
              onClick={handleLegendClick}
              // content={renderLegend}
              formatter={renderColorfulLegendText}
            />

            {chart.map((chartItem) => {
              switch (chartItem.type) {
                case 'line':
                  return (
                    <Line
                      key={chartItem.dataKey}
                      type="monotone"
                      dataKey={chartItem.dataKey}
                      name={chartItem.name}
                      id={chartItem?.longName}
                      unit={chartItem?.unit}
                      stroke={chartItem.stroke}
                      fill={chartItem.fill}
                      activeDot={chartItem.activeDot}
                      dot={chartItem.dot}
                      strokeWidth={chartItem.strokeWidth}
                      strokeDasharray={chartItem?.strokeDasharray ?? [chartItem.strokeWidth, 0]}
                      hide={!chartState[chartItem.dataKey].visible}
                      tickFormatter={
                        chartItem?.tickFormatter ? chartItem.tickFormatter : (value) => `${value}%`
                      }
                      yAxisId={chartItem.yAxisId}
                    />
                  );
                case 'bar':
                  return (
                    <Bar
                      key={chartItem.dataKey}
                      type="monotone"
                      dataKey={chartItem.dataKey}
                      name={chartItem.name}
                      id={chartItem?.longName}
                      unit={chartItem?.unit}
                      stroke={chartItem.stroke}
                      fill={chartItem.fill}
                      activeDot={chartItem.activeDot}
                      dot={chartItem.dot}
                      strokeWidth={chartItem.strokeWidth}
                      hide={!chartState[chartItem.dataKey].visible}
                      tickFormatter={
                        chartItem?.tickFormatter ? chartItem.tickFormatter : (value) => `${value}%`
                      }
                      yAxisId={chartItem.yAxisId}
                      stackId={chartItem?.stackId}
                    />
                  );

                case 'area':
                  return (
                    <>
                      {/* <defs>
                      <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                        <stop offset={off} stopColor="green" stopOpacity={1} />
                        <stop offset={off} stopColor="red" stopOpacity={1} />
                      </linearGradient>
                    </defs> */}
                      {/* <Area type="monotone" dataKey="uv" stroke="#000" fill="url(#splitColor)" /> */}

                      <Area
                        key={chartItem.dataKey}
                        type="monotone"
                        dataKey={chartItem.dataKey}
                        name={chartItem.name}
                        id={chartItem?.longName}
                        unit={chartItem?.unit}
                        stroke={chartItem.stroke}
                        // fill="url(#splitColor)"
                        fill={chartItem.fill}
                        hide={!chartState[chartItem.dataKey].visible}
                        tickFormatter={
                          chartItem?.tickFormatter
                            ? chartItem.tickFormatter
                            : (value) => `${value}%`
                        }
                        yAxisId={chartItem.yAxisId}
                        stackId={chartItem?.stackId}
                      />
                    </>
                  );

                default:
                  return null;
              }
            })}

            {chartProps?.hideBrush || downloadView ? null : (
              <Brush
                dataKey={chartProps?.xAxisDataKey || 'date'}
                onChange={handleBrushChange}
                startIndex={brushData?.startIndex ?? 0}
                endIndex={brushData?.endIndex ?? 11}
                tickFormatter={formatTickValue}
                y={560}
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </ErrorBoundary>
  );
};

function fallbackRender({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div role="alert">
      <p>
        No chart data available. for this period. Please update the global date range. and click Try
        again button.
      </p>
      <Button onClick={resetErrorBoundary} variant="contained">
        Try again
      </Button>
    </div>
  );
}
