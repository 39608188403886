/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useState, useEffect } from 'react';
import moment from 'moment';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { OrderBy } from 'components/OrderBy';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';

import universeService from '../../services/UniverseService';

import { orderByOptions } from 'config';
import { sortSearchResults } from 'services/util';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
  paper: {
    padding: '2px 4px',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  input: {
    '& .MuiInput-underline:before': {
      border: 0,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 0,
    },
  },
  popper: {
    width: '100%!important',
    position: 'relative',
    height: '42vh',
  },
}));

const LoadDataDialog = ({ isOpen, close, loadSpec, type, title, description }) => {
  const [searchResult, setSearchResult] = useState([]);
  const [order, setOrder] = useState(orderByOptions[0]);

  const classes = useStyles();

  const handleOrderChange = (option) => {
    setOrder(option);
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await universeService.searchSpec({ type });
      const sorted = sortSearchResults(result?.specs?.data, 'name', 'asc');
      setSearchResult(sorted);
    };
    isOpen && fetchData();
  }, [isOpen, type]);

  useEffect(() => {
    const sorted = sortSearchResults(searchResult, order.key, order.value);
    setSearchResult(sorted);
  }, [order, searchResult]);

  const handleClose = () => {
    close(false);
  };
  const handleLoad = (id) => {
    loadSpec(id);
    close(false);
  };

  return (
    <div style={{ minWidth: 400 }}>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isOpen}
        onClose={handleClose}
        style={{ minWidth: 400 }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent className={classes.DialogContent}>
          <DialogContentText>{description}</DialogContentText>
          <Autocomplete
            classes={{
              popper: classes.popper,
            }}
            disablePortal={true}
            defaultValue={null}
            value={null}
            id="loadDataForAnalysis"
            name="loadDataForAnalysis"
            options={searchResult || []}
            freeSolo={true}
            openOnFocus={true}
            open={true}
            disabled={!searchResult?.length}
            getOptionLabel={(option) => `${option?.name} ${option?.description}`}
            renderOption={(option, { inputValue }) => {
              const matches = option?.name ? match(option?.name || '', inputValue) : [];
              const parts = option?.name ? parse(option?.name || '', matches) : [];

              const matchesDesc = option?.description ? match(option?.description, inputValue) : [];
              const partsDesc = option?.description ? parse(option?.description, matchesDesc) : [];

              const name =
                parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                )) || '-';

              const description =
                partsDesc.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                )) || '-';

              const desc = (
                <>
                  {description} <br />
                  <span>
                    Created: {moment(option?.created_at).format('MMMM Do YYYY, h:mm:ss a')}
                  </span>
                  {option?.last_modified ? (
                    <>
                      <br />
                      <span>
                        Modified: {moment(option?.last_modified).format('MMMM Do YYYY, h:mm:ss a')}
                      </span>
                    </>
                  ) : null}
                </>
              );
              return (
                <ListItem button key={`${option?.id}`} onClick={() => handleLoad(option?.id)}>
                  <ListItemText primary={name} secondary={desc} />
                  <Divider />
                </ListItem>
              );
            }}
            renderInput={(params) => (
              <Box display="flex" flex="1" alignItems="space-between">
                <Paper className={classes.paper} elevation={4}>
                  <IconButton className={classes.iconButton} aria-label="Search">
                    <SearchIcon />
                  </IconButton>
                  <TextField className={classes.input} {...params} placeholder="" />
                </Paper>
                <Box display="flex" style={{ marginLeft: 20 }}>
                  <OrderBy order={order} handleOrderChange={handleOrderChange} height />
                </Box>
              </Box>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LoadDataDialog;
