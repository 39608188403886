/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import omit from 'lodash.omit';

import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SaveIcon from '@material-ui/icons/Save';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import TimelineIcon from '@material-ui/icons/Timeline';
import DescriptionIcon from '@material-ui/icons/Description';
import styles, { Accordion, AccordionSummary, AccordionDetails } from './DataForAnalysis.styles';

import Layout from 'components/Layout';
import AssetUniverse from 'components/AssetUniverse';
import DataForAnalysisPreferences from 'components/DataForAnalysisPreferences';
import Benchmarks from 'components/Benchmarks';
import RiskFreeRate from 'components/RiskFreeRate';
import MacroFactors from 'components/MacroFactors';
import AssetPerformance from 'components/AssetPerformance';
import SaveDataDialog from 'components/SaveDataDialog';
import LoadDataDialog from 'components/LoadDataDialog';
import AlertDialog from 'components/AlertDialog';
import PreventNavigation from 'components/PreventNavigation';
import { SpecDialog } from 'components/SpecDialog';

import {
  saveDataForAnalysis,
  saveDataForAnalysisInState,
  loadDataForAnalysis,
  deleteDataForAnalysis,
  resetDataForAnalysis,
  validateDataForAnalysis,
  isDirtyDataForAnalysis,
} from 'redux/actions';

import universeService from 'services/UniverseService';

const DataForAnalysis = () => {
  const [universeList, setUniverseList] = useState([]);
  const [isSaveFormOpen, setSaveFormDialog] = useState(false);
  const [isLoadFormOpen, setLoadFormDialog] = useState(false);
  const [isResetOpen, setIsResetOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [isAssetPerformanceOpen, setIsAssetPerformanceOpen] = useState(false);
  const [openSpec, setOpenSpec] = React.useState(false);

  const { benchmarks, macrofactors, ...rest } = useSelector((state) => state.dataForAnalysis);
  const { preferenceData } = useSelector((state) => state.app?.preference);
  // const preferenceData = appState.app?.preference?.preferenceData;
  const dataForAnalysis = { ...rest };

  const { name, description, id } = dataForAnalysis;
  const dispatch = useDispatch();

  const defaultFormValues = { ...dataForAnalysis, ...preferenceData };

  const methods = useForm({
    defaultValues: defaultFormValues,
    shouldUnregister: false,
  });
  const watchForm = methods.watch();
  const { isDirty } = methods.formState;

  const classes = makeStyles(styles, { name: 'DataForAnalysis' })();

  useEffect(() => {
    const initialDataForAnalysis = dataForAnalysis?.returnFrequency
      ? { ...dataForAnalysis }
      : { ...dataForAnalysis, ...preferenceData };

    methods.reset(initialDataForAnalysis);
  }, []);

  useEffect(() => {
    const dataForAnalysisData = dataForAnalysis?.returnFrequency
      ? { ...dataForAnalysis }
      : { ...dataForAnalysis, ...preferenceData };

    methods.reset(dataForAnalysisData);
  }, [dataForAnalysis.id]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await universeService.getUniverse();
      setUniverseList(result.data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const formData = { ...watchForm, benchmarks, macrofactors };
    dispatch(validateDataForAnalysis(formData));
  }, [watchForm, benchmarks, macrofactors]);

  useEffect(() => {
    isDirty && dispatch(isDirtyDataForAnalysis(true));
  }, [isDirty]);

  const onSubmit = async (data) => {
    const { name, description } = data;
    const formattedData = omit(data, ['name', 'description']);
    const formData = {
      name,
      description,
      dataForAnalysis: { ...formattedData, benchmarks, macrofactors },
    };
    const id = dataForAnalysis?.id ? dataForAnalysis.id : null;
    dispatch(saveDataForAnalysis(formData, id));
    dispatch(isDirtyDataForAnalysis(false));
    methods.reset(data);
    setSaveFormDialog(false);
  };

  const onSubmitNew = async (data) => {
    const { name, description } = data;
    const formattedData = omit(data, ['name', 'description']);
    const formData = {
      name,
      description,
      dataForAnalysis: { ...formattedData, benchmarks, macrofactors },
    };
    dispatch(saveDataForAnalysis(formData));
    dispatch(isDirtyDataForAnalysis(false));
    methods.reset(data);
    setSaveFormDialog(false);
  };

  const onError = (errors, e) => console.log(errors, e);

  const saveToStore = async () => {
    const formData = { ...watchForm, benchmarks, macrofactors };
    dispatch(saveDataForAnalysisInState(formData));
  };

  const handleSaveClose = () => {
    methods.reset(dataForAnalysis);
    setSaveFormDialog(false);
  };
  const handleLoadClose = () => {
    setLoadFormDialog(false);
  };
  const handleSave = () => {
    methods.handleSubmit(onSubmit, onError)();
    setSaveFormDialog(false);
  };

  const handleSaveAsNew = () => {
    methods.handleSubmit(onSubmitNew, onError)();
  };

  const handleOpenSave = () => {
    setSaveFormDialog(true);
  };

  const handleLoad = () => {
    setLoadFormDialog(true);
  };

  const handleLoadSpec = (id) => {
    dispatch(loadDataForAnalysis(id));
    dispatch(isDirtyDataForAnalysis(false));
  };

  const handleReset = () => {
    setIsResetOpen(true);
  };
  const handleResetConfirm = () => {
    dispatch(resetDataForAnalysis());
    methods.reset({}, { isDirty: false });

    setIsResetOpen(false);
  };
  const handleResetCancel = () => {
    setIsResetOpen(false);
  };

  const handleDelete = () => {
    setIsDeleteOpen(true);
  };

  const handleDeleteConfirm = () => {
    dispatch(deleteDataForAnalysis(dataForAnalysis.id));
    methods.reset();
    setIsDeleteOpen(false);
  };
  const handleDeleteCancel = () => {
    setIsDeleteOpen(false);
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleAssetPerformanceDrawer = (value) => {
    // const [isAssetPerformanceOpen, setIsAssetPerformanceOpen]
    setIsAssetPerformanceOpen(value);
  };

  const handleClickOpenSpec = () => {
    setOpenSpec(true);
  };

  const handleCloseSpec = () => {
    setOpenSpec(false);
  };

  return (
    <Layout>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
          <Drawer
            anchor="right"
            open={isAssetPerformanceOpen}
            onClose={() => handleAssetPerformanceDrawer(false)}
            className={classes.drawerAssets}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <AssetPerformance
              universe={watchForm.universe}
              riskFreeRate={watchForm.riskFreeRate}
              startDate={watchForm.startDate}
              endDate={watchForm.endDate}
              investorCurrency={watchForm.investorCurrency}
              returnFrequency={watchForm.returnFrequency}
              benchmarks={benchmarks}
              macrofactors={macrofactors}
              handleClose={handleAssetPerformanceDrawer}
              expanded={expanded === 'assetPerformance'}
            />
          </Drawer>
          <SaveDataDialog
            isOpen={isSaveFormOpen}
            close={handleSaveClose}
            save={handleSave}
            saveAsNew={handleSaveAsNew}
            title="Save Data for Analysis."
            formName="Data for Analysis"
            type="dataForAnalysis"
            name={name}
            description={description}
            id={id}
          />
          {isLoadFormOpen && (
            <LoadDataDialog
              isOpen={isLoadFormOpen}
              loadSpec={handleLoadSpec}
              close={handleLoadClose}
              type="dataForAnalysis"
              title="Load Data for Analysis"
              description="Select Data for Analysis option to load."
            />
          )}
          <AlertDialog
            isOpen={isResetOpen}
            title="Do you want to reset Data for Analysis form?"
            handleConfirm={handleResetConfirm}
            handleCancel={handleResetCancel}
          />
          <AlertDialog
            isOpen={isDeleteOpen}
            title="Do you want to delete Data for Analysis from the Database?"
            handleConfirm={handleDeleteConfirm}
            handleCancel={handleDeleteCancel}
          />

          <SpecDialog open={openSpec} onClose={handleCloseSpec} id={dataForAnalysis?.id} />

          <Container>
            <Grid container spacing={3} alignItems="flex-start">
              <Grid item xs={6}>
                <Box display="flex">
                  <Typography variant="h4" as="h1">
                    Data for Analysis
                  </Typography>
                  <Tooltip title="Click for Asset Performance Chart" arrow>
                    <IconButton
                      color="primary"
                      aria-label="Asset Performance Chart"
                      component="span"
                      onClick={handleAssetPerformanceDrawer}
                    >
                      <TimelineIcon />
                    </IconButton>
                  </Tooltip>

                  {dataForAnalysis?.id ? (
                    <Tooltip title="Click for saved JSON spec" arrow>
                      <IconButton
                        color="primary"
                        aria-label="Saved JSON spec"
                        component="span"
                        onClick={handleClickOpenSpec}
                      >
                        <DescriptionIcon />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={1} alignItems="flex-end" justify="flex-end">
                  <Grid item xs={12}>
                    <Button
                      size="small"
                      className={classes.button}
                      onClick={() => handleOpenSave()}
                      type="button"
                      variant="contained"
                      color="primary"
                      startIcon={<SaveIcon />}
                    >
                      save
                    </Button>

                    <Button
                      size="small"
                      className={classes.button}
                      variant="contained"
                      onClick={() => handleLoad()}
                      color="primary"
                      startIcon={<OpenInBrowserIcon />}
                    >
                      Load
                    </Button>
                    <Button
                      size="small"
                      className={classes.button}
                      variant="contained"
                      onClick={() => handleReset()}
                      color="primary"
                      startIcon={<FiberNewIcon />}
                    >
                      Reset
                    </Button>
                    {dataForAnalysis?.id && (
                      <Button
                        size="small"
                        className={classes.button}
                        variant="contained"
                        onClick={() => handleDelete()}
                        color="primary"
                        startIcon={<DeleteForeverIcon />}
                      >
                        Delete
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Container className={classes.root} style={{ margin: 0 }}>
              <Accordion elevation={4}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>Asset Universe</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <AssetUniverse universeList={universeList} />
                </AccordionDetails>
              </Accordion>
              <Accordion elevation={4}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>Preferences</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <DataForAnalysisPreferences />
                </AccordionDetails>
              </Accordion>
              <Accordion elevation={4}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>Benchmarks</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Benchmarks universeList={universeList} />
                </AccordionDetails>
              </Accordion>
              <Accordion elevation={4}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}> Risk Free Rate</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <RiskFreeRate universeList={universeList} />
                </AccordionDetails>
              </Accordion>

              <Accordion elevation={4}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography className={classes.heading}>Macro Factors</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <MacroFactors universeList={universeList} />
                </AccordionDetails>
              </Accordion>

              {/* <Accordion elevation={4} onChange={handleAccordionChange('assetPerformance')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4a-content"
                  id="panel4a-header"
                >
                  <Typography className={classes.heading}>Asset Performance</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {expanded === 'assetPerformance' ? (
                    <AssetPerformance
                      expanded={expanded === 'assetPerformance'}
                      universe={watchForm.universe}
                      riskFreeRate={watchForm.riskFreeRate}
                      startDate={watchForm.startDate}
                      endDate={watchForm.endDate}
                      investorCurrency={watchForm.investorCurrency}
                      returnFrequency={watchForm.returnFrequency}
                      benchmarks={benchmarks}
                      macrofactors={macrofactors}
                      handleClose={handleAssetPerformanceDrawer}
                    />
                  ) : null}
                </AccordionDetails>
              </Accordion> */}
            </Container>
          </Container>
        </form>
        <PreventNavigation dirty={isDirty} dirtyForm="dataForAnalysis" saveToStore={saveToStore} />
      </FormProvider>
    </Layout>
  );
};

export default DataForAnalysis;
