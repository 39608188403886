import Box from '@material-ui/core/Box';

import { ParalaLineChart } from 'components/Dashboard';
import { PerformanceHeatMap } from './PerformanceReport/PerformanceHeatMap';
import { getColorById } from 'lib/colors';
import {
  formatNumber,
  formatDate,
  getYear,
  getMonthName,
  getMonthNumber,
  getMonthNameByIndex,
} from './attributesData';

const getGrowthsOfAUnitValue = (pyPortfolioReturns) => {
  const growthsOfAUnitValueArr = pyPortfolioReturns.reduce((acc, row, index) => {
    let growthOfAUnitValue = 0;
    const portfolioReturn = parseFloat(row.PortfolioReturn);
    // formatNumber(row.PortfolioReturn, 2);
    if (index === 0) {
      growthOfAUnitValue = 1 + portfolioReturn;
    } else {
      const previousGrowthOfAUnitValue = acc[index - 1].growthOfAUnitValue;
      growthOfAUnitValue = previousGrowthOfAUnitValue * (1 + portfolioReturn);
    }

    const returnDate =
      typeof row.ReturnDate === 'string' ? row.ReturnDate : row.ReturnDate?.toString();

    const formattedDate = formatDate(returnDate);

    return [
      ...acc,
      {
        ...row,
        portfolioReturn,
        growthOfAUnitValue,
        cumulativePerformance: growthOfAUnitValue - 1,
        value: formatNumber(growthOfAUnitValue - 1, 100),
        date: formattedDate,
        year: getYear(formattedDate),
        month: getMonthName(formattedDate),
        monthNumber: parseInt(getMonthNumber(formattedDate)),
      },
    ];
  }, []);
  return growthsOfAUnitValueArr;
};

const generateMonthArray = (startMonth, endMonth) => {
  const monthArray = [];
  for (let i = startMonth; i <= endMonth; i++) {
    monthArray.push({
      monthNumber: i,
      month: getMonthNameByIndex(i),
    });
  }

  return monthArray;
};

const months = generateMonthArray(1, 12);

export const PerformanceReports = ({ pyPortfolioReturns, dateIndex }) => {
  const orderedPortfolioReturns = pyPortfolioReturns.sort((a, b) => {
    return a.ReturnDate > b.ReturnDate ? 1 : -1;
  });

  const arr = getGrowthsOfAUnitValue(orderedPortfolioReturns);

  const heatMapData = arr.reduce((acc, row) => {
    const { year, month, portfolioReturn, monthNumber } = row;
    const yearIndex = acc.findIndex((item) => item.year === year);
    if (yearIndex === -1) {
      return [
        ...acc,
        {
          year,
          id: year,
          data: [
            {
              x: month,
              y: portfolioReturn,
              monthNumber,
            },
          ],
        },
      ];
    } else {
      return [
        ...acc.slice(0, yearIndex),
        {
          ...acc[yearIndex],
          data: [
            ...acc[yearIndex].data,
            {
              x: month,
              y: portfolioReturn,
              monthNumber,
            },
          ],
        },
        ...acc.slice(yearIndex + 1),
      ];
    }
  }, []);

  const sortedHeatMapData = heatMapData
    .map((row) => {
      return {
        ...row,
        data: months.map((month) => {
          const monthIndex = row.data.findIndex((item) => item.monthNumber === month.monthNumber);
          if (monthIndex === -1) {
            return {
              x: month.month,
              y: null,
              monthNumber: month.monthNumber,
            };
          } else {
            return row.data[monthIndex];
          }
        }),
      };
    })
    .sort((a, b) => a.year - b.year)
    .map((row) => {
      return {
        ...row,
        data: [
          ...row.data.sort((a, b) => a.monthNumber - b.monthNumber),
          {
            x: 'YTD',
            y:
              row.data.reduce((acc, item) => {
                if (item.y) {
                  return acc * (item.y + 1);
                } else {
                  return acc;
                }
              }, 1) - 1,

            monthNumber: 13,
          },
        ],
      };
    });

  const cumulativePerformanceChart = [
    {
      id: 'Cumulative Performance',
      type: 'line',
      activeDot: false,
      color: getColorById(1),
      dataKey: `value`,
      dot: false,
      fill: getColorById(1),
      longName: 'Cumulative Performance',
      name: 'Cumulative Performance',
      stroke: getColorById(1),
      strokeWidth: 3,
      stackId: 1,
      unit: '%',
      yAxisId: 'y-axis-1',
    },
  ];

  return (
    <Box display="flex" flex={1} flexDirection="column">
      <Box
        style={{
          marginBottom: 30,
          paddingBottom: 30,
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        <PerformanceHeatMap data={sortedHeatMapData} />
      </Box>
      <Box
        style={{
          marginBottom: 30,
          paddingBottom: 30,
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        <ParalaLineChart
          chart={cumulativePerformanceChart}
          lineCharData={arr}
          title="Portfolio Cumulative Performance"
          description=""
          dateIndex={dateIndex}
        />
      </Box>
    </Box>
  );
};
