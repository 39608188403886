import { USER_LOGIN, USER_LOGOUT, USER_LOGIN_IN_PROGRESS } from '../../types';

const initialAuthState = {
  isAuthenticated: false,
  loginInProgress: false,
};

const authReducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case USER_LOGIN_IN_PROGRESS:
      return {
        ...state,
        loginInProgress: !state.loginInProgress,
      };
    case USER_LOGIN:
      return action.payload;
    case USER_LOGOUT:
      return initialAuthState;
    default:
      return state;
  }
};

export default authReducer;
