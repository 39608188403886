import { useState, useEffect } from 'react';
import Alert from '@material-ui/lab/Alert';
import Skeleton from '@material-ui/lab/Skeleton';

import UniverseService from '../../services/UniverseService';

import { errorTypes } from '../../config/const';

const SpecValidationErrors = ({ id, type }) => {
  const [errors, setErrors] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const result = await UniverseService.validateSpecByType(id, type);
      setErrors(result);
      setIsFetching(false);
    };
    setIsFetching(true);
    fetchData();
  }, [id, type]);

  return (
    <div>
      {isFetching ? (
        <Alert severity="error">
          <Skeleton width={300} height={20} />
        </Alert>
      ) : (
        <>
          {errors?.map((error) => {
            const errorFields = error?.loc?.join(',');
            const errorType = errorTypes[error?.type];
            return (
              <Alert key={errorFields} severity="error">
                {errorFields ? `${errorFields} - ` : ``}
                {error?.msg ? `${error.msg} - ` : ``}
                {errorType ? `${errorType}` : ``}
              </Alert>
            );
          })}
        </>
      )}
    </div>
  );
};

export default SpecValidationErrors;
