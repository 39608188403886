import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  progressBoxColor: {
    color: (props) =>
      Math.round(props.progress) < 100 ? theme.palette.error.main : theme.palette.success.main,
    fontSize: '0.55rem',
  },
  colorPrimary: {
    color: (props) =>
      Math.round(props.progress) < 100 ? theme.palette.error.main : theme.palette.success.main,
    fontSize: '1.2rem',
  },
}));
// (props) => (Math.round(props.progress) < 100 ? 'green' : 'red')
const CircularProgressWithLabel = ({ progress }) => {
  const classes = useStyles({ progress: progress });

  return (
    <Box position="relative" display="inline-flex" className={classes.progressBox}>
      <CircularProgress
        size={30}
        variant="determinate"
        value={progress}
        color="primary"
        className={classes.colorPrimary}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Tooltip title={`Form completed ${progress}%`}>
          {Math.round(progress) < 100 ? (
            <Typography
              variant="caption"
              component="div"
              className={classes.progressBoxColor}
            >{`${Math.round(progress)}%`}</Typography>
          ) : (
            <DoneAllIcon className={classes.colorPrimary} />
          )}
        </Tooltip>
      </Box>
    </Box>
  );
};

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  progress: PropTypes.number.isRequired,
};

export default CircularProgressWithLabel;
