import { useEffect, useRef, useState } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    padding: theme.spacing(2),
    fontSize: theme.typography.pxToRem(14),
  },
}))(Tooltip);

export const TypographyPerLine = ({ title, sx, classes, testid, variant, maxLines = 1 }) => {
  const titleRef = useRef(null);
  const [isTitleLong, setIsTitleLong] = useState(false);

  const isEllipsisActive = (e) => {
    return e.offsetHeight < e.scrollHeight || e.offsetWidth < e.scrollWidth;
  };

  useEffect(() => {
    if (titleRef?.current) {
      setIsTitleLong(isEllipsisActive(titleRef.current));
    }
  }, [titleRef]);

  return (
    <LightTooltip title={isTitleLong ? title : ''} arrow placement="top">
      <Typography
        ref={titleRef}
        data-testid={testid}
        classes={classes}
        variant={variant}
        style={{
          width: '100%',
          textAlign: 'left',
          display: '-webkit-box',
          wordBreak: 'break-all',
          overflow: 'hidden',
          WebkitLineClamp: maxLines,
          WebkitBoxOrient: 'vertical',
          ...sx,
        }}
      >
        {title}
      </Typography>
    </LightTooltip>
  );
};
