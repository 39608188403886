import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import UniverseService from 'services/UniverseService';
import ModelStatus from '../ModelStatus';
import { OrderBy } from 'components/OrderBy';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

import { orderByOptions } from 'config';
import { sortSearchResults } from 'services/util';

import {
  updateRankingSelectedModels,
  updateRankingModels,
  isDirtyRankingReportingOutput,
} from 'redux/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    width: '100%',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  cardSubheader: {
    color: 'white',
  },
  list: {
    height: 460,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  dateLabel: {
    width: '100%',
    display: 'inline-block',
  },
  white: {
    color: 'white!important',
  },
  invalidModel: {
    // backgroundColor: 'rgba(0, 0, 0, 0.04)',
    opacity: 0.5,
  },
  rootPaper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function RankingReportModelSelector({ reportOnPortfolio }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [order, setOrder] = React.useState(orderByOptions[0]);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const { rankingReportData } = useSelector((state) => state.rankingReport);
  const { selectedModels } = rankingReportData;

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const searchInput = React.useRef(null);

  const handleOrderChange = (option) => {
    const sortedLeft = sortSearchResults(left, option.key, option.value);
    const sortedRight = sortSearchResults(right, option.key, option.value);

    setOrder(option);
    setLeft(sortedLeft);
    setRight(sortedRight);
  };

  const handleSearch = () => {
    setSearchTerm(searchInput.current.value);
  };

  const resetSearch = () => {
    setSearchTerm('');
    searchInput.current.value = '';
  };

  useEffect(() => {
    // TODO: optimize, save to state the list don't request every time from api
    const fetchData = async () => {
      const specModel = reportOnPortfolio ? 'optimization' : 'estimator';
      const reportModels = await UniverseService.searchSpec({ type: specModel });
      const formattedReportModels = reportModels?.specs?.data || [];

      const sortedResult = sortSearchResults(formattedReportModels, order.key, order.value);

      dispatch(updateRankingModels(specModel, sortedResult));
      const rightModels =
        sortedResult.filter((model) =>
          selectedModels.find(
            (selectedModel) => selectedModel.id === model.id || selectedModel === model.id,
          ),
        ) || [];

      setRight(rightModels);
      dispatch(updateRankingSelectedModels(rightModels));

      setLeft(not(sortedResult, rightModels));
    };

    fetchData();
  }, [reportOnPortfolio, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items)?.length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    dispatch(isDirtyRankingReportingOutput(true));
    const rightModels = right.concat(leftChecked);
    setRight(rightModels);
    dispatch(updateRankingSelectedModels(rightModels));

    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    dispatch(isDirtyRankingReportingOutput(true));
    setLeft(left.concat(rightChecked));
    const rightModels = not(right, rightChecked);
    setRight(rightModels);
    dispatch(updateRankingSelectedModels(rightModels));

    setChecked(not(checked, rightChecked));
  };

  const customList = (title, items = [], showSearch) => {
    const listItems =
      searchTerm && showSearch
        ? items.filter(
            (item) =>
              item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              item.description.toLowerCase().includes(searchTerm.toLowerCase()),
          )
        : items;

    return (
      <Card>
        <CardHeader
          className={classes.cardHeader}
          classes={{ subheader: classes.cardSubheader }}
          avatar={
            showSearch ? null : (
              <>
                <Checkbox
                  onClick={handleToggleAll(items)}
                  checked={numberOfChecked(items) === items?.length && items.length !== 0}
                  indeterminate={
                    numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
                  }
                  disabled={items?.length === 0}
                  inputProps={{ 'aria-label': 'all items selected' }}
                  // className={classes.white}
                  classes={{
                    root: classes.white,
                    checked: classes.white,
                    disabled: classes.white,
                    indeterminate: classes.white,
                    colorSecondary: classes.white,
                  }}
                />
              </>
            )
          }
          title={
            showSearch ? (
              <Grid item sm={12}>
                <Paper className={classes.rootPaper}>
                  <InputBase
                    className={classes.input}
                    placeholder={`Search ${
                      reportOnPortfolio ? `Portfolio Model` : `Estimator Model`
                    }`}
                    inputProps={{ 'aria-label': 'Search' }}
                    inputRef={searchInput}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSearch();
                      }
                    }}
                  />
                  <IconButton
                    onClick={handleSearch}
                    className={classes.iconButton}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                  <Divider className={classes.divider} orientation="vertical" />
                  <OrderBy order={order} handleOrderChange={handleOrderChange} icon />
                  <Divider className={classes.divider} orientation="vertical" />
                  <IconButton
                    onClick={resetSearch}
                    color="primary"
                    className={classes.iconButton}
                    aria-label="clear"
                  >
                    <ClearIcon />
                  </IconButton>
                </Paper>
              </Grid>
            ) : (
              <>
                <Box position="relative">
                  <span>{title}</span>
                </Box>
              </>
            )
          }
          subheader={
            showSearch ? `` : `${numberOfChecked(listItems)}/${listItems?.length} selected`
          }
        />
        <Divider />
        <List className={classes.list} dense component="div" role="list">
          {listItems.map((item, index) => {
            const labelId = `transfer-list-all-item-${item.name}-label`;
            const modelValidation = {
              status: item.status === 'verified',
              issues: ['No end date selected', 'Missing macrofactors'],
            };
            const isModelValid = modelValidation?.status;
            const issues = modelValidation?.issues;

            const primaryTitle = (
              <>
                <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                  <Box style={{ wordBreak: 'break-all' }}>
                    <Typography
                      variant="body2"
                      classes={{
                        root: isModelValid ? `` : classes.invalidModel,
                      }}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                  <Box>
                    <ModelStatus status={isModelValid} issues={issues} />
                  </Box>
                </Box>
              </>
            );
            const secondary = (
              <Box style={{ wordBreak: 'break-all' }}>
                <Typography variant="body2">{item.description}</Typography>
                {item.id && item.created_at && (
                  <Typography className={classes.dateLabel} variant="caption">
                    Created: {moment(item.created_at).format('MMMM Do YYYY, h:mm:ss a')}
                  </Typography>
                )}
                {item.id && item.last_modified && (
                  <Typography className={classes.dateLabel} variant="caption">
                    Modified: {moment(item.last_modified).format('MMMM Do YYYY, h:mm:ss a')}
                  </Typography>
                )}
              </Box>
            );

            return (
              <ListItem
                key={item.id}
                role="listitem"
                button={isModelValid}
                onClick={isModelValid ? handleToggle(item) : null}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={isModelValid && checked.indexOf(item) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                    disabled={!isModelValid}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={primaryTitle} secondary={secondary} />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Card>
    );
  };

  return (
    <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
      <Grid item sm={12}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6" component="span">
            Select {reportOnPortfolio ? `Portfolio Model` : `Estimator Model`}
          </Typography>
        </Box>
      </Grid>
      <Grid item sm={5}>
        {customList('Choices', left, true)}
      </Grid>
      <Grid item sm={2}>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid sm={5} item>
        {customList('Chosen', right, false)}
      </Grid>
    </Grid>
  );
}
