import { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { help } from 'config/help';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    padding: theme.spacing(2),
    fontSize: theme.typography.pxToRem(14),
  },
}))(Tooltip);

const TooltipHtml = ({ help }) => {
  return (
    <>
      {help.map((item, index) => {
        return (
          <Box key={index} style={{ marginBottom: 10 }}>
            <Typography variant="subtitle" color="inherit" style={{ fontSize: 16 }}>
              {item.title}
            </Typography>
            <Typography variant="body2" color="inherit" component="p">
              {item.description}
            </Typography>
          </Box>
        );
      })}
    </>
  );
};

export const HelpInfo = ({ name, title, isHtml }) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const tooltipText = help[name] ? help[name] : title;
  const tooltipContent = isHtml ? <TooltipHtml help={help[name]} /> : tooltipText;
  return (
    <Box display="flex" alignItems="center">
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <LightTooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={tooltipContent}
          placement="top"
        >
          <IconButton aria-label="Help" onClick={handleTooltipOpen}>
            <InfoOutlinedIcon />
          </IconButton>
        </LightTooltip>
      </ClickAwayListener>
    </Box>
  );
};
