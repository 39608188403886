import * as React from 'react';
import moment from 'moment';
import groupBy from 'lodash.groupby';

// mui
import Box from '@material-ui/core/Box';

// app
import { ParalaLineChart, IndividualAssetPercentContributions } from 'components/Dashboard';

import { formatDataToPieChart, formatDataToLineChart } from 'lib/chart';
import { getColorById } from 'lib/colors';
import { vintageRegex, findNameWithId } from './attributesData';

export const AssetAllocation = ({
  pyPortfolioReturns,
  idsAndNames,
  dateIndex,
  showKeys = false,
}) => {
  const pyPortfolioReturnsFormatted = React.useMemo(() => {
    return formatDataToPieChart(pyPortfolioReturns, idsAndNames, vintageRegex);
  }, [pyPortfolioReturns, idsAndNames]);

  const pyPortfolioReturnsFormattedLineChart = React.useMemo(() => {
    return formatDataToLineChart(pyPortfolioReturns, idsAndNames, vintageRegex).sort(
      (a, b) => a.date - b.date,
    );
  }, [pyPortfolioReturns, idsAndNames]);

  const firstPyPortfolioReturns = Object.keys(pyPortfolioReturns[0]).filter((key) =>
    vintageRegex.test(key),
  );

  const PyPortfolioFormattedAreaChart = firstPyPortfolioReturns
    .map((item, index) => {
      const [name, id] = item.split('_');

      const riskFactorName = findNameWithId(id, idsAndNames);
      const color = getColorById(id);

      return {
        id: id,
        activeDot: false,
        color: color,
        dataKey: `${id}.value`,
        dot: false,
        fill: color,
        longName: riskFactorName,
        name: riskFactorName,
        stroke: color,
        strokeWidth: 3,
        type: 'area',
        stackId: 1,
        unit: '%',
        yAxisId: 'y-axis-1',
      };
    })
    .filter((item) => item.id)
    .sort((a, b) => a.name.localeCompare(b.name));

  const pieChartDataObject = groupBy(pyPortfolioReturnsFormatted, 'dateFormatted');

  const pieChartData = Object.keys(pieChartDataObject).map((key) => {
    return {
      id: key,
      name: moment(key, 'DD/MM/YYYY').format('MMMM YYYY'),
      data: pieChartDataObject[key][0].data,
    };
  });

  if (pieChartData.length === 0) {
    return null;
  }

  return (
    <Box>
      <IndividualAssetPercentContributions
        title="Asset Allocation as of "
        chartData={pieChartData}
      />
      <Box>
        <ParalaLineChart
          chart={PyPortfolioFormattedAreaChart}
          lineCharData={pyPortfolioReturnsFormattedLineChart}
          title="Asset Allocation Over Time"
          description=""
          showKeys={showKeys}
          dateIndex={dateIndex}
          yAxisLabels={{
            left: 'Contribution',
          }}
          chartProps={{
            yAxis1: {
              domain: [0, 100],
              // format round integer
              tickFormatter: (value) => `${Math.round(value)}%`,
            },
          }}
        />
      </Box>
    </Box>
  );
};
