/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, Chip } from '@material-ui/core';

import InlineInput from '../InlineInput';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
  paper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  input: {
    padding: '5px',
    '& .MuiOutlinedInput-input': {
      padding: '5px',
    },
    '& .MuiInput-underline:before': {
      border: 0,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 0,
    },
  },
  popper: {
    width: '100%!important',
    position: 'relative',
  },
  chip: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const RiskFactorDialog = ({
  modalTitle,
  fieldOptions,
  items,
  isOpen,
  close,
  handleDelete,
  handleSave,
}) => {
  const classes = useStyles();

  const { control, watch, setValue } = useFormContext();

  useEffect(() => {
    setValue(fieldOptions.maximum.name, fieldOptions.maximum.defaultValue);
    setValue(fieldOptions.minimum.name, fieldOptions.minimum.defaultValue);
  }, []);

  const minMaxValues = watch([fieldOptions.minimum.name, fieldOptions.maximum.name]);

  const handleClose = () => {
    close(false);
  };

  const handleConfirm = () => {
    // TODO: save data
    handleSave(minMaxValues, items);
    close(false);
  };

  return (
    <div style={{ minWidth: 400 }}>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isOpen}
        onClose={handleClose}
        style={{ minWidth: 400 }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{modalTitle}</DialogTitle>
        <DialogContent className={classes.DialogContent}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div className={classes.chip}>
                {items.map((item) => {
                  return items.length > 1 ? (
                    <Chip
                      key={item.id}
                      onDelete={handleDelete(item)}
                      label={item.name}
                      color="primary"
                    />
                  ) : (
                    <Chip key={item.id} label={item.name} color="primary" />
                  );
                })}
              </div>
            </Grid>
            <Grid item xs={12}>
              <InlineInput
                control={control}
                defaultValue={fieldOptions.minimum.defaultValue}
                text={fieldOptions.minimum.title}
                type="number"
                name={fieldOptions.minimum.name}
                leftGrid={8}
                rightGrid={4}
              />
            </Grid>
            <Grid item xs={12}>
              <InlineInput
                control={control}
                defaultValue={fieldOptions.maximum.defaultValue}
                text={fieldOptions.maximum.title}
                type="number"
                name={fieldOptions.maximum.name}
                leftGrid={8}
                rightGrid={4}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="primary">
            Cancel
          </Button>

          <Button onClick={() => handleConfirm()} type="submit" variant="contained" color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RiskFactorDialog;
