import { useQuery } from 'react-query';
import jobsService from 'services/JobsService';

export const useGetReportDetails = (id) => {
  return useQuery(['REPORTS_DETAILS', id], () => jobsService.getJobDetails(id), {
    staleTime: Infinity,
    cacheTime: Infinity,
    retry: 1,
  });
};
