// MUI

import Box from '@material-ui/core/Box';

import { ChartWithSelect, ParalaLineChart } from 'components/Dashboard';

export const AssetLevelAttributionReports = ({
  showReports,
  showKeys,
  dateIndex,
  chartDataGroupedById,
  decompositionOfAlphaForTheAssets,
  extractUnconditionalAlphaDecomposition,
  extractConditionalRiskFactorExpectedReturn,
  extractUnConditionalRiskFactorExpectedReturn,
}) => {
  return (
    <Box display="flex" flex={1} flexDirection="column">
      {/* decompositionOfAlphaForTheAssets */}
      {showReports.decompositionOfAlphaForTheAssets.visible ? (
        <Box
          style={{
            marginBottom: 30,
            paddingBottom: 30,
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        >
          <ChartWithSelect
            chartId="decompositionOfAlphaForTheAssets"
            chart={decompositionOfAlphaForTheAssets}
            chartDataGroupedById={chartDataGroupedById}
            // isBiAxis={true}
            // chartProps={{
            //   yAxis2: {
            //     domain: [0, 100],
            //   },
            // }}
            // yAxisLabels={{
            //   left: 'Return',
            //   right: 'Portfolio weight',
            // }}
            title="Decomposition of alpha for the assets"
            description="
          This chart decomposes alpha contributions returns to {REPLACE_NAME}. Time-varying alpha captures (alpha1 x expected macro-factor change)."
            showKeys={showKeys}
            dateIndex={dateIndex}
          />
        </Box>
      ) : null}
      {/* decompositionOfAlphaForTheAssets */}
      {/* extractUnconditionalAlphaDecomposition */}
      {showReports.extractUnconditionalAlphaDecomposition.visible ? (
        <Box
          style={{
            marginBottom: 30,
            paddingBottom: 30,
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        >
          <ChartWithSelect
            chartId="extractUnconditionalAlphaDecomposition"
            chart={extractUnconditionalAlphaDecomposition}
            chartDataGroupedById={chartDataGroupedById}
            // isBiAxis={true}
            title="Unconditional alpha decomposition"
            description="This chart shows how the model learns as the calibration horizon lengthens. It shows
          the time-varying alpha for each macro-factor (alpha1 x the time-averaged macro-factor
          value) for {REPLACE_NAME}."
            showKeys={showKeys}
            dateIndex={dateIndex}
          />
        </Box>
      ) : null}
      {/* extractUnconditionalAlphaDecomposition */}
      {/* extractConditionalRiskFactorExpectedReturn */}
      {showReports.extractConditionalRiskFactorExpectedReturn.visible ? (
        <Box
          style={{
            marginBottom: 30,
            paddingBottom: 30,
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        >
          <ChartWithSelect
            chartId="extractConditionalRiskFactorExpectedReturn"
            chart={extractConditionalRiskFactorExpectedReturn}
            chartDataGroupedById={chartDataGroupedById}
            // isBiAxis={true}
            title="Conditional risk factor expected returns"
            description="This chart shows the total beta contribution to return (ie [beta0 + sum(beta1s x
macro-factors)] x expected risk factor return) for {REPLACE_NAME}."
            showKeys={showKeys}
            dateIndex={dateIndex}
          />
        </Box>
      ) : null}
      {/* extractConditionalRiskFactorExpectedReturn */}
      {/* extractUnConditionalRiskFactorExpectedReturn */}
      {showReports.extractUnConditionalRiskFactorExpectedReturn.visible ? (
        <Box
          style={{
            marginBottom: 30,
            paddingBottom: 30,
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        >
          <ChartWithSelect
            chartId="extractUnConditionalRiskFactorExpectedReturn"
            chart={extractUnConditionalRiskFactorExpectedReturn}
            chartDataGroupedById={chartDataGroupedById}
            // isBiAxis={true}
            title="Unconditional risk factor expected returns"
            description="This chart shows each constant beta contribution to total return (beta0 x its expected
risk factor return) for {REPLACE_NAME}."
            showKeys={showKeys}
            dateIndex={dateIndex}
          />
        </Box>
      ) : null}
      {/* extractUnConditionalRiskFactorExpectedReturn */}
    </Box>
  );
};
