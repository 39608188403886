/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useFormContext } from 'react-hook-form';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography, Grid, Chip, InputAdornment } from '@material-ui/core';

import InlineInput from '../InlineInput';
import MUISwitch from '../MUISwitch';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
  paper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  input: {
    padding: '5px',
    '& .MuiOutlinedInput-input': {
      padding: '5px',
    },
    '& .MuiInput-underline:before': {
      border: 0,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 0,
    },
  },
  popper: {
    width: '100%!important',
    position: 'relative',
  },
  chip: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const MultiAssetConstraintDialog = ({ items, isOpen, close, handleSave, handleDelete }) => {
  const classes = useStyles();

  const { control, register, watch } = useFormContext();

  const minMaxValues = watch(['minimumClassWeight', 'maximumClassWeight', 'applyNetConstraint']);

  const handleClose = () => {
    close(false);
  };

  const handleConfirm = () => {
    // TODO: save data
    handleSave(minMaxValues, items);
    close(false);
  };

  return (
    <div style={{ minWidth: 400 }}>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isOpen}
        onClose={handleClose}
        style={{ minWidth: 400 }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Set Weights for:</DialogTitle>
        <DialogContent className={classes.DialogContent}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div className={classes.chip}>
                {items?.map((item) => {
                  return items?.length > 1 ? (
                    <Chip
                      key={item.id}
                      onDelete={handleDelete(item)}
                      label={item.displayValue}
                      color="primary"
                    />
                  ) : (
                    <Chip key={item.displayValue} label={item.displayValue} color="primary" />
                  );
                })}
              </div>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle" component="span">
                Apply Net Constraint
              </Typography>

              <MUISwitch register={register} defaultCheck={false} name="applyNetConstraint" />
            </Grid>
            <Grid item xs={12}>
              <InlineInput
                control={control}
                text="Minimum class weight"
                type="number"
                name="minimumClassWeight"
                leftGrid={8}
                rightGrid={4}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
              />
            </Grid>
            <Grid item xs={12}>
              <InlineInput
                control={control}
                text="Maximum class weight"
                type="number"
                name="maximumClassWeight"
                leftGrid={8}
                rightGrid={4}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="primary">
            Cancel
          </Button>

          <Button onClick={() => handleConfirm()} type="submit" variant="contained" color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MultiAssetConstraintDialog;
