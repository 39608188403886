import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';

import { Box, Typography, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import AddIcon from '@material-ui/icons/Add';

import { HelpInfo } from '../HelpInfo';
import FormAutoComplete from '../FormAutoComplete';
import MUISwitch from '../MUISwitch';
import SelectedMultiAssetConstraint from '../SelectedMultiAssetConstraint';

import portfolioService from '../../services/PortfolioService';

import { addMultiAssetConstraint } from '../../redux/actions';

const MultiAssetConstraints = () => {
  const [addConstraintStatus, setAddConstraintStatus] = useState(true);
  const [constraintsTable, setConstraintsTable] = useState([]);
  const [constraintsTableField, setConstraintsTableField] = useState([]);
  const [constraintsTableFieldValue, setConstraintsTableFieldValue] = useState([]);
  const [uuid, setUuid] = useState('');
  const dispatch = useDispatch();

  const { control, setValue, register, watch } = useFormContext();

  const { selectedMultiAssetConstraint } = useSelector((state) => state.portfolioModel);

  const selectedConstraintsTable = watch('constraintsTable');
  const selectedConstraintsTableField = watch('constraintsTableField');
  const selectedConstraintsTableFieldValue = watch('constraintsTableFieldValue');
  const applyConstraints = watch('applyConstraints');

  useEffect(() => {
    const fetchData = async () => {
      const result = await portfolioService.getConstraintsTable();
      setConstraintsTable(result.data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const hasAllValues =
      selectedConstraintsTable &&
      selectedConstraintsTableField &&
      selectedConstraintsTableFieldValue &&
      applyConstraints;

    setAddConstraintStatus(!hasAllValues);
  }, [
    selectedConstraintsTable,
    selectedConstraintsTableField,
    selectedConstraintsTableFieldValue,
    applyConstraints,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await portfolioService.getConstraintsTableField(selectedConstraintsTable.id);
      setValue('constraintsTableField', null);
      setValue('constraintsTableFieldValue', []);
      setConstraintsTableFieldValue([]);
      setConstraintsTableField(result.data);
      setUuid(new Date().getTime());
    };
    selectedConstraintsTable?.id && fetchData();
  }, [selectedConstraintsTable?.id, setValue]);
  // TODO: reset autocomplete component when parent dropdown changes options/values
  useEffect(() => {
    const fetchData = async () => {
      const result = await portfolioService.getConstraintsTableFieldValue(
        selectedConstraintsTableField.id,
      );
      setValue('constraintsTableFieldValue', []);
      setConstraintsTableFieldValue(result.data);
      setUuid(new Date().getTime());
    };
    selectedConstraintsTableField?.id && fetchData();
  }, [selectedConstraintsTableField?.id, setValue]);

  const addConstraint = () => {
    // build costraint object based on the Multi-asset constraint type applyConstraints 1 - UNIQUE, 2 TOTAL_WEIGHT
    let constraint = [];
    if (applyConstraints === 'UNIQUE') {
      constraint = selectedConstraintsTableFieldValue.map((fieldValue) => {
        return {
          customTable: selectedConstraintsTable,
          customTableField: selectedConstraintsTableField,
          fieldValue: fieldValue,
          applyConstraints,
          conditionValue: `= '${fieldValue?.name}'`,
          displayValue: `${selectedConstraintsTable?.name}.${selectedConstraintsTableField?.name} = '${fieldValue?.name}'`,
        };
      });
    } else if (applyConstraints === 'TOTAL_WEIGHT') {
      const constraintFields = selectedConstraintsTableFieldValue
        .map((fieldValue) => {
          return `'${fieldValue.name}'`;
        })
        .join();

      constraint = [
        {
          customTable: selectedConstraintsTable,
          customTableField: selectedConstraintsTableField,
          fieldValue: constraintFields,
          applyConstraints,
          conditionValue: `IN (${constraintFields})`,
          displayValue: `${selectedConstraintsTable?.name}.${selectedConstraintsTableField?.name} = IN (${constraintFields})`,
        },
      ];
    }
    dispatch(addMultiAssetConstraint(constraint));
    // TODO: reset constraint table fields after add to redux
    setValue('constraintsTableField', null);
    setValue('constraintsTableFieldValue', []);
    setConstraintsTableFieldValue([]);
    setUuid(new Date().getTime());
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={1} xs={12} alignItems="center">
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle" component="span">
                Include Constrained Assets
              </Typography>
              <HelpInfo name="includeConstrainedAssets" />
              <MUISwitch register={register} defaultCheck={true} name="includeConstrainedAssets" />
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" as="h5">
            Constraint Table
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormAutoComplete options={constraintsTable} name="constraintsTable" />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" as="h5">
            Custom Field
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormAutoComplete
            key={uuid}
            options={constraintsTableField}
            name="constraintsTableField"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" as="h5">
            Table Field Value(s)
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormAutoComplete
            key={uuid}
            multiple
            options={constraintsTableFieldValue}
            name="constraintsTableFieldValue"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" as="h5">
            Multi-asset constraint type
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <Controller
              id="model-core"
              name="applyConstraints"
              control={control}
              render={(props) => {
                return (
                  <RadioGroup aria-label="Apply constraints" {...props}>
                    <FormControlLabel
                      value="UNIQUE"
                      checked={props.value === 'UNIQUE'}
                      control={<Radio />}
                      label="Apply constraints to all sets of values for each UNIQUE field value"
                    />
                    <FormControlLabel
                      value="TOTAL_WEIGHT"
                      checked={props.value === 'TOTAL_WEIGHT'}
                      control={<Radio />}
                      label="Apply constraints to the TOTAL WEIGHT in Assets matching the selected field values"
                    />
                  </RadioGroup>
                );
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Button
            onClick={() => addConstraint()}
            disabled={addConstraintStatus}
            type="button"
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
          >
            Add Constraint
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" as="h5">
            Selected Multi-Asset Constraint
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <SelectedMultiAssetConstraint listItems={selectedMultiAssetConstraint} />
        </Grid>
      </Grid>
    </>
  );
};

export default MultiAssetConstraints;
