import { combineReducers } from 'redux';
import appReducer from './app/app.reducer';
import authReducer from './auth/auth.reducer';
import dataForAnalysisReducer from './dataForAnalysis/data.for.analysis.reducer';
import estimatorModelReducer from './estimatorModel/estimator.model.reducer';
import portfolioModelReducer from './portfolioModel/portfolio.reducer';
import reportingReducer from './reporting/reporting.reducer';
import rankingReportReducer from './rankingReport/rankingReport.reducer';

// COMBINED REDUCERS
const rootReducer = {
  app: appReducer,
  auth: authReducer,
  dataForAnalysis: dataForAnalysisReducer,
  estimatorModel: estimatorModelReducer,
  portfolioModel: portfolioModelReducer,
  reporting: reportingReducer,
  rankingReport: rankingReportReducer,
};

export default combineReducers(rootReducer);
