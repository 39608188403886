import * as types from '../../types';

const initialData = {
  portfolioData: {
    name: '',

    constraintsTableFieldValue: [],
    referenceBenchmark: [],
    portfolioModelParameters: null,
    numberOrPercentage: null,
    numberAssets: '',
    assetsRankingCriteria: null,
    sortingOrder: null,
    multiPeriodForecasting: null,
    portfolioWeights: null,
    allowZeroWeights: null,
    MultiStatWeightsMean: null,
    MultiStatWeightsAlpha: null,
    MultiStatWeightsVariance: null,
    MultiStatWeightsTrackingError: null,
    MultiStatWeightsUtility: null,
    MultiStatWeightsSharpeRatio: null,
    MultiStatWeightsInfoRatio: null,
    riskAversionCoEfficient: null,
    includeConstrainedAssets: false,
  },
  exposureTolerance: [],
  assetRiskFactorCorrelations: [],
  selectedMultiAssetConstraint: [],
};

const portfolioModelReducer = (state = { ...initialData }, action) => {
  switch (action.type) {
    case types.USER_LOGOUT:
      return initialData;
    case types.PORTFOLIO_MODEL_LOAD_DATA: {
      const {
        exposureTolerance,
        assetRiskFactorCorrelations,
        selectedMultiAssetConstraint,
        ...rest
      } = action.payload;
      return {
        ...state,
        portfolioData: {
          ...state.portfolioData,
          ...rest,
        },
        exposureTolerance,
        assetRiskFactorCorrelations,
        selectedMultiAssetConstraint,
      };
    }
    case types.PORTFOLIO_MODEL_SAVE_DATA:
      return {
        ...state,
        portfolioData: {
          ...state.portfolioData,
          ...action.payload,
        },
      };
    case types.PORTFOLIO_MODEL_SAVE_DATA_IN_STATE:
      return {
        ...state,
        portfolioData: action.payload,
      };
    case types.PORTFOLIO_MODEL_RESET:
      return {
        ...initialData,
        portfolioModelParameters: state.portfolioModelParameters,
      };
    case types.ADD_MULTI_ASSET_CONSTRAINT:
      return {
        ...state,
        selectedMultiAssetConstraint: [...state.selectedMultiAssetConstraint, ...action.payload],
      };
    case types.ADD_MULTI_ASSET_CONSTRAINT_WEIGHT: {
      const updatedMultiAssetConstraint = state.selectedMultiAssetConstraint.map((constraint) => {
        return action.payload.constraints.includes(constraint)
          ? { ...constraint, weight: { ...action.payload.values } }
          : constraint;
      });
      return {
        ...state,
        selectedMultiAssetConstraint: [...updatedMultiAssetConstraint],
      };
    }
    case types.REMOVE_MULTI_ASSET_CONSTRAINT:
      return {
        ...state,
        selectedMultiAssetConstraint: state.selectedMultiAssetConstraint.filter(
          (item) => item !== action.payload,
        ),
      };
    case types.ADD_EXPOSURE_TOLERANCE: {
      const exposureTolerance = [
        ...state.exposureTolerance.filter(
          (exposure) => !action.payload.find((item) => item.id === exposure.id),
        ),
        ...action.payload,
      ];

      return {
        ...state,
        exposureTolerance: exposureTolerance,
      };
    }
    case types.ADD_NEUTRAL_RISK_FACTOR: {
      return {
        ...state,
        assetRiskFactorCorrelations: [
          ...state.assetRiskFactorCorrelations.filter(
            (neutral) => !action.payload.find((item) => item.id === neutral.id),
          ),
          ...action.payload,
        ],
      };
    }
    case types.PORTFOLIO_MODEL_GET_PARAMETERS: {
      return {
        ...state,
        portfolioModelParameters: action.payload,
      };
    }
    default:
      return state;
  }
};

export default portfolioModelReducer;
