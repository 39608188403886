import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';

export function LoadingItem({ isLoading, title }) {
  return (
    <>
      <ListItem alignItems="center">
        <ListItemIcon>
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            <CheckIcon
              style={{
                color: 'green',
              }}
            />
          )}
        </ListItemIcon>
        <ListItemText primary={title} />
      </ListItem>
    </>
  );
}
