/* eslint-disable operator-linebreak */
/* eslint-disable arrow-body-style */
import axios from 'axios';
import omit from 'lodash.omit';
import moment from 'moment';
import { getAccessToken } from './firebase';
import { removeNullFromData } from './util';

import universeService from './UniverseService';

const estimatorService = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    // Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

estimatorService.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.data.errors &&
      error.response.data.errors[0].code === 'INVALID_TOKEN'
    ) {
      // return AuthService.updateAccessToken().then((accessToken) => {
      //   error.config.headers.Authorization = `Bearer ${accessToken}`;
      //   return apiClient.request(error.config);
      // });
    }
    return Promise.reject(error);
  },
);

const formatSubSetMacrofactors = (columns) => {
  // const omitList = ['macrofactors'];
  // const formattedColumns = omit(columns, omitList);
  //  Subset: key === 'macrofactors' ? 'macrofactors' : parseInt(key.replace('category-', '')),

  let macrofactors = [];

  for (const [key, value] of Object.entries(columns)) {
    macrofactors = [
      ...macrofactors,
      ...value?.arrayOfIDs.map((macrofactorID) => ({
        macroFactorId: parseInt(macrofactorID),
        subsetNumber: key === 'macrofactors' ? 0 : parseInt(key.replace('category-', '')),
      })),
    ];
    // spec[key] = omit(formattedFormData[key], omitList);
  }

  return macrofactors;
};

const formatMacrofactors = (columns) => {
  const { macrofactors } = columns;

  return macrofactors?.arrayOfIDs || [];
};

const formatBenchmarks = (benchmarks, premium, loadings) => {
  return benchmarks.map((benchmark, index) => ({
    benchmarkId: benchmark.id,
    selfFinancing: benchmark.selfFinancing,
    predictablePremium: premium[index] ? 1 : 0,
    predictableLoading: loadings[index] ? 1 : 0,
  }));
};

const formatEstimatorBeforePost = (spec) => {
  const formattedSpec = {
    alpha: {
      alphaModel: parseFloat(spec.coreModelSpecification, 10),
      sigmaAlpha: spec?.sigmaAlpha ? parseFloat(spec?.sigmaAlpha, 10) : 0,
    },
    beta: {
      benchmark: formatBenchmarks(spec.benchmarks, spec.premium, spec.loadings),
    },
    model: {
      estimationStrategy: parseFloat(spec.modelEstimationStrategy, 10),
      simulationBlocks: parseFloat(spec.simulationBlocks, 10),
      drawsPerBlocks: parseFloat(spec.drawsPerBlock, 10),
      adjustmentSpeed: parseFloat(spec.adjustmentSpeed, 10),
      maxH: parseInt(spec?.maxH, 10),
      savePredDensity: spec?.savePredDensity || false,
    },
    vintage: {
      startDate: moment(spec.startDate).format('YYYY-MM-DD'),
      endDate: moment(spec.endDate).format('YYYY-MM-DD'),
      frequency: parseInt(spec.generationFrequency, 10),
      minNumberOfObservations: parseInt(spec.minNumberOfObservations, 10),
      rollingWindow: spec.rollingWindow,
      windowLength: spec.rollingWindow ? parseInt(spec.vintageWindowLength, 10) : 0,
      // ...(spec?.rollingWindow && {
      //   windowLength: parseInt(spec.vintageWindowLength, 10),
      // }),
      customizeVintageCalendar: spec.customizeVintageCalendar,
      ...(spec?.customizeVintageCalendar && {
        vintageMonthEndDay: parseInt(spec.vintageMonthEndDay, 10),
      }),
    },

    ...(spec?.enableCompleteSubsetRegression && {
      completeSubsetRegression: {
        enableCSR: spec.enableCompleteSubsetRegression || false,
        subsetCounts: parseInt(spec.variableCount, 10),
        modelWeightingRule: spec.modelWeightRule,
        gamma: spec.gammaCSR,
        consolidationRule: spec.consolidationRule,
        macrofactors: formatSubSetMacrofactors(spec?.subsetRegressionData?.columns),
      },
    }),
  };
  return removeNullFromData(formattedSpec);
};

const saveSimulatedModelCombinations = async (formData, type = 'estimator', spec_id) => {
  const accessToken = await getAccessToken();

  try {
    const { name, description, ref_spec_id } = formData;

    // get only the variables used in model combinations
    const { modelWeightRuleSimulatedModels, weightingSensitivity, selectedModels } =
      formData?.estimatorModel;
    const formattedFormData = {
      modelWeightRuleSimulatedModels,
      weightingSensitivity,
      selectedModels,
      isSimulatedModelCombination: true,
    };
    const spec = {};

    // const formattedSpec = formatEstimatorBeforePost(formData.estimatorModel);
    const formattedSpec = formattedFormData;
    // if name has SMC in it then don't add SMC to the name, else add SMC to the name
    const nameWithSMC = name.includes(' - SMC') ? name : `${name} - SMC`;

    const data = {
      name: nameWithSMC,
      description,
      ref_spec_id,
      spec: formattedSpec,
      type,
    };

    const specData = spec_id
      ? await estimatorService.patch(`/specs/${spec_id}`, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
      : await estimatorService.post('/specs', data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        });

    const formattedResult = formatSMCResultData(specData.data);

    return formattedResult;
  } catch (error) {
    if (error.response.data) return { error: error.response.data };
    throw new Error(error);
  }
};

const saveEstimatorModelSpec = async (formData, type = 'estimator', spec_id) => {
  const accessToken = await getAccessToken();

  try {
    const { name, description, ref_spec_id } = formData;

    const omitList = [
      'id',
      'name',
      'description',
      'created_at',
      'last_modified',
      'loadingsAll',
      'premiumAll',
    ];
    const formattedFormData = omit(formData, omitList);
    const spec = {};

    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(formattedFormData)) {
      spec[key] = omit(formattedFormData[key], omitList);
    }
    const formattedSpec = formatEstimatorBeforePost(formData.estimatorModel);

    const data = {
      name,
      description,
      ref_spec_id,
      spec: formattedSpec,
      type,
    };

    const specData = spec_id
      ? await estimatorService.patch(`/specs/${spec_id}`, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
      : await estimatorService.post('/specs', data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        });

    const formattedResult = formatResultData(specData.data);

    return formattedResult;
  } catch (error) {
    if (error.response.data) return { error: error.response.data };
    throw new Error(error);
  }
};

const loadSpec = async (id) => {
  const accessToken = await getAccessToken();

  try {
    let formattedResult = {};
    const apiURL = `/specs/${id}`;
    const result = await estimatorService.get(apiURL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    if (result) {
      formattedResult = result.data?.spec?.isSimulatedModelCombination
        ? formatSMCResultData(result.data)
        : formatResultData(result.data);
    }

    return formattedResult;
  } catch (error) {
    return error;
  }
};

const formatSMCResultData = async (data) => {
  const { spec, ...rest } = data;

  return data;
};

const formatResultData = async (data) => {
  const estimation = data.spec;
  const { spec, ...rest } = data;

  const riskData = await buildRiskData(estimation?.beta?.benchmark);

  const subsetRegressionData = {
    columnOrder: [
      'macrofactors',
      'category-1',
      'category-2',
      'category-3',
      'category-4',
      'category-5',
      'category-6',
      'category-7',
      'category-8',
    ],
    columns: { ...buildSubsetRegressionData(estimation?.completeSubsetRegression) },
    macroeconomicVariables: await getMacroeconomicVariables(
      estimation?.completeSubsetRegression?.macrofactors,
    ),
  };

  const formattedData = {
    ...rest,
    coreModelSpecification: estimation?.alpha?.alphaModel,
    modelEstimationStrategy: estimation?.model?.estimationStrategy,
    sigmaAlpha: estimation?.alpha?.sigmaAlpha,
    simulationBlocks: estimation?.model?.simulationBlocks,
    drawsPerBlock: estimation?.model?.drawsPerBlocks,
    adjustmentSpeed: estimation?.model?.adjustmentSpeed,
    maxH: estimation?.model?.maxH,
    savePredDensity: estimation?.model?.savePredDensity,
    benchmarks: riskData?.benchmarks || [],
    premium: riskData?.premium || [],
    loadings: riskData?.loadings || [],
    startDate: estimation?.vintage?.startDate,
    endDate: estimation?.vintage?.endDate,
    generationFrequency: estimation?.vintage?.frequency,
    minNumberOfObservations: estimation?.vintage?.minNumberOfObservations,
    rollingWindow: estimation?.vintage?.rollingWindow,
    vintageWindowLength: estimation?.vintage?.windowLength,
    customizeVintageCalendar: estimation?.vintage?.customizeVintageCalendar,
    vintageMonthEndDay: estimation?.vintage?.vintageMonthEndDay,
    enableCompleteSubsetRegression: estimation?.completeSubsetRegression?.enableCSR,
    modelWeightRule: estimation?.completeSubsetRegression?.modelWeightingRule,
    variableCount: estimation?.completeSubsetRegression?.subsetCounts,
    gammaCSR: estimation?.completeSubsetRegression?.gamma,
    consolidationRule: getConsolidationRule(
      estimation?.completeSubsetRegression?.consolidationRule,
    ),
    subsetRegressionData,
  };

  return formattedData;
};

const getConsolidationRule = (rule) => {
  return rule?.value ? rule.value : rule;
};

const buildRiskData = async (betaBenchmark) => {
  // return object with 3 arrays: premium, loadings, benchmarks
  const benchmarksIDs = betaBenchmark?.map((benchmark) => {
    return {
      benchmarkId: benchmark?.benchmarkId,
      selfFinancing: benchmark?.selfFinancing,
    };
  });

  const benchmarkList = await getBenchmarks(benchmarksIDs);

  const loadings = betaBenchmark?.map((benchmark) =>
    benchmark?.predictableLoading === 1 ? true : false,
  );

  const premium = betaBenchmark?.map((benchmark) =>
    benchmark?.predictablePremium === 1 ? true : false,
  );

  return {
    benchmarks: benchmarkList,
    premium,
    loadings,
  };
};

const getBenchmarks = async (benchmarks) => {
  try {
    if (benchmarks?.length) {
      const benchmarksIDs = benchmarks.map((benchmark) => benchmark.benchmarkId);
      const benchmarksData = await universeService.getAssetsList(benchmarksIDs);
      const benchmarksDataSorted = benchmarks?.map((benchmark) => {
        const benchmarkInfo = benchmarksData?.data?.find(
          (data) => data.id === benchmark.benchmarkId,
        );

        return {
          ...benchmarkInfo,
          selfFinancing: benchmark.selfFinancing,
        };
      });

      return benchmarksDataSorted;
    }
    return [];
  } catch (error) {
    console.log('error', error);
    return error;
  }
};

const buildSubsetRegressionData = (data) => {
  const tempData = {
    macrofactors: {
      id: 'macrofactors',
      title: 'Macro Factors',
      arrayOfIDs: [],
    },
    'category-1': {
      id: 'category-1',
      title: 'Category 1',
      arrayOfIDs: [],
    },
    'category-2': {
      id: 'category-2',
      title: 'Category 2',
      arrayOfIDs: [],
    },
    'category-3': {
      id: 'category-3',
      title: 'Category 3',
      arrayOfIDs: [],
    },
    'category-4': {
      id: 'category-4',
      title: 'Category 4',
      arrayOfIDs: [],
    },
    'category-5': {
      id: 'category-5',
      title: 'Category 5',
      arrayOfIDs: [],
    },
    'category-6': {
      id: 'category-6',
      title: 'Category 6',
      arrayOfIDs: [],
    },
    'category-7': {
      id: 'category-7',
      title: 'Category 7',
      arrayOfIDs: [],
    },
    'category-8': {
      id: 'category-8',
      title: 'Category 8',
      arrayOfIDs: [],
    },
  };
  data?.macrofactors?.map((macrofactor) => {
    const category =
      macrofactor?.subsetNumber === 0 ? `macrofactorsD` : `category-${macrofactor?.subsetNumber}`;

    tempData[`${category}`]?.arrayOfIDs?.push(macrofactor.macroFactorId);

    return true;
  });

  return tempData;
};

const getMacroeconomicVariables = async (macrofactorsData) => {
  let arrayOfIDs = macrofactorsData?.map((macrofactor) => macrofactor.macroFactorId) || [];
  const macrofactorsList = (await universeService.getAssetsList(arrayOfIDs)) || [];

  const macroeconomicVariables = {};
  if (macrofactorsList?.length) {
    for (const item of macrofactorsList?.data) {
      macroeconomicVariables[item.id] = { id: `${item.id}`, name: item.name };
    }
  }

  return macroeconomicVariables;
};

const getAssets = async (id) => {
  const accessToken = await getAccessToken();

  try {
    const apiURL = `/universes/${id}/assets`;
    const assetsList = await estimatorService.get(apiURL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return assetsList.data;
  } catch (error) {
    return { error: true, message: error };
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  saveEstimatorModelSpec,
  saveSimulatedModelCombinations,
  loadSpec,
  getAssets,
};
