import * as React from 'react';
import { Waypoint } from 'react-waypoint';
import { ParalaPieChart, ParalaBarChart } from 'components/Dashboard';
import Box from '@material-ui/core/Box';
import { useCurrentPng } from 'recharts-to-png';

import { toPng } from 'html-to-image';

export const IndividualAssetPercentContributions = ({
  chartId,
  chart,
  chartData,
  title,
  description,
  showKeys,
  ...props
}) => {
  const [selectedChart, setSelectedChart] = React.useState(chartData[0]);
  const [visible, setVisible] = React.useState(false);
  const [downloadView, setDownloadView] = React.useState(false);

  const { data, id, name } = selectedChart;

  const handleMenuItemClick = (id) => {
    const selectedChartData = chartData.find((chartDataItem) => chartDataItem.id === id);
    setSelectedChart(selectedChartData);
  };

  const handleWaypointEnter = () => {
    setVisible(true);
  };

  const handleWaypointLeave = () => {
    setVisible(false);
  };

  const titleWithFundName = (
    <>
      {title} <strong>{name}</strong>.
    </>
  );

  const selectBoxData = chartData.map((chartDataItem) => {
    return {
      id: chartDataItem.id,
      name: chartDataItem.name,
    };
  });

  const chartRef = React.useRef(null);
  const [getPng, { ref, isLoading }] = useCurrentPng({ margin: '20px' });

  // test new download
  const onDownloadClick = React.useCallback(() => {
    if (chartRef.current === null) {
      return;
    }
    setDownloadView(true);
    const fileName = title.replace(/ /g, '_').toLowerCase();

    toPng(chartRef.current, {
      cacheBust: true,
      backgroundColor: 'white',
      style: { margin: '10px' },
    })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = `${fileName}.png`;
        link.href = dataUrl;
        link.click();
        setDownloadView(false);
      })
      .then(() => {
        setDownloadView(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [chartRef, title]);

  return (
    <Waypoint onEnter={handleWaypointEnter} onLeave={handleWaypointLeave}>
      {visible ? (
        <Box ref={chartRef}>
          <ParalaPieChart
            chartId="expectationsAndPortfolioWeights"
            chart={chart}
            pieCharData={data}
            title={titleWithFundName}
            description={description}
            showKeys={showKeys}
            selectBox={{
              data: selectBoxData,
              handleMenuItemClick: handleMenuItemClick,
              selectedId: id,
              secondaryText: 'Click here to select a fund',
            }}
            onDownloadClick={onDownloadClick}
            isLoading={isLoading}
            downloadView={downloadView}
          />
          <Box style={{ marginTop: '20px' }}>
            <ParalaBarChart
              chartId="expectationsAndPortfolioWeights"
              chart={chart}
              charData={data}
            />
          </Box>
        </Box>
      ) : null}
    </Waypoint>
  );
};
