import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';

import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';

import SaveIcon from '@material-ui/icons/Save';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Accordion, AccordionSummary, AccordionDetails } from './RankingReport.styles.js';

import SaveDataDialog from '../../components/SaveDataDialog';
import LoadDataDialog from '../../components/LoadDataDialog';
import AlertDialog from '../../components/AlertDialog';
import PreventNavigation from '../../components/PreventNavigation';

import Layout from '../../components/Layout';
import ReportingPreferences from '../../components/ReportingPreferences';
import RankingReportModelSelector from '../../components/RankingReportModelSelector';
import RankingReportTab from '../../components/RankingReportTab';
import GenerateRankingReports from '../../components/GenerateRankingReports';

import {
  saveRankingReportingOutput,
  saveRankingReportingOutputInState,
  loadRankingReportingOutput,
  deleteRankingReportingOutput,
  resetRankingReportingOutput,
  isDirtyRankingReportingOutput,
} from '../../redux/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: 0,
  },
  heading: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightRegular,
  },
  button: {
    margin: theme.spacing(1),
  },
  optionHeading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const RankingReport = () => {
  const [isSaveFormOpen, setSaveFormDialog] = useState(false);
  const [isLoadFormOpen, setLoadFormDialog] = useState(false);
  const [isResetOpen, setIsResetOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const dispatch = useDispatch();
  const { rankingReportData } = useSelector((state) => state.rankingReport);
  const { name, description, id, spec, selectedModels } = rankingReportData;

  const classes = useStyles();
  const methods = useForm({ defaultValues: spec, shouldUnregister: false });
  const { watch, control } = methods;
  const watchForm = methods.watch();
  const { isDirty } = methods.formState;

  const formValues = watch();

  useEffect(() => {
    const initialRankingReportData = {};

    methods.reset(initialRankingReportData);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    methods.reset(rankingReportData);
  }, [rankingReportData.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async (data) => {
    const idForm = rankingReportData?.id ? rankingReportData.id : null;
    const formData = { ...data, selectedModels };
    dispatch(saveRankingReportingOutput(formData, idForm));
    methods.reset(data);
    setSaveFormDialog(false);
  };

  const onSubmitNew = async (data) => {
    const formData = { ...data, selectedModels };
    dispatch(saveRankingReportingOutput(formData, null));
    methods.reset(data);
    setSaveFormDialog(false);
  };
  const onError = (errors, e) => console.log(errors, e);

  const saveToStore = async () => {
    const formData = { ...watchForm, selectedModels };
    dispatch(saveRankingReportingOutputInState(formData));
  };

  const handleSaveClose = () => {
    setSaveFormDialog(false);
  };
  const handleLoadClose = () => {
    setLoadFormDialog(false);
  };
  const handleSave = () => {
    methods.handleSubmit(onSubmit, onError)();
    setSaveFormDialog(false);
  };

  const handleSaveAsNew = () => {
    methods.handleSubmit(onSubmitNew, onError)();
  };

  const handleOpenSave = () => {
    setSaveFormDialog(true);
  };

  const handleLoad = () => {
    setLoadFormDialog(true);
  };

  const handleLoadSpec = (id) => {
    dispatch(loadRankingReportingOutput(id));
    dispatch(isDirtyRankingReportingOutput(false));
  };

  const handleReset = () => {
    setIsResetOpen(true);
  };
  const handleResetConfirm = () => {
    dispatch(resetRankingReportingOutput());
    methods.reset({}, { isDirty: false });
    setIsResetOpen(false);
  };
  const handleResetCancel = () => {
    setIsResetOpen(false);
  };

  const handleDelete = () => {
    setIsDeleteOpen(true);
  };

  const handleDeleteConfirm = () => {
    dispatch(deleteRankingReportingOutput(id));
    methods.reset({}, { isDirty: false });
    setIsDeleteOpen(false);
  };
  const handleDeleteCancel = () => {
    setIsDeleteOpen(false);
  };

  return (
    <Layout>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
          <SaveDataDialog
            isOpen={isSaveFormOpen}
            close={handleSaveClose}
            save={handleSave}
            saveAsNew={handleSaveAsNew}
            title="Save Ranking Report."
            formName="Report"
            type="rankingReport"
            name={name}
            description={description}
            id={id}
          />
          {isLoadFormOpen && (
            <LoadDataDialog
              isOpen={isLoadFormOpen}
              loadSpec={handleLoadSpec}
              close={handleLoadClose}
              type="rankingReport"
              title="Load Ranking Report"
              description="Select Ranking Report option to load."
            />
          )}
          <AlertDialog
            isOpen={isResetOpen}
            title="Do you want to reset Ranking Report form?"
            handleConfirm={handleResetConfirm}
            handleCancel={handleResetCancel}
          />
          <AlertDialog
            isOpen={isDeleteOpen}
            title="Do you want to delete Ranking Report from the Database?"
            handleConfirm={handleDeleteConfirm}
            handleCancel={handleDeleteCancel}
          />
          <Container>
            <Grid container spacing={3} alignItems="flex-start">
              <Grid item xs={6}>
                <Typography variant="h4" as="h1">
                  Ranking Report
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={1} alignItems="flex-end" justify="flex-end">
                  <Grid item xs={12}>
                    <Button
                      size="small"
                      className={classes.button}
                      onClick={() => handleOpenSave()}
                      type="button"
                      variant="contained"
                      color="primary"
                      startIcon={<SaveIcon />}
                    >
                      save
                    </Button>

                    <Button
                      size="small"
                      className={classes.button}
                      variant="contained"
                      onClick={() => handleLoad()}
                      color="primary"
                      startIcon={<OpenInBrowserIcon />}
                    >
                      Load
                    </Button>
                    <Button
                      size="small"
                      className={classes.button}
                      variant="contained"
                      onClick={() => handleReset()}
                      color="primary"
                      startIcon={<FiberNewIcon />}
                    >
                      Reset
                    </Button>

                    <Button
                      size="small"
                      className={classes.button}
                      variant="contained"
                      onClick={() => handleDelete()}
                      color="primary"
                      startIcon={<DeleteForeverIcon />}
                    >
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={3} alignItems="flex-start">
              <Container className={classes.root} style={{ margin: 0 }}>
                <Accordion elevation={4}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>Ranking Report Models</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <RankingReportModelSelector reportOnPortfolio={false} />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion elevation={4}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>Ranking Output Reports</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid item xs={12}>
                      <Grid container spacing={3} alignItems="stretch">
                        <RankingReportTab />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion elevation={4}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>Preferences</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ReportingPreferences showOverWrite showUseHistoricalLearning showOneOffRun />
                  </AccordionDetails>
                </Accordion>

                <Accordion elevation={4}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>Generate Reports</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <GenerateRankingReports
                      formValues={formValues}
                      selectedModels={selectedModels}
                      control={control}
                    />
                  </AccordionDetails>
                </Accordion>
              </Container>
            </Grid>
          </Container>
        </form>
        {/* <PreventNavigation dirty={isDirty} dirtyForm="rankingReport" saveToStore={saveToStore} /> */}
      </FormProvider>
    </Layout>
  );
};

export default RankingReport;
