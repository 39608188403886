// MUI

import Box from '@material-ui/core/Box';

import {
  ChartWithSelect,
  ParalaLineChart,
  IndividualAssetPercentContributions,
} from 'components/Dashboard';

const CustomizedAxisTick = (props) => {
  const { x, y, stroke, payload } = props;
  const displayValue = props?.tickFormatter ? props.tickFormatter(payload.value) : payload.value;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#667" transform="rotate(-35)" fontSize={11}>
        {displayValue}
      </text>
    </g>
  );
};

export const RiskReports = ({
  showReports,
  showKeys,
  dateIndex,

  pieChartData,
  CPitPctFormattedAreaChart,
  CPitPctFormattedLineChart,

  pyLongBetaTGroupedByVintageBarChartData,
  pyLongBetaTChart,
  pyLongBetaTLineChart,
  pyLongBetaTFormattedForChart,
  pyLongBetaTBarChart,
  BetaPTFormatted,
  BetaPTAreaChart,
  BetaPTLineChart,
  isEstimator,
}) => {
  if (pieChartData?.length === 0) {
    return null;
  }

  return (
    <Box display="flex" flex={1} flexDirection="column">
      {/* IndividualAssetPercentContributions /> */}
      {!isEstimator && showReports.individualAssetPercentContributions.visible ? (
        <>
          <Box
            style={{
              marginBottom: 30,
              paddingBottom: 30,
              borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            }}
          >
            <IndividualAssetPercentContributions
              title="Individual Asset Percent Contributions to Total Portfolio Risk as of "
              chartData={pieChartData}
            />
            <Box>
              <ParalaLineChart
                chart={CPitPctFormattedAreaChart}
                lineCharData={CPitPctFormattedLineChart}
                title="Individual Asset Percent Contributions to Total Portfolio Risk"
                description=""
                showKeys={showKeys}
                dateIndex={dateIndex}
                yAxisLabels={{
                  left: 'Contribution',
                }}
                chartProps={{
                  yAxis1: {
                    domain: [0, 100],
                    // format round integer
                    tickFormatter: (value) => `${Math.round(value)}%`,
                  },
                }}
              />
            </Box>
          </Box>
        </>
      ) : null}
      {/* IndividualAssetPercentContributions */}
      {/* pyLongBetaTGroupedByVintage */}
      <Box
        style={{
          marginBottom: 30,
          paddingBottom: 30,
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        <ChartWithSelect
          chartId="pyLongBetaTGroupedByVintage"
          chart={pyLongBetaTChart}
          chartDataGroupedById={pyLongBetaTGroupedByVintageBarChartData}
          title="Asset Risk Factor Exposures Ending {REPLACE_NAME}"
          showKeys={showKeys}
          dateIndex={dateIndex}
          yAxisLabels={{
            left: 'Beta',
          }}
          chartProps={{
            chartMargin: {
              top: 5,
              right: 30,
              left: 20,
              bottom: 160,
            },
            xAxisDataKey: 'name',
            xAxisTick: <CustomizedAxisTick />,
            xInterval: 0,
            xAxisFormatter: (value) => `${value} `,
            yAxis1: {
              name: 'Beta',
              tickFormatter: (value) => `${value.toFixed(2)}`,
            },
            hideBrush: true,
          }}
        />
      </Box>

      {/* pyLongBetaTLineChart, */}
      {/* pyLongBetaTGroupedByAsset */}

      <Box
        style={{
          marginBottom: 30,
          paddingBottom: 30,
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        <ChartWithSelect
          chartId="pyLongBetaTGroupedByAsset"
          chart={pyLongBetaTLineChart}
          chartDataGroupedById={pyLongBetaTFormattedForChart}
          title="Asset Risk Factor Exposure over Time: {REPLACE_NAME}"
          showKeys={showKeys}
          dateIndex={dateIndex}
          yAxisLabels={{
            left: 'Beta',
          }}
          chartProps={{
            yAxis1: {
              name: 'Beta',
              tickFormatter: (value) => `${value.toFixed(2)}`,
            },
          }}
        />
      </Box>
      {/* pyLongBetaTBarChart */}
      <Box
        style={{
          marginBottom: 30,
          paddingBottom: 30,
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        <ChartWithSelect
          chartId="pyLongBetaTGroupedByAssetBar"
          chart={pyLongBetaTBarChart}
          chartDataGroupedById={pyLongBetaTFormattedForChart}
          title="Asset Risk Factor Exposure over Time: {REPLACE_NAME}"
          showKeys={showKeys}
          dateIndex={dateIndex}
          yAxisLabels={{
            left: 'Beta',
          }}
          chartProps={{
            yAxis1: {
              name: 'Beta',
              tickFormatter: (value) => `${value.toFixed(2)}`,
            },
          }}
        />
      </Box>
      {/* pyLongBetaTGroupedByVintage */}
      {/*   BetaPTFormatted,
  BetaPTLineChart, */}
      {!isEstimator ? (
        <>
          <Box
            style={{
              marginBottom: 30,
              paddingBottom: 30,
              borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            }}
          >
            <ParalaLineChart
              chartId="BetaPTAreaChart"
              chart={BetaPTAreaChart}
              lineCharData={BetaPTFormatted}
              title="Portfolio Risk Factor Exposure over Time"
              showKeys={showKeys}
              dateIndex={dateIndex}
              yAxisLabels={{
                left: 'Beta',
              }}
              chartProps={{
                yAxis1: {
                  name: 'Beta',
                  tickFormatter: (value) => `${value.toFixed(2)}`,
                },
              }}
            />
          </Box>
          <Box
            style={{
              marginBottom: 30,
              paddingBottom: 30,
              borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            }}
          >
            <ParalaLineChart
              chartId="BetaPTLineChart"
              chart={BetaPTLineChart}
              lineCharData={BetaPTFormatted}
              title="Portfolio Risk Factor Exposure over Time"
              showKeys={showKeys}
              dateIndex={dateIndex}
              yAxisLabels={{
                left: 'Beta',
              }}
              chartProps={{
                yAxis1: {
                  name: 'Beta',
                  tickFormatter: (value) => `${value.toFixed(2)}`,
                },
              }}
            />
          </Box>{' '}
        </>
      ) : null}

      {/*   BetaPTFormatted,
  BetaPTLineChart, */}
    </Box>
  );
};
