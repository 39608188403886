import { useSelector } from 'react-redux';

import RiskModelSpecification from '../RiskModelSpecification';

const RiskModelDetails = () => {
  const { estimatorRules } = useSelector((state) => state.estimatorModel);
  // TODO: check benchmarks loading, from which object to load benchmarks???
  const { benchmarks } = useSelector((state) => state.dataForAnalysis);
  return (
    <>
      <RiskModelSpecification
        benchmarks={benchmarks}
        isDisabled={estimatorRules?.riskModelSpecification ? true : false}
        isLoadingsDisabled={estimatorRules?.riskModelSpecificationLoadings ? true : false}
      />
    </>
  );
};

export default RiskModelDetails;
