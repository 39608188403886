import { useParams } from 'react-router-dom';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import Layout from 'components/Layout';
import Box from '@material-ui/core/Box';

import styles from './ReportsDashboard.styles';
import { ReportsDashboardContent } from './ReportsDashboardContent';
import { LoadingList, LoadingItem, ErrorItem } from './components';

import {
  IDS_NAMES,
  PY_CHART_DATA,
  PY_PORTFOLIO_RETURNS,
  PY_LONG_BETA_T,
  BETA_PT,
  CPITPCT,
} from 'lib/DashboardReport/queryKeys';
import { useGetReportsStreamData } from 'lib/hooks/useGetReportsStreamData';
import { useGetReportDetails } from 'lib/hooks/useGetReportDetails';

export const ReportsDashboard = () => {
  // get id from url
  const { id } = useParams();

  const {
    isLoading: isLoadingIDsNames,
    data: idsAndNames,
    isError: isErrorIDsNames,
    error: errorIDsNames,
  } = useGetReportsStreamData(IDS_NAMES, id, 'idsAndNames.csv');
  const {
    isLoading: isLoadingPyChartData,
    data: pyChartData,
    isError: isErrorPyChartData,
    error: errorPyChartData,
  } = useGetReportsStreamData(PY_CHART_DATA, id, 'pyChartData.csv');
  const {
    isLoading: isLoadingCPitPct,
    data: CPitPct,
    isError: isErrorCPitPct,
    error: errorCPitPct,
  } = useGetReportsStreamData(CPITPCT, id, 'CPitPct.csv');
  const {
    isLoading: isLoadingPyPortfolioReturns,
    data: pyPortfolioReturns,
    isError: isErrorPyPortfolioReturns,
    error: errorPyPortfolioReturns,
  } = useGetReportsStreamData(PY_PORTFOLIO_RETURNS, id, 'pyPortfolioReturns.csv');
  const {
    isLoading: isLoadingPyLongBetaT,
    data: pyLongBetaT,
    isError: isErrorPyLongBetaT,
    error: errorPyLongBetaT,
  } = useGetReportsStreamData(PY_LONG_BETA_T, id, 'pyLongBetaT.csv');
  const {
    isLoading: isLoadingBetaPT,
    data: BetaPT,
    isError: isErrorBetaPT,
    error: errorBetaPT,
  } = useGetReportsStreamData(BETA_PT, id, 'BetaPT.csv');

  const { data: reportDetails } = useGetReportDetails(id);

  const titleArray = reportDetails ? reportDetails?.name?.split(' - ') : [];
  titleArray?.pop();

  const title = titleArray?.length > 0 ? titleArray.join(' - ') : 'Reports Dashboard';

  const isLoading =
    isLoadingIDsNames ||
    isLoadingPyChartData ||
    isLoadingCPitPct ||
    isLoadingPyPortfolioReturns ||
    isLoadingPyLongBetaT ||
    isLoadingBetaPT;

  const isError = isErrorIDsNames || isErrorPyChartData || isErrorPyPortfolioReturns;

  if (isLoading) {
    return (
      <ReportsDashboardPage title={title}>
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          <LoadingList>
            <LoadingItem isLoading={isLoadingIDsNames} title="IDs and Names" />
            <LoadingItem isLoading={isLoadingPyChartData} title="Py Chart Data" />
            <LoadingItem isLoading={isLoadingCPitPct} title="CPitPct" />
            <LoadingItem isLoading={isLoadingPyPortfolioReturns} title="Py Portfolio Returns" />
            <LoadingItem isLoading={isLoadingPyLongBetaT} title="Py Long BetaT" />
            <LoadingItem isLoading={isLoadingBetaPT} title="Beta PT" />
          </LoadingList>
        </Box>
      </ReportsDashboardPage>
    );
  }

  if (isError) {
    return (
      <ReportsDashboardPage title={title}>
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          <LoadingList>
            <ErrorItem isError={isErrorIDsNames} error={errorIDsNames} title="IDs and Names" />
            <ErrorItem
              isError={isErrorPyChartData}
              error={errorPyChartData}
              title="Py Chart Data"
            />
            <ErrorItem isError={isErrorCPitPct} error={errorCPitPct} title="CPitPct" />
            <ErrorItem
              isError={isErrorPyPortfolioReturns}
              error={errorPyPortfolioReturns}
              title="Py Portfolio Returns"
            />
            <ErrorItem
              isError={isErrorPyLongBetaT}
              error={errorPyLongBetaT}
              title="Py Long BetaT"
            />
            <ErrorItem isError={isErrorBetaPT} error={errorBetaPT} title="Beta PT" />
          </LoadingList>
        </Box>
      </ReportsDashboardPage>
    );
  }

  return (
    <ReportsDashboardContent
      title={title}
      {...{ idsAndNames, pyChartData, CPitPct, pyPortfolioReturns, pyLongBetaT, BetaPT }}
      isError={false}
    />
  );
};

const ReportsDashboardPage = ({ title, children }) => {
  const classes = makeStyles(styles, { name: 'ReportsDashboards' })();
  return (
    <Layout>
      <Container maxWidth="lg" style={{ margin: 0 }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6" as="h3">
            {title ?? 'Reports Dashboard'}
          </Typography>
        </Box>
        <Container className={classes.root} style={{ margin: 0 }}>
          {children}
        </Container>
      </Container>
    </Layout>
  );
};
