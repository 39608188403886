import * as types from '../../types';
import { showMessage, loadEstimatorModel } from '../../actions';

import universeService from '../../../services/UniverseService';
import portfolioService from '../../../services/PortfolioService';

// Portfolio Model save
export const savePortfolioModel = (data, id) => async (dispatch) => {
  try {
    const type = 'optimization';

    const result = await portfolioService.savePortfolioModelSpec(data, type, id);

    if (result?.error) {
      dispatch(showMessage({ type: 'error', message: result.error?.detail }));
    } else {
      dispatch(showMessage({ type: 'success', message: 'Data saved successfully.' }));

      dispatch({
        type: types.PORTFOLIO_MODEL_SAVE_DATA,
        payload: result,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const savePortfolioModelInState = (data) => async (dispatch) => {
  dispatch({
    type: types.PORTFOLIO_MODEL_SAVE_DATA_IN_STATE,
    payload: data,
  });
};

export const resetPortfolioModel = () => (dispatch) => {
  dispatch({
    type: types.PORTFOLIO_MODEL_RESET,
    payload: '',
  });
  dispatch({
    type: types.APP_PORTFOLIO_MODEL_RESET,
    payload: '',
  });
};

export const deletePortfolioModel = (id) => async (dispatch) => {
  try {
    await universeService.deleteUniverse(id);
    dispatch(resetPortfolioModel());
    dispatch(showMessage({ type: 'success', message: 'Data deleted successfully.' }));
  } catch (error) {}
};

// Load Data
export const loadPortfolioModel = (id) => async (dispatch) => {
  const result = await portfolioService.loadSpec(id);

  const estimatorModeID = result?.ref_spec_id;

  if (estimatorModeID) {
    dispatch(loadEstimatorModel(estimatorModeID));
  }
  dispatch({
    type: types.PORTFOLIO_MODEL_LOAD_DATA,
    payload: result,
  });

  dispatch(showMessage({ type: 'success', message: 'Data loaded successfully.' }));
};

export const addMultiAssetConstraint = (constraint) => async (dispatch) => {
  dispatch({
    type: types.ADD_MULTI_ASSET_CONSTRAINT,
    payload: constraint,
  });
};
export const addMultiAssetConstraintWeight = (values, constraints) => async (dispatch) => {
  dispatch({
    type: types.ADD_MULTI_ASSET_CONSTRAINT_WEIGHT,
    payload: { values, constraints },
  });
};

export const removeMultiAssetConstraint = (constraint) => async (dispatch) => {
  dispatch({
    type: types.REMOVE_MULTI_ASSET_CONSTRAINT,
    payload: constraint,
  });
};

export const addExposureTolerance = (value, factors) => async (dispatch) => {
  const data = factors.map((factor) => {
    return {
      id: factor.id,
      min: value.minimumExposureTolerance,
      max: value.maximumExposureTolerance,
    };
  });
  dispatch({
    type: types.ADD_EXPOSURE_TOLERANCE,
    payload: data,
  });
};
export const addNeutralRiskFactor = (value, factors) => async (dispatch) => {
  const data = factors.map((factor) => {
    return {
      id: factor.id,
      min: value.minimumBenchmarkCorrelation,
      max: value.maximumBenchmarkCorrelation,
    };
  });
  dispatch({
    type: types.ADD_NEUTRAL_RISK_FACTOR,
    payload: data,
  });
};

export const getPortfolioModelParameters = () => async (dispatch) => {
  try {
    const result = await portfolioService.getPortfolioModelParameters();

    if (result) {
      dispatch({
        type: types.PORTFOLIO_MODEL_GET_PARAMETERS,
        payload: result?.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};
