import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SignIn from '../../components/SignIn';

const Login = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);

  const { state } = useLocation();

  if (isAuthenticated === true) {
    return <Redirect to={state?.from || '/'} />;
  }

  return (
    <>
      <SignIn />
    </>
  );
};

export default Login;
