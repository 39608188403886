import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Typography, Grid } from '@material-ui/core';

import FormAutoComplete from '../FormAutoComplete';

import universeService from '../../services/UniverseService';

const RiskFreeRate = ({ universeList }) => {
  const [riskFreeRateList, setRiskFreeRateList] = useState([]);
  const { watch } = useFormContext();

  const watchRiskFree = watch('riskFree', false);

  useEffect(() => {
    const fetchData = async () => {
      const result = await universeService.getAssets(watchRiskFree.id);
      setRiskFreeRateList(result.data);
    };
    watchRiskFree?.id && fetchData();
  }, [watchRiskFree?.id]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" as="h5">
            Select Universe
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormAutoComplete options={universeList} name="riskFree" />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" as="h5">
            Select risk free rate
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormAutoComplete options={riskFreeRateList} name="riskFreeRate" />
        </Grid>
      </Grid>
    </>
  );
};

export default RiskFreeRate;
