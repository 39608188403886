import { Controller } from 'react-hook-form';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 500,
    minWidth: 300,
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[4],
    '&.Mui-disabled': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  FormControl: {
    width: '100%',
    maxWidth: 500,
    minWidth: 300,
  },
}));

const FormSelect = ({
  control,
  data,
  name,
  disabled,
  emptyValue,
  emptyDefaultValue = '',
  displayEmpty = false,
}) => {
  const classes = useStyles();

  return (
    <>
      <FormControl variant="outlined" className={classes.FormControl}>
        <Controller
          control={control}
          name={name}
          render={({ onChange, name, value }) => (
            <Select
              className={classes.root}
              disabled={disabled}
              name={name}
              displayEmpty={displayEmpty}
              value={value || value === 0 ? value : displayEmpty ? emptyDefaultValue : data[0]}
              onChange={(e) => onChange(e.target.value)}
              labelId={`${name}-label`}
            >
              {displayEmpty && emptyValue && (
                <MenuItem value={emptyDefaultValue}>{emptyValue}</MenuItem>
              )}
              {data?.map((item) => (
                <MenuItem
                  key={item?.value ? item.value : item.id}
                  value={item?.value || item?.value === 0 ? item.value : item.id}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
    </>
  );
};

export default FormSelect;
