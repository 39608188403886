/* eslint-disable operator-linebreak */
/* eslint-disable arrow-body-style */
import axios from 'axios';
import omit from 'lodash.omit';
import { removeNullFromData } from './util';
import config from '../config';

import universeService from './UniverseService';
import { getAccessToken } from './firebase';

const portfolioService = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    // Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

portfolioService.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.data.errors &&
      error.response.data.errors[0].code === 'INVALID_TOKEN'
    ) {
      // return AuthService.updateAccessToken().then((accessToken) => {
      //   error.config.headers.Authorization = `Bearer ${accessToken}`;
      //   return apiClient.request(error.config);
      // });
    }
    return Promise.reject(error);
  },
);

const numberToPercentage = (number) => {
  const percentage = (parseFloat(number) * 0.01).toFixed(4);

  return parseFloat(percentage);
};

const percentageToNumber = (percentage) => {
  return parseFloat(percentage) * 100;
};

const formatPortfolioBeforePost = (data) => {
  const { showShortAssetWeight } = data;
  const longAssetWeightTotalMaximum = parseFloat(data?.longAssetWeightTotalMaximum, 10)
    ? numberToPercentage(data?.longAssetWeightTotalMaximum)
    : 0;
  const shortAssetWeightTotalMaximum = parseFloat(data?.shortAssetWeightTotalMaximum, 10)
    ? numberToPercentage(data?.shortAssetWeightTotalMaximum)
    : 0;
  const longAssetWeightTotalMinimum = parseFloat(data?.longAssetWeightTotalMinimum, 10)
    ? numberToPercentage(data?.longAssetWeightTotalMinimum)
    : 0;
  const shortAssetWeightTotalMinimum = parseFloat(data?.shortAssetWeightTotalMinimum, 10)
    ? numberToPercentage(data?.shortAssetWeightTotalMinimum)
    : 0;

  const sendRiskAversion =
    (data?.portfolioWeights === config.multiStateWeightID && data?.MultiStatWeightsUtility > 0) ||
    data?.portfolioWeights === config.showRiskAversionID;

  const formattedSpec = {
    sort: {
      percentFlag: data?.numberOrPercentage,
      quantity: parseInt(data?.numberAssets, 10),
      sortDirection: data?.sortingOrder,
      includeCustomFlag: data?.includeConstrainedAssets ? 1 : 0,
      variableIndex: data?.assetsRankingCriteria,
    },
    preferences: {
      multiPeriodForecasting: data?.multiPeriodForecasting ? true : false,
      referenceBenchmark: data?.referenceBenchmark?.map(
        (referenceBenchmark) => referenceBenchmark?.id,
      ),
      allowZeroWeights: data?.allowZeroWeights ? true : false,
      portfolioWeights: data?.portfolioWeights,
      ...(sendRiskAversion && {
        riskAversion: parseFloat(data?.riskAversionCoEfficient),
      }),

      ...(data?.portfolioWeights === config.multiStateWeightID && {
        meanWt: parseFloat(data?.MultiStatWeightsMean),
        alphaWt: parseFloat(data?.MultiStatWeightsAlpha),
        varWt: parseFloat(data?.MultiStatWeightsVariance),
        tEWt: parseFloat(data?.MultiStatWeightsTrackingError),
        sharpeWt: parseFloat(data?.MultiStatWeightsSharpeRatio),
        infoWt: parseFloat(data?.MultiStatWeightsInfoRatio),
        utilWt: parseFloat(data?.MultiStatWeightsUtility),
      }),
    },
    constraintSpec: {
      maxLongWeight: numberToPercentage(data?.longAssetWeightIndividualMaximum),
      minLongWeight: data?.longAssetWeightIndividualMinimum
        ? numberToPercentage(data?.longAssetWeightIndividualMinimum)
        : 0,
      maxBuySell: numberToPercentage(data?.maximumAssetTurnover),
      maxTotalLongWeight: numberToPercentage(data?.longAssetWeightTotalMaximum),
      minTotalLongWeight: numberToPercentage(data?.longAssetWeightTotalMinimum),
      shortWeightEnabled: showShortAssetWeight ? true : false,
      maxShortWeight: showShortAssetWeight
        ? numberToPercentage(data?.shortAssetWeightIndividualMaximum)
        : 0,
      minShortWeight: showShortAssetWeight
        ? numberToPercentage(data?.shortAssetWeightIndividualMinimum)
        : 0,
      maxTotalShortWeight: showShortAssetWeight
        ? numberToPercentage(data?.shortAssetWeightTotalMaximum)
        : 0,
      minTotalShortWeight: showShortAssetWeight
        ? numberToPercentage(data?.shortAssetWeightTotalMinimum)
        : 0,
      maxTotalNetWeight: showShortAssetWeight ? numberToPercentage(data?.totalNetWeightMaximum) : 0,
      minTotalNetWeight: showShortAssetWeight ? numberToPercentage(data?.totalNetWeightMinimum) : 0,
      minTotalGrossWeight: showShortAssetWeight
        ? numberToPercentage(data?.totalGrossWeightMinimum)
        : 0,
      maxTotalGrossWeight: showShortAssetWeight
        ? numberToPercentage(data?.totalGrossWeightMaximum)
        : 0,
      targetPortfolioRiskFactorExposures: data?.targetPortfolioRiskFactorExposures,
      filterAssetsByRiskCorrelations: data?.filterAssetsByRiskCorrelations,
      exposureTolerance: data?.exposureTolerance,
      assetRiskFactorCorrelations: data?.assetRiskFactorCorrelations,
    },
    customConstraints: data?.selectedMultiAssetConstraint?.map((customConstraints) => ({
      iCustomFieldTM: customConstraints?.customTable?.id,
      iCustomFieldID: customConstraints?.customTableField?.id,
      sCriteria: customConstraints?.conditionValue,
      minWeight: numberToPercentage(customConstraints?.weight?.minimumClassWeight),
      maxWeight: numberToPercentage(customConstraints?.weight?.maximumClassWeight),
      applyNetConstraint: customConstraints?.weight?.applyNetConstraint ? true : false,
    })),
    selectedMultiAssetConstraint: data?.selectedMultiAssetConstraint,
    showShortAssetWeight,
  };

  return removeNullFromData(formattedSpec);
};

const formatResultData = async (data) => {
  const portfolio = data.spec;

  const { spec, ...rest } = data;

  const formattedData = {
    ...rest,
    numberOrPercentage: portfolio?.sort?.percentFlag,
    numberAssets: portfolio?.sort?.quantity,
    sortingOrder: portfolio?.sort?.sortDirection,
    assetsRankingCriteria: portfolio?.sort?.variableIndex,
    includeConstrainedAssets: portfolio?.sort?.includeCustomFlag ? true : false,
    multiPeriodForecasting: portfolio?.preferences?.multiPeriodForecasting,
    allowZeroWeights: portfolio?.preferences?.allowZeroWeights,
    // TODO: format
    referenceBenchmark: portfolio?.preferences?.referenceBenchmark
      ? await getBenchmarks(portfolio?.preferences?.referenceBenchmark)
      : [],
    portfolioWeights: portfolio?.preferences?.portfolioWeights,
    riskAversionCoEfficient: portfolio?.preferences?.riskAversion,
    MultiStatWeightsMean: portfolio?.preferences?.meanWt,
    MultiStatWeightsAlpha: portfolio?.preferences?.alphaWt,
    MultiStatWeightsVariance: portfolio?.preferences?.varWt,
    MultiStatWeightsTrackingError: portfolio?.preferences?.tEWt,
    MultiStatWeightsSharpeRatio: portfolio?.preferences?.sharpeWt,
    MultiStatWeightsInfoRatio: portfolio?.preferences?.infoWt,
    MultiStatWeightsUtility: portfolio?.preferences?.utilWt,

    showShortAssetWeight: portfolio?.constraintSpec?.shortWeightEnabled,
    longAssetWeightIndividualMaximum: percentageToNumber(portfolio?.constraintSpec?.maxLongWeight),
    longAssetWeightIndividualMinimum: percentageToNumber(portfolio?.constraintSpec?.minLongWeight),
    // portfolio?.constraintSpec?.minLongWeight || portfolio?.constraintSpec?.minLongWeight === 0
    //   ? portfolio?.constraintSpec?.minLongWeight
    //   : null,
    longAssetWeightTotalMaximum: percentageToNumber(portfolio?.constraintSpec?.maxTotalLongWeight),
    longAssetWeightTotalMinimum: percentageToNumber(portfolio?.constraintSpec?.minTotalLongWeight),
    maximumAssetTurnover: percentageToNumber(portfolio?.constraintSpec?.maxBuySell),

    shortAssetWeightIndividualMaximum: percentageToNumber(
      portfolio?.constraintSpec?.maxShortWeight,
    ),
    shortAssetWeightIndividualMinimum: percentageToNumber(
      portfolio?.constraintSpec?.minShortWeight,
    ),
    shortAssetWeightTotalMaximum: percentageToNumber(
      portfolio?.constraintSpec?.maxTotalShortWeight,
    ),
    shortAssetWeightTotalMinimum: percentageToNumber(
      portfolio?.constraintSpec?.minTotalShortWeight,
    ),
    totalNetWeightMaximum: percentageToNumber(portfolio?.constraintSpec?.maxTotalNetWeight),
    totalNetWeightMinimum: percentageToNumber(portfolio?.constraintSpec?.minTotalNetWeight),
    totalGrossWeightMinimum: percentageToNumber(portfolio?.constraintSpec?.minTotalGrossWeight),
    totalGrossWeightMaximum: percentageToNumber(portfolio?.constraintSpec?.maxTotalGrossWeight),

    targetPortfolioRiskFactorExposures:
      portfolio?.constraintSpec?.targetPortfolioRiskFactorExposures,
    filterAssetsByRiskCorrelations: portfolio?.constraintSpec?.filterAssetsByRiskCorrelations,
    exposureTolerance: portfolio?.constraintSpec?.exposureTolerance || [],
    assetRiskFactorCorrelations: portfolio?.constraintSpec?.assetRiskFactorCorrelations || [],

    selectedMultiAssetConstraint: portfolio?.selectedMultiAssetConstraint || [],
  };
  return formattedData;
};

const getBenchmarks = async (benchmarks) => {
  try {
    if (benchmarks.length) {
      const benchmarksData = await universeService.getAssetsList(benchmarks);
      const benchmarksDataSorted = benchmarks?.map((benchmarkId) =>
        benchmarksData?.data.find((data) => data.id === benchmarkId),
      );

      const result = benchmarksDataSorted.map((benchmark, index) => {
        return {
          ...benchmark,
        };
      });
      return result;
    }
    return [];
  } catch (error) {
    console.log('error', error);
    return error;
  }
};

const savePortfolioModelSpec = async (formData, type = 'optimization', spec_id) => {
  const accessToken = await getAccessToken();

  try {
    const { name, description, ref_spec_id } = formData;

    const omitList = ['id', 'name', 'description', 'created_at', 'last_modified'];
    const formattedFormData = omit(formData, omitList);
    const spec = {};

    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(formattedFormData)) {
      spec[key] = omit(formattedFormData[key], omitList);
    }
    const formattedSpec = formatPortfolioBeforePost(formData.portfolioModel);

    const data = {
      name,
      description,
      ref_spec_id,
      spec: formattedSpec,
      type,
    };

    const specData = spec_id
      ? await portfolioService.patch(`/specs/${spec_id}`, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
      : await portfolioService.post('/specs', data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        });

    const formattedResult = formatResultData(specData.data);

    return formattedResult;
  } catch (error) {
    if (error.response.data) return { error: error.response.data };
    throw new Error(error);
  }
};

const loadSpec = async (id) => {
  const accessToken = await getAccessToken();

  try {
    let formattedResult = {};
    const apiURL = `/specs/${id}`;
    const result = await portfolioService.get(apiURL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    if (result) {
      formattedResult = formatResultData(result.data);
    }

    return formattedResult;
  } catch (error) {
    return error;
  }
};

const getConstraintsTable = async () => {
  const accessToken = await getAccessToken();

  try {
    const apiURL = `/constraints`;
    const constraints = await portfolioService.get(apiURL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return constraints.data;
  } catch (error) {
    return { error: true, message: error };
  }
};

const getConstraintsTableField = async (id) => {
  const accessToken = await getAccessToken();

  try {
    // https://parala-api.35-246-17-43.nip.io/api/v1/beta/constraints/{constraint_id}/customfield
    const apiURL = `/constraints/${id}/customfield`;
    const constraintsTableList = await portfolioService.get(apiURL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return constraintsTableList.data;
  } catch (error) {
    return { error: true, message: error };
  }
};

const getConstraintsTableFieldValue = async (id) => {
  const accessToken = await getAccessToken();

  try {
    // https://parala-api.35-246-17-43.nip.io/api/v1/beta/customfields/{customfield_id}/tablefield
    const apiURL = `/customfields/${id}/tablefield`;
    const constraintsTableList = await portfolioService.get(apiURL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return constraintsTableList.data;
  } catch (error) {
    return { error: true, message: error };
  }
};

const getPortfolioModelParameters = async () => {
  const accessToken = await getAccessToken();

  try {
    const apiURL = `/portfolio-model-parameters`;
    const portfolioModelParameters = await portfolioService.get(apiURL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return portfolioModelParameters.data;
  } catch (error) {
    return { error: true, message: error };
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  savePortfolioModelSpec,
  loadSpec,
  getConstraintsTable,
  getConstraintsTableField,
  getConstraintsTableFieldValue,
  getPortfolioModelParameters,
};
