import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import styles from './AppDataInfo.styles';

const AppDataInfoCard = ({ title, id, name, description, created_at, last_modified }) => {
  const classes = makeStyles(styles)();

  return (
    <>
      <Typography variant="h6" as="h4">
        {title}
      </Typography>
      <Typography className={classes.root} variant="subtitle2">
        {name ? name : 'Not Saved'}
      </Typography>

      {id && description && (
        <Typography className={classes.root} gutterBottom variant="caption">
          {description}
        </Typography>
      )}
      {id && created_at && (
        <Typography className={classes.root} gutterBottom variant="caption">
          Created: {moment(created_at).format('MMMM Do YYYY, h:mm:ss a')}
        </Typography>
      )}
      {id && last_modified && (
        <Typography className={classes.root} variant="caption">
          Modified: {moment(last_modified).format('MMMM Do YYYY, h:mm:ss a')}
        </Typography>
      )}
    </>
  );
};

export default AppDataInfoCard;
