import moment from 'moment';
import { getColorById } from 'lib/colors';

const colorCodes = [
  '#1e4669',
  '#3e8abf',
  '#59aa59',
  'rgb(0, 0, 255)',
  'rgb(0, 153, 255)',
  'rgb(255, 153, 153)',
  'rgb(0, 255, 255)',
  'rgb(128, 0, 0)',
  'rgb(0, 128, 0)',
  'rgb(0, 0, 128)',
  'rgb(128, 128, 0)',
  'rgb(128, 0, 128)',
  'rgb(0, 128, 128)',
  'rgb(128, 0, 255)',
  'rgb(255, 0, 128)',
  'rgb(153, 255, 204)',
  'rgb(0, 128, 255)',
  'rgb(128, 255, 255)',
  'rgb(255, 255, 128)',
  'rgb(255, 128, 255)',
  'rgb(255, 128, 128)',
  'rgb(128, 128, 255)',
  'rgb(128, 128, 0)',
  'rgb(255, 102, 102)',
  'rgb(64, 128, 255)',
  'rgb(128, 64, 255)',
  'rgb(255, 80, 80)',
  'rgb(255, 128, 64)',
  'rgb(128, 255, 64)',
  'rgb(64, 64, 255)',
  'rgb(64, 64, 128)',
  'rgb(255, 64, 64)',
  'rgb(128, 64, 64)',
  'rgb(64, 255, 64)',
  'rgb(64, 128, 64)',
  'rgb(64, 128, 0)',
];

export const GetColour = (index) => {
  return getColorById(index);
};

// format number to 2 decimal places
export const formatNumber = (number, percentage) => {
  const newNumber = percentage ? (number * 100).toFixed(2) : number.toFixed(2);
  // return the number
  return parseFloat(newNumber);
};

export const getYear = (date) => {
  const year = moment(date).format('YYYY');
  return year;
};

export const getMonthName = (date) => {
  const month = moment(date).format('MMM');
  return month;
};

export const getMonthNumber = (date) => {
  const month = moment(date).format('M');
  return month;
};

export const getMonthNameByIndex = (index) => {
  const month = moment()
    .month(index - 1)
    .format('MMM');
  return month;
};

export const formatDate = (date) => {
  return moment(date).format('YYYY-MM-DD');
};

export const findNameWithId = (id, idsAndNames) => {
  return (
    idsAndNames.find((i) => i.id === id || i.id?.toString()?.trim() === id?.toString()?.trim())
      ?.name || 'not found'
  );
};

export const splitNameAndId = (nameAndId, hasExtraUnderscore = false) => {
  let name, id;

  if (nameAndId?.includes(', ')) {
    [name, id] = nameAndId.split(', ');
  } else if (hasExtraUnderscore) {
    const splitValues = nameAndId.split('_');
    name = `${splitValues[0]}_${splitValues[1]}`;
    id = splitValues[2];
  } else {
    [name, id] = nameAndId.split('_');
  }

  return { name, id };
};

export const formatData = (data, idsAndNames) => {
  return data
    .map((item) => {
      const objectKeys = Object.keys(item);
      let newObject = {};
      newObject['id'] = item.AssetID;
      newObject['name'] = findNameWithId(item.AssetID, idsAndNames);
      newObject['date'] = item.ReturnDate;
      newObject['dateFormatted'] = moment(item.ReturnDate, 'YYYYMMDD').format('DD/MM/YYYY');

      objectKeys.forEach((key) => {
        if (attributesMap[key]) {
          newObject[key] = {
            ...attributesMap[key],
            value: formatNumber(item[key], 100),
            originalValue: item[key],
          };
        } else if (key.match(timeVaryingAlphaZRegex)) {
          const { name, id } = splitNameAndId(key, false);

          const macroFactorName = findNameWithId(id, idsAndNames);

          newObject[key] = {
            name: `Time-varying alpha from ${macroFactorName}`,
            longName: `Time-varying alpha from ${macroFactorName}`,
            id: id,
            dataKey: `${key}.value`,
            value: formatNumber(item[key], 100),
            originalValue: item[key],
            ...attributesMap[name],
          };
        } else if (key.match(timeVaryingAlphaRegex)) {
          const { name, id } = splitNameAndId(key, true);

          const macroFactorName = findNameWithId(id, idsAndNames);

          newObject[key] = {
            name: `Time-varying alpha from ${macroFactorName}`,
            longName: `Average time-varying alpha from ${macroFactorName} across all assets`,
            id: id,
            dataKey: `${key}.value`,
            value: formatNumber(item[key], 100),
            originalValue: item[key],
            ...attributesMap[name],
          };
        } else if (key.match(riskFactorRegex)) {
          const { name, id } = splitNameAndId(key, true);
          const riskFactorName = findNameWithId(id, idsAndNames);

          newObject[key] = {
            name: riskFactorName,
            longName: `Expected return for ${riskFactorName}`,
            id: id,
            dataKey: `${key}.value`,
            value: formatNumber(item[key], 100),
            originalValue: item[key],
            ...attributesMap[name],
            color: GetColour(id),
          };
        } else if (
          key.match(macrofactorZRegex) ||
          key.match(macrofactorZRegexWithUnderscore) ||
          key.match(macrofactorZStarRegex)
        ) {
          const { name, id } = splitNameAndId(key, false);
          const macroFactorName = findNameWithId(id, idsAndNames);

          newObject[key] = {
            name: macroFactorName,
            longName: macroFactorName,
            id: id,
            dataKey: `${key}.value`,
            value: formatNumber(item[key], 100),
            originalValue: item[key],
            ...attributesMap[name],
          };
        } else if (
          key.match(decompositionAlphaRegex) ||
          key.match(decompositionAlphaRegexWithUnderscore)
        ) {
          const { name, id } = splitNameAndId(key, false);
          const macroFactorName = findNameWithId(id, idsAndNames);

          newObject[key] = {
            name: findNameWithId(id, idsAndNames),
            longName: `Time-varying alpha from timing ${macroFactorName}`,
            id: id,
            dataKey: `${key}.value`,
            value: formatNumber(item[key], 100),
            originalValue: item[key],

            ...attributesMap[name],
          };
        } else if (
          key.match(portfolioAlphaDecompositionRegex) ||
          key.match(portfolioAlphaDecompositionRegexWithUnderscore)
        ) {
          const { name, id } = splitNameAndId(key, false);

          const riskFactorName = findNameWithId(id, idsAndNames);

          newObject[key] = {
            name: `Time-varying alpha ${riskFactorName}`,
            longName: `Time-varying alpha contribution from timing ${riskFactorName}`,
            id: id,
            dataKey: `${key}.value`,
            value: formatNumber(item[key], 100),
            originalValue: item[key],
            ...attributesMap[name],
          };
        } else if (
          key.match(unconditionalAlphaDecompositionRegex) ||
          key.match(unconditionalAlphaDecompositionRegexWithUnderscore) ||
          key.match(unconditionalAlphaDecompositionRegexWithUnderscoreNew)
        ) {
          const { name, id } = splitNameAndId(key, false);

          const riskFactorName = findNameWithId(id, idsAndNames);

          newObject[key] = {
            name: `Unconditional alpha from ${riskFactorName}`,
            longName: `Unconditional alpha from ${riskFactorName}`,
            id: id,
            dataKey: `${key}.value`,
            value: formatNumber(item[key], 100),
            originalValue: item[key],
            ...attributesMap[name],
          };
        } else if (key.match(sensitivityRegex) || key.match(sensitivityRegexWithUnderscore)) {
          const { name, id } = splitNameAndId(key, false);

          const riskFactorName = findNameWithId(id, idsAndNames);

          newObject[key] = {
            name: `Sensitivity to ${riskFactorName}`,
            longName: `Sensitivity to ${riskFactorName}`,
            id: id,
            dataKey: `${key}.value`,
            value: formatNumber(item[key], 100),
            originalValue: item[key],
            ...attributesMap[name],
          };
        } else if (
          key.match(conditionalRiskFactorExpectedReturnRegex) ||
          key.match(conditionalRiskFactorExpectedReturnRegexWithUnderscore) ||
          key.match(conditionalRiskFactorExpectedReturnRegexWithUnderscoreNew)
        ) {
          const { name, id } = splitNameAndId(key, true);
          const riskFactorName = findNameWithId(id, idsAndNames);

          newObject[key] = {
            name: `${riskFactorName}`,
            longName: `${riskFactorName}`,
            id: id,
            dataKey: `${key}.value`,
            value: formatNumber(item[key], 100),
            originalValue: item[key],
            ...attributesMap[name],
          };
        } else if (
          key.match(unconditionalRiskFactorExpectedReturnRegex) ||
          key.match(unconditionalRiskFactorExpectedReturnRegexWithUnderscore) ||
          key.match(unconditionalRiskFactorExpectedReturnRegexWithUnderscoreNew)
        ) {
          const { name, id } = splitNameAndId(key, true);
          const riskFactorName = findNameWithId(id, idsAndNames);

          newObject[key] = {
            name: `${riskFactorName}`,
            longName: `${riskFactorName}`,
            id: id,
            dataKey: `${key}.value`,
            value: formatNumber(item[key], 100),
            originalValue: item[key],
            ...attributesMap[name],
          };
        } else newObject[key] = item[key];
      });

      return {
        ...newObject,
      };
    })
    .sort((a, b) => a.date - b.date);
};

export const groupById = (data, idsAndNames) => {
  const result = {};
  data.forEach((item) => {
    if (!result[item.id]) {
      result[item.id] = {
        id: item.id,
        name: findNameWithId(item.id, idsAndNames),
        data: [],
      };
    }
    result[item.id].data.push({
      ...item,
    });
  });
  // return array of objects

  return Object.values(result);
};

export const getLineAttributes = (key, data = {}, index) => {
  const attributeValue = {
    ...attributesMap[key],
    ...data,
  };
  const color = data?.id ? getColorById(data.id) : attributeValue.color || GetColour(index + 3);

  return {
    ...defaultLineAttributes,
    ...attributeValue,
    longName: attributeValue.longName || attributeValue.name,
    stroke: color,
    fill: color,
  };
};

// ^a1\d{1,2}(?:\_bar\*z)\d{1,2}
// a11_bar*z1, 688552 , a11_bar*z1_522206
export const timeVaryingAlphaRegex = /^a1\d{1,2}(?:\_bar\*z)\d{1,2}/;
export const timeVaryingAlphaZRegex = /^ai1\d{1,2}(?:\*z)\d{1,2}_\d/;

export const riskFactorRegex = /^Bmk_mu\d{1,2}/;
export const macrofactorZRegex = /^z\d{1,2},\s\d/;
export const macrofactorZRegexWithUnderscore = /^z\d{1,2}_\d/;

export const macrofactorZStarRegex = /^zStar\d{1,2}_\d/;

export const decompositionAlphaRegex = /^ai1\d{1,2}(?:\*z)\d{1,2},\s\d/;
export const decompositionAlphaRegexWithUnderscore = /^ai1\d{1,2}(?:\*z)\d{1,2}_\d/;

export const portfolioAlphaDecompositionRegex = /^wt\*a1\d{1,2}(?:\*z)\d{1,2},\s\d/;
export const portfolioAlphaDecompositionRegexWithUnderscore = /^wt\*a1\d{1,2}(?:\*z)\d{1,2}_\d/;

export const unconditionalAlphaDecompositionRegex = /^ai1\d{1,2}(?:\*z)\d{1,2}bar,\s\d/;
export const unconditionalAlphaDecompositionRegexWithUnderscore = /^ai1\d{1,2}(?:\*z)\d{1,2}bar_\d/;
export const unconditionalAlphaDecompositionRegexWithUnderscoreNew =
  /^ai1\d{1,2}(?:\*z)bar\d{1,2}_\d/;

export const sensitivityRegex = /^ai1\d{1,2},\s\d/;
export const sensitivityRegexWithUnderscore = /^ai1\d{1,2}_\d/;

// TODO: check format if was changed?
export const conditionalRiskFactorExpectedReturnRegex = /^bit\d{1,2}(?:\*Bmk_mu)\d{1,2},\s\d/;
export const conditionalRiskFactorExpectedReturnRegexWithUnderscore =
  /^bit\d{1,2}(?:\*Bmk_mu)\d{1,2}_\d/;
export const conditionalRiskFactorExpectedReturnRegexWithUnderscoreNew =
  /^bit\d{1,2}(?:\*Bmk_mu)_\d/;

export const unconditionalRiskFactorExpectedReturnRegex = /^bi\d{1,2}(?:\*Bmk_mu)\d{1,2},\s\d/;
export const unconditionalRiskFactorExpectedReturnRegexWithUnderscore =
  /^bi\d{1,2}(?:\*Bmk_mu)\d{1,2}_\d/;
export const unconditionalRiskFactorExpectedReturnRegexWithUnderscoreNew =
  /^bi\d{1,2}(?:\*Bmk_mu)_\d/;

export const vintageRegex = /^VintageWeight_\d/;

export const totalBetaReturn = /^bit*Bmk_mu/;

const defaultLineAttributes = {
  type: 'line',
  strokeWidth: 3,
  dot: false,
  activeDot: false,
  unit: '%',
  yAxisId: 'y-axis-1',
};

export const attributesMap = {
  a0_bar: {
    dataKey: 'a0_bar.value',
    name: 'Constant alpha',
    longName: 'Average constant alpha across all assets',
    color: GetColour(24),
    strokeWidth: 5,
  },
  'ai0+ai1*z': {
    dataKey: 'ai0+ai1*z.value',
    name: 'Total alpha',
    longName: 'Total alpha',
    color: GetColour(26),
  },
  'a1_bar*z': {
    dataKey: 'a1_bar*z.value',
    name: 'Time-varying alpha',
    longName: 'Average total time-varying alpha across all assets',
    color: GetColour(100),
    strokeWidth: 5,
  },
  'a0_bar+a1_bar*z': {
    dataKey: 'a0_bar+a1_bar*z.value',
    name: 'Average total alpha across all assets',
    longName: 'Average total alpha across all assets',
    color: GetColour(2),
  },
  'b0_bar*mu': {
    dataKey: 'b0_bar*mu.value',
    name: 'Constant beta',
    color: GetColour(27),
    strokeWidth: 5,
  },
  'b0_bar*mu+a1_bar*z': {
    dataKey: 'b0_bar*mu+a1_bar*z.value',
    name: 'Constant beta + time-varying alpha',
    longName: 'Constant beta + time-varying alpha',
    color: GetColour(35),
  },
  Et_bar: {
    dataKey: 'Et_bar.value',
    name: 'Total return',
    longName: 'Average total return across all assets',
    color: GetColour(30),
    type: 'area',
  },
  Eit: {
    dataKey: 'Eit.value',
    name: 'Total expected return',
    longName: 'Total expected return',
    color: GetColour(30),
  },
  ai0: {
    dataKey: 'ai0.value',
    name: 'Constant alpha',
    longName: 'Constant alpha',
    color: GetColour(24),
    strokeWidth: 5,
  },
  'ai1*z': {
    dataKey: 'ai1*z.value',
    name: 'Total time-varying alpha',
    longName: 'Total time-varying alpha',
    color: GetColour(23),
    strokeWidth: 5,
  },
  'bi0*Bmk_mu': {
    dataKey: 'bi0*Bmk_mu.value',
    name: 'Constant beta contribution',
    longName: 'Constant beta contribution',
    color: GetColour(27),
    strokeWidth: 5,
  },
  Vit: {
    dataKey: 'Vit.value',
    name: 'Variance',
    longName: 'Variance',
    color: GetColour(21),
  },
  w: {
    dataKey: 'w.value',
    name: 'Portfolio weight',
    longName: 'Portfolio weight',
    color: GetColour(10),
  },
  '(wit-wibar)*rit': {
    dataKey: '(wit-wibar)*rit.value',
    name: 'Return from varying weights',
    longName: 'Return from varying weights',
    color: GetColour(15),
  },
  '(wit-wibar)*(rit-bi*rmt)': {
    dataKey: '(wit-wibar)*(rit-bi*rmt).value',
    name: 'Alpha from varying weights',
    longName: 'Alpha from varying weights',
    color: GetColour(16),
  },
  'wit*rit+': {
    dataKey: 'wit*rit+.value',
    name: 'Total contribution to portfolio return',
    longName: 'Total contribution to portfolio return',
    color: GetColour(11),
  },
  'wit*(rit-bit*rmt)': {
    dataKey: 'wit*(rit-bit*rmt).value',
    name: 'Contribution to portfolio alpha',
    longName: 'Contribution to portfolio alpha',
    color: GetColour(12),
  },
  '(wit-wibar)': {
    dataKey: '(wit-wibar).value',
    name: 'Deviation from average weight',
    longName: 'Deviation from average weight',
    color: GetColour(2),
  },
  '(wt-wbar)*rt': {
    dataKey: '(wt-wbar)*rt.value',
    name: 'Return from varying weights',
    longName: 'Contribution to the portfolio return from varying weights',
    color: GetColour(15),
  },
  '(wt-wbar)*(rt-b*rmt)': {
    dataKey: '(wt-wbar)*(rt-b*rmt).value',
    name: 'Alpha from varying weights',
    longName: 'Contribution to the portfolio alpha from varying weights',
    color: GetColour(16),
  },
  'wt*rt+': {
    dataKey: 'wt*rt+.value',
    name: 'Portfolio return',
    longName: 'Portfolio return',
    color: GetColour(11),
  },
  'wt*(rt-bt*rmt)': {
    dataKey: 'wt*(rt-bt*rmt).value',
    name: 'Portfolio alpha',
    longName: 'Portfolio alpha',
    color: GetColour(12),
  },
  'wt*(a0+a1*z)': {
    dataKey: 'wt*(a0+a1*z).value',
    name: 'Total alpha',
    longName: 'Total alpha',
    color: GetColour(111),
  },
  'wt*a1*z': {
    dataKey: 'wt*a1*z.value',
    name: 'Time-varying alpha',
    longName: 'Time-varying alpha',
    color: GetColour(23),
  },
  'wt*a0': {
    dataKey: 'wt*a0.value',
    name: 'Constant alpha',
    longName: 'Constant alpha',
    color: GetColour(24),
    strokeWidth: 5,
  },

  // ...Array(10)
  //   .fill(0)
  //   .reduce((acc, _, index) => {
  //     const i = index + 1;
  //     return {
  //       ...acc,
  //       [`a1${i}_bar*z${i}`]: {
  //         color: GetColour(index),
  //       },
  //       [`Bmk_mu${i}`]: {
  //         color: GetColour(index),
  //       },
  //       [`z${i}`]: {
  //         color: GetColour(index),
  //       },
  //       [`zStar${i}`]: {
  //         color: GetColour(index),
  //       },
  //       [`ai1${i}*z${i}bar`]: {
  //         color: GetColour(index),
  //       },
  //       [`bit${i}*Bmk_mu${i}`]: {
  //         color: GetColour(index),
  //       },
  //       [`bi0${i}*Bmk_mu${i}`]: {
  //         color: GetColour(index),
  //       },
  //     };
  //   }, {}),

  'bit*Bmk_mu': {
    dataKey: 'bit*Bmk_mu.value',
    name: 'Total beta return',
    longName: 'Total beta return',
    type: 'area',
    color: GetColour(1),
    strokeWidth: 6,
  },
};
