import React from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import AppDataInfo from '../AppDataInfo';
import CircularProgressWithLabel from '../CircularProgressWithLabel';
import FormStatus from '../FormStatus';
import styles from './LeftSidebar.style';

import config from '../../config';

const LeftSidebar = () => {
  const classes = makeStyles(styles, { name: 'LeftSidebar' })();
  const location = useLocation();
  const progressValues = useSelector((state) => state.app);

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <div className={classes.toolbar} />

      <List>
        {config.sidebarMenu.map((item) => (
          <ListItem
            className={classes.listItem}
            selected={item.route === location.pathname}
            button
            component={RouterLink}
            to={item.route}
            key={item.id}
          >
            <ListItemText className={classes.listItemText} primary={item.title} />
            {item.progress &&
              progressValues[item.progress] &&
              progressValues[item.progress].progress > 0 && (
                <CircularProgressWithLabel progress={progressValues[item.progress].progress} />
              )}
            {item.progress && progressValues[item.progress] && (
              <FormStatus status={progressValues[item.progress].isFormDirty} />
            )}
          </ListItem>
        ))}
      </List>

      <AppDataInfo />
    </Drawer>
  );
};

export default LeftSidebar;
