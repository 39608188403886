import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const SaveDataDialog = ({
  isOpen,
  close,
  save,
  saveAsNew,
  title,
  fromDescription,
  formName,
  type,
  name,
  description,
  id,
}) => {
  const [saveName, setSaveName] = useState(name || '');
  const { errors, register, watch } = useFormContext();

  const watchName = watch('name');

  useEffect(() => {
    setSaveName(watchName);
  }, [watchName]);

  const handleClose = () => {
    close(false);
  };
  const handleSave = () => {
    if (saveName) {
      save();
    } else {
      // show error message
    }
  };

  return (
    <div style={{ minWidth: 400 }}>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        style={{ minWidth: 400 }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {fromDescription && <DialogContentText>{fromDescription}</DialogContentText>}
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="data-save-name">{formName} Name</InputLabel>
            <Input
              inputRef={register({
                required: true,
              })}
              id="data-save-name"
              name="name"
              defaultValue={name || ''}
              autoFocus
              margin="dense"
              error={errors.saveName}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              inputRef={register}
              id="data-save-description"
              name="description"
              defaultValue={description || ''}
              label={`${formName} Description`}
              multiline
              fullWidth
              rows={2}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="primary">
            Cancel
          </Button>
          {id && (
            <Button onClick={() => saveAsNew()} color="primary">
              Save as New
            </Button>
          )}
          <Button onClick={() => handleSave()} type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SaveDataDialog;
