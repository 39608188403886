import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import { ArtifactListByCategory } from 'components/ArtifactsByCategory';
import ComponentsInfo from 'components/ComponentsInfo';
import ArtifactList from 'components/ArtifactList';
import jobsService from 'services/JobsService';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ArtifactsByCategoryTabs({ id, uuid }) {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [artifacts, setArtifacts] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      setIsFetching(true);
      const result = await jobsService.listArtifactsByCategory(id);
      setArtifacts(result);
      setIsFetching(false);
    };
    fetchData();
  }, [id, uuid]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const toTitleCase = (phrase) => {
    return phrase
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  if (isFetching)
    return (
      <>
        <Tabs value={selectedTab}>
          <Tab label="Loading" {...a11yProps(selectedTab)} />
        </Tabs>

        <TabPanel value={selectedTab} index={0}>
          <Box minHeight={300}>Downloading artifacts.</Box>
        </TabPanel>
      </>
    );
  if (!artifacts) return <div>There is no artifact available.</div>;

  return (
    <div className={classes.root}>
      <AppBar position="relative" color="default">
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="reports tab"
        >
          {Object.entries(artifacts).map(([key, value], index) => {
            return <Tab label={toTitleCase(key)} {...a11yProps(index)} />;
          })}
          <Tab label="Info" {...a11yProps(Object.entries(artifacts).length)} />
        </Tabs>
      </AppBar>
      {Object.entries(artifacts).map(([key, value], index) => {
        return (
          <TabPanel value={selectedTab} index={index}>
            {Array.isArray(value) ? (
              <ArtifactList artifacts={value} jobID={id} section={key} />
            ) : (
              <ArtifactListByCategory artifacts={value} jobID={id} />
            )}
          </TabPanel>
        );
      })}
      <TabPanel value={selectedTab} index={Object.entries(artifacts).length}>
        {id ? <ComponentsInfo id={id} open /> : null}
      </TabPanel>
    </div>
  );
}
