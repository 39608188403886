import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArtifactList from 'components/ArtifactList';

const toTitleCase = (phrase) => {
  return phrase
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const ArtifactListByCategory = ({ artifacts, jobID }) => {
  return Object.entries(artifacts).map(([key, value], index) => {
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <Typography variant="h6" gutterBottom component="div">
            {toTitleCase(key)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: 'column' }}>
          {Array.isArray(value) ? (
            <ArtifactList artifacts={value} jobID={jobID} section={key} />
          ) : (
            <ArtifactListByCategory artifacts={value} jobID={jobID} />
          )}
        </AccordionDetails>
      </Accordion>
    );
  });
};
