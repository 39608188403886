import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, FormProvider, useWatch } from 'react-hook-form';

import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';

import SaveIcon from '@material-ui/icons/Save';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Accordion, AccordionSummary, AccordionDetails } from './ReportingOutput.styles';

import SaveDataDialog from 'components/SaveDataDialog';
import LoadDataDialog from 'components/LoadDataDialog';
import AlertDialog from 'components/AlertDialog';
import PreventNavigation from 'components/PreventNavigation';

import Layout from 'components/Layout';
import ReportingPreferences from 'components/ReportingPreferences';
import MUISwitch from 'components/MUISwitch';
import ReportModelSelector from 'components/ReportModelSelector';
import OutputReport from 'components/OutputReport';
import GenerateReports from 'components/GenerateReports';

import config from '../../config';

import {
  saveReportingOutput,
  saveReportingOutputInState,
  loadReportingOutput,
  deleteReportingOutput,
  resetReportingOutput,
  isDirtyReportingOutput,
  updateSelectedModels,
  updateModels,
} from 'redux/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: 0,
  },
  heading: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightRegular,
  },
  button: {
    margin: theme.spacing(1),
  },
  optionHeading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const ReportingOutput = () => {
  const [isSaveFormOpen, setSaveFormDialog] = useState(false);
  const [isLoadFormOpen, setLoadFormDialog] = useState(false);
  const [isResetOpen, setIsResetOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const dispatch = useDispatch();
  const { reportingData } = useSelector((state) => state.reporting);
  const { name, description, id, selectedModels, reportOnPortfolioModels } = reportingData;

  const classes = useStyles();
  const methods = useForm({ defaultValues: reportingData, shouldUnregister: false });
  const { control, register, watch } = methods;
  const watchForm = methods.watch();
  const { isDirty } = methods.formState;

  const reportOnPortfolioModelsValue = watch('reportOnPortfolioModels');
  const formValues = useWatch({ control });

  useEffect(() => {
    if (reportOnPortfolioModelsValue) {
      methods.setValue(config.attributionReport.name, false);
    }
  }, [reportOnPortfolioModelsValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const initialReportingData = {};

    methods.reset(initialReportingData);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    reportingData.id && methods.reset(reportingData);
  }, [reportingData.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async (data) => {
    const idForm = reportingData?.id ? reportingData.id : null;
    const formData = { ...data, selectedModels };

    dispatch(saveReportingOutput(formData, idForm));
    methods.reset(data);
    setSaveFormDialog(false);
  };

  const onSubmitNew = async (data) => {
    const formData = { ...data, selectedModels };
    dispatch(saveReportingOutput(formData, null));
    methods.reset(data);
    setSaveFormDialog(false);
  };
  const onError = (errors, e) => console.log(errors, e);

  const saveToStore = async () => {
    const formData = { ...watchForm, selectedModels };
    dispatch(saveReportingOutputInState(formData));
  };

  const handleSaveClose = () => {
    setSaveFormDialog(false);
  };
  const handleLoadClose = () => {
    setLoadFormDialog(false);
  };
  const handleSave = () => {
    methods.handleSubmit(onSubmit, onError)();
    setSaveFormDialog(false);
  };

  const handleSaveAsNew = () => {
    methods.handleSubmit(onSubmitNew, onError)();
  };

  const handleOpenSave = () => {
    setSaveFormDialog(true);
  };

  const handleLoad = () => {
    setLoadFormDialog(true);
  };

  const handleLoadSpec = (id) => {
    dispatch(loadReportingOutput(id));
    dispatch(isDirtyReportingOutput(false));
  };

  const handleReset = () => {
    setIsResetOpen(true);
  };
  const handleResetConfirm = () => {
    dispatch(resetReportingOutput());
    methods.reset({}, { isDirty: false });
    setIsResetOpen(false);
  };
  const handleResetCancel = () => {
    setIsResetOpen(false);
  };

  const handleDelete = () => {
    setIsDeleteOpen(true);
  };

  const handleDeleteConfirm = () => {
    dispatch(deleteReportingOutput(id));
    methods.reset({}, { isDirty: false });
    setIsDeleteOpen(false);
  };
  const handleDeleteCancel = () => {
    setIsDeleteOpen(false);
  };

  return (
    <Layout>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
          <SaveDataDialog
            isOpen={isSaveFormOpen}
            close={handleSaveClose}
            save={handleSave}
            saveAsNew={handleSaveAsNew}
            title="Save Reporting Output."
            formName="Report"
            type="reporting"
            name={name}
            description={description}
            id={id}
          />
          {isLoadFormOpen && (
            <LoadDataDialog
              isOpen={isLoadFormOpen}
              loadSpec={handleLoadSpec}
              close={handleLoadClose}
              type="reporting"
              title="Load Reporting Output"
              description="Select Reporting Output option to load."
            />
          )}
          <AlertDialog
            isOpen={isResetOpen}
            title="Do you want to reset Reporting Output form?"
            handleConfirm={handleResetConfirm}
            handleCancel={handleResetCancel}
          />
          <AlertDialog
            isOpen={isDeleteOpen}
            title="Do you want to delete Reporting Output from the Database?"
            handleConfirm={handleDeleteConfirm}
            handleCancel={handleDeleteCancel}
          />
          <Container>
            <Grid container spacing={3} alignItems="flex-start">
              <Grid item xs={6}>
                <Typography variant="h4" as="h1">
                  Reporting Output
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={1} alignItems="flex-end" justify="flex-end">
                  <Grid item xs={12}>
                    <Button
                      size="small"
                      className={classes.button}
                      onClick={() => handleOpenSave()}
                      type="button"
                      variant="contained"
                      color="primary"
                      startIcon={<SaveIcon />}
                    >
                      save
                    </Button>

                    <Button
                      size="small"
                      className={classes.button}
                      variant="contained"
                      onClick={() => handleLoad()}
                      color="primary"
                      startIcon={<OpenInBrowserIcon />}
                    >
                      Load
                    </Button>
                    <Button
                      size="small"
                      className={classes.button}
                      variant="contained"
                      onClick={() => handleReset()}
                      color="primary"
                      startIcon={<FiberNewIcon />}
                    >
                      Reset
                    </Button>

                    <Button
                      size="small"
                      className={classes.button}
                      variant="contained"
                      onClick={() => handleDelete()}
                      color="primary"
                      startIcon={<DeleteForeverIcon />}
                    >
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={3} alignItems="flex-start">
              <Container className={classes.root} style={{ margin: 0 }}>
                <Accordion elevation={4}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>Report Models</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography variant="h6" component="span">
                          Report on Portfolio Models
                        </Typography>
                        <MUISwitch
                          register={register}
                          defaultCheck={reportOnPortfolioModels || false}
                          name="reportOnPortfolioModels"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ReportModelSelector
                          reportOnPortfolio={reportOnPortfolioModelsValue}
                          selectedModels={selectedModels}
                          updateSelectedModels={updateSelectedModels}
                          updateModels={updateModels}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion elevation={4}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>Output Report</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid item xs={12}>
                      <Grid container spacing={3} alignItems="stretch">
                        <OutputReport
                          reportVariant="assetForecastPropertyReport"
                          reportOnPortfolioModelsValue={reportOnPortfolioModelsValue}
                        />
                        <OutputReport reportVariant="rankingReportAndAnalysis" />
                        {reportOnPortfolioModelsValue ? (
                          <OutputReport reportVariant="portfolioPerformanceReport" />
                        ) : null}
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion elevation={4}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>Preferences</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ReportingPreferences showOverWrite showUseHistoricalLearning showOneOffRun />
                  </AccordionDetails>
                </Accordion>

                <Accordion elevation={4}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>Generate Reports</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <GenerateReports
                      formValues={formValues}
                      selectedModels={selectedModels}
                      control={control}
                    />
                  </AccordionDetails>
                </Accordion>
              </Container>
            </Grid>
          </Container>
        </form>
        {/* <PreventNavigation dirty={isDirty} dirtyForm="reportingOutput" saveToStore={saveToStore} /> */}
      </FormProvider>
    </Layout>
  );
};

export default ReportingOutput;
