/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import userService from '../../services/UserService';
import FormAutoComplete from '../../components/FormAutoComplete';

import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker } from '@material-ui/pickers';

const DataForAnalysisPreferences = () => {
  const [currencies, setCurrencies] = useState([]);
  const { control } = useFormContext();

  useEffect(() => {
    const fetchData = async () => {
      const result = await userService.getCurrencies();
      setCurrencies(result.data);
    };
    fetchData();
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6" as="h5">
          Choose investor currency
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormAutoComplete options={currencies} name="investorCurrency" />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" as="h5">
          Return Frequency
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <Controller
            id="return-frequency"
            render={(props) => (
              <RadioGroup row aria-label="Return Frequency" {...props}>
                <FormControlLabel value="daily" control={<Radio />} label="Daily" />
                <FormControlLabel value="weekly" control={<Radio />} label="Weekly" />
                <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
              </RadioGroup>
            )}
            name="returnFrequency"
            control={control}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={3} md={2}>
            <Typography variant="h6" as="h5">
              Earliest Date
            </Typography>
          </Grid>
          <Grid item xs={6} sm={9} md={10}>
            <Controller
              control={control}
              name="startDate"
              render={({ onChange, onBlur, name, value }) => (
                <KeyboardDatePicker
                  name={name}
                  value={value}
                  clearable
                  autoOk
                  placeholder="MM-DD-YYYY"
                  onChange={(date) => onChange(date ? date.format() : null)}
                  onBlur={(value) => onBlur(value ? value : null)}
                  format="MM-DD-YYYY"
                />
              )}
            />
          </Grid>
        </Grid>{' '}
      </Grid>
      {/* End Date */}
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={3} md={2}>
            <Typography variant="h6" as="h5">
              End Date
            </Typography>
          </Grid>
          <Grid item xs={6} sm={9} md={10}>
            <Controller
              control={control}
              name="endDate"
              render={({ onChange, onBlur, name, value }) => (
                <KeyboardDatePicker
                  name={name}
                  value={value}
                  clearable
                  autoOk
                  placeholder="MM-DD-YYYY"
                  onChange={(date) => onChange(date ? date.format() : null)}
                  onBlur={(value) => onBlur(value ? value : null)}
                  format="MM-DD-YYYY"
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DataForAnalysisPreferences;
