import * as types from '../../types';
import { auth } from '../../../services/firebase';
import { getUserPreferences } from '../../actions';

// User log in
export const loginUser = (user) => (dispatch) => {
  dispatch({
    type: types.USER_LOGIN,
    payload: user,
  });
  dispatch(getUserPreferences());
};

export const loginInProgress = () => (dispatch) => {
  dispatch({
    type: types.USER_LOGIN_IN_PROGRESS,
  });
};

export const logoutUser = () => (dispatch) => {
  dispatch({
    type: types.USER_LOGOUT,
  });
  auth.signOut();
};

export const submitSignUp =
  ({ email, password }) =>
  async (dispatch) => {
    dispatch(loginInProgress());
    try {
      auth.createUserWithEmailAndPassword(email, password);
      dispatch(loginInProgress());
    } catch (error) {
      dispatch({
        type: types.USER_ERROR,
      });
      dispatch(loginInProgress());
    }
  };

export const submitLogin =
  ({ email, password }) =>
  async (dispatch) => {
    dispatch(loginInProgress());
    try {
      auth.signInWithEmailAndPassword(email, password);
      dispatch(loginInProgress());
    } catch (error) {
      dispatch({
        type: types.USER_ERROR,
      });
      dispatch(loginInProgress());
    }
  };
