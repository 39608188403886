/* eslint-disable operator-linebreak */
/* eslint-disable arrow-body-style */
import axios from 'axios';
import isEmpty from 'lodash.isempty';
import { getAccessToken } from './firebase';

const appService = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    // Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

appService.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.data.errors &&
      error.response.data.errors[0].code === 'INVALID_TOKEN'
    ) {
      // return AuthService.updateAccessToken().then((accessToken) => {
      //   error.config.headers.Authorization = `Bearer ${accessToken}`;
      //   return apiClient.request(error.config);
      // });
    }
    return Promise.reject(error);
  },
);

const getLookups = async (params) => {
  const accessToken = await getAccessToken();
  try {
    const apiURL = `/lookups`;
    const result = await appService.get(apiURL, {
      params,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return result.data;
  } catch (error) {
    return error;
  }
};

const saveLookup = async ({ data }) => {
  const accessToken = await getAccessToken();
  try {
    const apiURL = `/lookups`;
    const result = await appService.post(apiURL, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return result.data;
  } catch (error) {
    return error;
  }
};

const updateLookup = async ({ data, id }) => {
  const accessToken = await getAccessToken();
  try {
    const apiURL = `/lookups/${id}`;
    const result = await appService.put(apiURL, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return result.data;
  } catch (error) {
    return error;
  }
};

const deleteLookup = async ({ id }) => {
  const accessToken = await getAccessToken();
  try {
    const apiURL = `/lookups/${id}`;
    const result = await appService.delete(apiURL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return result.data;
  } catch (error) {
    return error;
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { getLookups, deleteLookup, saveLookup, updateLookup };
