import differenceWith from 'lodash.differencewith';

import {
  DATA_FOR_ANALYSIS_LOAD_DATA,
  DATA_FOR_ANALYSIS_LOAD_DATA_FROM_ESTIMATOR,
  DATA_FOR_ANALYSIS_SAVE_DATA,
  DATA_FOR_ANALYSIS_SAVE_DATA_IN_STATE,
  DATA_FOR_ANALYSIS_RESET,
  DATA_FOR_ANALYSIS_ADD_BENCHMARK,
  DATA_FOR_ANALYSIS_UPDATE_BENCHMARK,
  DATA_FOR_ANALYSIS_REMOVE_BENCHMARK,
  DATA_FOR_ANALYSIS_REORDER_BENCHMARK,
  DATA_FOR_ANALYSIS_ADD_MACROFACTOR,
  DATA_FOR_ANALYSIS_REMOVE_MACROFACTOR,
  DATA_FOR_ANALYSIS_REORDER_MACROFACTOR,
  USER_LOGOUT,
} from '../../types';

const initialState = {
  universe: null,
  name: null,
  description: null,
  allowShortSales: false,
  shortUniverse: null,
  overrideFeeWithDefault: false,
  defaultFeePrior: 1.25,
  benchmarkUniverse: null,
  investorCurrency: null,
  returnFrequency: null,
  earliestDate: null,
  riskFree: null,
  riskFreeRate: null,
  availableBenchmark: [],
  benchmarks: [],
  macrofactors: [],
};

const dataForAnalysisReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGOUT:
      return initialState;
    case DATA_FOR_ANALYSIS_LOAD_DATA:
      return {
        ...action.payload,
      };
    case DATA_FOR_ANALYSIS_LOAD_DATA_FROM_ESTIMATOR:
      return { ...action.payload };
    case DATA_FOR_ANALYSIS_SAVE_DATA:
      return {
        ...action.payload,
      };
    case DATA_FOR_ANALYSIS_SAVE_DATA_IN_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case DATA_FOR_ANALYSIS_RESET: {
      return initialState;
    }
    case DATA_FOR_ANALYSIS_ADD_BENCHMARK:
      const addBenchmarks = differenceWith(
        action.payload,
        state.benchmarks,
        (arrVal, othVal) => arrVal.id === othVal.id,
      );

      return {
        ...state,
        benchmarks: [...state.benchmarks, ...addBenchmarks],
      };
    case DATA_FOR_ANALYSIS_UPDATE_BENCHMARK:
      const updatedBenchmark = state.benchmarks.map((benchmark) => {
        return benchmark.id === action.payload.id
          ? { ...benchmark, selfFinancing: !benchmark.selfFinancing }
          : benchmark;
      });

      return {
        ...state,
        benchmarks: [...updatedBenchmark],
      };
    case DATA_FOR_ANALYSIS_REMOVE_BENCHMARK:
      const newBenchmarks = state.benchmarks;
      newBenchmarks.splice(action.payload, 1);
      return {
        ...state,
        benchmarks: [...newBenchmarks],
      };
    case DATA_FOR_ANALYSIS_REORDER_BENCHMARK:
      return {
        ...state,
        benchmarks: [...action.payload],
      };
    case DATA_FOR_ANALYSIS_ADD_MACROFACTOR:
      const addMacrofactors = differenceWith(
        action.payload,
        state.macrofactors,
        (arrVal, othVal) => arrVal.id === othVal.id,
      );

      return {
        ...state,
        macrofactors: [...state.macrofactors, ...addMacrofactors],
      };
    case DATA_FOR_ANALYSIS_REMOVE_MACROFACTOR:
      const newMacrofactors = state.macrofactors;
      newMacrofactors.splice(action.payload, 1);
      return {
        ...state,
        macrofactors: [...newMacrofactors],
      };
    case DATA_FOR_ANALYSIS_REORDER_MACROFACTOR:
      return {
        ...state,
        macrofactors: [...action.payload],
      };
    default:
      return state;
  }
};

export default dataForAnalysisReducer;
