import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.error.main,
    fontSize: 14,
  },
}));

const Error = ({ error }) => {
  const classes = useStyles();

  return (
    <Typography variant="h6" classes={{ root: classes.root }}>
      {error}
    </Typography>
  );
};

Error.propTypes = {
  error: PropTypes.string.isRequired,
};

export default Error;
