import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';

import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import InlineInput from '../../components/InlineInput';
import FormSelect from '../../components/FormSelect';
import CompleteSubsetRegression from '../../components/CompleteSubsetRegression';
import MUISwitch from '../../components/MUISwitch';
import { HelpInfo } from '../../components/HelpInfo';

import config from '../../config';

const MacroeconomicVariables = () => {
  const { estimatorRules } = useSelector((state) => state.estimatorModel);
  const { control, register, watch, errors } = useFormContext();
  const enableCompleteSubsetRegressionValue = watch('enableCompleteSubsetRegression');

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Typography variant="h6" as="h5">
              Complete Subset Regression
            </Typography>
            <HelpInfo name="completeSubsetRegression" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={6}>
              <Typography variant="body1">Enable complete subset regression</Typography>
            </Grid>
            <Grid item xs={6}>
              <MUISwitch
                register={register}
                defaultCheck={false}
                name="enableCompleteSubsetRegression"
                disabled={estimatorRules?.enableCompleteSubsetRegression}
              />
            </Grid>
          </Grid>
        </Grid>
        {enableCompleteSubsetRegressionValue && !estimatorRules?.enableCompleteSubsetRegression ? (
          <>
            <Grid item xs={12}>
              <Typography variant="h6" as="h5">
                Macroeconomic variables
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CompleteSubsetRegression />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <Typography variant="h6" as="h5">
                  Model Weighting Rule
                </Typography>
                <HelpInfo name="modelWeightingRule" isHtml />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <FormControl component="fieldset">
                    <Controller
                      id="model-weighting"
                      defaultValue=""
                      name="modelWeightRule"
                      control={control}
                      render={(props) => (
                        <RadioGroup aria-label=" Model Weighting Rule" {...props}>
                          <FormControlLabel
                            value="equalWeightedCombinations"
                            disabled={estimatorRules?.modelWeightRule}
                            control={<Radio />}
                            label="Equal Weighted Combinations"
                          />
                          <FormControlLabel
                            value="posteriorOddsWeighting"
                            disabled={estimatorRules?.modelWeightRule}
                            control={<Radio />}
                            label="Posterior Odds Weighting"
                          />
                          <FormControlLabel
                            value="optimalPredictiveWeighting"
                            disabled={estimatorRules?.modelWeightRule}
                            control={<Radio />}
                            label="Optimal Predictive Weighting"
                          />
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <InlineInput
                        control={control}
                        text="Variable Count"
                        placeholder="Eg. 9"
                        helperText="Please enter as integer."
                        name="variableCount"
                        disabled={estimatorRules?.variableCount}
                        error={errors?.variableCount?.message}
                        leftGrid={5}
                        rightGrid={7}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InlineInput
                        control={control}
                        text="Gamma - CSR"
                        name="gammaCSR"
                        disabled={estimatorRules?.gammaCSR}
                        placeholder="Eg. 1"
                        error={errors?.gammaCSR?.message}
                        leftGrid={5}
                        rightGrid={7}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" as="h5">
                Consolidation
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box mb={5}>
                <FormSelect
                  control={control}
                  name="consolidationRule"
                  label="Consolidation Rule"
                  emptyValue={config.consolidationRule[0].value}
                  emptyDefaultValue={config.consolidationRule[0].value}
                  data={config.consolidationRule}
                />
              </Box>
            </Grid>
          </>
        ) : null}
      </Grid>
    </>
  );
};

export default MacroeconomicVariables;
