import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';

import InlineInput from '../../components/InlineInput';
import MUISwitch from '../../components/MUISwitch';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  datePicker: {
    width: 180,
    borderRadius: 4,
    border: 0,
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    backgroundColor: theme.palette.background.default,
    padding: 5,
  },
  input: {
    width: 200,
    backgroundColor: theme.palette.background.default,
    borderRadius: 4,
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      border: 0,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 0,
      },
      '&:hover fieldset': {
        border: 0,
      },
      '&.Mui-focused fieldset': {
        border: 0,
      },
    },
  },
}));

const TimeframeAndVintaging = () => {
  const classes = useStyles();
  const { preference } = useSelector((state) => state.app);
  const preferredStartDate = preference?.preferenceData?.startDate
    ? preference.preferenceData.startDate
    : '01-01-1990';
  const preferredEndDate = preference?.preferenceData?.endDate
    ? preference.preferenceData.endDate
    : null;
  const { estimatorData } = useSelector((state) => state.estimatorModel);
  const { rollingWindow, customizeVintageCalendar } = estimatorData;

  const { control, register, watch, setValue, errors } = useFormContext();
  const { startDate, endDate } = watch(['startDate', 'endDate']);

  const rollingWindowValue = watch('rollingWindow');
  const customizeVintageCalendarValue = watch('customizeVintageCalendar');

  useEffect(() => {
    (startDate === undefined || startDate === null) &&
      setValue('startDate', preferredStartDate, { shouldValidate: true, shouldDirty: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (endDate === undefined || endDate === null) &&
      setValue('endDate', preferredEndDate, { shouldValidate: true, shouldDirty: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3} alignItems="flex-start">
            <Grid item sm={12} md={4}>
              <Typography variant="subtitle1">Start Date</Typography>
            </Grid>
            <Grid item sm={12} md={8}>
              <Controller
                control={control}
                name="startDate"
                value={preferredStartDate}
                render={({ onChange, onBlur, name, value }) => {
                  const defaultValue = value ? value : preferredStartDate;

                  return (
                    <KeyboardDatePicker
                      className={classes.datePicker}
                      name={name}
                      value={defaultValue}
                      // minDate={preferredStartDate}
                      disableFuture
                      clearable
                      autoOk
                      placeholder="MM-DD-YYYY"
                      onChange={(date) => onChange(date ? date.format() : preferredStartDate)}
                      format="MM-DD-YYYY"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3} alignItems="flex-start">
            <Grid item sm={12} md={4}>
              <Typography variant="subtitle1">End Date</Typography>
            </Grid>
            <Grid item sm={12} md={8}>
              <Controller
                control={control}
                name="endDate"
                defaultValue={null}
                render={({ onChange, onBlur, name, value }) => {
                  const defaultValue = value ? value : preferredEndDate;
                  return (
                    <KeyboardDatePicker
                      className={classes.datePicker}
                      name={name}
                      value={defaultValue}
                      minDate={preferredStartDate}
                      autoOk
                      clearable
                      placeholder="MM-DD-YYYY"
                      onChange={(date) => onChange(date ? date.format() : null)}
                      format="MM-DD-YYYY"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <InlineInput
            control={control}
            text="Generation frequency"
            helperText="Please enter as integer."
            name="generationFrequency"
            error={errors?.generationFrequency?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <InlineInput
            control={control}
            text="Minimum number of observations"
            helperText="Please enter as integer."
            name="minNumberOfObservations"
            error={errors?.minNumberOfObservations?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="span">
            Rolling window
          </Typography>
          <MUISwitch register={register} defaultCheck={rollingWindow} name="rollingWindow" />
        </Grid>
        {/* TODO: create new component for Title and small input */}
        {rollingWindowValue ? (
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="body1" component="span">
                  Vintage window length
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  id="standard-basic"
                  defaultValue=""
                  render={(props) => (
                    <TextField
                      {...props}
                      className={classes.input}
                      elevation={4}
                      variant="outlined"
                    />
                  )}
                  name="vintageWindowLength"
                  control={control}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Typography variant="h6" component="span">
            Customise vintage calendar
          </Typography>
          <MUISwitch
            register={register}
            defaultCheck={customizeVintageCalendar}
            name="customizeVintageCalendar"
          />
        </Grid>
        {/* TODO: create new component for Title and small input */}
        {customizeVintageCalendarValue ? (
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="body1" component="span">
                  Vintage Month End Day
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  id="standard-basic"
                  defaultValue=""
                  render={(props) => (
                    <TextField
                      {...props}
                      className={classes.input}
                      elevation={4}
                      variant="outlined"
                    />
                  )}
                  name="vintageMonthEndDay"
                  control={control}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        {/* TODO: phase 2 Upload custom vintage calendar */}
        {/* <Grid item xs={12}>
          <Box mb={5}>
            <Button type="button" variant="contained" color="primary">
              Upload custom vintage calendar
            </Button>
          </Box>
        </Grid> */}
      </Grid>
    </>
  );
};

export default TimeframeAndVintaging;
