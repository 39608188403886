import * as types from '../../types';
import { showMessage, isDirtyDataForAnalysis, validateDataForAnalysis } from '../../actions';
import universeService from '../../../services/UniverseService';
import dfaService from '../../../services/DfaService';

// Load Data
export const loadDataForAnalysis = (id) => async (dispatch) => {
  const result = await dfaService.loadSpec(id);

  dispatch({
    type: types.DATA_FOR_ANALYSIS_LOAD_DATA,
    payload: result,
  });
  dispatch(validateDataForAnalysis(result));
  dispatch(isDirtyDataForAnalysis(false));
  dispatch(showMessage({ type: 'success', message: 'Data loaded successfully.' }));
};

export const saveDataForAnalysis = (data, id) => async (dispatch) => {
  try {
    const type = 'dataForAnalysis';
    const result = await dfaService.saveDfaSpec(data, type, id);
    if (result?.error) {
      dispatch(showMessage({ type: 'error', message: result.error?.detail }));
    } else {
      dispatch(showMessage({ type: 'success', message: 'Data saved successfully.' }));

      dispatch({
        type: types.DATA_FOR_ANALYSIS_SAVE_DATA,
        payload: result,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const saveDataForAnalysisInState = (data) => async (dispatch) => {
  dispatch({
    type: types.DATA_FOR_ANALYSIS_SAVE_DATA_IN_STATE,
    payload: data,
  });
};

export const resetDataForAnalysis = () => (dispatch) => {
  dispatch(isDirtyDataForAnalysis(null));
  dispatch({
    type: types.DATA_FOR_ANALYSIS_RESET,
    payload: '',
  });
  dispatch({
    type: types.APP_DATA_FOR_ANALYSIS_RESET,
    payload: '',
  });
};

export const deleteDataForAnalysis = (id) => async (dispatch) => {
  try {
    await universeService.deleteUniverse(id);
    dispatch(resetDataForAnalysis());
    dispatch(isDirtyDataForAnalysis(null));
    dispatch(showMessage({ type: 'success', message: 'Data deleted successfully.' }));
  } catch (error) {}
};

// Benchmarks
export const addBenchmark = (data) => (dispatch) => {
  dispatch({
    type: types.DATA_FOR_ANALYSIS_ADD_BENCHMARK,
    payload: data,
  });
  dispatch(isDirtyDataForAnalysis(true));
};

export const updateBenchmark = (data) => (dispatch) => {
  dispatch({
    type: types.DATA_FOR_ANALYSIS_UPDATE_BENCHMARK,
    payload: data,
  });
  dispatch(isDirtyDataForAnalysis(true));
};

export const removeBenchmark = (data) => (dispatch) => {
  dispatch({
    type: types.DATA_FOR_ANALYSIS_REMOVE_BENCHMARK,
    payload: data,
  });
  dispatch(isDirtyDataForAnalysis(true));
};

export const reorderBenchmark = (data) => (dispatch) => {
  dispatch({
    type: types.DATA_FOR_ANALYSIS_REORDER_BENCHMARK,
    payload: data,
  });
  dispatch(isDirtyDataForAnalysis(true));
};

// Macrofactors
export const addMacrofactor = (data) => (dispatch) => {
  const { availableBenchmark, timeSeriesFiltration } = data;
  const selectedMacroFactors = availableBenchmark.map((macrofactor) => ({
    ...macrofactor,
    timeSeriesFiltration: { ...timeSeriesFiltration },
  }));

  dispatch({
    type: types.DATA_FOR_ANALYSIS_ADD_MACROFACTOR,
    payload: selectedMacroFactors,
  });
  // dispatch(updateSubsetRegressionMacrofactors(selectedMacroFactors));
  dispatch(isDirtyDataForAnalysis(true));
};

export const removeMacrofactor = (data) => (dispatch) => {
  dispatch({
    type: types.DATA_FOR_ANALYSIS_REMOVE_MACROFACTOR,
    payload: data,
  });

  dispatch(isDirtyDataForAnalysis(true));
};

export const reorderMacrofactor = (data) => (dispatch) => {
  dispatch({
    type: types.DATA_FOR_ANALYSIS_REORDER_MACROFACTOR,
    payload: data,
  });
  dispatch(isDirtyDataForAnalysis(true));
};
