import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ReportingPreferences from 'components/ReportingPreferences';
import ReportModelSelector from 'components/ReportModelSelector';
import { updateSelectedSegmentedModels, updateSegmentedModels } from 'redux/actions';

export const SimulatedSegmentedModelCombinations = () => {
  // TODO - SAVE simulated models data to DB
  // TODO - Load simulated models data to DB
  const { control, errors } = useFormContext();

  const { segmentedModelData } = useSelector((state) => state.estimatorModel);
  const { selectedModels } = segmentedModelData;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ReportModelSelector
            reportOnPortfolio={false}
            selectedModels={selectedModels}
            updateSelectedModels={updateSelectedSegmentedModels}
            updateModels={updateSegmentedModels}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" component="h5" style={{ marginBottom: 20 }}>
            Preference
          </Typography>
          <ReportingPreferences showOverWrite={true} />
        </Grid>
      </Grid>
    </>
  );
};
