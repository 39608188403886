import * as React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

export const SelectBox = ({ handleMenuItemClick, selectedId, secondaryText, data }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick = (id) => {
    handleMenuItemClick(id);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectedName = data?.find((item) => item.id === selectedId)?.name;

  return (
    <>
      <List
        component="nav"
        aria-label={secondaryText}
        style={{
          padding: 0,
        }}
      >
        <ListItem
          button
          aria-haspopup="true"
          aria-controls="lock-menu"
          aria-label={secondaryText}
          onClick={handleClickListItem}
          style={{
            paddingTop: 0,
          }}
        >
          <ListItemText primary={selectedName} />
          {anchorEl ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {data.map((item) => {
          const { id, name } = item;
          return (
            <MenuItem key={id} selected={id === selectedId} onClick={() => handleClick(id)}>
              {name}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
