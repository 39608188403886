/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { submitSignUp } from '../../redux/actions';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {`Copyright © Parala ${new Date().getFullYear()}.`}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const SignUpForm = () => {
  const { loginInProgress } = useSelector(state => state.auth);
  const { handleSubmit, control, errors: fieldsErrors } = useForm();
  const dispatch = useDispatch();
  const classes = useStyles();

  const onSubmitSignUp = async data => {
    dispatch(submitSignUp(data));
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <AccountCircleIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmitSignUp)}>
          <FormControl fullWidth className={classes.margin} variant="outlined">
            <Controller
              name="email"
              render={renderProps => (
                <TextField
                  {...renderProps}
                  id="email"
                  required
                  fullWidth
                  helperText={fieldsErrors.email ? fieldsErrors.email.message : null}
                  variant="outlined"
                  label="Email Address"
                  error={fieldsErrors.email && true}
                />
              )}
              control={control}
              defaultValue=""
              rules={{
                required: 'Email address is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Invalid email address',
                },
              }}
            />
          </FormControl>
          <FormControl fullWidth className={classes.margin} variant="outlined">
            <Controller
              name="password"
              render={renderProps => (
                <TextField
                  {...renderProps}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  helperText={fieldsErrors.password ? fieldsErrors.password.message : null}
                  error={fieldsErrors.password && true}
                />
              )}
              control={control}
              defaultValue=""
              rules={{
                required: 'Password is required',
              }}
            />
          </FormControl>

          <div className={classes.wrapper}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              className={classes.submit}
              disabled={loginInProgress}
            >
              Sign Up
            </Button>
            {loginInProgress && (
              <CircularProgress disableShrink className={classes.buttonProgress} />
            )}
          </div>
          <Grid container>
            <Grid item xs>
              <Link component={RouterLink} to="/forgot-password" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link component={RouterLink} to="/login" variant="body2">
                Sign In
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default SignUpForm;
