// MUI

import Box from '@material-ui/core/Box';

import { ChartWithSelect } from 'components/Dashboard';

export const MacroFactoLevelAttributionReports = ({
  showReports,
  showKeys,
  dateIndex,
  chartDataGroupedById,
  macrofactorIds,
  alphaContribution,
}) => {
  return (
    <Box display="flex" flex={1} flexDirection="column">
      {/* alphaContribution */}
      {macrofactorIds.map((macrofactor, index) => {
        const { name, id } = macrofactor;

        return showReports.alphaContribution.visible ? (
          <Box
            key={id}
            style={{
              marginBottom: 30,
              paddingBottom: 30,
              borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            }}
          >
            <ChartWithSelect
              chartId="alphaContribution"
              chart={alphaContribution[index]}
              chartDataGroupedById={chartDataGroupedById}
              isBiAxis={true}
              isTripleAxis={true}
              yAxisLabels={{
                left: 'Return',
                right: 'Sensitivity',
                right2: 'Macro factor value',
              }}
              chartProps={{
                yAxis1: {
                  tickFormatter: (value) => `${value.toFixed(2)}%`,
                },
                yAxis2: {
                  tickFormatter: (value) => `${value.toFixed(2)}%`,
                },
                yAxis3: {
                  tickFormatter: (value) => `${value.toFixed(2)}%`,
                },
              }}
              title={`Alpha contribution for: ${name}`}
              description={`This chart shows the contributions of the various components of alpha to
          ${name} for:  {REPLACE_NAME}`}
              showKeys={showKeys}
              dateIndex={dateIndex}
            />
          </Box>
        ) : null;
      })}
      {/* alphaContribution */}
    </Box>
  );
};
