// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/heatmap
import { MyResponsiveHeatMap } from 'components/HeatMap';
import { formatNumber } from '../attributesData';

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export const PerformanceHeatMap = ({ data }) => (
  <MyResponsiveHeatMap
    heatmap={data}
    displayMonths={13}
    formatValue={(value) => {
      if (value === null) return null;
      return `${formatNumber(value, true)}%`;
    }}
    xLegend="Month"
    yLegend="Year"
    left={60}
    right={60}
    cellWidth={40}
  />
);
