import { red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        'input::-webkit-inner-spin-button': {
          display: 'none',
        },
        'input[type=number]::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
        'input[type=number]::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
      },
    },
  },
  typography: {
    fontFamily: ['Work Sans', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: '#1E4669',
    },
    secondary: {
      main: '#1E4669',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
      gray: '#F2F2F2',
    },
  },
});

export default theme;
