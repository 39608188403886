// MUI

import Box from '@material-ui/core/Box';

import { AssetAllocation } from './AssetAllocation';

export const AssetAllocationReports = ({
  showReports,
  showKeys,
  dateIndex,
  idsAndNames,
  pyPortfolioReturns,
}) => {
  return (
    <Box display="flex" flex={1} flexDirection="column">
      {/* AssetAllocation */}
      {showReports.assetAllocation.visible ? (
        <AssetAllocation
          pyPortfolioReturns={pyPortfolioReturns}
          idsAndNames={idsAndNames}
          showKeys={showKeys}
          dateIndex={dateIndex}
        />
      ) : null}
      {/* AssetAllocation */}
    </Box>
  );
};
