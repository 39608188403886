import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { addBenchmark, addMacrofactor } from '../../redux/actions';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  input: {
    margin: theme.spacing(1),
    '& .MuiInput-underline:before': {
      border: 0,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 0,
    },
  },
}));

const { timeSeriesOption } = config;

const AvailableBenchmarkForm = ({
  availableBenchmarkList,
  benchMarkPlaceholder,
  macroFactor = false,
  timeFiltration = false,
  addButtonLabel,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { control, handleSubmit, reset, setValue } = useForm();
  const defaultTimeSeriesValue = timeSeriesOption[0] || null;

  const onSubmit = (data) => {
    if (macroFactor) {
      dispatch(addMacrofactor(data));
      setValue('timeSeriesFiltration', defaultTimeSeriesValue);
    } else {
      const benchmarks = data.availableBenchmark.map((benchmark) => ({
        ...benchmark,
        selfFinancing: false,
      }));
      dispatch(addBenchmark(benchmarks));
    }
    reset();
  };

  return (
    <>
      <Grid item xs={12} style={{ maxWidth: 530 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                render={({ onChange, ...props }) => (
                  <Autocomplete
                    options={availableBenchmarkList || []}
                    multiple
                    disabled={!availableBenchmarkList?.length}
                    getOptionLabel={(option) => option.name || ''}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <Paper className={classes.paper} elevation={4}>
                        <TextField
                          className={classes.input}
                          {...params}
                          placeholder={benchMarkPlaceholder}
                        />
                      </Paper>
                    )}
                    renderOption={(option, { inputValue }) => {
                      const matches = match(option.name, inputValue);
                      const parts = parse(option.name, matches);

                      return (
                        <div>
                          {parts.map((part, index) => (
                            <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                              {part.text}
                            </span>
                          ))}
                        </div>
                      );
                    }}
                    onChange={(e, data) => onChange(data)}
                    {...props}
                  />
                )}
                defaultValue={[]}
                id="timeSeriesFiltration"
                onChange={([, data]) => data}
                name="availableBenchmark"
                control={control}
              />
            </Grid>
            {timeFiltration && (
              <Grid item xs={8}>
                <Controller
                  render={({ onChange, ...props }) => (
                    <Autocomplete
                      options={timeSeriesOption}
                      multiple={false}
                      disabled={!availableBenchmarkList?.length || !timeSeriesOption?.length}
                      getOptionLabel={(option) => option.name || ''}
                      filterSelectedOptions
                      renderInput={(params) => (
                        <Paper className={classes.paper} elevation={4}>
                          <TextField
                            className={classes.input}
                            {...params}
                            placeholder="Time Series Filtration"
                          />
                        </Paper>
                      )}
                      onChange={(e, data) => onChange(data)}
                      {...props}
                    />
                  )}
                  defaultValue={defaultTimeSeriesValue}
                  id="timeSeriesFiltration"
                  // TODO update default value from state
                  onChange={([, data]) => data}
                  name="timeSeriesFiltration"
                  control={control}
                />
              </Grid>
            )}
            <Grid item xs={4}>
              <Button
                variant="contained"
                size="small"
                color="primary"
                disabled={!availableBenchmarkList?.length}
                onClick={handleSubmit(onSubmit)}
              >
                {addButtonLabel}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
};

export default AvailableBenchmarkForm;
