import moment from 'moment';
import { getColorById } from './colors';

export const findNameWithId = (id, idsAndNames = []) => {
  return (
    idsAndNames?.find((i) => i.id === id || i.id?.toString()?.trim() === id?.toString()?.trim())
      ?.name || 'not found'
  );
};

// format number to 2 decimal places
export const formatNumber = (number, percentage) => {
  const newNumber = percentage ? (number * percentage).toFixed(2) : number.toFixed(2);
  // return the number
  return parseFloat(newNumber);
};

export const formatDataToPieChart = (dataArray, idsAndNames, regex = /^\w+/) => {
  return dataArray.map((item) => {
    const objectKeys = Object.keys(item);
    let newObject = {};
    let data = [];

    newObject['date'] = item.ReturnDate;
    newObject['dateFormatted'] = moment(item.ReturnDate, 'YYYYMMDD').format('DD/MM/YYYY');
    newObject['WeightVintageDate'] = item?.WeightVintageDate || null;

    objectKeys.forEach((key) => {
      const [name, id] = key.split('_');
      const riskFactorName = findNameWithId(id, idsAndNames);
      const color = getColorById(id);

      if (riskFactorName !== 'not found' && key.match(regex)) {
        const value = typeof item[key] === 'string' ? item[key].trim() : item[key];

        data.push({
          name: riskFactorName,
          value: formatNumber(parseFloat(value), 100),
          formattedValue: formatNumber(parseFloat(value), 100),
          originalValue: value,
          ...item,
          color: color,
          fill: color,
          stroke: color,
        });
      }
    });
    newObject['data'] = data?.sort((a, b) => a?.name?.localeCompare(b?.name));

    return newObject;
  });
};

export const formatDataToLineChart = (dataArray, idsAndNames, regex = /^\w+/) => {
  return dataArray.map((item) => {
    const objectKeys = Object.keys(item);
    let newObject = {};
    let itemData = {};

    newObject['date'] = item.ReturnDate;
    newObject['dateFormatted'] = moment(item.ReturnDate, 'YYYYMMDD').format('DD/MM/YYYY');
    newObject['WeightVintageDate'] = item?.WeightVintageDate || null;

    objectKeys.forEach((key) => {
      const [name, id] = key.split('_');
      const riskFactorName = findNameWithId(id, idsAndNames);
      const color = getColorById(id);

      if (riskFactorName !== 'not found' && key.match(regex)) {
        const value = typeof item[key] === 'string' ? item[key].trim() : item[key];

        itemData[id] = {
          name: riskFactorName,
          value: formatNumber(parseFloat(value), 100),
          formattedValue: formatNumber(parseFloat(value), 100),
          originalValue: value,
          ...item,
          color: color,
          fill: color,
          stroke: color,
        };
      }
    });

    return { ...newObject, ...itemData };
  });
};
