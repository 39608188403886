/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import MenuAppBar from './MenuAppBar';
import { Login, SignUp, PrivateRoute } from 'containers';

import Landing from 'containers/Landing';
import DataForAnalysis from 'containers/DataForAnalysis';
import EstimatorModel from 'containers/EstimatorModel';
import PortfolioModel from 'containers/PortfolioModel';
import ReportingOutput from 'containers/ReportingOutput';
import RankingReport from 'containers/RankingReport';
import Reports from 'containers/Reports';
import ListingAllSpec from 'containers/ListingAllSpec';
import { ReportsDetail } from 'containers/ReportsDetail';
import { ReportsDashboard, EstimatorReportsDashboard } from 'containers/ReportsDashboard';
import Translate from 'containers/Translate';

import { auth } from '../services/firebase';
import { loginUser } from '../redux/actions';

function onAuthStateChange(callback) {
  return auth.onAuthStateChanged((user) => {
    if (user) {
      const { loginInProgress, uid, displayName, photoURL, email } = user;
      callback({
        isAuthenticated: true,
        loginInProgress,
        uid,
        displayName,
        photoURL,
        email,
      });
    } else {
      callback({ isAuthenticated: false });
    }
  });
}

function App() {
  const dispatch = useDispatch();

  const loginTheUser = (user) => {
    dispatch(loginUser(user));
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChange(loginTheUser);
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <Router>
      <div style={{ display: 'flex' }}>
        <MenuAppBar />
        <Switch>
          <PrivateRoute exact path="/">
            <Landing />
          </PrivateRoute>
          <PrivateRoute exact path="/data-for-analysis">
            <DataForAnalysis />
          </PrivateRoute>
          <PrivateRoute exact path="/estimator-model">
            <EstimatorModel />
          </PrivateRoute>
          <PrivateRoute exact path="/portfolio-model">
            <PortfolioModel />
          </PrivateRoute>
          <PrivateRoute exact path="/ranking-report">
            <RankingReport />
          </PrivateRoute>
          <PrivateRoute exact path="/reporting-output">
            <ReportingOutput />
          </PrivateRoute>
          <PrivateRoute exact path="/reports">
            <Reports />
          </PrivateRoute>
          <PrivateRoute path="/reports/:id/dashboard">
            <ReportsDashboard />
          </PrivateRoute>

          <PrivateRoute path="/reports/:id/e/dashboard">
            <EstimatorReportsDashboard />
          </PrivateRoute>

          <PrivateRoute path="/reports/:id">
            <ReportsDetail />
          </PrivateRoute>
          <PrivateRoute path="/listing/:type">
            <ListingAllSpec />
          </PrivateRoute>
          <PrivateRoute path="/translate-friendly">
            <Translate />
          </PrivateRoute>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/signup">
            <SignUp />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
