import { ResponsiveLine } from '@nivo/line';
import uniq from 'lodash.uniq';

const Chart = ({ data }) => {
  const dataArray = data[0].data;
  const uniqValues = uniq(dataArray?.map((val) => val?.count));

  const getMaxChartValue = (uniqValues) => {
    const max = Math.max(...uniqValues);

    return max * 1.2;
  };

  const maxYValue = getMaxChartValue(uniqValues);

  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 50, right: 20, bottom: 50, left: 70 }}
      yFormat=" >-.2f"
      // curve="natural"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        format: '%Y',
        tickValues: 'every 2 years',
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendPosition: 'middle',
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Asset Count',
        legendOffset: -50,
        legendPosition: 'middle',
        format: (e) => Math.floor(e) === e && e,
      }}
      enablePoints={false}
      pointLabelYOffset={-12}
      useMesh={true}
      xScale={{
        type: 'time',
        format: '%Y-%m-%d',
        useUTC: false,
        precision: 'day',
      }}
      xFormat="time:%Y-%m-%d"
      yScale={{
        type: 'linear',
        stacked: false,
        max: maxYValue,
      }}
      animate={true}
      tooltip={(input) => {
        return (
          <div
            style={{
              padding: 5,
              backgroundColor: 'white',
              boxShadow:
                ' 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
            }}
          >
            <strong>{input.point.data.xFormatted}</strong> - {input.point.data.y}
          </div>
        );
      }}
      legends={[
        {
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: 'left-to-right',
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};

export default Chart;
