/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Typography, Grid, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const useStyles = makeStyles({
  root: {
    '&:hover': {
      color: 'red',
      cursor: 'pointer',
    },
  },
});

function DataItem({ data, index, handleClick, handleSwitch }) {
  const classes = useStyles();
  return (
    <Draggable draggableId={`${data.id}`} index={index}>
      {(provided, snapshot) => {
        const style = {
          backgroundColor: snapshot.isDragging ? 'blue' : 'white',
        };
        return (
          <ListItem
            selected={snapshot.isDragging ? true : false}
            style={style}
            button
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <ListItemText
              primary={data.name}
              secondary={
                data?.timeSeriesFiltration ? (
                  data.timeSeriesFiltration.name
                ) : (
                  <Grid item xs={12}>
                    <Typography variant="body2" component="span" color="primary">
                      Self-Financing
                    </Typography>
                    <Switch
                      checked={data?.selfFinancing}
                      onChange={() => handleSwitch(data)}
                      size="small"
                      color="primary"
                      name="selfFinancing"
                    />
                  </Grid>
                )
              }
            />
            <ListItemIcon classes={{ root: classes.root }}>
              <HighlightOffIcon onClick={() => handleClick(index)} />
            </ListItemIcon>
          </ListItem>
        );
      }}
    </Draggable>
  );
}

const DataList = function DataList({ list, handleClick, handleSwitch }) {
  return list.map((data, index) => {
    return (
      <>
        <DataItem
          data={data}
          key={`${data.id}-${index}`}
          index={index}
          handleClick={handleClick}
          handleSwitch={handleSwitch}
        />
        <Divider />
      </>
    );
  });
};

const OrderList = ({ initialList, handleClick, handleSwitch = null, reorderSelectedList }) => {
  const [state, setState] = useState({ list: initialList });

  useEffect(() => {
    setState({ list: initialList });
  }, [initialList]);

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const list = reorder(state.list, result.source.index, result.destination.index);
    reorderSelectedList(list);
    setState({ list });
  }

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <List ref={provided.innerRef} {...provided.droppableProps}>
              <DataList list={state.list} handleClick={handleClick} handleSwitch={handleSwitch} />
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default OrderList;
