const drawerWidth = 280;

const styles = (theme) => ({
  drawer: {
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.background.white,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  drawerContainer: {
    overflow: 'auto',
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  listItem: {
    borderRadius: 10,
    selected: {
      background: 'rgba(196, 196, 196, 0.2)!important',
      listItemText: {
        fontWeight: 700,
      },
    },
  },
});

export default styles;
