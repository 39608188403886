import * as React from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Popover from '@material-ui/core/Popover';

import jobsService from '../../services/JobsService';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
  },
  listText: {
    wordWrap: 'break-word',
  },
  popover: {
    maxWidth: 800,
  },
  typographyPopover: {
    fontSize: '0.8rem',
    padding: 20,
  },
}));

const ComponentsInfo = ({ id, open }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [componentsData, setComponentsData] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);
  const [downloadStarted, setDownloadStarted] = React.useState(false);
  const [logContent, setLogContent] = React.useState('Loading log file content.');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const { components } = componentsData || [];

  const handleClick = (event, logPath) => {
    setAnchorEl(event.currentTarget);
    downloadLog(logPath);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const openPopover = Boolean(anchorEl);
  const idPopover = openPopover ? 'simple-popover' : undefined;

  React.useEffect(() => {
    const fetchData = async () => {
      setIsFetching(true);
      const result = await jobsService.listComponents(id);
      setComponentsData(result);
      setIsFetching(false);
    };
    open && fetchData();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const downloadLog = async (log) => {
    setDownloadStarted(true);
    const result = await jobsService.downloadLog(id, log);
    setLogContent(result);
  };

  return (
    <Box margin={1}>
      <Typography variant="h6" gutterBottom component="div">
        Components
      </Typography>
      <Box>
        {components?.length > 0 ? (
          <Grid container spacing={3} className={classes.root}>
            {components.map((component) => {
              return (
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <ListItem className={classes.listItem} key={component.id} role={undefined} dense>
                    <ListItemIcon>
                      <>
                        {component.status === 'Succeeded' ? (
                          <CheckCircleIcon
                            onClick={(e) => handleClick(e, component.log_path)}
                            style={{
                              color: theme.palette.success.main,
                              marginLeft: 5,
                            }}
                          />
                        ) : (
                          <ErrorIcon
                            onClick={(e) => handleClick(e, component.log_path)}
                            style={{
                              color: theme.palette.error.main,
                              marginLeft: 5,
                              cursor: 'pointer',
                            }}
                          />
                        )}
                        <Popover
                          classes={{ root: classes.popover }}
                          id={idPopover}
                          open={openPopover}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          elevation="1"
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                        >
                          <Typography
                            className={classes.typographyPopover}
                            dangerouslySetInnerHTML={{ __html: logContent }}
                          />
                        </Popover>
                      </>
                    </ListItemIcon>
                    <ListItemText className={classes.listText} primary={component.name} />
                  </ListItem>
                </Grid>
              );
            })}
          </Grid>
        ) : isFetching ? (
          <Typography variant="body2" gutterBottom component="p">
            Downloading components information.
          </Typography>
        ) : (
          <Typography variant="body2" gutterBottom component="p">
            There is no components information available.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ComponentsInfo;
