import React from 'react';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';

import { fade, makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormSelect from '../../components/FormSelect';

const useStyles = makeStyles((theme) => ({
  input: {
    overflow: 'hidden',
    borderRadius: 4,
    border: 0,
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&>.MuiInputBase-input ': {
      width: 100,
    },
    '&$focused': {
      backgroundColor: '#fff',
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
      border: 0,
      outline: 0,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.grey[200],
    },
  },
}));

const SelectionCriteria = () => {
  const classes = useStyles();
  const { control } = useFormContext();

  const { portfolioModelParameters } = useSelector((state) => state.portfolioModel);
  const fundRankingCriteriaOptions = portfolioModelParameters?.fund_ranking_criteria;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6" as="h5">
                Number of assets
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <Controller
                  id="model-core"
                  name="numberOrPercentage"
                  control={control}
                  render={(props) => {
                    return (
                      <RadioGroup aria-label="Number of assets" {...props}>
                        <FormControlLabel
                          value="1"
                          checked={parseInt(props.value) === 1}
                          control={<Radio />}
                          label="Percentage of assets (of total universe)"
                        />
                        <FormControlLabel
                          value="0"
                          checked={parseInt(props.value) === 0}
                          control={<Radio />}
                          label="Number of assets"
                        />
                      </RadioGroup>
                    );
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Controller
                defaultValue=""
                render={(props) => (
                  <TextField
                    type={`text`}
                    variant="outlined"
                    helperText="Enter number (of assets) or percentage (of total universe)"
                    InputProps={{ classes }}
                    elevation={4}
                    inputProps={{
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                    {...props}
                  />
                )}
                name="numberAssets"
                control={control}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" as="h5">
                  Assets ranking criteria
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box mb={5}>
                  <FormSelect
                    control={control}
                    name="assetsRankingCriteria"
                    data={fundRankingCriteriaOptions}
                    displayEmpty
                    emptyValue="Select Assets ranking criteria"
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6" as="h5">
                Sorting order
              </Typography>
              <Typography variant="body2">Explanation of how selection affects output.</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <Controller
                  id="sortingOrder"
                  name="sortingOrder"
                  control={control}
                  render={(props) => {
                    return (
                      <RadioGroup aria-label="Number of assets" {...props}>
                        <FormControlLabel
                          value="0"
                          checked={parseInt(props.value) === 0}
                          control={<Radio />}
                          label="Bottom ranked assets"
                        />
                        <FormControlLabel
                          value="1"
                          checked={parseInt(props.value) === 1}
                          control={<Radio />}
                          label="Top ranked assets"
                        />
                      </RadioGroup>
                    );
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SelectionCriteria;
