import { Controller, useFormContext } from 'react-hook-form';
import Switch from '@material-ui/core/Switch';

const MUISwitch = ({ defaultCheck, name, disabled }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={(props) => {
        return (
          <>
            <Switch
              onChange={(e) => props.onChange(e.target.checked)}
              checked={Boolean(props.value)}
              value={props.value}
              color="primary"
              defaultCheck={defaultCheck}
              disabled={disabled}
            />
          </>
        );
      }}
    />
  );
};

export default MUISwitch;
