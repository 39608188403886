/* eslint-disable react-hooks/exhaustive-deps */
import { Controller, useFormContext } from 'react-hook-form';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Grid } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { HelpInfo } from '../HelpInfo';

const useStyles = makeStyles((theme) => ({
  alignCenter: {
    textAlign: 'center',
  },
  riskFactor: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
}));

const RiskModelSpecification = ({ benchmarks, isDisabled, isLoadingsDisabled }) => {
  const classes = useStyles();
  const { control, setValue } = useFormContext();

  const checkAllPremium = (value) => {
    benchmarks &&
      benchmarks.map((item, index) => {
        setValue(`premium[${index}]`, value ? true : false);
        return true;
      });
  };

  const checkAllLoadings = (value) => {
    benchmarks &&
      benchmarks.map((item, index) => {
        setValue(`loadings[${index}]`, value ? true : false);
        return true;
      });
  };

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <Typography variant="h6">Predictable Risk Factor</Typography>
        </Grid>

        <Grid item xs={6}></Grid>
        <Grid item xs={3} className={classes.riskFactor}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography variant="subtitle" component="span">
              Premium
            </Typography>
            <HelpInfo name="premiumAll" />
          </Box>
          <Controller
            name={`premiumAll`}
            defaultValue={false}
            control={control}
            render={(props) => (
              <Checkbox
                onChange={(e) => {
                  props.onChange(e.target.checked);
                  checkAllPremium(e.target.checked);
                }}
                checked={props.value}
                disabled={isDisabled}
              />
            )}
          />
        </Grid>
        <Grid item xs={3} className={classes.riskFactor}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography variant="subtitle" component="span">
              Loadings
            </Typography>
            <HelpInfo name="loadingsAll" />
          </Box>
          <Controller
            name={`loadingsAll`}
            defaultValue={false}
            control={control}
            render={(props) => (
              <Checkbox
                onChange={(e) => {
                  props.onChange(e.target.checked);
                  checkAllLoadings(e.target.checked);
                }}
                checked={props.value}
                disabled={isDisabled || isLoadingsDisabled}
              />
            )}
          />
        </Grid>
      </Grid>
      {/* listing */}
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {benchmarks &&
            benchmarks?.map((benchmark, index) => (
              <>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">{benchmark.name}</Typography>
                  <Typography variant="subtitle2" display="block">
                    Self Financing : {benchmark.selfFinancing ? 'Yes' : 'No'}
                  </Typography>
                </Grid>
                <Grid item xs={3} className={classes.alignCenter}>
                  <Controller
                    name={`premium[${index}]`}
                    control={control}
                    render={(props) => {
                      return (
                        <Checkbox
                          value={
                            props?.value
                              ? { value: true, ...benchmark }
                              : { value: false, ...benchmark }
                          }
                          name={`premium[${index}]`}
                          onChange={(e) => {
                            props.onChange(e.target.checked ? true : false);
                            setValue('premiumAll', false);
                          }}
                          checked={props.value || false}
                          disabled={isDisabled}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={3} className={classes.alignCenter}>
                  <Controller
                    name={`loadings[${index}]`}
                    control={control}
                    render={(props) => (
                      <Checkbox
                        value={props.value}
                        name={`loadings[${index}]`}
                        onChange={(e) => {
                          props.onChange(e.target.checked ? true : false);
                          setValue('loadingsAll', false);
                        }}
                        checked={props.value || false}
                        disabled={isDisabled || isLoadingsDisabled}
                      />
                    )}
                  />
                </Grid>
              </>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RiskModelSpecification;
