import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as moment from 'moment';
import 'moment-precise-range-plugin';
import { useQuery } from 'react-query';

import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Drawer from '@material-ui/core/Drawer';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

import CachedIcon from '@material-ui/icons/Cached';
import DateRangeIcon from '@material-ui/icons/DateRange';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import RepeatOneIcon from '@material-ui/icons/RepeatOne';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AssessmentIcon from '@material-ui/icons/Assessment';
import DashboardIcon from '@material-ui/icons/Dashboard';

import Layout from 'components/Layout';

import ArtifactsByCategoryTabs from 'components/ArtifactsByCategoryTabs';
import ReportStatus from './ReportStatus';
import { VisualReports } from 'containers/VisualReports';

import jobsService from 'services/JobsService';
import portfolioService from 'services/PortfolioService';
import { useGetReportsStreamData } from 'lib/hooks/useGetReportsStreamData';
import config from 'config';
import { MAX_NUMBER_OF_ITEMS } from 'config/const';

import { IDS_NAMES } from 'lib/DashboardReport/queryKeys';
import { useGetReportDetails } from 'lib/hooks/useGetReportDetails';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawerAssets: {
    width: `calc(100% - ${drawerWidth}px)`,
  },
  drawerPaper: {
    width: `calc(100% - ${drawerWidth}px)`,
    padding: theme.spacing(3),
  },
}));

export const ReportsDetail = () => {
  const classes = useStyles();

  const { id } = useParams();
  const dispatch = useDispatch();

  const [uuid, setUuid] = React.useState(Date.now());
  const [isVisualReportsOpen, setVisualReportsOpen] = React.useState(false);

  const { data: report, isLoading, refetch } = useGetReportDetails(id);

  const {
    isLoading: isLoadingIDsNames,
    data: idsAndNames,
    isError: isErrorIDsNames,
    error: errorIDsNames,
  } = useGetReportsStreamData(IDS_NAMES, id, 'idsAndNames.csv');

  const artifactCount = idsAndNames?.length || 0;
  const isArtifactCountTooBig = artifactCount > MAX_NUMBER_OF_ITEMS || artifactCount === 0;

  const assetVisualReports = useQuery(
    ['ASSET_VISUAL_REPORTS', id],
    async () => {
      const result = (await jobsService.getJobVisualReports(id)) || [];
      return result;
    },
    {
      onError: (err) => console.error(err),
      keepPreviousData: true,
      enabled: !isArtifactCountTooBig && !isLoadingIDsNames && !isErrorIDsNames,
    },
  );

  const riskFactorVisualReports = useQuery(
    ['RISK_FACTOR_VISUAL_REPORTS', id],
    async () => {
      const result = (await jobsService.getJobVisualReports(id, 'riskFactor')) || [];
      return result;
    },
    {
      onError: (err) => console.error(err),
      keepPreviousData: true,
      enabled: !isArtifactCountTooBig && !isLoadingIDsNames && !isErrorIDsNames,
    },
  );

  const portfolioModelParameters = useQuery(
    ['PORTFOLIO-MODEL-PARAMETERS'],
    async () => {
      const result = await portfolioService.getPortfolioModelParameters();

      return result.data;
    },
    {
      onError: (err) => console.error(err),
      keepPreviousData: true,
    },
  );

  const { fund_ranking_criteria, portfolio_weights_rules } = portfolioModelParameters.data || {};

  const duration =
    report?.created_at && report?.finished_at
      ? moment.preciseDiff(moment(report?.created_at), moment(report.finished_at))
      : null;

  const handleVisualReports = (value) => {
    setVisualReportsOpen(value);
  };

  const isEstimatorModel = report?.name?.includes('Estimator Model');
  const isRankingReport = report?.name?.includes('Ranking Report');
  const isSucceeded = report?.status?.toLowerCase() === 'succeeded';

  const disableDashboardButton =
    isArtifactCountTooBig ||
    isLoadingIDsNames ||
    isErrorIDsNames ||
    isRankingReport ||
    !isSucceeded;

  if (isLoading) {
    return (
      <Layout>
        <Container maxWidth="lg" style={{ margin: 0 }}>
          Loading...
        </Container>
      </Layout>
    );
  }

  return (
    <Layout>
      <Drawer
        anchor="right"
        open={isVisualReportsOpen}
        onClose={() => handleVisualReports(false)}
        className={classes.drawerAssets}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <VisualReports
          heatmapReports={assetVisualReports.data?.heatmapReports || []}
          riskFactorReports={riskFactorVisualReports.data?.heatmapReports || []}
          title={report?.name}
        />
      </Drawer>
      <Container maxWidth="lg" style={{ margin: 0 }}>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12}>
            <Paper style={{ padding: 20 }}>
              <Box
                display="flex"
                alignItems="start"
                justifyContent="space-between"
                style={{ marginBottom: 10 }}
              >
                <Box display="flex" flex="1" alignItems="center" justifyContent="flex-start">
                  <Typography variant="h6" as="h3">
                    {report?.name}
                  </Typography>
                  <Tooltip
                    title={
                      assetVisualReports.isLoading || assetVisualReports.isFetching
                        ? 'Loading...'
                        : assetVisualReports.isError
                        ? `There was an error loading reports`
                        : `${assetVisualReports.data?.heatmapReports?.length} heatmap reports`
                    }
                  >
                    <IconButton
                      color="primary"
                      aria-label="Visual Reports"
                      component="span"
                      size="small"
                      style={{ marginLeft: 10 }}
                      onClick={handleVisualReports}
                      disabled={
                        !report?.id ||
                        assetVisualReports.isLoading ||
                        assetVisualReports.isError ||
                        assetVisualReports?.data?.length === 0 ||
                        assetVisualReports.isFetching ||
                        isArtifactCountTooBig
                      }
                    >
                      <AssessmentIcon />
                      {assetVisualReports.isLoading || assetVisualReports.isFetching ? (
                        <Box position="absolute">
                          <CircularProgress size={20} />
                        </Box>
                      ) : null}
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={
                      isErrorIDsNames
                        ? 'Error loading artifacts'
                        : isLoadingIDsNames
                        ? 'Loading artifacts'
                        : isArtifactCountTooBig
                        ? 'Too many artifacts'
                        : ''
                    }
                  >
                    <div>
                      <IconButton
                        component={Link}
                        to={
                          isEstimatorModel
                            ? `/reports/${id}/e/dashboard`
                            : `/reports/${id}/dashboard`
                        }
                        disabled={disableDashboardButton}
                        color="primary"
                        aria-label="Dashboard"
                        size="small"
                        style={{ marginLeft: 10 }}
                      >
                        <DashboardIcon size={20} />
                      </IconButton>
                    </div>
                  </Tooltip>
                  <IconButton
                    color="primary"
                    aria-label="Refresh"
                    component="span"
                    size="small"
                    style={{ marginLeft: 10 }}
                    onClick={refetch}
                  >
                    <CachedIcon />
                  </IconButton>
                </Box>
                <Box display="flex" alignItems="center">
                  <ReportStatus report={report} handleRefresh={refetch} />
                </Box>
              </Box>
              <Box>
                <Grid container spacing={3} alignItems="flex-start">
                  <Grid item xs={12} sm={6}>
                    <Typography
                      variant="subtitle1"
                      color="textSecondary"
                      component="p"
                      dangerouslySetInnerHTML={{ __html: report?.description }}
                    />
                    {report?.spec?.dataForAnalysis?.startDate ? (
                      <Typography variant="subtitle2">
                        <b>Start Date:</b>{' '}
                        {moment(report?.spec?.dataForAnalysis?.startDate).format('DD/MM/YYYY')}
                      </Typography>
                    ) : null}
                    {report?.spec?.dataForAnalysis?.endDate ? (
                      <Typography variant="subtitle2">
                        <b>End Date:</b>{' '}
                        {moment(report?.spec?.dataForAnalysis?.endDate).format('DD/MM/YYYY')}
                      </Typography>
                    ) : null}
                    {report?.spec?.estimator?.alpha?.alphaModel ? (
                      <Typography variant="subtitle2">
                        <b>Alpha Model Specification:</b>{' '}
                        {config.coreModelSpecification?.find(
                          (item) => item.id === report?.spec?.estimator?.alpha?.alphaModel,
                        )?.name || ''}
                      </Typography>
                    ) : null}
                    {report?.spec?.optimization?.preferences?.portfolioWeights ? (
                      <Typography variant="subtitle2">
                        <b>Portfolio Weights:</b>{' '}
                        {portfolio_weights_rules?.find(
                          (item) =>
                            item.value ===
                            report?.spec?.optimization?.preferences?.portfolioWeights,
                        )?.name || ''}
                      </Typography>
                    ) : null}
                    {report?.spec?.optimization?.sort?.variableIndex ? (
                      <Typography variant="subtitle2">
                        <b>Assets ranking criteria (portfolio):</b>{' '}
                        {
                          fund_ranking_criteria?.find(
                            (item) => item.value === report.spec.optimization.sort.variableIndex,
                          )?.name
                        }
                      </Typography>
                    ) : null}

                    {report?.spec?.reporting?.assetRanking?.sortCriteria ? (
                      <Typography variant="subtitle2">
                        <b>Assets ranking criteria:</b>{' '}
                        {
                          fund_ranking_criteria?.find(
                            (item) =>
                              item.value === report.spec.reporting.assetRanking.sortCriteria,
                          )?.name
                        }
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box display="flex" alignItems="center" style={{ marginBottom: 10 }}>
                      <DateRangeIcon />
                      <Typography variant="subtitle2" style={{ marginLeft: 10, padding: 0 }}>
                        {moment(report?.created_at).format('MMMM Do YYYY, h:mm:ss a')}
                      </Typography>
                    </Box>

                    <Box display="flex" alignItems="center" style={{ marginBottom: 10 }}>
                      <EventAvailableIcon />
                      <Typography variant="subtitle2" style={{ marginLeft: 10, padding: 0 }}>
                        {moment(report?.finished_at).format('MMMM Do YYYY, h:mm:ss a')}
                      </Typography>
                    </Box>
                    {duration ? (
                      <Box display="flex" alignItems="center" style={{ marginBottom: 10 }}>
                        <AccessTimeIcon />
                        <Typography variant="subtitle2" style={{ marginLeft: 10, padding: 0 }}>
                          {duration}
                        </Typography>
                      </Box>
                    ) : null}
                    <Box display="flex" alignItems="center" style={{ marginBottom: 10 }}>
                      <ChangeHistoryIcon />
                      <Typography variant="subtitle2" style={{ marginLeft: 10, padding: 0 }}>
                        Use historical learning:{' '}
                        <strong>{report?.spec?.pipeline?.useHistoricalData ? 'Yes' : 'No'}</strong>
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" style={{ marginBottom: 10 }}>
                      <RepeatOneIcon />
                      <Typography variant="subtitle2" style={{ marginLeft: 10, padding: 0 }}>
                        One-off run: <strong>{report?.one_off_run ? 'Yes' : 'No'}</strong>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
          {/* <Grid item xs={12}>
            {report?.id ? <ArtifactsByCategory id={report.id} open /> : null}
          </Grid> */}
          <Grid item xs={12}>
            <Paper>
              {report?.id ? <ArtifactsByCategoryTabs id={report.id} uuid={uuid} /> : null}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};
