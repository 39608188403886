/* eslint-disable react-hooks/exhaustive-deps */
import { useFormContext } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, InputAdornment } from '@material-ui/core';

import SelectNeutralRiskFactor from './SelectNeutralRiskFactor';
import InlineInput from '../InlineInput';
import MUISwitch from '../MUISwitch';

import { addExposureTolerance, addNeutralRiskFactor } from '../../redux/actions';

const useStyles = makeStyles((theme) => ({
  subtitle: {
    textTransform: 'uppercase',
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '1.5px',
    fontWeight: 700,
  },
  input: {
    width: 200,
    backgroundColor: theme.palette.background.default,
    borderRadius: 4,
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      border: 0,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 0,
      },
      '&:hover fieldset': {
        border: 0,
      },
      '&.Mui-focused fieldset': {
        border: 0,
      },
    },
  },
}));

const exposureToleranceOptions = {
  minimum: {
    name: 'minimumExposureTolerance',
    title: 'Minimum Exposure Tolerance',
    defaultValue: -1000,
  },
  maximum: {
    name: 'maximumExposureTolerance',
    title: 'Maximum Exposure Tolerance',
    defaultValue: 1000,
  },
};

const neutralRiskFactorOptions = {
  minimum: {
    name: 'minimumBenchmarkCorrelation',
    title: 'Minimum Benchmark Correlation',
    defaultValue: -99,
  },
  maximum: {
    name: 'maximumBenchmarkCorrelation',
    title: 'Maximum Benchmark Correlation',
    defaultValue: 99,
  },
};

const PortfolioModelConstraints = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const riskFactorsDFA = useSelector((state) => state.dataForAnalysis.benchmarks) || [];

  const exposureToleranceValues =
    useSelector((state) => state.portfolioModel.exposureTolerance) || [];
  const assetRiskFactorCorrelationsValues =
    useSelector((state) => state.portfolioModel.assetRiskFactorCorrelations) || [];
  const { control, register, watch } = useFormContext();

  const targetPortfolioRiskFactorExposuresValue = watch('targetPortfolioRiskFactorExposures');
  const filterAssetsByRiskCorrelationsValue = watch('filterAssetsByRiskCorrelations');
  const showShortAssetWeightValue = watch('showShortAssetWeight');

  const handleSaveDataCorrelation = (value, factors) => {
    dispatch(addNeutralRiskFactor(value, factors));
  };

  const handleSaveDataExposure = (value, factors) => {
    dispatch(addExposureTolerance(value, factors));
  };

  return (
    <>
      <Grid container spacing={3}>
        {/* Left Side */}
        <Grid item xs={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6" as="h6">
                Long Asset Weight
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.subtitle} variant="subtitle2" as="span">
                Individual
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <InlineInput
                control={control}
                text="Minimum"
                type="number"
                name="longAssetWeightIndividualMinimum"
                leftGrid={6}
                rightGrid={6}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
              />
            </Grid>
            <Grid item xs={12}>
              <InlineInput
                control={control}
                text="Maximum"
                type="number"
                name="longAssetWeightIndividualMaximum"
                leftGrid={6}
                rightGrid={6}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.subtitle} variant="subtitle2" as="span">
                Total
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <InlineInput
                control={control}
                text="Minimum"
                type="number"
                name="longAssetWeightTotalMinimum"
                leftGrid={6}
                rightGrid={6}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
              />
            </Grid>
            <Grid item xs={12}>
              <InlineInput
                control={control}
                text="Maximum"
                type="number"
                name="longAssetWeightTotalMaximum"
                leftGrid={6}
                rightGrid={6}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
              />
            </Grid>
            <Grid item xs={12}>
              <InlineInput
                control={control}
                text="maximum asset turnover"
                textClass={classes.subtitle}
                type="number"
                name="maximumAssetTurnover"
                leftGrid={6}
                rightGrid={6}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Right Side */}

        <Grid item xs={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6" component="span">
                Short Asset Weight
              </Typography>
              <MUISwitch register={register} name="showShortAssetWeight" />
            </Grid>
            {showShortAssetWeightValue ? (
              <>
                <Grid item xs={12}>
                  <Typography className={classes.subtitle} variant="subtitle2" as="span">
                    Individual
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <InlineInput
                    control={control}
                    text="Minimum"
                    type="number"
                    name="shortAssetWeightIndividualMinimum"
                    leftGrid={6}
                    rightGrid={6}
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InlineInput
                    control={control}
                    text="Maximum"
                    type="number"
                    name="shortAssetWeightIndividualMaximum"
                    leftGrid={6}
                    rightGrid={6}
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.subtitle} variant="subtitle2" as="span">
                    Total
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <InlineInput
                    control={control}
                    text="Minimum"
                    type="number"
                    name="shortAssetWeightTotalMinimum"
                    leftGrid={6}
                    rightGrid={6}
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InlineInput
                    control={control}
                    text="Maximum"
                    type="number"
                    name="shortAssetWeightTotalMaximum"
                    leftGrid={6}
                    rightGrid={6}
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography className={classes.subtitle} variant="subtitle2" as="span">
                    total net weight
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <InlineInput
                    control={control}
                    text="Minimum"
                    type="number"
                    name="totalNetWeightMinimum"
                    leftGrid={6}
                    rightGrid={6}
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InlineInput
                    control={control}
                    text="Maximum"
                    type="number"
                    name="totalNetWeightMaximum"
                    leftGrid={6}
                    rightGrid={6}
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                  />
                </Grid>
                {/* Total gross weight */}
                <Grid item xs={12}>
                  <Typography className={classes.subtitle} variant="subtitle2" as="span">
                    total gross weight
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <InlineInput
                    control={control}
                    text="Minimum"
                    type="number"
                    name="totalGrossWeightMinimum"
                    leftGrid={6}
                    rightGrid={6}
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InlineInput
                    control={control}
                    text="Maximum"
                    type="number"
                    name="totalGrossWeightMaximum"
                    leftGrid={6}
                    rightGrid={6}
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                  />
                </Grid>
              </>
            ) : null}
          </Grid>
        </Grid>

        {/* End Right Side */}
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6" as="h6">
                Risk factors
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle" component="span">
                        Filter Assets by Risk Factor Correlations:
                      </Typography>
                      <MUISwitch
                        register={register}
                        defaultCheck={true}
                        name="filterAssetsByRiskCorrelations"
                      />
                    </Grid>
                    {filterAssetsByRiskCorrelationsValue ? (
                      <Grid item xs={12}>
                        <SelectNeutralRiskFactor
                          name="assetRiskFactorCorrelations"
                          btnTitle="set Benchmark Correlation"
                          fieldOptions={neutralRiskFactorOptions}
                          modalTitle="Set Weights for:"
                          listItems={riskFactorsDFA}
                          listValues={assetRiskFactorCorrelationsValues}
                          handleSaveData={handleSaveDataCorrelation}
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle" component="span">
                        Target Portfolio Risk Factor Exposures
                      </Typography>
                      <MUISwitch
                        register={register}
                        defaultCheck={true}
                        name="targetPortfolioRiskFactorExposures"
                      />
                    </Grid>
                    {targetPortfolioRiskFactorExposuresValue ? (
                      <>
                        <Grid item xs={12}>
                          <SelectNeutralRiskFactor
                            name="exposureTolerance"
                            btnTitle="Set Exposure Tolerance"
                            fieldOptions={exposureToleranceOptions}
                            modalTitle="Set Exposure Tolerance for:"
                            listItems={riskFactorsDFA}
                            listValues={exposureToleranceValues}
                            handleSaveData={handleSaveDataExposure}
                          />
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PortfolioModelConstraints;
