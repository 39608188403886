// MUI

import Box from '@material-ui/core/Box';

import { ChartWithSelect, ParalaLineChart } from 'components/Dashboard';

export const PortfolioLevelAttributionReports = ({
  showReports,
  showKeys,
  dateIndex,
  chartDataGroupedById,
  expectationsAndPortfolioWeights,
  returnsFromTrackingTheModelWeights,
  portfolioReturnsFromTrackingTheModelWeights,
  portfolioAlphaDecomposition,
  totalReturnData,
  isEstimator,
}) => {
  return (
    <Box display="flex" flex={1} flexDirection="column">
      {/* expectationsAndPortfolioWeights */}
      {showReports.expectationsAndPortfolioWeights.visible ? (
        <Box
          style={{
            marginBottom: 30,
            paddingBottom: 30,
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        >
          {isEstimator ? (
            <ChartWithSelect
              chartId="expectationsAndPortfolioWeights"
              chart={expectationsAndPortfolioWeights}
              chartDataGroupedById={chartDataGroupedById}
              isBiAxis={true}
              yAxisLabels={{
                left: 'Return',
              }}
              title="Expectations"
              description="This chart shows the contributions to expected return for {REPLACE_NAME}"
              showKeys={showKeys}
              dateIndex={dateIndex}
            />
          ) : (
            <ChartWithSelect
              chartId="expectationsAndPortfolioWeights"
              chart={expectationsAndPortfolioWeights}
              chartDataGroupedById={chartDataGroupedById}
              chartProps={{
                yAxis2: {
                  domain: [0, 100],
                },
              }}
              isBiAxis={true}
              yAxisLabels={{
                left: 'Return',
                right: 'Portfolio weight',
              }}
              title="Expectations and portfolio weights"
              description="This chart shows the contributions to expected return and the portfolio weights for {REPLACE_NAME}"
              showKeys={showKeys}
              dateIndex={dateIndex}
            />
          )}
        </Box>
      ) : null}
      {/* expectationsAndPortfolioWeights */}
      {/* returnsFromTrackingTheModelWeights */}
      {!isEstimator && showReports.returnsFromTrackingTheModelWeights.visible ? (
        <Box
          style={{
            marginBottom: 30,
            paddingBottom: 30,
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        >
          <ChartWithSelect
            chartId="returnsFromTrackingTheModelWeights"
            chart={returnsFromTrackingTheModelWeights}
            chartDataGroupedById={chartDataGroupedById}
            isBiAxis={true}
            chartProps={{
              yAxis2: {
                domain: [0, 100],
              },
            }}
            yAxisLabels={{
              left: 'Return',
              right: 'Portfolio weight',
            }}
            title="Returns from tracking the model weights"
            description="This chart shows the benefit of adopting the model weights over maintaining
constant weights at their mean values for {REPLACE_NAME}"
            showKeys={showKeys}
            dateIndex={dateIndex}
          />
        </Box>
      ) : null}
      {/* returnsFromTrackingTheModelWeights */}
      {/* portfolioReturnsFromTrackingTheModelWeights */}
      {!isEstimator && showReports.portfolioReturnsFromTrackingTheModelWeights.visible ? (
        <Box
          style={{
            marginBottom: 30,
            paddingBottom: 30,
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        >
          <ParalaLineChart
            chart={portfolioReturnsFromTrackingTheModelWeights}
            lineCharData={totalReturnData}
            title="Portfolio returns from tracking the model weights"
            description="This chart shows the benefit of adopting the model weights over maintaining
constant weights at their mean values across the entire portfolio."
            showKeys={showKeys}
            dateIndex={dateIndex}
          />
        </Box>
      ) : null}
      {/* portfolioReturnsFromTrackingTheModelWeights */}
      {/* portfolioAlphaDecomposition */}
      {!isEstimator && showReports.portfolioAlphaDecomposition.visible ? (
        <Box
          style={{
            marginBottom: 30,
            paddingBottom: 30,
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        >
          <ParalaLineChart
            chart={portfolioAlphaDecomposition}
            lineCharData={totalReturnData}
            title="Portfolio alpha decomposition"
            description="This chart decomposes alpha contributions to total return, suitably weighted
          for the entire portfolio. Time-varying alpha captures (alpha1 x expected
          macro-factor change)."
            showKeys={showKeys}
            dateIndex={dateIndex}
          />
        </Box>
      ) : null}
      {/* portfolioAlphaDecomposition */}
    </Box>
  );
};
