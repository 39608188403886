export const VERIFIED = 'verified';
export const NOT_VERIFIED = 'notVerified';

export const errorTypes = {
  'value_error.missing': 'Value missing',
};

export const REPORT_NAME = [
  {
    id: '1MonthsconditionalExpectedReturn',
    name: '1 Months Conditional Expected Return',
  },
  {
    id: '1MonthsconditionalExpectedReturnRank',
    name: '1 Months Conditional Expected Return Rank',
  },
  {
    id: '3MonthsconditionalExpectedReturn',
    name: '3 Months Conditional Expected Return',
  },
  {
    id: '6MonthsconditionalExpectedReturn',
    name: '6 Months Conditional Expected Return',
  },
];

export const REPORT_MONTH_OPTIONS = [
  { id: 1, value: 12, name: '12 Months' },
  { id: 2, value: 24, name: '24 Months' },
  { id: 3, value: 36, name: '36 Months' },
  { id: 4, value: 48, name: '48 Months' },
  { id: 5, value: 60, name: '60 Months' },
  { id: 6, value: 72, name: '72 Months' },
  { id: 7, value: 84, name: '84 Months' },
  { id: 8, value: 96, name: '96 Months' },
  { id: 9, value: 108, name: '108 Months' },
  { id: 10, value: 120, name: '120 Months' },
  { id: 11, value: 132, name: '132 Months' },
  { id: 12, value: 144, name: '144 Months' },
  { id: 13, value: 156, name: '156 Months' },
  { id: 14, value: 168, name: '168 Months' },
  { id: 15, value: 180, name: '180 Months' },
  { id: 16, value: 192, name: '192 Months' },
  { id: 17, value: 204, name: '204 Months' },
  { id: 18, value: 216, name: '216 Months' },
  { id: 19, value: 228, name: '228 Months' },
  { id: 20, value: 240, name: '240 Months' },
  { id: 21, value: 252, name: '252 Months' },
  { id: 22, value: 264, name: '264 Months' },
  { id: 23, value: 276, name: '276 Months' },
  { id: 24, value: 288, name: '288 Months' },
  { id: 25, value: 300, name: '300 Months' },
  { id: 26, value: 312, name: '312 Months' },
  { id: 27, value: 324, name: '324 Months' },
  { id: 28, value: 336, name: '336 Months' },
  { id: 29, value: 348, name: '348 Months' },
  { id: 30, value: 360, name: '360 Months' },
  { id: 31, value: 372, name: '372 Months' },
  { id: 32, value: 384, name: '384 Months' },
  { id: 33, value: 396, name: '396 Months' },
  { id: 34, value: 408, name: '408 Months' },
  { id: 35, value: 420, name: '420 Months' },
  { id: 36, value: 432, name: '432 Months' },
  { id: 37, value: 444, name: '444 Months' },
  { id: 38, value: 456, name: '456 Months' },
  { id: 39, value: 468, name: '468 Months' },
  { id: 40, value: 480, name: '480 Months' },
  { id: 41, value: 492, name: '492 Months' },
  { id: 42, value: 504, name: '504 Months' },
  { id: 43, value: 516, name: '516 Months' },
  { id: 44, value: 528, name: '528 Months' },
  { id: 45, value: 540, name: '540 Months' },
  { id: 46, value: 552, name: '552 Months' },
  { id: 47, value: 564, name: '564 Months' },
  { id: 48, value: 576, name: '576 Months' },
  { id: 49, value: 588, name: '588 Months' },
  { id: 50, value: 600, name: '600 Months' },
  { id: 10000, value: 10000, name: 'All Months' },
];

const REPORT_FORMAT_OPTIONS_INTEGER = [/.+Rank(.*?)+$/i, /.+Fractile(.*?)+$/i];

const REPORT_FORMAT_OPTIONS_DECIMALS = [/.+alphaTStat(.*?)+$/i, /.+sharpeRatio(.*?)+$/i];

const REPORT_FORMAT_OPTIONS_PERCENTAGE = [
  /.+conditionalAlpha(.*?)+$/i,
  /.+conditionalExpectedReturn(.*?)+$/i,
  /.+Conditional Expected Return+$/i,
  /.+Conditional Expected Return OutReturns+$/i,
  /.+unConditional Alpha OutReturns(.*?)+$/i,
  /.+Out Returns$/i,
  /EtB$/i,
];

const REPORT_FORMAT_HIGH_IS_WORSE = [
  // /.+sharpeRatio(.*?)+$/i,
  /.+alphaTStat(.*?)+$/i,
  /.+Rank(.*?)+$/i,
  /.+Fractile(.*?)+$/i,
];

export const isPercentage = (name) => {
  return REPORT_FORMAT_OPTIONS_PERCENTAGE.some((regex) => regex.test(name));
};

export const isDecimal = (name) => {
  return REPORT_FORMAT_OPTIONS_DECIMALS.some((regex) => regex.test(name));
};
export const isInteger = (name) => {
  return REPORT_FORMAT_OPTIONS_INTEGER.some((regex) => regex.test(name));
};

const isHighIsWorse = (name, highIsWorse) => {
  if (highIsWorse !== undefined) return highIsWorse;

  return REPORT_FORMAT_HIGH_IS_WORSE.some((regex) => regex.test(name));
};

export const reportFormattingOptions = (name, lookups) => {
  const formattingOption =
    lookups.find((item) => {
      return item.name === name;
    }) || null;

  const displayReportName = formattingOption
    ? formattingOption?.friendly_name || formattingOption?.name
    : name;

  return { displayReportName, highIsWorse: isHighIsWorse(name, formattingOption?.highIsWorse) };
};

export const REPORT_FORMAT_NAME = [
  // OutReturns
  {
    id: 'sharpeRatio OutReturns',
    RegExp: /sharpeRatio OutReturns/gm,
    replaceWith: 'Realized Return',
  },
  {
    id: 'conditionalExpectedReturnOutReturns',
    RegExp: /Conditional Expected Return OutReturns/gm,
    replaceWith: 'Realized Return',
  },
  {
    id: 'conditionalAlphaReturnOutReturns',
    RegExp: / Conditional Alpha OutReturns/gm,
    replaceWith: ' Realized Return',
  },
  {
    id: 'unConditionalAlphaOutReturns',
    RegExp: /unConditional Alpha OutReturns/gm,
    replaceWith: 'Realized Return',
  },
  {
    id: 'AlphaTStatReturnOutReturns',
    RegExp: /alphaTStat OutReturns/gm,
    replaceWith: 'Realized Return',
    format: 'decimal',
  },
  // Unconditional Expected Return", "Conditional Alpha", "Unconditional Alpha", "Alpha t-Stat", "Share Ratio"
  // OutSampleRank
  {
    id: 'conditionalExpectedReturnOutSampleRank',
    RegExp: /Conditional Expected Return OutSampleRank/gm,
    replaceWith: 'Realized Return Rank',
    highIsWorse: true,
  },
  // OutSampleFractile
  {
    id: 'conditionalExpectedReturnOutSampleFractile',
    RegExp: /Conditional Expected Return OutSampleFractile/gm,
    replaceWith: 'Realized Return Fractile',
    highIsWorse: true,
  },

  {
    id: 'conditionalExpectedReturnFractile',
    RegExp: /Conditional Expected Return Fractile/gm,
    replaceWith: 'Conditional Expected Return Fractile',
    highIsWorse: true,
  },
  {
    id: 'conditionalExpectedReturnRank',
    RegExp: /Conditional Expected Return Rank/gm,
    replaceWith: 'Conditional Expected Return Rank',
    highIsWorse: true,
  },
];

export const translateGroups = [
  {
    id: '1',
    type: 'HEATMAP',
    name: 'Heatmap',
  },
];

// Dashboard
export const MAX_NUMBER_OF_ITEMS = 125;
