import { useState } from 'react';
import { useQuery } from 'react-query';
import groupBy from 'lodash.groupby';

import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
// icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';

// app
import Layout from 'components/Layout';
import { Accordion, AccordionSummary, AccordionDetails } from 'components/CustomAccordion';
import { TranslateGroup } from 'components/TranslateGroup';
import appService from 'services/AppService';
import styles from './Translate.styles';
import { translateGroups } from 'config/const';

const Translate = () => {
  const classes = makeStyles(styles, { name: 'Translate' })();
  const [addNew, setAddNew] = useState(null);
  // TODO fetch all
  const params = {
    size: 100,
  };
  const allLookups = useQuery(
    ['ALL_LOOKUPS'],
    async () => {
      const result = (await appService.getLookups(params)) || [];
      return result;
    },
    {
      onError: (err) => console.error(err),
      keepPreviousData: true,
    },
  );

  const { data } = allLookups;
  const lookups = data?.lookups?.data || [];

  const lookupsGroupedByType = groupBy(lookups, 'type');

  const handleAdd = (e, type) => {
    e.stopPropagation();
    setAddNew(type);
    console.log('handleAdd', type);
  };

  const handleAddNew = (type) => {
    setAddNew(null);
  };

  return (
    <Layout>
      <Container maxWidth="lg" style={{ margin: 0 }}>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={6}>
            <Box display="flex">
              <Typography variant="h4" as="h1">
                Translate
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Container lassName={classes.root} style={{ margin: 0 }}>
        {allLookups.isLoading ? <div>Loading...</div> : null}
        {allLookups.isError ? <div>Error</div> : null}
        {allLookups.isSuccess ? (
          <>
            {Object.keys(lookupsGroupedByType).map((type) => {
              const name = translateGroups.find((item) => item.type === type)?.name || type;
              const lookups = lookupsGroupedByType[type];

              return (
                <Accordion elevation={4} key={type}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Typography className={classes.heading}>{name}</Typography>
                      <IconButton onClick={(e) => handleAdd(e, type)}>
                        <AddIcon />
                      </IconButton>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TranslateGroup
                      lookups={lookups}
                      addNew={addNew === type}
                      type={type}
                      handleAddNew={handleAddNew}
                    />
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </>
        ) : null}
      </Container>
    </Layout>
  );
};
export default Translate;
