import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Layout from 'components/Layout';
import CollapsibleTableSpec from 'components/CollapsibleTableSpec';

import universeService from 'services/UniverseService';
import config from 'config';

// "dataForAnalysis" "estimator" "optimization" "rankingReport" "report" "pipelineSpec"
const ListingAllSpec = () => {
  let { type } = useParams();
  let history = useHistory();
  const [rows, setRows] = useState([]);

  const pageInfo = config.specDetails.find((spec) => spec.id === type);
  const { id } =
    useSelector((state) =>
      pageInfo?.reduxSubKey
        ? state[pageInfo.reduxKey][pageInfo.reduxSubKey]
        : state[pageInfo.reduxKey],
    ) || null;
  // fetchData
  const fetchData = async (type) => {
    const result = await universeService.searchSpec({ type });
    setRows(result?.specs?.data || []);
  };

  useEffect(() => {
    fetchData(type);
  }, [type]);

  const handleDelete = () => {
    fetchData(type);
  };
  const handleClick = (slug) => {
    history.push(slug);
  };

  return (
    <Layout>
      <Container maxWidth="lg" style={{ margin: 0 }}>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12}>
            {config.specDetails.map((spec) => (
              <Button
                key={spec.id}
                variant="contained"
                color={type === spec.id ? `primary` : `inherit`}
                onClick={() => handleClick(spec.slug)}
                style={{ marginLeft: 10, marginBottom: 10 }}
              >
                {spec.title}
              </Button>
            ))}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" as="h1">
              All {pageInfo?.title}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            {rows?.length ? (
              <CollapsibleTableSpec
                rows={rows}
                type={type}
                loadedSpecId={id}
                handleSpecDelete={handleDelete}
              />
            ) : null}
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default ListingAllSpec;
