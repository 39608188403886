import * as types from '../../types';

const initialAppState = {
  dataForAnalysis: {
    progress: null,
    isFormDirty: null,
  },
  estimatorModel: {
    progress: null,
    isFormDirty: null,
  },
  portfolioModel: {
    progress: null,
    isFormDirty: null,
  },
  reportingOutput: {
    progress: null,
    isFormDirty: null,
  },
  rankingReportingOutput: {
    progress: null,
    isFormDirty: null,
  },

  messages: null,
  preference: {
    preferenceData: {
      investorCurrency: null,
      returnFrequency: null,
      startDate: null,
    },
    isPreferenceOpen: false,
  },
  pagination: {
    size: 10,
    page: 0,
    search: '',
  },
};

const appReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case types.USER_LOGOUT:
      return initialAppState;
    case types.APP_LOAD_USER_PREFERENCES:
      return {
        ...state,
        preference: {
          ...state.preference,
          preferenceData: {
            ...action.payload,
            investorCurrency: { id: parseInt(action.payload.currencyId) },
          },
        },
      };
    case types.VALIDATE_DATA_FOR_ANALYSIS:
      return {
        ...state,
        dataForAnalysis: {
          ...state.dataForAnalysis,
          progress: action.payload,
        },
      };
    case types.IS_DIRTY_DATA_FOR_ANALYSIS:
      return {
        ...state,
        dataForAnalysis: {
          ...state.dataForAnalysis,
          isFormDirty: action.payload,
        },
      };
    case types.APP_DATA_FOR_ANALYSIS_RESET:
      return {
        ...state,
        dataForAnalysis: {
          ...initialAppState.dataForAnalysis,
        },
      };
    case types.VALIDATE_ESTIMATOR_MODEL:
      return {
        ...state,
        estimatorModel: {
          ...state.estimatorModel,
          progress: action.payload,
        },
      };
    case types.IS_DIRTY_ESTIMATOR_MODEL:
      return {
        ...state,
        estimatorModel: {
          ...state.estimatorModel,
          isFormDirty: action.payload,
        },
      };
    case types.APP_ESTIMATOR_MODEL_RESET:
      return {
        ...state,
        estimatorModel: {
          ...initialAppState.estimatorModel,
        },
      };
    case types.VALIDATE_PORTFOLIO_MODEL:
      return {
        ...state,
        portfolioModel: {
          ...state.portfolioModel,
          progress: action.payload,
        },
      };
    case types.IS_DIRTY_PORTFOLIO_MODEL:
      return {
        ...state,
        portfolioModel: {
          ...state.portfolioModel,
          isFormDirty: action.payload,
        },
      };
    case types.APP_PORTFOLIO_MODEL_RESET:
      return {
        ...state,
        portfolioModel: {
          ...initialAppState.portfolioModel,
        },
      };
    case types.IS_DIRTY_REPORTING_OUTPUT:
      return {
        ...state,
        reportingOutput: {
          ...state.reportingOutput,
          isFormDirty: action.payload,
        },
      };
    case types.IS_DIRTY_RANKING_REPORTING_OUTPUT:
      return {
        ...state,
        rankingReportingOutput: {
          ...state.reportingOutput,
          isFormDirty: action.payload,
        },
      };

    case types.APP_SAVE_PREFERENCE:
      return {
        ...state,
        preference: {
          ...state.preference,
          preferenceData: action.payload,
        },
      };
    case types.APP_OPEN_PREFERENCE:
      return {
        ...state,
        preference: {
          ...state.preference,
          isPreferenceOpen: true,
        },
      };
    case types.APP_CLOSE_PREFERENCE:
      return {
        ...state,
        preference: {
          ...state.preference,
          isPreferenceOpen: false,
        },
      };
    case types.APP_SHOW_MESSAGE:
      return {
        ...state,
        messages: { ...action.payload },
      };
    case types.APP_HIDE_MESSAGE:
      return {
        ...state,
        messages: {},
      };

    case types.APP_UPDATE_PAGINATION:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
      };

    default:
      return state;
  }
};

export default appReducer;
