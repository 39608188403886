/* eslint-disable operator-linebreak */
/* eslint-disable arrow-body-style */
import axios from 'axios';
import qs from 'qs';
import omit from 'lodash.omit';
import moment from 'moment';
import config from '../config';
import { removeNullFromData } from './util';
import { getAccessToken } from './firebase';

const dfaService = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    // Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

dfaService.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.data.errors &&
      error.response.data.errors[0].code === 'INVALID_TOKEN'
    ) {
      // return AuthService.updateAccessToken().then((accessToken) => {
      //   error.config.headers.Authorization = `Bearer ${accessToken}`;
      //   return apiClient.request(error.config);
      // });
    }
    return Promise.reject(error);
  },
);

const formatDfaBeforePost = (spec) => {
  const formattedSpec = {
    currencyAssetId: spec?.investorCurrency?.id,
    returnFrequency: spec?.returnFrequency,
    startDate: moment(spec.startDate).format('YYYY-MM-DD'),
    endDate: moment(spec.endDate).format('YYYY-MM-DD'),
    longUniverse: {
      universeId: spec?.universe?.id || null,
      fundDefaultFee: parseFloat(spec?.defaultFeePrior, 10),
      fundIgnoreFeeFlag: spec?.overrideFeeWithDefault,
    },
    ...(spec?.allowShortSales && {
      shortUniverse: {
        universeId: spec?.allowShortSales ? spec?.shortUniverse?.id : null,
        fundDefaultFee: parseFloat(spec?.defaultFeePrior, 10),
        fundIgnoreFeeFlag: spec?.overrideFeeWithDefault,
      },
    }),

    riskFree: {
      assetId: spec?.riskFree?.id || null,
    },
    riskFreeRate: {
      assetId: spec?.riskFreeRate?.id || null,
    },
    riskFactors: spec?.benchmarks.map((benchmark) => ({
      assetId: benchmark.id,
      selfFinancing: benchmark.selfFinancing,
    })),

    macroFactors: spec?.macrofactors.map((macrofactor) => ({
      assetId: macrofactor.id,
      macrofactorFilter: macrofactor.timeSeriesFiltration.id,
    })),
  };

  return removeNullFromData(formattedSpec);
};

const saveDfaSpec = async (formData, type = 'dataForAnalysis', spec_id) => {
  const accessToken = await getAccessToken();

  try {
    const { name, description } = formData;

    const omitList = [
      'id',
      'name',
      'description',
      'benchmarkUniverse',
      'macrofactorUniverse',
      'created_at',
      'last_modified',
    ];
    const formattedFormData = omit(formData, omitList);
    const spec = {};

    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(formattedFormData)) {
      spec[key] = omit(formattedFormData[key], omitList);
    }

    const formattedSpec = formatDfaBeforePost(spec.dataForAnalysis);

    const data = {
      name,
      description,
      spec: formattedSpec,
      type,
    };

    const specData = spec_id
      ? await dfaService.patch(`/specs/${spec_id}`, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
      : await dfaService.post('/specs', data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        });

    const formattedResult = formatResultData(specData.data);
    return formattedResult;
  } catch (error) {
    if (error.response.data) return { error: error.response.data };
    throw new Error(error);
  }
};
const loadSpec = async (id) => {
  const accessToken = await getAccessToken();

  try {
    let formattedResult = {};
    const apiURL = `/specs/${id}`;
    const result = await dfaService.get(apiURL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    if (result) {
      formattedResult = formatResultData(result.data);
    }
    return formattedResult;
  } catch (error) {
    return error;
  }
};

const formatResultData = async (data) => {
  const dfa = data.spec;
  const { spec, ...rest } = data;

  return {
    ...rest,
    universe: dfa?.longUniverse?.universeId ? { id: dfa?.longUniverse?.universeId } : null,
    allowShortSales: dfa?.shortUniverse?.universeId ? true : false,
    shortUniverse: dfa?.shortUniverse?.universeId ? { id: dfa?.shortUniverse?.universeId } : null,
    overrideFeeWithDefault: dfa?.longUniverse?.fundIgnoreFeeFlag || false,
    defaultFeePrior: dfa?.longUniverse?.fundDefaultFee,
    investorCurrency: dfa?.currencyAssetId ? { id: dfa?.currencyAssetId } : null,
    returnFrequency: dfa?.returnFrequency || null,
    startDate: dfa?.startDate || null,
    endDate: dfa?.endDate || null,
    riskFree: dfa?.riskFree?.assetId ? { id: dfa?.riskFree?.assetId } : null,
    riskFreeRate: dfa?.riskFreeRate?.assetId ? { id: dfa?.riskFreeRate?.assetId } : null,
    benchmarks: dfa?.riskFactors ? await getBenchmarks(dfa.riskFactors) : [],
    macrofactors: dfa?.macroFactors
      ? await getMacrofactors(dfa.macroFactors)
      : dfa?.macrofactors
      ? await getMacrofactors(dfa.macrofactors)
      : [],
  };
};

const getBenchmarks = async (benchmarks) => {
  try {
    if (benchmarks.length) {
      const benchmarksIDs = benchmarks.map((benchmark) => benchmark.assetId);
      const benchmarksData = await getAssetsList(benchmarksIDs);

      const benchmarksDataSorted = benchmarks?.map((benchmark) => {
        const benchmarkInfo = benchmarksData?.data.find((data) => data.id === benchmark.assetId);

        return {
          ...benchmarkInfo,
          selfFinancing: benchmark.selfFinancing,
        };
      });

      return benchmarksDataSorted;
    }
    return [];
  } catch (error) {
    console.log('error', error);
    return error;
  }
};

const getMacrofactors = async (macrofactors) => {
  try {
    if (macrofactors.length) {
      const { timeSeriesOption } = config;
      const macrofactorsIDs = macrofactors.map((macrofactor) => macrofactor.assetId);
      const macroFactorsData = await getAssetsList(macrofactorsIDs);

      const macroFactorsDataSorted = macrofactors?.map((macrofactor) => {
        const macrofactorInfo = macroFactorsData?.data.find(
          (data) => data.id === macrofactor.assetId,
        );

        return {
          ...macrofactorInfo,
          timeSeriesFiltration: timeSeriesOption.find(
            (option) => option.id === macrofactor.macrofactorFilter,
          ),
        };
      });

      return macroFactorsDataSorted;
    }
    return [];
  } catch (error) {
    return error;
  }
};

const getAssets = async (id) => {
  const accessToken = await getAccessToken();

  try {
    const apiURL = `/universes/${id}/assets`;
    const assetsList = await dfaService.get(apiURL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return assetsList.data;
  } catch (error) {
    return { error: true, message: error };
  }
};

const getAssetsList = async (assetIDArray) => {
  const accessToken = await getAccessToken();

  try {
    const apiURL = `/assets`;
    const assetsList = await dfaService.get(apiURL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      params: {
        asset_id: [...assetIDArray],
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });

    return assetsList.data;
  } catch (error) {
    return { error: true, message: error };
  }
};

const getUniversePerformance = async (data, returnFrequency) => {
  const accessToken = await getAccessToken();

  try {
    // https://parala-api.35-246-17-43.nip.io/api/v1/beta/universe-performance
    const apiURL = `/universe-performance`;
    const result = await dfaService.post(apiURL, data, {
      params: { return_frequency: returnFrequency },
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return result.data;
  } catch (error) {
    return { error: true, data: error.response.data };
  }
};

const getAssetPerformance = async (data, returnFrequency) => {
  const accessToken = await getAccessToken();

  try {
    // https://parala-api.35-246-17-43.nip.io/api/v1/beta/asset-performance
    const apiURL = `/asset-performance`;
    const result = await dfaService.post(apiURL, data, {
      params: { return_frequency: returnFrequency },
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return result.data;
  } catch (error) {
    return { error: true, data: error.response.data };
  }
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  saveDfaSpec,
  loadSpec,
  getAssets,
  getUniversePerformance,
  getAssetPerformance,
};
