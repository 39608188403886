/* eslint-disable operator-linebreak */
/* eslint-disable arrow-body-style */
import axios from 'axios';
import isEmpty from 'lodash.isempty';
import { getAccessToken } from './firebase';

const reportService = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    // Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

reportService.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.data.errors &&
      error.response.data.errors[0].code === 'INVALID_TOKEN'
    ) {
      // return AuthService.updateAccessToken().then((accessToken) => {
      //   error.config.headers.Authorization = `Bearer ${accessToken}`;
      //   return apiClient.request(error.config);
      // });
    }
    return Promise.reject(error);
  },
);

const formatDataRankingReport = (data) => {
  const { forwardRankingHorizon, assetsRankingCriteria, fractileCount, dataFreq, ...rest } = data;
  const { id, created_at, last_modified, status, ...spec } = rest;

  const horizons = forwardRankingHorizon
    .map((item, index) => (item ? index : null))
    .filter((horizon) => horizon);

  const reporting = {
    reporting: {
      assetRanking: {
        horizons: horizons,
        runRanking: true,
        sortCriteria: assetsRankingCriteria,
        binCount: fractileCount,
        dataFreq,
      },
    },
  };
  return {
    ...spec,
    ...reporting,
  };
};

//
const parseDataRankingReport = (data) => {
  const { spec, ...rest } = data;

  const assetRanking = spec?.reporting?.assetRanking;

  let horizons = [];

  assetRanking?.horizons.forEach((horizon) => {
    horizons[horizon] = true;
  });

  return {
    ...rest,
    spec: {
      ...spec,
      forwardRankingHorizon: horizons,
      assetsRankingCriteria: assetRanking?.sortCriteria,
      fractileCount: assetRanking?.binCount,
      dataFreq: assetRanking?.dataFreq,
    },
  };
};

const isAssetForecastReport = (spec) => {
  return (
    !isEmpty(spec?.forecastHorizon) ||
    spec?.expectedReturnForecast ||
    spec?.riskForecast ||
    spec?.subsetModelComponents ||
    spec?.simulationBasedReports ||
    spec?.modelParameters ||
    spec?.estimatorAttributionReports
  );
};
const isAssetRankingReport = (spec) => {
  return (
    !isEmpty(spec?.forecastHorizonReportAnalysis) ||
    !isEmpty(spec?.assetsRankingCriteria) ||
    spec?.fractileCount ||
    spec?.fractileCount === 0
  );
};

const isReportOnPortfolioValues = (spec) => {
  return [
    spec?.portfolioReturnTimesSeries,
    spec?.portfolioRiskForecast,
    spec?.portfolioConstituentsAndWeights,
    spec?.portfolioSimulationBasedReports,
    spec?.portfolioAttributionReports,
  ].some((value) => value === true);
};

const formatDataReportingOutput = (data) => {
  const {
    id,
    created_at,
    last_modified,
    status,
    selectedModels,
    enableSpecUpdate,
    reportOnPortfolioModels,
    rankingReportAndAnalysis,
    overwriteLastDataDate,
    lastDataDate,
    overwriteLastVintageDate,
    lastVintageDate,
    ...spec
  } = data;

  // check if we need to add assetForecast to spec
  const isAssetForecast = isAssetForecastReport(spec);

  const isAssetRanking = isAssetRankingReport(spec);

  const isReportOnPortfolioModels = reportOnPortfolioModels && isReportOnPortfolioValues(spec);

  const reporting = {
    reporting: {
      ...(isAssetForecast && {
        assetForecast: {
          horizon: spec?.forecastHorizon ? parseInt(spec?.forecastHorizon, 10) : null,
          expectations: spec?.expectedReturnForecast,
          risk: spec?.riskForecast,
          subset: spec?.subsetModelComponents,
          simulation: spec?.simulationBasedReports,
          parameters: spec?.modelParameters,
          attributionReport: spec?.estimatorAttributionReports,
        },
      }),
      ...(isAssetRanking && {
        assetRanking: {
          horizons: spec?.forecastHorizonReportAnalysis
            ? [parseInt(spec?.forecastHorizonReportAnalysis, 10)]
            : [],
          runRanking: rankingReportAndAnalysis,
          sortCriteria: spec?.assetsRankingCriteria,
          binCount: spec?.fractileCount,
        },
      }),
      ...(isReportOnPortfolioModels && {
        portfolioPerformance: {
          returnTimeSeries: spec?.portfolioReturnTimesSeries,
          riskForecast: spec?.portfolioRiskForecast,
          constituentWeights: spec?.portfolioConstituentsAndWeights,
          simulationReport: spec?.portfolioSimulationBasedReports,
          attributionReport: spec?.portfolioAttributionReports,
        },
      }),
    },
  };
  return {
    selectedModels,
    enableSpecUpdate,
    reportOnPortfolioModels,
    rankingReportAndAnalysis,
    overwriteLastDataDate,
    lastDataDate,
    overwriteLastVintageDate,
    lastVintageDate,
    ...reporting,
  };
};

const parseDataReportingOutput = (data) => {
  const { spec, ...rest } = data;
  const { reporting } = spec;

  return {
    ...rest,
    spec: {
      ...spec,
      forecastHorizon: reporting?.assetForecast?.horizon,
      expectedReturnForecast: reporting?.assetForecast?.expectations,
      riskForecast: reporting?.assetForecast?.risk,
      subsetModelComponents: reporting?.assetForecast?.subset,
      simulationBasedReports: reporting?.assetForecast?.simulation,
      modelParameters: reporting?.assetForecast?.parameters,

      forecastHorizonReportAnalysis: reporting?.assetRanking?.horizons,
      rankingReportAndAnalysis: reporting?.assetRanking?.runRanking,
      assetsRankingCriteria: reporting?.assetRanking?.sortCriteria,
      fractileCount: reporting?.assetRanking?.binCount,

      portfolioReturnTimesSeries: reporting?.portfolioPerformance?.returnTimeSeries || false,
      portfolioRiskForecast: reporting?.portfolioPerformance?.riskForecast || false,
      portfolioConstituentsAndWeights: reporting?.portfolioPerformance?.constituentWeights || false,
      portfolioSimulationBasedReports: reporting?.portfolioPerformance?.simulationReport || false,
      portfolioAttributionReports: reporting?.portfolioPerformance?.attributionReport || false,
    },
  };
};

// save Ranking Report
const saveRankingReportSpec = async (formData, spec_id, type = 'rankingReport') => {
  const accessToken = await getAccessToken();
  const { name, description, ref_spec_id, ...rest } = formData;

  try {
    const data = {
      name,
      description,
      ref_spec_id,
      spec: formatDataRankingReport(rest),
      type,
    };

    const specData = spec_id
      ? await reportService.patch(`/specs/${spec_id}`, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
      : await reportService.post('/specs', data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        });

    return parseDataRankingReport(specData.data);
  } catch (error) {
    if (error.response.data) return { error: error.response.data };
    throw new Error(error);
  }
};

const saveReportingOutputSpec = async (formData, spec_id, type = 'reporting') => {
  const accessToken = await getAccessToken();
  const { name, description, ref_spec_id, ...rest } = formData;

  try {
    const data = {
      name,
      description,
      ref_spec_id,
      spec: formatDataReportingOutput(rest),
      type,
    };

    const specData = spec_id
      ? await reportService.patch(`/specs/${spec_id}`, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
      : await reportService.post('/specs', data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        });

    return parseDataReportingOutput(specData.data);
  } catch (error) {
    if (error.response.data) return { error: error.response.data };
    throw new Error(error);
  }
};

const loadRankingReportSpec = async (id) => {
  const accessToken = await getAccessToken();
  try {
    const apiURL = `/specs/${id}`;
    const result = await reportService.get(apiURL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return parseDataRankingReport(result.data);
  } catch (error) {
    return error;
  }
};

const loadReportingOutputSpec = async (id) => {
  const accessToken = await getAccessToken();
  try {
    const apiURL = `/specs/${id}`;
    const result = await reportService.get(apiURL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return parseDataReportingOutput(result.data);
  } catch (error) {
    return error;
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  saveReportingOutputSpec,
  saveRankingReportSpec,
  loadRankingReportSpec,
  formatDataRankingReport,
  loadReportingOutputSpec,
  formatDataReportingOutput,
  isAssetForecastReport,
  isAssetRankingReport,
};
