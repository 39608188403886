// @ts-nocheck
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import LeftSidebar from '../LeftSidebar';
import CustomizedSnackbar from '../CustomizedSnackbar';
import Preferences from '../../containers/Preferences';

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    backgroundColor: (props) =>
      props.bg ? theme.palette.background.gray : theme.palette.background.default,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

const Layout = ({ bg, children, maxWidth }) => {
  const [showMessage, setShowMessage] = useState({});
  const { messages, preference } = useSelector((state) => state.app);
  const { isPreferenceOpen } = preference;
  const props = { bg };
  const classes = useStyles(props);

  useEffect(() => {
    setShowMessage(messages);
  }, [messages]);

  return (
    <>
      <LeftSidebar />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={maxWidth ? maxWidth : 'lg'} className={classes.container}>
          {children}
        </Container>
      </main>
      <Preferences isPreferenceOpen={isPreferenceOpen} />
      {showMessage?.type && showMessage?.message && (
        <CustomizedSnackbar
          variant={showMessage.type}
          message={showMessage.message}
          show={showMessage}
        />
      )}
    </>
  );
};

export default Layout;
