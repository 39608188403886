/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import omit from 'lodash.omit';

import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SaveIcon from '@material-ui/icons/Save';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import DescriptionIcon from '@material-ui/icons/Description';
import styles, { Accordion, AccordionSummary, AccordionDetails } from './EstimatorModel.styles';

import Layout from 'components/Layout';
import ModelSpecification from 'components/ModelSpecification';
import RiskModelDetails from 'components/RiskModelDetails';
import TimeframeAndVintaging from 'components/TimeframeAndVintaging';
import MacroeconomicVariables from 'components/MacroeconomicVariables';
import { SimulatedEstimationModelCombinations } from 'components/SimulatedEstimationModelCombinations';
import { SimulatedSegmentedModelCombinations } from 'components/SimulatedSegmentedModelCombinations';
import { HelpInfo } from 'components/HelpInfo';

import SaveDataDialog from 'components/SaveDataDialog';
import LoadDataDialog from 'components/LoadDataDialog';
import AlertDialog from 'components/AlertDialog';
import PreventNavigation from 'components/PreventNavigation';
import { SpecDialog } from 'components/SpecDialog';

import {
  isDirtyEstimatorModel,
  loadEstimatorModel,
  saveEstimatorModel,
  saveSimulatedModelCombinations,
  saveEstimatorModelInState,
  resetEstimatorModel,
  deleteEstimatorModel,
  validateEstimatorModel,
  refreshSubsetRegressionMacrofactors,
  updateIsModelCombination,
  updateIsSegmentedModelCombination,
} from 'redux/actions';

const EstimatorModel = () => {
  const [isSaveFormOpen, setSaveFormDialog] = useState(false);
  const [isLoadFormOpen, setLoadFormDialog] = useState(false);
  const [isResetOpen, setIsResetOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [openSpec, setOpenSpec] = useState(false);

  const {
    estimatorRules,
    estimatorData,
    simulatedModelData,
    isSimulatedModelCombination,
    isSegmentedModelCombination,
  } = useSelector((state) => state.estimatorModel);

  const isModelCombination = isSimulatedModelCombination || isSegmentedModelCombination;

  // const [isSimulatedModelCombination, setIsModelCombination] = useState(isSimulatedModelCombination);

  const ref_spec_id = useSelector((state) => state.dataForAnalysis?.id);
  const { benchmarks, macrofactors } = useSelector((state) => state.dataForAnalysis);
  const { name, description, id } = estimatorData;

  const dispatch = useDispatch();

  const schema = yup.object().shape({
    ...(!estimatorRules?.sigmaAlpha && {
      sigmaAlpha: yup
        .number()
        .typeError('Please enter as a decimal.')
        .nullable()
        .transform((v, o) => (o === '' ? null : v)),
    }),
    ...(!estimatorRules?.simulationBlocks && {
      simulationBlocks: yup
        .number()
        .integer('Please enter as a integer.')
        .typeError('Please enter integer value.')
        .nullable()
        .transform((v, o) => (o === '' ? null : v)),
    }),
    ...(!estimatorRules?.drawsPerBlock && {
      drawsPerBlock: yup
        .number()
        .integer('Please enter as a integer.')
        .typeError('Please enter integer value.')
        .nullable()
        .transform((v, o) => (o === '' ? null : v)),
    }),
    ...(!estimatorRules?.adjustmentSpeed && {
      adjustmentSpeed: yup
        .number()
        .typeError('Please enter as a decimal.')
        .nullable()
        .transform((v, o) => (o === '' ? null : v)),
    }),
    generationFrequency: yup
      .number()
      .integer('Please enter as a integer.')
      .typeError('Please enter as a integer.')
      .nullable()
      .transform((v, o) => (o === '' ? null : v)),
    minNumberOfObservations: yup
      .number()
      .integer('Please enter as a integer.')
      .typeError('Please enter as a integer.')
      .nullable()
      .transform((v, o) => (o === '' ? null : v)),
    ...(!estimatorRules?.variableCount && {
      variableCount: yup
        .number()
        .positive()
        .integer('Please enter as a integer.')
        .typeError('Please enter integer value.')
        .nullable()
        .transform((v, o) => (o === '' ? null : v)),
    }),
    ...(!estimatorRules?.gammaCSR && {
      gammaCSR: yup
        .number()
        .min(0)
        .typeError('Gamma - CSR must be greater than or equal to 0')
        .nullable()
        .transform((v, o) => (o === '' ? null : v)),
    }),
    weightingSensitivity: yup
      .number()
      .min(0)
      .typeError('Weighting Sensitivity must be greater than or equal to 0')
      .nullable()
      .transform((v, o) => (o === '' ? null : v)),
  });

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: estimatorData,
    shouldUnregister: false,
    resolver: yupResolver(schema),
  });

  const watchForm = methods.watch();
  const { isDirty } = methods.formState;

  const classes = makeStyles(styles, { name: 'EstimatorModel' })();

  useEffect(() => {
    methods.reset(estimatorData);
  }, [estimatorData.id]);

  useEffect(() => {
    const formData = { ...watchForm };
    dispatch(validateEstimatorModel(formData, estimatorRules));
  }, [watchForm]);

  useEffect(() => {
    isDirty && dispatch(isDirtyEstimatorModel(true));
  }, [isDirty]);

  useEffect(() => {
    const data = macrofactors?.map((macrofactor) => {
      return { id: macrofactor.id, name: macrofactor.name };
    });
    data?.length && dispatch(refreshSubsetRegressionMacrofactors(data));
  }, [macrofactors]);

  const onSubmit = async (data) => {
    const { name, description } = data;
    const formattedData = omit(data, ['name', 'description']);
    const { subsetRegressionData } = estimatorData;
    const { selectedModels } = simulatedModelData;
    const formData = {
      name,
      description,
      ref_spec_id,
      estimatorModel: isSimulatedModelCombination
        ? { ...formattedData, selectedModels }
        : { ...formattedData, subsetRegressionData, benchmarks },
    };
    const idForm = estimatorData?.id ? estimatorData.id : null;
    isSimulatedModelCombination
      ? dispatch(saveSimulatedModelCombinations(formData, idForm))
      : dispatch(saveEstimatorModel(formData, idForm));
    dispatch(isDirtyEstimatorModel(false));
    methods.reset(data);
    setSaveFormDialog(false);
  };

  const onSubmitNew = async (data) => {
    const { name, description } = data;
    const formattedData = omit(data, ['name', 'description']);
    const { subsetRegressionData } = estimatorData;
    const formData = {
      name,
      description,
      ref_spec_id,
      estimatorModel: { ...formattedData, subsetRegressionData, benchmarks },
    };
    const id = null;
    dispatch(saveEstimatorModel(formData, id));
    dispatch(isDirtyEstimatorModel(false));
    methods.reset(data);
    setSaveFormDialog(false);
  };

  const onError = (errors, e) => console.log(errors, e);

  const saveToStore = async () => {
    const { subsetRegressionData } = estimatorData;
    const formData = { ...watchForm, subsetRegressionData, benchmarks };
    dispatch(saveEstimatorModelInState(formData));
  };

  const handleSaveClose = () => {
    methods.reset(estimatorData);
    setSaveFormDialog(false);
  };
  const handleLoadClose = () => {
    setLoadFormDialog(false);
  };
  const handleSave = () => {
    methods.handleSubmit(onSubmit, onError)();
    setSaveFormDialog(false);
  };

  const handleSaveAsNew = () => {
    methods.handleSubmit(onSubmitNew, onError)();
  };

  const handleOpenSave = () => {
    setSaveFormDialog(true);
  };

  const handleLoad = () => {
    setLoadFormDialog(true);
  };

  const handleLoadSpec = (id) => {
    dispatch(loadEstimatorModel(id));
    dispatch(isDirtyEstimatorModel(false));
  };

  const handleReset = () => {
    setIsResetOpen(true);
  };
  const handleResetConfirm = () => {
    methods.reset(estimatorData, { isDirty: false });
    dispatch(resetEstimatorModel());
    setIsResetOpen(false);
  };
  const handleResetCancel = () => {
    setIsResetOpen(false);
  };

  const handleDelete = () => {
    setIsDeleteOpen(true);
  };

  const handleDeleteConfirm = () => {
    dispatch(deleteEstimatorModel(id));
    methods.reset();
    setIsDeleteOpen(false);
  };
  const handleDeleteCancel = () => {
    setIsDeleteOpen(false);
  };

  const handleModelCombination = () => {
    dispatch(updateIsModelCombination(!isSimulatedModelCombination));
  };

  const handleSegmentedModelCombination = () => {
    dispatch(updateIsSegmentedModelCombination(!isSegmentedModelCombination));
  };

  const handleClickOpenSpec = () => {
    setOpenSpec(true);
  };

  const handleCloseSpec = () => {
    setOpenSpec(false);
  };

  return (
    <Layout>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
          <SaveDataDialog
            isOpen={isSaveFormOpen}
            close={handleSaveClose}
            save={handleSave}
            saveAsNew={handleSaveAsNew}
            title="Save Estimator Model."
            formName="Estimator Model"
            type="estimatorModel"
            name={name}
            description={description}
            id={id}
          />
          {isLoadFormOpen && (
            <LoadDataDialog
              isOpen={isLoadFormOpen}
              loadSpec={handleLoadSpec}
              close={handleLoadClose}
              type="estimator"
              title="Load Estimator Model"
              description="Select Estimator Model option to load."
            />
          )}
          <AlertDialog
            isOpen={isResetOpen}
            title="Do you want to reset Estimator Model form?"
            handleConfirm={handleResetConfirm}
            handleCancel={handleResetCancel}
          />
          <AlertDialog
            isOpen={isDeleteOpen}
            title="Do you want to delete Estimator Model from the Database?"
            handleConfirm={handleDeleteConfirm}
            handleCancel={handleDeleteCancel}
          />
          <SpecDialog open={openSpec} onClose={handleCloseSpec} id={estimatorData?.id} />
          <Container style={{ margin: 0 }}>
            <Grid container spacing={3} alignItems="flex-start">
              <Grid item xs={6}>
                <Box display="flex">
                  <Typography variant="h4" as="h1">
                    Estimator Model
                  </Typography>
                  {estimatorData?.id ? (
                    <Tooltip title="Click for saved JSON spec" arrow>
                      <IconButton
                        color="primary"
                        aria-label="Saved JSON spec"
                        component="span"
                        onClick={handleClickOpenSpec}
                      >
                        <DescriptionIcon />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={1} alignItems="flex-end" justify="flex-end">
                  <Grid item xs={12}>
                    <Button
                      size="small"
                      className={classes.button}
                      onClick={() => handleOpenSave()}
                      type="button"
                      variant="contained"
                      color="primary"
                      startIcon={<SaveIcon />}
                    >
                      save
                    </Button>
                    <Button
                      size="small"
                      className={classes.button}
                      variant="contained"
                      onClick={() => handleLoad()}
                      color="primary"
                      startIcon={<OpenInBrowserIcon />}
                    >
                      Load
                    </Button>
                    <Button
                      size="small"
                      className={classes.button}
                      variant="contained"
                      onClick={() => handleReset()}
                      color="primary"
                      startIcon={<FiberNewIcon />}
                    >
                      Reset
                    </Button>
                    {estimatorData?.id ? (
                      <Button
                        size="small"
                        className={classes.button}
                        variant="contained"
                        onClick={() => handleDelete()}
                        color="primary"
                        startIcon={<DeleteForeverIcon />}
                      >
                        Delete
                      </Button>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <Typography variant="subtitle" component="span" style={{ minWidth: 220 }}>
                    Simulated Model Combinations
                  </Typography>
                  <Switch
                    onChange={handleModelCombination}
                    color="primary"
                    checked={isSimulatedModelCombination}
                    name="simulatedModelCombinations"
                    disabled={estimatorRules?.simulatedModelCombinations}
                  />

                  <HelpInfo name="simulatedModelCombinations" />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <Typography variant="subtitle" component="span" style={{ minWidth: 220 }}>
                    Segmented Model Combinations
                  </Typography>
                  <Switch
                    onChange={handleSegmentedModelCombination}
                    color="primary"
                    checked={isSegmentedModelCombination}
                    name="segmentedModelCombinations"
                    disabled={estimatorRules?.simulatedSegmentedModelCombinations}
                  />

                  <HelpInfo name="simulatedSegmentedModelCombinations" />
                </Box>
              </Grid>
            </Grid>
            <Container className={classes.root} style={{ margin: 0 }}>
              <Accordion elevation={4} disabled={isModelCombination}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>Core Model Specification</Typography>
                </AccordionSummary>
                {!isModelCombination ? (
                  <AccordionDetails>
                    <ModelSpecification />
                  </AccordionDetails>
                ) : null}
              </Accordion>
              <Accordion elevation={4} disabled={isModelCombination}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>Risk Model Specification</Typography>
                </AccordionSummary>
                {!isModelCombination ? (
                  <AccordionDetails>
                    <RiskModelDetails />
                  </AccordionDetails>
                ) : null}
              </Accordion>
              <Accordion elevation={4} disabled={isModelCombination}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>Timeframe and Vintaging</Typography>
                </AccordionSummary>
                {!isModelCombination ? (
                  <AccordionDetails>
                    <TimeframeAndVintaging />
                  </AccordionDetails>
                ) : null}
              </Accordion>
              <Accordion elevation={4} disabled={isModelCombination}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    Macroeconomic Variables and Categories
                  </Typography>
                </AccordionSummary>
                {!isModelCombination ? (
                  <AccordionDetails>
                    <MacroeconomicVariables />
                  </AccordionDetails>
                ) : null}
              </Accordion>
              {/*Start Simulated Estimation Model Combinations  */}
              <Accordion elevation={4} disabled={!isSimulatedModelCombination}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>Simulated Model Combinations</Typography>
                </AccordionSummary>
                {isSimulatedModelCombination ? (
                  <AccordionDetails>
                    <SimulatedEstimationModelCombinations />
                  </AccordionDetails>
                ) : null}
              </Accordion>
              {/* End Simulated Estimation Model Combinations */}
              {/* isSegmentedModelCombination */}
              {/*Start Segmented Estimation Model Combinations  */}
              <Accordion elevation={4} disabled={!isSegmentedModelCombination}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>Segmented Model Combinations</Typography>
                </AccordionSummary>
                {isSegmentedModelCombination ? (
                  <AccordionDetails>
                    <SimulatedSegmentedModelCombinations />
                  </AccordionDetails>
                ) : null}
              </Accordion>
              {/* End Segmented Estimation Model Combinations */}
            </Container>
          </Container>
        </form>
      </FormProvider>
      <PreventNavigation dirty={isDirty} dirtyForm="estimatorModel" saveToStore={saveToStore} />
    </Layout>
  );
};

export default EstimatorModel;
