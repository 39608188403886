import { useMemo } from 'react';
import { HeatMap } from '@nivo/heatmap';
import chroma from 'chroma-js';
import Box from '@material-ui/core/Box';

const CustomCell = ({ cell, borderWidth, onMouseEnter, onMouseMove, onMouseLeave, onClick }) => {
  const handlers = useMemo(
    () => ({
      onMouseEnter: onMouseEnter ? onMouseEnter(cell) : undefined,
      onMouseMove: onMouseMove ? onMouseMove(cell) : undefined,
      onMouseLeave: onMouseLeave ? onMouseLeave(cell) : undefined,
      onClick: onClick ? onClick(cell) : undefined,
    }),
    [cell, onMouseEnter, onMouseMove, onMouseLeave, onClick],
  );

  if (cell.value === null || isNaN(cell.value)) {
    return (
      <g transform={`translate(${cell.x}, ${cell.y})`} data-testid={`cell.${cell.id}`}>
        <rect
          key={cell.id}
          fill={`#f0f0f0`}
          fillOpacity={cell.opacity}
          strokeWidth={borderWidth}
          stroke={cell.borderColor}
          width={cell.width}
          height={cell.height}
          transform={`translate(${cell.width * -0.5}, ${cell.height * -0.5})`}
        />
        <text
          textAnchor="middle"
          dominantBaseline="central"
          fill={'#000'}
          style={{
            fontSize: 11,
            fill: undefined,
            userSelect: 'none',
          }}
        >
          N/A
        </text>
      </g>
    );
  }

  return (
    <g
      transform={`translate(${cell.x}, ${cell.y})`}
      data-testid={`cell.${cell.id}`}
      style={{ cursor: 'pointer' }}
      opacity={cell.opacity}
      {...handlers}
    >
      <rect
        key={cell.id}
        fill={cell.color}
        fillOpacity={cell.opacity}
        strokeWidth={borderWidth}
        stroke={cell.borderColor}
        width={cell.width}
        height={cell.height}
        transform={`translate(${cell.width * -0.5}, ${cell.height * -0.5})`}
      />
      <text
        textAnchor="middle"
        dominantBaseline="central"
        fill={chroma.contrast('#fff', cell?.color) > 4.5 ? '#fff' : '#000'}
        style={{
          fontSize: 11,
          fill: undefined,
          userSelect: 'none',
        }}
      >
        {cell.formattedValue}
      </text>
    </g>
  );
};

export const MyResponsiveHeatMap = ({
  heatmap,
  displayMonths,
  formatValue,
  highIsWorse,
  xLegend = '',
  yLegend = 'Assets',
  left = 240,
  right = 240,
  cellWidth = 60,
}) => {
  // highIsWorse ? 'blue_green' : 'green_blue';
  // if color scheme is opposite of bestIsHigh, reverse the color scale
  // try to multiply with -1 the value that is generated?

  const divergingColorScheme = {
    type: 'diverging',
    scheme: highIsWorse ? 'green_blue' : 'blues',
    divergeAt: 0.5,
  };

  const data = heatmap
    ?.map((row) => {
      return {
        ...row,
        id: row?.name && row.name !== '' ? row.name : row.id,
        data: row?.data?.slice(-1 * displayMonths)?.map((item) => {
          return {
            ...item,
            y: item?.y === -99 || item?.y === null || isNaN(item?.y) ? null : item?.y,
          };
        }),
      };
    })
    .sort((a, b) => a?.name?.localeCompare(b?.name));

  const height = 120 + data?.length * 40;
  const width = 600 + displayMonths * cellWidth;

  return (
    <Box
    // style={{ height: `${height}px`, width: `${width}px` }}
    >
      <HeatMap
        height={height}
        width={width}
        data={data}
        margin={{ top: 60, right: right, bottom: 60, left }}
        valueFormat={formatValue}
        cellComponent={CustomCell}
        axisTop={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -90,
          legend: xLegend,
          legendOffset: 46,
        }}
        axisRight={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: yLegend,
          legendPosition: 'middle',
          legendOffset: left - 10,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: yLegend,
          legendPosition: 'middle',
          legendOffset: -left + 8,
        }}
        colors={divergingColorScheme}
        // colors={{
        //   type: 'diverging',
        //   scheme: colorScheme,
        //   divergeAt: 0.5,
        // }}
        emptyColor="#555555"
        legends={[
          {
            anchor: 'bottom left',
            translateX: 0,
            translateY: height - 90,
            length: '500',
            thickness: 8,
            direction: 'row',
            tickPosition: 'after',
            tickSize: 3,
            tickSpacing: 5,
            tickOverlap: false,
            tickFormat: formatValue,
            title: highIsWorse ? 'Best' : 'Worst',
            titleAlign: 'start',
            titleOffset: 10,
          },
          {
            anchor: 'bottom left',
            translateX: 0,
            translateY: height - 90,
            length: 500,
            thickness: 8,
            direction: 'row',
            tickPosition: 'after',
            tickSize: 3,
            tickSpacing: 5,
            tickOverlap: false,
            tickFormat: formatValue,
            title: highIsWorse ? 'Worst' : 'Best',
            titleAlign: 'end',
            titleOffset: 10,
          },
        ]}
        theme={{
          legend: {
            text: {
              fontSize: 12,
              fill: '#333333',
            },
          },
          legends: {
            title: {
              text: {
                fontSize: 11,
                fill: '#333333',
              },
            },
            text: {
              fontSize: 11,
              fill: '#333333',
            },
            ticks: {
              line: {},
              text: {
                fontSize: 10,
                fill: '#333333',
              },
            },
          },
        }}
      />
    </Box>
  );
};
