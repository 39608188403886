import * as React from 'react';
import { useQuery } from 'react-query';
import Box from '@material-ui/core/Box';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Typography from '@material-ui/core/Typography';

import { MyResponsiveHeatMap } from 'components/HeatMap';
import appService from 'services/AppService';

import {
  REPORT_MONTH_OPTIONS,
  REPORT_NAME,
  reportFormattingOptions,
  isPercentage,
  isDecimal,
  isInteger,
} from 'config/const';

export const VisualReports = ({ heatmapReports, riskFactorReports, title }) => {
  const [activeReport, setActiveReport] = React.useState({
    type: 'asset',
    selectedIndex: 0,
    data: heatmapReports[0],
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElMonth, setAnchorElMonth] = React.useState(null);
  const [displayMonths, setDisplayMonths] = React.useState(REPORT_MONTH_OPTIONS[0]);

  // TODO use config value
  const params = {
    size: 100,
    type: 'HEATMAP',
  };
  const allLookups = useQuery(
    ['ALL_LOOKUPS', 'HEATMAP'],
    async () => {
      const result = (await appService.getLookups(params)) || [];
      return result;
    },
    {
      onError: (err) => console.error(err),
      keepPreviousData: true,
      cacheTime: 1000 * 60 * 60 * 24,
    },
  );

  const { data } = allLookups;
  const lookups = data?.lookups?.data || [];

  const selectedIndex = activeReport.selectedIndex;
  const riskFactorEnabled = riskFactorReports.length > 0;
  const isRiskFactorReport = activeReport.type === 'riskFactor';

  // TODO: check if high is best
  const bestIsHigh = activeReport.data?.name.includes('Rank');
  const reportsData = isRiskFactorReport ? riskFactorReports : heatmapReports;

  const reports = reportsData.map((report, index) => {
    return {
      id: index,
      name: report.name,
    };
  });

  const monthsData = Math.ceil(activeReport.data?.heatmap[0]?.data?.length / 12);
  const monthOptions = REPORT_MONTH_OPTIONS.slice(0, monthsData);

  const handleReportType = (reportType) => {
    setActiveReport({
      type: reportType,
      selectedIndex: 0,
      data: reportType === 'asset' ? heatmapReports[0] : riskFactorReports[0],
    });
  };

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (index) => {
    setActiveReport((prevState) => {
      return {
        ...prevState,
        selectedIndex: index,
        data: reportsData[index],
      };
    });
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickMonth = (event) => {
    setAnchorElMonth(event.currentTarget);
  };
  const handleClickMonthItem = (month) => {
    setDisplayMonths(month);
    setAnchorElMonth(null);
  };
  const handleCloseMonth = () => {
    setAnchorElMonth(null);
  };

  const reportName = REPORT_NAME.find((name) => name.id === reports[selectedIndex]?.name);
  const displayReportName = reportName ? reportName.name : reports[selectedIndex]?.name;

  const { displayReportName: displayReportNameFormatted, highIsWorse } = reportFormattingOptions(
    reports[selectedIndex]?.name,
    lookups,
  );

  const formatValue = (value) => {
    if (value === null || value === 'N/A') return 'N/A';

    if (isInteger(reports[selectedIndex]?.name)) {
      return parseInt(value);
    }
    if (isDecimal(reports[selectedIndex]?.name)) {
      return value.toFixed(2);
    }

    if (isPercentage(reports[selectedIndex]?.name)) {
      const percentageValue = value * 100;
      return `${percentageValue.toFixed(2)}%`;
    }

    return value.toFixed(2);
  };

  return (
    <>
      <Box>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Box display="flex" alignItems="center">
          {riskFactorEnabled ? (
            <Box>
              <ButtonGroup disableElevation variant="contained">
                <Button
                  color={isRiskFactorReport ? `` : `primary`}
                  onClick={() => handleReportType('asset')}
                >
                  Asset Reports
                </Button>
                <Button
                  color={isRiskFactorReport ? `primary` : ``}
                  onClick={() => handleReportType('riskFactor')}
                >
                  Risk Factor
                </Button>
              </ButtonGroup>
            </Box>
          ) : null}
          <Box>
            <List component="nav" aria-label="Select Report">
              <ListItem
                button
                aria-haspopup="true"
                aria-controls="lock-menu"
                aria-label="Select Report"
                onClick={handleClickListItem}
              >
                <ListItemText
                  primary={
                    displayReportNameFormatted ? displayReportNameFormatted : displayReportName
                  }
                  secondary="Select Report"
                />
              </ListItem>
            </List>
            <Menu
              id="lock-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {reports.map((report, index) => {
                const reportName = REPORT_NAME.find((name) => name.id === report.name);
                const displayReportName = reportName ? reportName.name : report.name;

                const { displayReportName: displayReportNameFormatted } = reportFormattingOptions(
                  report.name,
                  lookups,
                );

                return (
                  <MenuItem
                    key={report.id}
                    selected={index === selectedIndex}
                    onClick={() => handleMenuItemClick(report.id)}
                  >
                    {displayReportNameFormatted ? displayReportNameFormatted : displayReportName}
                  </MenuItem>
                );
              })}
            </Menu>
          </Box>
          {/* Month selection */}
          <Box>
            <List component="nav" aria-label="Select Report">
              <ListItem
                button
                aria-haspopup="true"
                aria-controls="lock-menu"
                aria-label="Select Report"
                onClick={handleClickMonth}
              >
                <ListItemText primary={displayMonths.name} secondary="Select months display data" />
              </ListItem>
            </List>
            <Menu
              id="month-selection"
              anchorEl={anchorElMonth}
              keepMounted
              open={Boolean(anchorElMonth)}
              onClose={handleCloseMonth}
            >
              {monthOptions.map((month, index) => (
                <MenuItem
                  key={month.id}
                  selected={index === selectedIndex}
                  onClick={() => handleClickMonthItem(month)}
                >
                  {month.name}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Box>
        <Box>
          <Typography variant="h6" gutterBottom>
            {highIsWorse ? (
              <>
                The{' '}
                <span
                  style={{
                    fontWeight: 600,
                  }}
                >
                  lowest number is best
                </span>{' '}
                and the{' '}
                <span
                  style={{
                    fontWeight: 600,
                  }}
                >
                  highest number is worst
                </span>
                .
              </>
            ) : (
              <>
                The{' '}
                <span
                  style={{
                    fontWeight: 600,
                  }}
                >
                  highest number is best
                </span>{' '}
                and the{' '}
                <span
                  style={{
                    fontWeight: 600,
                  }}
                >
                  lowest number is worst
                </span>
                .
              </>
            )}
          </Typography>
          <Box display="flex" alignItems="center" justifyContent="flex-start">
            <Box display="flex" alignItems="center" justifyContent="flex-start" mr={2}>
              <Box
                component="span"
                style={{
                  backgroundColor: highIsWorse ? '#e0f3db' : '#08306b',
                  width: '20px',
                  height: '20px',
                  marginRight: '10px',
                }}
              ></Box>
              <Typography variant="body1">
                {highIsWorse ? 'Lowest (best)' : 'Highest (best)'}
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" justifyContent="flex-start">
              <Box
                style={{
                  backgroundColor: highIsWorse ? '#084081' : '#deebf7',
                  width: '20px',
                  height: '20px',
                  marginRight: '10px',
                }}
              ></Box>
              <Typography variant="body1">
                {highIsWorse ? 'Highest (worst)' : 'Lowest (worst)'}
              </Typography>
            </Box>
          </Box>
        </Box>
        <MyResponsiveHeatMap
          key={`${activeReport.type}-${selectedIndex}-${displayMonths.id}`}
          heatmap={activeReport.data?.heatmap}
          displayMonths={displayMonths.value}
          highIsWorse={highIsWorse}
          formatValue={formatValue}
        />
      </Box>
    </>
  );
};
