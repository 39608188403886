import { useTheme, withStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(13),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const ModelStatus = ({ status, issues }) => {
  const theme = useTheme();

  const tooltipContent = status ? (
    <>
      <Typography color="inherit">Model is valid</Typography>
    </>
  ) : (
    <>
      <Typography color="inherit">Model is not valid</Typography>
      {issues.map((issue) => (
        <span key={issue} style={{ display: 'inline-block' }}>
          {issue}
        </span>
      ))}
    </>
  );

  return (
    <>
      {status !== null && (
        <HtmlTooltip arrow title={tooltipContent}>
          {status ? (
            <CheckCircleIcon
              style={{
                color: theme.palette.success.main,
                marginLeft: 5,
              }}
            />
          ) : (
            <ErrorIcon
              style={{
                color: theme.palette.error.main,
                marginLeft: 5,
              }}
            />
          )}
        </HtmlTooltip>
      )}
    </>
  );
};

export default ModelStatus;
