import { Controller } from 'react-hook-form';

import { fade, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { HelpInfo } from 'components/HelpInfo';

const useStylesInput = makeStyles((theme) => ({
  root: {
    width: 100,
    overflow: 'hidden',
    borderRadius: 4,
    border: 0,
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&$focused': {
      backgroundColor: '#fff',
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  focused: {},
}));

const InlineInput = ({
  disabled,
  error,
  control,
  helperText,
  placeholder,
  text,
  name,
  value,
  defaultValue = '',
  type = 'text',
  step,
  leftGrid = 4,
  rightGrid = 8,
  textClass,
  endAdornment,
  showHelpIcon,
}) => {
  const classes = useStylesInput();

  return (
    <Grid container spacing={3} alignItems="flex-start">
      <Grid item xs={12} md={leftGrid}>
        <Box display="flex" alignItems="center">
          <Typography className={textClass} variant="subtitle1">
            {text}
          </Typography>
          {showHelpIcon ? <HelpInfo name={name} /> : null}
        </Box>
      </Grid>
      <Grid item xs={12} sm={rightGrid}>
        <Controller
          defaultValue={defaultValue}
          value={value ? value : defaultValue}
          disabled={disabled}
          render={(props) => (
            <TextField
              disabled={disabled}
              type={type ? type : `text`}
              placeholder={placeholder || ''}
              variant="outlined"
              error={error}
              helperText={error ? error : helperText}
              elevation={4}
              InputProps={{
                classes,
                ...(endAdornment && {
                  endAdornment,
                }),
              }}
              inputProps={{
                step,
                type: 'number',
                'aria-labelledby': 'input-slider',
              }}
              {...props}
            />
          )}
          name={name}
          control={control}
        />
      </Grid>
    </Grid>
  );
};

export default InlineInput;
