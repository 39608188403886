/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { closePreference, savePreference } from '../../redux/actions';
import userService from '../../services/UserService';
import FormAutoComplete from '../../components/FormAutoComplete';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker } from '@material-ui/pickers';

const Preferences = ({ isPreferenceOpen }) => {
  const [currencies, setCurrencies] = useState([]);
  const dispatch = useDispatch();

  const { preference } = useSelector((state) => state.app);
  const { preferenceData } = preference;

  const methods = useForm({ defaultValues: preferenceData, shouldUnregister: false });

  const onSubmit = async (data) => {
    const formData = { ...data, currencyId: data?.investorCurrency?.id };
    dispatch(savePreference(formData));
    dispatch(closePreference());
  };
  const onError = (errors, e) => console.log(errors, e);

  useEffect(() => {
    methods.reset(preferenceData);
  }, [preferenceData]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await userService.getCurrencies();
      setCurrencies(result.data);
    };
    fetchData();
  }, []);

  const handleSave = () => {
    methods.handleSubmit(onSubmit, onError)();
  };
  const handleClose = () => {
    dispatch(closePreference());
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isPreferenceOpen}
        onClose={handleClose}
        style={{ minWidth: 400 }}
        aria-labelledby="form-dialog-title"
      >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
            <DialogTitle id="form-dialog-title">Preferences</DialogTitle>
            <DialogContent>
              <DialogContentText>Select Data for Analysis option to load.</DialogContentText>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h6" as="h5">
                    Choose investor currency
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormAutoComplete options={currencies} name="investorCurrency" />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" as="h5">
                    Return Frequency
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <Controller
                      id="return-frequency"
                      render={(props) => (
                        <RadioGroup row aria-label="Return Frequency" {...props}>
                          <FormControlLabel value="daily" control={<Radio />} label="Daily" />
                          <FormControlLabel value="weekly" control={<Radio />} label="Weekly" />
                          <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
                        </RadioGroup>
                      )}
                      name="returnFrequency"
                      control={methods.control}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Typography variant="h6" as="h5">
                            Start Date
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            control={methods.control}
                            name="startDate"
                            defaultValue={null}
                            render={({ onChange, onBlur, name, value }) => (
                              <KeyboardDatePicker
                                name={name}
                                value={value}
                                clearable
                                autoOk
                                placeholder="MM-DD-YYYY"
                                onChange={(date) => onChange(date ? date.format() : null)}
                                onBlur={(value) => onBlur(value ? value : null)}
                                format="MM-DD-YYYY"
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Typography variant="h6" as="h5">
                            End Date
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            control={methods.control}
                            name="endDate"
                            defaultValue={null}
                            render={({ onChange, onBlur, name, value }) => (
                              <KeyboardDatePicker
                                // className={classes.datePicker}
                                name={name}
                                value={value}
                                clearable
                                autoOk
                                placeholder="MM-DD-YYYY"
                                onChange={(date) => onChange(date ? date.format() : null)}
                                onBlur={(value) => onBlur(value ? value : null)}
                                format="MM-DD-YYYY"
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
              <Button onClick={handleSave} type="submit" color="primary">
                Save
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </>
  );
};

export default Preferences;
