const config = {
  locale: 'en',
  localeCountry: 'en-GB',
  translateRoute: '/translate-friendly',
  sidebarMenu: [
    {
      id: 1,
      title: 'Home',
      route: '/',
    },
    {
      id: 2,
      title: 'Data for Analysis',
      route: '/data-for-analysis',
      progress: 'dataForAnalysis',
    },
    {
      id: 3,
      title: 'Estimator Model',
      route: '/estimator-model',
      progress: 'estimatorModel',
    },
    {
      id: 4,
      title: 'Portfolio Model',
      route: '/portfolio-model',
      progress: 'portfolioModel',
    },
    {
      id: 5,
      title: 'Ranking Report',
      route: '/ranking-report',
    },
    {
      id: 6,
      title: 'Reporting Output',
      route: '/reporting-output',
    },
    {
      id: 7,
      title: 'Reports',
      route: '/reports',
    },
  ],
  // "dataForAnalysis" "estimator" "optimization" "rankingReport" "report" "pipelineSpec"
  specDetails: [
    {
      id: 'dataForAnalysis',
      title: 'Data For Analysis',
      slug: '/listing/dataForAnalysis',
      reduxKey: 'dataForAnalysis',
    },
    {
      id: 'estimator',
      title: 'Estimator Model',
      slug: '/listing/estimator',
      reduxKey: 'estimatorModel',
      reduxSubKey: 'estimatorData',
    },
    {
      id: 'optimization',
      title: 'Portfolio Model',
      slug: '/listing/optimization',
      reduxKey: 'portfolioModel',
      reduxSubKey: 'portfolioData',
    },
    {
      id: 'rankingReport',
      title: 'Ranking Report',
      slug: '/listing/rankingReport',
      reduxKey: 'rankingReport',
      reduxSubKey: 'rankingReportData',
    },
    {
      id: 'reporting',
      title: 'Reporting Output',
      slug: '/listing/reporting',
      reduxKey: 'reporting',
      reduxSubKey: 'reportingData',
    },
  ],
  timeSeriesOption: [
    {
      id: 0,
      name: 'No Filter',
    },
    {
      id: 6,
      name: '1 Mth EWMA - 12 Mth EWMA',
    },
    {
      id: 21,
      name: '1 Mth Lag',
    },
    {
      id: 3,
      name: '2 Mth MA - 12 Mth MA',
    },
    {
      id: 4,
      name: 'Current - 12 Mth EWMA',
    },
    {
      id: 1,
      name: 'Current - 12 Mth MA',
    },
    {
      id: 13,
      name: 'Current - 18 Mth EWMA',
    },
    {
      id: 9,
      name: 'Current - 18 Mth MA',
    },
    {
      id: 14,
      name: 'Current - 24 Mth EWMA',
    },
    {
      id: 10,
      name: 'Current - 24 Mth MA',
    },

    {
      id: 5,
      name: 'Current - 3 Mth EWMA',
    },
    {
      id: 2,
      name: 'Current - 3 Mth MA',
    },
    {
      id: 15,
      name: 'Current - 36 Mth EWMA',
    },
    {
      id: 11,
      name: 'Current - 36 Mth MA',
    },
    {
      id: 12,
      name: 'Current - 6 Mth EWMA',
    },
    {
      id: 8,
      name: 'Current - 6 Mth MA',
    },
    {
      id: 7,
      name: 'Current - Lag 1 Mth',
    },

    {
      id: 25,
      name: 'Current - Lag 1 Mth all Squared',
    },
    {
      id: 23,
      name: 'Lag 1 Mth - Lag 13 Mth',
    },
    {
      id: 22,
      name: 'Lag 1 Mth - Lag 2 Mth',
    },

    {
      id: 24,
      name: 'Level Squared',
    },
    {
      id: 20,
      name: 'Log 1Mth Lag - Log 13 Mth Lag',
    },
    {
      id: 16,
      name: 'Log Current',
    },
    {
      id: 17,
      name: 'Log Current - Log 1Mth Lag',
    },
    {
      id: 26,
      name: 'Log Current - Log 1 Mth Lag, all Squared',
    },
    {
      id: 19,
      name: 'Log Current - Log 12 Mth Lag',
    },
    {
      id: 18,
      name: 'Log Current - Log 6 Mth Lag',
    },
    {
      id: 27,
      name: 'Log Current - Log 3 Mth Lag',
    },
  ],
  coreModelSpecification: [
    {
      id: 1,
      name: 'Dogmatic CAPM',
    },
    {
      id: 2,
      name: 'Bayesian CAPM',
    },
    {
      id: 3,
      name: 'Bayesian Macro Alpha',
    },
    {
      id: 4,
      name: 'Skeptic Macro Alpha',
    },
  ],

  modelEstimationStrategy: [
    {
      id: 1,
      name: 'Analytical Core Model',
    },
    {
      id: 2,
      name: 'Simulated Core Model',
    },
    {
      id: 3,
      name: 'Stochastic Volatility',
    },
    {
      id: 4,
      name: 'Time-Varying Parameters',
    },
    {
      id: 5,
      name: 'Stochastic Volatility + Time-Varying Parameters',
    },
    {
      id: 6,
      name: 'Markov Switching',
    },
  ],

  consolidationRule: [
    {
      value: 'noConsolidation',
      name: 'No Consolidation',
    },
    {
      value: 'maxPredictiveFit',
      name: 'Max Predictive Fit',
    },
    {
      value: 'principalComponents',
      name: 'Principal Components',
    },
  ],

  multiStateWeightID: 'weightedMultiStat',
  // TODO check this ID with Steven feedback
  showRiskAversionID: 'meanVariance',

  constraintTable: [
    {
      id: 1,
      name: 'Min & Max',
    },
    {
      id: 2,
      name: 'Min & Max 2',
    },
    {
      id: 3,
      name: 'Min & Max 3',
    },
  ],
  // Add restriction array with the field names, if field name is part of the array then disable
  // ID of object is equal to the coreModelSpecification ID
  estimatorModelRestrictions: {
    1: {
      sigmaAlpha: true,
      riskModelSpecification: true,
      modelEstimationStrategy: [1],
      simulationBlocks: true,
      drawsPerBlock: true,
      adjustmentSpeed: true,
      enableCompleteSubsetRegression: true,
      modelWeightRule: true,
      variableCount: true,
      gammaCSR: true,
      //consolidation: true,
      1: {
        sigmaAlpha: true,
        riskModelSpecification: true,
        modelEstimationStrategy: [1],
        simulationBlocks: true,
        drawsPerBlock: true,
        adjustmentSpeed: true,
        enableCompleteSubsetRegression: true,
        modelWeightRule: true,
        variableCount: true,
        gammaCSR: true,
        savePredDensity: true,
        //consolidation: true,
      },
    },
    2: {
      modelEstimationStrategy: [1, 2],
      riskModelSpecificationLoadings: true,
      simulationBlocks: true,
      drawsPerBlock: true,
      adjustmentSpeed: true,
      enableCompleteSubsetRegression: true,
      modelWeightRule: true,
      variableCount: true,
      gammaCSR: true,
      //consolidation: true,
      1: {
        modelEstimationStrategy: [1, 2],
        riskModelSpecificationLoadings: true,
        simulationBlocks: true,
        drawsPerBlock: true,
        adjustmentSpeed: true,
        enableCompleteSubsetRegression: true,
        modelWeightRule: true,
        variableCount: true,
        gammaCSR: true,
        savePredDensity: true,
        //consolidation: true,
      },
      2: {
        modelEstimationStrategy: [1, 2],
        riskModelSpecificationLoadings: true,
        adjustmentSpeed: true,
        enableCompleteSubsetRegression: true,
        modelWeightRule: true,
        variableCount: true,
        gammaCSR: true,
        //consolidation: true,
      },
    },
    3: {
      sigmaAlpha: true,
      modelEstimationStrategy: [1, 2, 3, 4, 5, 6],
      simulationBlocks: true,
      drawsPerBlock: true,
      adjustmentSpeed: true,
      //consolidation: true,
      1: {
        modelEstimationStrategy: [1, 2, 3, 4, 5, 6],
        simulationBlocks: true,
        drawsPerBlock: true,
        adjustmentSpeed: true,
        savePredDensity: true,
        //consolidation: true,
      },
      2: {
        modelEstimationStrategy: [1, 2, 3, 4, 5, 6],
        adjustmentSpeed: true,
        //consolidation: true,
      },
      3: {
        modelEstimationStrategy: [1, 2, 3, 4, 5, 6],
        //consolidation: true,
      },
      4: {
        modelEstimationStrategy: [1, 2, 3, 4, 5, 6],
        //consolidation: true,
      },
      5: {
        modelEstimationStrategy: [1, 2, 3, 4, 5, 6],
        //consolidation: true,
      },
      6: {
        modelEstimationStrategy: [1, 2, 3, 4, 5, 6],
        adjustmentSpeed: true,
        //consolidation: true,
      },
    },
    4: {
      sigmaAlpha: true,
      modelEstimationStrategy: [1, 2, 3, 4, 5, 6],
      simulationBlocks: true,
      drawsPerBlock: true,
      adjustmentSpeed: true,
      //consolidation: true,
      1: {
        modelEstimationStrategy: [1, 2, 3, 4, 5, 6],
        simulationBlocks: true,
        drawsPerBlock: true,
        adjustmentSpeed: true,
        savePredDensity: true,
        //consolidation: true,
      },
      2: {
        modelEstimationStrategy: [1, 2, 3, 4, 5, 6],
        adjustmentSpeed: true,
        //consolidation: true,
      },
      3: {
        modelEstimationStrategy: [1, 2, 3, 4, 5, 6],
        //consolidation: true,
      },
      4: {
        modelEstimationStrategy: [1, 2, 3, 4, 5, 6],
        //consolidation: true,
      },
      5: {
        modelEstimationStrategy: [1, 2, 3, 4, 5, 6],
        //consolidation: true,
      },
      6: {
        modelEstimationStrategy: [1, 2, 3, 4, 5, 6],
        adjustmentSpeed: true,
        //consolidation: true,
      },
    },
  },

  fractileCount: [
    {
      value: 0,
      id: '4',
      name: '4',
    },
    {
      value: 1,
      id: '5',
      name: '5',
    },
    { value: 2, id: '5+2', name: '5+2' },
    { value: 3, id: '10', name: '10' },
    { value: 4, id: '10+2', name: '10+2' },
  ],
  forwardRankingHorizon: [
    {
      id: 0,
      name: 'forwardRankingHorizon[3]',
      label: '3 months forward',
      value: 3,
    },
    {
      id: 1,
      name: 'forwardRankingHorizon[6]',
      label: '6 months forward',
      value: 6,
    },
    {
      id: 2,
      name: 'forwardRankingHorizon[12]',
      label: '12 months forward',
      value: 12,
    },
    {
      id: 3,
      name: 'forwardRankingHorizon[18]',
      label: '18 months forward',
      value: 18,
    },
    {
      id: 4,
      name: 'forwardRankingHorizon[24]',
      label: '24 months forward',
      value: 24,
    },
    {
      id: 5,
      name: 'forwardRankingHorizon[36]',
      label: '36 months forward',
      value: 36,
    },
  ],
  selectReport: [
    {
      id: 1,
      name: 'rankingAnalysis',
      label: 'Ranking Analysis',
    },
    {
      id: 2,
      name: 'rankingReport',
      label: 'Ranking Report',
    },
  ],

  // exportOptions: [
  //   {
  //     id: 1,
  //     name: 'portfolioReturnSeries',
  //     label: 'Portfolio Return Series',
  //   },
  //   {
  //     id: 2,
  //     name: 'rankingReport',
  //     label: 'Constituents and Weights',
  //   },
  //   {
  //     id: 3,
  //     name: 'backtestLogFile',
  //     label: 'Backtest log file',
  //   },
  //   {
  //     id: 4,
  //     name: 'portfolioPerformanceStats',
  //     label: 'Portfolio Performance Stats',
  //   },
  //   {
  //     id: 5,
  //     name: 'attributionDetailReport',
  //     label: 'Attribution Detail Report',
  //   },
  //   {
  //     id: 6,
  //     name: 'portfolioBetaReport',
  //     label: 'Portfolio Beta Report',
  //   },
  //   {
  //     id: 7,
  //     name: 'varianceReport',
  //     label: 'Variance Report',
  //   },
  //   {
  //     id: 8,
  //     name: 'riskContributionReport',
  //     label: 'Risk Contribution Report',
  //   },
  //   {
  //     id: 9,
  //     name: 'matlabWorkspaceReport',
  //     label: 'Matlab Workspace Report',
  //   },

  //   {
  //     id: 10,
  //     name: 'assetBetaReport',
  //     label: 'Asset Beta report (covers individual asset factor exposures through time)',
  //   },
  //   {
  //     id: 11,
  //     name: 'covarianceMatrixReport',
  //     label: 'Covariance Matrix Report',
  //   },
  //   {
  //     id: 12,
  //     name: 'exportTimeSeries',
  //     label: 'Export time-series',
  //   },
  // ],

  assetForecastPropertyReport: {
    title: 'Asset Forecast Property Reports',
    value: 'assetForecastPropertyReport',
    id: 1,
    options: [
      {
        id: 1,
        name: 'expectedReturnForecast',
        label: 'Expected Return Forecast',
      },
      {
        id: 2,
        name: 'riskForecast',
        label: 'Risk Forecast',
      },
      {
        id: 3,
        name: 'subsetModelComponents',
        label: 'Subset Model Components',
      },
      {
        id: 4,
        name: 'simulationBasedReports',
        label: 'Simulation-Based Reports',
      },
    ],
  },
  attributionReport: {
    id: 6,
    name: 'estimatorAttributionReports',
    label: 'Attribution Reports',
  },

  portfolioPerformanceReport: {
    id: 2,
    title: 'Portfolio Performance Reports',
    value: 'portfolioPerformanceReport',
    options: [
      {
        id: 1,
        name: 'portfolioReturnTimesSeries',
        label: 'Return Times Series',
      },
      {
        id: 2,
        name: 'portfolioRiskForecast',
        label: 'Risk Forecast',
      },
      {
        id: 3,
        name: 'portfolioConstituentsAndWeights',
        label: 'Constituents and Weights',
      },
      {
        id: 4,
        name: 'portfolioSimulationBasedReports',
        label: 'Simulation-Based Reports',
      },
      {
        id: 4,
        name: 'portfolioAttributionReports',
        label: 'Attribution Reports',
      },
    ],
  },
  rankingReportAndAnalysis: {
    id: 3,
    title: 'Ranking Report and Analysis',
  },

  fileType: [
    {
      id: 1,
      name: 'CSV',
      label: 'CSV',
    },
    {
      id: 2,
      name: 'XML',
      label: 'XML',
    },
  ],
};

export const orderByOptions = [
  {
    key: 'name',
    value: 'asc',
    label: 'Name (A-Z)',
  },
  {
    key: 'name',
    value: 'desc',
    label: 'Name (Z-A)',
  },
  {
    key: 'created_at',
    value: 'desc',
    label: 'Created At (Newest)',
  },
  {
    key: 'created_at',
    value: 'asc',
    label: 'Created At (Oldest)',
  },
  {
    key: 'last_modified',
    value: 'desc',
    label: 'Modified At (Newest)',
  },
  {
    key: 'last_modified',
    value: 'asc',
    label: 'Modified At (Oldest)',
  },
];

export default config;
