import { useSelector } from 'react-redux';

import Container from '@material-ui/core/Container';

import AppDataInfoCard from './AppDataInfoCard';

const AppDataInfo = () => {
  const dataForAnalysis = useSelector((state) => state.dataForAnalysis);
  const { estimatorData } = useSelector((state) => state.estimatorModel);
  const { portfolioData } = useSelector((state) => state.portfolioModel);
  const { reportingData } = useSelector((state) => state.reporting);

  return (
    <Container>
      <AppDataInfoCard
        title="Data for Analysis"
        name={dataForAnalysis.name}
        description={dataForAnalysis.description}
        created_at={dataForAnalysis.created_at}
        id={dataForAnalysis.id}
        last_modified={dataForAnalysis.last_modified}
      />
      <AppDataInfoCard
        title="Estimator Model"
        name={estimatorData.name}
        description={estimatorData.description}
        created_at={estimatorData.created_at}
        id={estimatorData.id}
        last_modified={estimatorData.last_modified}
      />
      <AppDataInfoCard
        title="Portfolio Model"
        name={portfolioData.name}
        description={portfolioData.description}
        created_at={portfolioData.created_at}
        id={portfolioData.id}
        last_modified={portfolioData.last_modified}
      />
      <AppDataInfoCard
        title="Reporting Output"
        name={reportingData.name}
        description={reportingData.description}
        created_at={reportingData.created_at}
        id={reportingData.id}
        last_modified={reportingData.last_modified}
      />
    </Container>
  );
};

export default AppDataInfo;
