/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import omit from 'lodash.omit';

import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SaveIcon from '@material-ui/icons/Save';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import DescriptionIcon from '@material-ui/icons/Description';

import styles, { Accordion, AccordionSummary, AccordionDetails } from './PortfolioModel.styles';

import Layout from 'components/Layout';

import SelectionCriteria from 'components/SelectionCriteria';
import PortfolioModelPreferences from 'components/PortfolioModelPreferences';
import PortfolioModelConstraints from 'components/PortfolioModelConstraints';
import MultiAssetConstraints from 'components/MultiAssetConstraints';

import SaveDataDialog from 'components/SaveDataDialog';
import LoadDataDialog from 'components/LoadDataDialog';
import AlertDialog from 'components/AlertDialog';
import PreventNavigation from 'components/PreventNavigation';
import { SpecDialog } from 'components/SpecDialog';

import {
  savePortfolioModel,
  loadPortfolioModel,
  savePortfolioModelInState,
  resetPortfolioModel,
  deletePortfolioModel,
  isDirtyPortfolioModel,
  validatePortfolioModel,
  getPortfolioModelParameters,
} from 'redux/actions';

const PortfolioModel = () => {
  const [isSaveFormOpen, setSaveFormDialog] = useState(false);
  const [isLoadFormOpen, setLoadFormDialog] = useState(false);
  const [isResetOpen, setIsResetOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [openSpec, setOpenSpec] = useState(false);

  const {
    portfolioData,
    exposureTolerance,
    assetRiskFactorCorrelations,
    selectedMultiAssetConstraint,
  } = useSelector((state) => state.portfolioModel);
  const ref_spec_id = useSelector((state) => state.estimatorModel?.estimatorData?.id);

  const schema = yup.object().shape({
    numberAssets: yup
      .number()
      .typeError('Please enter as a decimal.')
      .nullable()
      .transform((v, o) => (o === '' ? null : v)),
  });

  const methods = useForm({
    mode: 'onChange',
    defaultValues: portfolioData,
    shouldUnregister: false,
    resolver: yupResolver(schema),
  });
  const watchForm = methods.watch();
  const { isDirty } = methods.formState;

  const classes = makeStyles(styles, { name: 'PortfolioModel' })();

  const { name, description, id } = portfolioData;
  const dispatch = useDispatch();

  useEffect(() => {
    methods.reset(portfolioData);
  }, [portfolioData.id]);

  useEffect(() => {
    const formData = {
      formData: { ...watchForm },
      exposureTolerance,
      assetRiskFactorCorrelations,
      selectedMultiAssetConstraint,
    };
    dispatch(validatePortfolioModel(formData));
  }, [watchForm, dispatch]);

  useEffect(() => {
    isDirty && dispatch(isDirtyPortfolioModel(true));
  }, [isDirty, dispatch]);

  useEffect(() => {
    dispatch(getPortfolioModelParameters());
  }, []);

  const onSubmit = async (data) => {
    const { name, description } = data;
    const formattedData = omit(data, ['name', 'description']);
    const formData = {
      name,
      description,
      ref_spec_id,
      portfolioModel: {
        ...formattedData,
        exposureTolerance,
        assetRiskFactorCorrelations,
        selectedMultiAssetConstraint,
      },
    };
    const id = portfolioData?.id ? portfolioData.id : null;
    dispatch(savePortfolioModel(formData, id));
    dispatch(isDirtyPortfolioModel(false));
    methods.reset(data);
    setSaveFormDialog(false);
  };

  const onSubmitNew = async (data) => {
    const { name, description } = data;
    const formattedData = omit(data, ['name', 'description']);
    const formData = {
      name,
      description,
      ref_spec_id,
      portfolioModel: {
        ...formattedData,
        exposureTolerance,
        assetRiskFactorCorrelations,
        selectedMultiAssetConstraint,
      },
    };
    const id = null;
    dispatch(savePortfolioModel(formData, id));
    dispatch(isDirtyPortfolioModel(false));
    methods.reset(data);
    setSaveFormDialog(false);
  };

  const onError = (errors, e) => console.log(errors, e);

  const saveToStore = async () => {
    // const { subsetRegressionData } = estimatorData;
    const formData = { ...watchForm };
    dispatch(savePortfolioModelInState(formData));
  };

  const handleSaveClose = () => {
    methods.reset(portfolioData);
    setSaveFormDialog(false);
  };
  const handleLoadClose = () => {
    setLoadFormDialog(false);
  };
  const handleSave = () => {
    methods.handleSubmit(onSubmit, onError)();
    setSaveFormDialog(false);
  };

  const handleSaveAsNew = () => {
    methods.handleSubmit(onSubmitNew, onError)();
  };

  const handleOpenSave = () => {
    setSaveFormDialog(true);
  };

  const handleLoad = () => {
    setLoadFormDialog(true);
  };

  const handleLoadSpec = (id) => {
    dispatch(loadPortfolioModel(id));
    dispatch(isDirtyPortfolioModel(false));
  };

  const handleReset = () => {
    setIsResetOpen(true);
  };
  const handleResetConfirm = () => {
    methods.reset(portfolioData, { isDirty: false });
    dispatch(resetPortfolioModel());
    setIsResetOpen(false);
  };
  const handleResetCancel = () => {
    setIsResetOpen(false);
  };

  const handleDelete = () => {
    setIsDeleteOpen(true);
  };

  const handleDeleteConfirm = () => {
    dispatch(deletePortfolioModel(id));
    methods.reset();
    setIsDeleteOpen(false);
  };
  const handleDeleteCancel = () => {
    setIsDeleteOpen(false);
  };
  const handleClickOpenSpec = () => {
    setOpenSpec(true);
  };

  const handleCloseSpec = () => {
    setOpenSpec(false);
  };

  return (
    <Layout>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
          <SaveDataDialog
            isOpen={isSaveFormOpen}
            close={handleSaveClose}
            save={handleSave}
            saveAsNew={handleSaveAsNew}
            title="Save Portfolio Model."
            formName="Portfolio Model"
            type="portfolioModel"
            name={name}
            description={description}
            id={id}
          />
          {isLoadFormOpen && (
            <LoadDataDialog
              isOpen={isLoadFormOpen}
              loadSpec={handleLoadSpec}
              close={handleLoadClose}
              type="optimization"
              title="Load Portfolio Model"
              description="Select Portfolio Model option to load."
            />
          )}
          <AlertDialog
            isOpen={isResetOpen}
            title="Do you want to reset Portfolio Model form?"
            handleConfirm={handleResetConfirm}
            handleCancel={handleResetCancel}
          />
          <AlertDialog
            isOpen={isDeleteOpen}
            title="Do you want to delete Portfolio Model from the Database?"
            handleConfirm={handleDeleteConfirm}
            handleCancel={handleDeleteCancel}
          />
          <SpecDialog open={openSpec} onClose={handleCloseSpec} id={portfolioData?.id} />
          <Container style={{ margin: 0 }}>
            <Grid container spacing={3} alignItems="flex-start">
              <Grid item xs={6}>
                <Box display="flex">
                  <Typography variant="h4" as="h1">
                    Portfolio Model
                  </Typography>
                  {portfolioData?.id ? (
                    <Tooltip title="Click for saved JSON spec" arrow>
                      <IconButton
                        color="primary"
                        aria-label="Saved JSON spec"
                        component="span"
                        onClick={handleClickOpenSpec}
                      >
                        <DescriptionIcon />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={1} alignItems="flex-end" justify="flex-end">
                  <Grid item xs={12}>
                    <Button
                      size="small"
                      className={classes.button}
                      onClick={() => handleOpenSave()}
                      type="button"
                      variant="contained"
                      color="primary"
                      startIcon={<SaveIcon />}
                    >
                      save
                    </Button>
                    <Button
                      size="small"
                      className={classes.button}
                      variant="contained"
                      onClick={() => handleLoad()}
                      color="primary"
                      startIcon={<OpenInBrowserIcon />}
                    >
                      Load
                    </Button>
                    <Button
                      size="small"
                      className={classes.button}
                      variant="contained"
                      onClick={() => handleReset()}
                      color="primary"
                      startIcon={<FiberNewIcon />}
                    >
                      Reset
                    </Button>
                    {portfolioData?.id ? (
                      <Button
                        size="small"
                        className={classes.button}
                        variant="contained"
                        onClick={() => handleDelete()}
                        color="primary"
                        startIcon={<DeleteForeverIcon />}
                      >
                        Delete
                      </Button>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Container className={classes.root} style={{ margin: 0 }}>
              <Accordion elevation={4}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>Selection criteria</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <SelectionCriteria />
                </AccordionDetails>
              </Accordion>
              <Accordion elevation={4}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>Preferences</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <PortfolioModelPreferences />
                </AccordionDetails>
              </Accordion>
              <Accordion elevation={4}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>Constraints</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <PortfolioModelConstraints />
                </AccordionDetails>
              </Accordion>

              <Accordion elevation={4}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography className={classes.heading}>Multi asset constraints</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <MultiAssetConstraints />
                </AccordionDetails>
              </Accordion>
            </Container>
          </Container>
        </form>
        <PreventNavigation dirty={isDirty} dirtyForm="portfolioModel" saveToStore={saveToStore} />
      </FormProvider>
    </Layout>
  );
};

export default PortfolioModel;
