import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import config from '../../config';
import jobsService from '../../services/JobsService';
import reportService from '../../services/ReportService';

import { showMessage, getPortfolioModelParameters } from '../../redux/actions';

const useStyles = makeStyles((theme) => ({
  headingTitle: {
    fontSize: 17,
    fontWeight: 400,
  },
  list: {
    width: '100%',
    overflow: 'auto',
    marginTop: -4,
  },
  boldText: {
    fontWeight: 500,
  },
}));

const GenerateRankingReports = ({ selectedModels, formValues, control }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [error, setError] = useState(null);
  const { portfolioModelParameters } = useSelector((state) => state.portfolioModel);
  const fundRankingCriteriaOptions = portfolioModelParameters?.fund_ranking_criteria;

  const { forwardRankingHorizon, assetsRankingCriteria, fractileCount } = formValues;

  const fractileCountValue = config.fractileCount.find((item) => item.value === fractileCount);
  const assetsRankingCriteriaValue = fundRankingCriteriaOptions?.find(
    (item) => item.value === assetsRankingCriteria,
  );
  const forwardRankingHorizonValues = forwardRankingHorizon
    ?.map((item, index) => (item ? index : null))
    .filter((horizon) => horizon);

  const forwardRankingHorizonLabels = config.forwardRankingHorizon.filter((item) =>
    forwardRankingHorizonValues?.includes(item.value),
  );

  useEffect(() => {
    dispatch(getPortfolioModelParameters());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleGenerateReports = async () => {
    setIsSubmitting(true);
    selectedModels.map(async (model) => {
      const { id, name, description } = model;
      const {
        reportOnPortfolioModels,
        oneOffRun,
        useHistoricalLearning,
        description: extraDescription,
        enableSpecUpdate,
        lastDataDate,
        overwriteLastDataDate,
        lastVintageDate,
        overwriteLastVintageDate,
      } = formValues;
      const specUpdate = {
        enableSpecUpdate,
        lastDataDate,
        overwriteLastDataDate,
        lastVintageDate,
        overwriteLastVintageDate,
      };

      const { reporting } = reportService.formatDataRankingReport(formValues);

      const formattedName = `${name} - Ranking Report`;

      const formattedDescription = extraDescription
        ? `${description}<br /> <strong>${extraDescription}</strong>`
        : description;

      const { data, dataForAnalysisObject, estimatorObject } = await jobsService.buildSpec(
        id,
        reporting,
        reportOnPortfolioModels,
        useHistoricalLearning,
        specUpdate,
      );

      const result = await jobsService.submitJob(
        formattedName,
        formattedDescription,
        data,
        oneOffRun,
      );

      if (result?.error) {
        dispatch(
          showMessage({ type: 'error', message: 'There was an error processing your request.' }),
        );
        setError(result.errorMessage);
      } else {
        dispatch(showMessage({ type: 'success', message: 'Job was submitted successfully.' }));

        if (enableSpecUpdate && overwriteLastDataDate && moment(lastDataDate).isValid()) {
          await jobsService.patchSpec(dataForAnalysisObject.id, dataForAnalysisObject);
        }

        if (enableSpecUpdate && overwriteLastVintageDate && moment(lastVintageDate).isValid()) {
          await jobsService.patchSpec(estimatorObject.id, estimatorObject);
        }

        setError(null);
      }
    });

    setIsSubmitting(false);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item sx={12} sm={6} md={3}>
            <Typography className={classes.headingTitle} variant="h6">
              Selected {formValues?.reportOnPortfolioModels ? `Portfolio` : `Estimator`} Models:
            </Typography>
          </Grid>
          <Grid item>
            <List className={classes.list} dense disablePadding component="div" role="list">
              {selectedModels?.map((item) => {
                const labelId = `transfer-list-all-item-${item.name}-label`;
                const secondary = (
                  <>
                    <Typography variant="body2">{item.description}</Typography>
                    {item.id && item.created_at && (
                      <Typography className={classes.dateLabel} variant="caption">
                        Created: {moment(item.created_at).format('MMMM Do YYYY, h:mm:ss a')}
                      </Typography>
                    )}
                    {item.id && item.last_modified && (
                      <Typography className={classes.dateLabel} variant="caption">
                        Modified: {moment(item.last_modified).format('MMMM Do YYYY, h:mm:ss a')}
                      </Typography>
                    )}
                  </>
                );

                return (
                  <ListItem key={item.id} role="listitem">
                    <ListItemText id={labelId} primary={item.name} secondary={secondary} />
                  </ListItem>
                );
              })}
              <ListItem />
            </List>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item sx={12} sm={6} md={3}>
            <Typography className={classes.headingTitle} variant="h6">
              Assets ranking criteria
            </Typography>
          </Grid>
          <Grid item>
            <List className={classes.list} dense disablePadding component="div" role="list">
              <ListItem role="listitem">
                <ListItemText
                  primary={assetsRankingCriteriaValue?.name}
                  classes={{ primary: classes.boldText }}
                />
              </ListItem>
              <ListItem />
            </List>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item sx={12} sm={6} md={3}>
            <Typography className={classes.headingTitle} variant="h6">
              Fractile Count
            </Typography>
          </Grid>
          <Grid item>
            <List className={classes.list} dense disablePadding component="div" role="list">
              <ListItem role="listitem">
                <ListItemText
                  primary={fractileCountValue?.name}
                  classes={{ primary: classes.boldText }}
                />
              </ListItem>
              <ListItem />
            </List>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item sx={12} sm={6} md={3}>
            <Typography className={classes.headingTitle} variant="h6">
              Recalculation frequency
            </Typography>
          </Grid>
          <Grid item>
            <List className={classes.list} dense disablePadding component="div" role="list">
              <ListItem role="listitem">
                <ListItemText
                  primary={formValues?.dataFreq}
                  classes={{ primary: classes.boldText }}
                />
              </ListItem>
              <ListItem />
            </List>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item sx={12} sm={6} md={3}>
            <Typography className={classes.headingTitle} variant="h6">
              Forward Ranking Horizon(s)
            </Typography>
          </Grid>
          <Grid item>
            <List className={classes.list} dense disablePadding component="div" role="list">
              {forwardRankingHorizonLabels?.map((item) => {
                return (
                  <ListItem key={item.id} role="listitem">
                    <ListItemText primary={item.label} classes={{ primary: classes.boldText }} />
                  </ListItem>
                );
              })}
              <ListItem />
            </List>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item sx={12} sm={6} md={3}>
            <Typography className={classes.headingTitle} variant="h6">
              Report
            </Typography>
          </Grid>
          <Grid item>
            <List className={classes.list} dense disablePadding component="div" role="list">
              {formValues?.rankingAnalysis ? (
                <ListItem key="assetForecastPropertyReport" role="listitem">
                  <ListItemText
                    primary={`Ranking Analysis`}
                    classes={{ primary: classes.boldText }}
                  />
                </ListItem>
              ) : null}
              {formValues?.rankingReport ? (
                <ListItem key="assetForecastPropertyReport" role="listitem">
                  <ListItemText
                    primary={`Ranking Report`}
                    classes={{ primary: classes.boldText }}
                  />
                </ListItem>
              ) : null}
            </List>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item sx={12} sm={6} md={3}>
            <Typography className={classes.headingTitle} variant="h6">
              Preferences
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" style={{ marginLeft: 12 }}>
              <span>Use historical learning: </span>
              <span className={classes.boldText}>
                {formValues?.useHistoricalLearning ? 'Yes' : 'No'}
              </span>
            </Typography>
            <Typography variant="body2" style={{ marginLeft: 12 }}>
              <span>One-off run: </span>
              <span className={classes.boldText}>{formValues?.oneOffRun ? 'Yes' : 'No'}</span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item sx={12} sm={6} md={3}>
            <Typography className={classes.headingTitle} variant="h6">
              Report Description
            </Typography>
          </Grid>
          <Grid item sx={12} sm={6} md={9}>
            <Controller
              render={(props) => (
                <TextField
                  {...props}
                  className={classes.input}
                  elevation={2}
                  variant="outlined"
                  minRows={2}
                  multiline
                  fullWidth
                  label="Description"
                />
              )}
              name={`description`}
              control={control}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{ marginTop: 20, marginBottom: 20 }}>
        <Button
          onClick={() => handleGenerateReports()}
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<KeyboardArrowRightIcon />}
          disabled={isSubmitting || selectedModels?.length === 0}
        >
          Generate Ranking Report
        </Button>
      </Grid>
      {error ? (
        <Grid item xs={12}>
          <Typography
            variant="body2"
            color="error"
            dangerouslySetInnerHTML={{
              __html: error,
            }}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default GenerateRankingReports;
