import * as types from '../../types';
import { showMessage } from '../../actions';

import reportService from '../../../services/ReportService';
import universeService from '../../../services/UniverseService';

const formatPostData = (data) => {
  return {
    ...data,
    selectedModels: data?.selectedModels?.map((model) => (model?.id ? model.id : model)) || [],
  };
};
// Reporting Output save
export const saveReportingOutput = (data, id) => async (dispatch) => {
  try {
    const type = 'reporting';
    const formattedData = formatPostData(data);

    const result = await reportService.saveReportingOutputSpec(formattedData, id, type);

    if (result?.error) {
      dispatch(showMessage({ type: 'error', message: result.error?.detail }));
    } else {
      const { spec, ...rest } = result;

      dispatch({
        type: types.REPORTING_OUTPUT_SAVE_DATA,
        payload: { ...spec, ...rest },
      });
      dispatch(showMessage({ type: 'success', message: 'Data saved successfully.' }));
    }
  } catch (error) {
    console.log(error);
  }
};

export const saveReportingOutputInState = (data) => async (dispatch) => {
  dispatch({
    type: types.REPORTING_OUTPUT_SAVE_DATA_IN_STATE,
    payload: data,
  });
};

export const resetReportingOutput = () => (dispatch) => {
  dispatch({
    type: types.REPORTING_OUTPUT_RESET,
    payload: '',
  });
};

export const deleteReportingOutput = (id) => async (dispatch) => {
  try {
    await universeService.deleteUniverse(id);
    dispatch(resetReportingOutput());
    dispatch(showMessage({ type: 'success', message: 'Data deleted successfully.' }));
  } catch (error) {}
};

// Load Data
export const loadReportingOutput = (id) => async (dispatch) => {
  const result = await reportService.loadReportingOutputSpec(id);

  const { spec, ...rest } = result;

  dispatch({
    type: types.REPORTING_OUTPUT_LOAD_DATA,
    payload: { ...spec, ...rest },
  });

  dispatch(showMessage({ type: 'success', message: 'Data loaded successfully.' }));
};

export const updateSelectedModels = (models) => (dispatch) => {
  dispatch({
    type: types.REPORTING_OUTPUT_UPDATE_SELECTED_MODELS,
    payload: models,
  });
};

export const updateModels = (specModel, models) => (dispatch) => {
  dispatch({
    type: types.REPORTING_OUTPUT_UPDATE_MODELS,
    payload: { specModel, models },
  });
};
