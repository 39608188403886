import * as React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import UniverseService from 'services/UniverseService';

export const SpecDialog = ({ data, open, onClose, id = null }) => {
  const [spec, setSpec] = React.useState(data);
  const descriptionElementRef = React.useRef(null);

  const fetchSpec = async (id) => {
    if (id) {
      const result = await UniverseService.loadSpec(id);
      result?.data?.spec && setSpec(result.data.spec);
    }
  };

  React.useEffect(() => {
    if (open && id) {
      fetchSpec(id);
    }
  }, [open, id]);

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="scroll-dialog-title">Spec details</DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
          <pre>{JSON.stringify(spec ? spec : data, null, 2)}</pre>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            navigator.clipboard.writeText(JSON.stringify(spec ? spec : data, null, 2));
          }}
        >
          Copy to clipboard
        </Button>
      </DialogActions>
    </Dialog>
  );
};
