import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { DragDropContext } from 'react-beautiful-dnd';

import {
  isDirtyEstimatorModel,
  saveEstimatorModelInState,
  validateEstimatorModel,
  updateSubsetRegression,
} from '../../redux/actions';

import Column from './Column';

const CompleteSubsetRegression = () => {
  const { estimatorData, estimatorRules } = useSelector((state) => state.estimatorModel);

  const { subsetRegressionData } = estimatorData;
  const dispatch = useDispatch();
  const { watch } = useFormContext();
  const watchForm = watch();

  function onDragEnd(result) {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const start = subsetRegressionData.columns[source.droppableId];
    const finish = subsetRegressionData.columns[destination.droppableId];

    if (start === finish) {
      const newArrayOfIDs = Array.from(start.arrayOfIDs);
      newArrayOfIDs.splice(source.index, 1);
      newArrayOfIDs.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        arrayOfIDs: newArrayOfIDs.map((item) => parseFloat(item)),
      };

      const newState = {
        ...subsetRegressionData,
        columns: {
          ...subsetRegressionData.columns,
          [newColumn.id]: newColumn,
        },
      };
      const formData = { ...watchForm, subsetRegressionData: newState };
      dispatch(saveEstimatorModelInState(formData));
      dispatch(isDirtyEstimatorModel(true));
      dispatch(updateSubsetRegression(newState));
      return;
    }

    // Moving from one list to another
    const startArrayOfIDs = Array.from(start.arrayOfIDs);
    startArrayOfIDs.splice(source.index, 1);
    const newStart = {
      ...start,
      arrayOfIDs: startArrayOfIDs.map((item) => parseFloat(item)),
    };

    const finishArrayOfIDs = Array.from(finish.arrayOfIDs);
    finishArrayOfIDs.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      arrayOfIDs: finishArrayOfIDs.map((item) => parseFloat(item)),
    };

    const newState = {
      ...subsetRegressionData,
      columns: {
        ...subsetRegressionData.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };
    const formData = { ...watchForm, subsetRegressionData: newState };
    dispatch(saveEstimatorModelInState(formData));
    dispatch(isDirtyEstimatorModel(true));
    dispatch(validateEstimatorModel(formData, estimatorRules));
    dispatch(updateSubsetRegression(newState));
  }

  const columnIdMacro = 'macrofactors';
  const columnMacro = subsetRegressionData?.columns[columnIdMacro] || [];
  const listMacro = columnMacro?.arrayOfIDs?.map(
    (id) => subsetRegressionData.macroeconomicVariables[id],
  );

  return (
    <div
      style={{
        display: 'flex',
        flex: '1 1',
        flexWrap: 'wrap',
        overflowX: 'auto',
        overflowY: 'hidden',
        backgroundColor: '#f6f7f9',
      }}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
          <div className="macrofactors">
            {listMacro?.length ? (
              <Column key={columnMacro.id} column={columnMacro} tasks={listMacro} />
            ) : null}
          </div>
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
          {subsetRegressionData?.columnOrder?.map((columnId, index) => {
            const column = subsetRegressionData.columns[columnId];
            const tasks = column?.arrayOfIDs?.map(
              (id) => subsetRegressionData?.macroeconomicVariables[id],
            );

            return columnId === 'macrofactors' ? null : (
              <Column key={column.id} column={column} tasks={tasks} />
            );
          })}
        </div>
      </DragDropContext>
    </div>
  );
};

export default CompleteSubsetRegression;
