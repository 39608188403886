import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import AvailableBenchmarkForm from '../AvailableBenchmarkForm';
import FormAutoComplete from '../FormAutoComplete';
import OrderList from '../OrderList';
import CustomizedSnackbar from '../CustomizedSnackbar';

import { Typography, Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

import { removeMacrofactor, reorderMacrofactor } from '../../redux/actions';
import universeService from '../../services/UniverseService';

const MacroFactors = ({ universeList }) => {
  const [availableMacroFactorList, setAvailableMacroFactorList] = useState([]);
  const [fetchListError, setFetchListError] = useState(null);
  const { macrofactors } = useSelector((state) => state.dataForAnalysis);
  const dispatch = useDispatch();
  const { watch } = useFormContext();

  const watchMacrofactorUniverse = watch('macrofactorUniverse', false);

  useEffect(() => {
    const fetchData = async () => {
      const result = await universeService.getAssets(watchMacrofactorUniverse.id);

      // setAvailableMacroFactorList(result.data);
      if (result.error) {
        setAvailableMacroFactorList([]);
        setFetchListError('Error fetching macrofactor list.');
      } else {
        setAvailableMacroFactorList(result.data);
        setFetchListError(null);
      }
    };
    watchMacrofactorUniverse?.id && fetchData();
  }, [watchMacrofactorUniverse?.id]);

  const removeSelectedBecnhmark = (index) => {
    dispatch(removeMacrofactor(index));
  };

  const reorderSelectedList = (list) => {
    dispatch(reorderMacrofactor(list));
  };

  return (
    <>
      {fetchListError && (
        <CustomizedSnackbar message={fetchListError} variant="error" show={!!fetchListError} />
      )}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" as="h5">
            Macro Factor Universe
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormAutoComplete name="macrofactorUniverse" options={universeList} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" as="h5">
            Available Macro Factors
          </Typography>
        </Grid>
        {/* Start availableBenchmark Form */}
        <AvailableBenchmarkForm
          availableBenchmarkList={availableMacroFactorList}
          macroFactor
          benchMarkPlaceholder="Macro Factor"
          timeFiltration
          addButtonLabel="Add Macro Factor"
        />
        {/* End availableBenchmark Form */}
        <Grid item xs={12}>
          <Typography variant="h6" as="h5">
            Selected Macro Factors
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            {macrofactors?.length ? (
              <OrderList
                initialList={macrofactors}
                reorderSelectedList={reorderSelectedList}
                handleClick={removeSelectedBecnhmark}
              />
            ) : (
              <Typography variant="body2" as="span" m={2} style={{ padding: 20 }}>
                No Macro Factor selected.
              </Typography>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default MacroFactors;
