import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Grid, TextField } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';

import FormSelect from '../../components/FormSelect';
import MUISwitch from '../MUISwitch';
import MultiStatWeight from '../MultiStatWeight';
import { HelpInfo } from '../HelpInfo';

import config from '../../config';

const useStyles = makeStyles((theme) => ({
  input: {
    width: 200,
    backgroundColor: theme.palette.background.default,
    borderRadius: 4,
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      border: 0,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 0,
      },
      '&:hover fieldset': {
        border: 0,
      },
      '&.Mui-focused fieldset': {
        border: 0,
      },
    },
  },
  paper: {
    maxWidth: 500,
    padding: '2px 4px',

    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  selectInput: {
    margin: theme.spacing(1),
    '& .MuiInput-underline:before': {
      border: 0,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 0,
    },
  },
}));

const PortfolioModelPreferences = () => {
  const [showMultiState, setShowMultiState] = useState(false);
  const [showRiskAversion, setShowRiskAversion] = useState(false);
  const classes = useStyles();

  const referenceBenchmark = useSelector((state) => state?.dataForAnalysis?.benchmarks) || [];
  const referenceBenchmarkValues =
    useSelector((state) => state?.portfolioModel?.portfolioData?.referenceBenchmark) || [];
  const referenceBenchmarkOptions =
    referenceBenchmark.length > 0 ? referenceBenchmark : referenceBenchmarkValues;

  const { portfolioModelParameters } = useSelector((state) => state.portfolioModel);

  const portfolioWeightsRules = portfolioModelParameters?.portfolio_weights_rules;
  const { control, register, watch } = useFormContext();
  const portfolioWeights = watch('portfolioWeights');
  const MultiStatWeightsUtilityValue = watch('MultiStatWeightsUtility');
  const referenceBenchmarkValue = watch('referenceBenchmark');

  useEffect(() => {
    portfolioWeights === config.multiStateWeightID
      ? setShowMultiState(true)
      : setShowMultiState(false);
  }, [portfolioWeights]);

  useEffect(() => {
    (portfolioWeights === config.multiStateWeightID && MultiStatWeightsUtilityValue > 0) ||
    portfolioWeights === config.showRiskAversionID
      ? setShowRiskAversion(true)
      : setShowRiskAversion(false);
  }, [portfolioWeights, MultiStatWeightsUtilityValue]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" component="h5">
            Multi-period forecasting
          </Typography>
          <MUISwitch register={register} defaultCheck={true} name="multiPeriodForecasting" />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6" as="h5">
                Portfolio Weights
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormSelect
                control={control}
                name="portfolioWeights"
                data={portfolioWeightsRules}
                displayEmpty
                emptyValue="Select Portfolio Weights"
              />
            </Grid>
            {showMultiState ? (
              <>
                <Grid item xs={12}>
                  <Typography variant="h6">Portfolio Weights</Typography>
                </Grid>

                <Grid item xs={12}>
                  <MultiStatWeight />
                </Grid>
              </>
            ) : null}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" component="span">
            Allow zero weights
          </Typography>
          <MUISwitch register={register} defaultCheck={true} name="allowZeroWeights" />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={1} xs={12} alignItems="center">
            <Typography variant="h6" as="h5">
              Reference Benchmark
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Controller
            render={({ onChange, ...props }) => (
              <Autocomplete
                key={`${referenceBenchmarkOptions.length}-${referenceBenchmarkValue?.length}`}
                options={referenceBenchmarkOptions}
                multiple
                disabled={!referenceBenchmarkOptions?.length}
                getOptionLabel={(option) => option.name || ''}
                filterSelectedOptions
                renderInput={(params) => (
                  <Paper className={classes.paper} elevation={4}>
                    <TextField
                      className={classes.selectInput}
                      {...params}
                      placeholder="Reference Benchmark"
                    />
                  </Paper>
                )}
                renderOption={(option, { inputValue }) => {
                  const matches = match(option.name, inputValue);
                  const parts = parse(option.name, matches);

                  return (
                    <div>
                      {parts.map((part, index) => (
                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                          {part.text}
                        </span>
                      ))}
                    </div>
                  );
                }}
                onChange={(e, data) => onChange(data)}
                {...props}
              />
            )}
            id="referenceBenchmark"
            onChange={([, data]) => data}
            name="referenceBenchmark"
            control={control}
            key={`referenceBenchmark-${referenceBenchmarkOptions.length}-${referenceBenchmarkValue?.length}`}
          />
        </Grid>
        {showRiskAversion ? (
          <>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <Typography variant="h6" as="h5">
                  Risk aversion co-efficient
                </Typography>
                <HelpInfo name="riskAversionCoEfficient" />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Controller
                id="riskAversionCoEfficient"
                defaultValue=""
                render={(props) => (
                  <TextField
                    {...props}
                    className={classes.input}
                    elevation={4}
                    variant="outlined"
                    type="number"
                    step={0.01}
                  />
                )}
                name="riskAversionCoEfficient"
                control={control}
              />
            </Grid>
          </>
        ) : null}
      </Grid>
    </>
  );
};

export default PortfolioModelPreferences;
