import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Controller } from 'react-hook-form';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import config from '../../config';
import jobsService from '../../services/JobsService';
import reportService from 'services/ReportService';
import { showMessage, getPortfolioModelParameters } from '../../redux/actions';

const useStyles = makeStyles((theme) => ({
  headingTitle: {
    fontSize: 17,
    fontWeight: 400,
  },
  list: {
    width: '100%',
    overflow: 'auto',
    marginTop: -4,
  },
  boldText: {
    fontWeight: 500,
  },
}));

const GenerateReports = ({ formValues, selectedModels, control }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [showAssetRankingReport, setShowAssetRankingReport] = useState(false);
  const [showAssetForecastReport, setShowAssetForecastReport] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { portfolioModelParameters } = useSelector((state) => state.portfolioModel);
  const fundRankingCriteriaOptions = portfolioModelParameters?.fund_ranking_criteria;

  useEffect(() => {
    dispatch(getPortfolioModelParameters());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (reportService.isAssetRankingReport(formValues)) {
      setShowAssetRankingReport(true);
    } else {
      setShowAssetRankingReport(false);
    }
    if (reportService.isAssetForecastReport(formValues)) {
      setShowAssetForecastReport(true);
    } else {
      setShowAssetForecastReport(false);
    }
  }, [formValues]);

  const { assetsRankingCriteria, fractileCount } = formValues;

  const assetForecast = config.assetForecastPropertyReport.options.filter(
    (item) => formValues[item.name],
  );

  const portfolioPerformance = config.portfolioPerformanceReport.options.filter(
    (item) => formValues[item.name],
  );

  const fractileCountValue = config.fractileCount.find((item) => item.value === fractileCount);
  const assetForecastValue = fundRankingCriteriaOptions?.find(
    (item) => item.value === assetsRankingCriteria,
  );

  const handleGenerateReports = async () => {
    setIsSubmitting(true);

    selectedModels.map(async (model) => {
      const { id, name, description } = model;
      const {
        reportOnPortfolioModels,
        oneOffRun,
        useHistoricalLearning,
        description: extraDescription,
        enableSpecUpdate,
        lastDataDate,
        overwriteLastDataDate,
        lastVintageDate,
        overwriteLastVintageDate,
      } = formValues;

      const specUpdate = {
        enableSpecUpdate,
        lastDataDate,
        overwriteLastDataDate,
        lastVintageDate,
        overwriteLastVintageDate,
      };

      const { reporting } = reportService.formatDataReportingOutput(formValues);

      const formattedName = reportOnPortfolioModels
        ? `${name} - Portfolio Model`
        : `${name} - Estimator Model`;

      const formattedDescription = extraDescription
        ? `${description}<br /> <strong>${extraDescription}</strong>`
        : description;

      const { data, dataForAnalysisObject, estimatorObject } = await jobsService.buildSpec(
        id,
        reporting,
        reportOnPortfolioModels,
        useHistoricalLearning,
        specUpdate,
      );

      const result = await jobsService.submitJob(
        formattedName,
        formattedDescription,
        data,
        oneOffRun,
      );

      if (result?.error) {
        dispatch(
          showMessage({ type: 'error', message: 'There was an error processing your request.' }),
        );
        setError(result.errorMessage);
      } else {
        dispatch(showMessage({ type: 'success', message: 'Job was submitted successfully.' }));

        if (enableSpecUpdate && overwriteLastDataDate && moment(lastDataDate).isValid()) {
          const result = await jobsService.patchSpec(
            dataForAnalysisObject.id,
            dataForAnalysisObject,
          );

          if (result?.error) {
            dispatch(
              showMessage({
                type: 'error',
                message: 'There was an error updating the last data date.',
              }),
            );
          } else {
            dispatch(
              showMessage({
                type: 'success',
                message: 'The last data date was updated successfully.',
              }),
            );
          }
        }

        if (enableSpecUpdate && overwriteLastVintageDate && moment(lastVintageDate).isValid()) {
          await jobsService.patchSpec(estimatorObject.id, estimatorObject);
        }

        setError(null);
      }
    });

    setIsSubmitting(false);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item sx={12} sm={6} md={3}>
            <Typography className={classes.headingTitle} variant="h6">
              Report on Portfolio Models:
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.headingTitle} variant="h6" style={{ marginLeft: 12 }}>
              <span className={classes.boldText}>
                {formValues?.reportOnPortfolioModels ? 'Yes' : 'No'}
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item sx={12} sm={6} md={3}>
            <Typography className={classes.headingTitle} variant="h6">
              Selected {formValues?.reportOnPortfolioModels ? `Portfolio` : `Estimator`} Models:
            </Typography>
          </Grid>
          <Grid item>
            <List className={classes.list} dense disablePadding component="div" role="list">
              {selectedModels.map((item) => {
                const labelId = `transfer-list-all-item-${item.name}-label`;
                const secondary = (
                  <>
                    <Typography variant="body2">{item.description}</Typography>
                    {item.id && item.created_at && (
                      <Typography className={classes.dateLabel} variant="caption">
                        Created: {moment(item.created_at).format('MMMM Do YYYY, h:mm:ss a')}
                      </Typography>
                    )}
                    {item.id && item.last_modified && (
                      <Typography className={classes.dateLabel} variant="caption">
                        Modified: {moment(item.last_modified).format('MMMM Do YYYY, h:mm:ss a')}
                      </Typography>
                    )}
                  </>
                );

                return (
                  <ListItem key={item.id} role="listitem">
                    <ListItemText id={labelId} primary={item.name} secondary={secondary} />
                  </ListItem>
                );
              })}
              <ListItem />
            </List>
          </Grid>
        </Grid>
      </Grid>
      {showAssetForecastReport ? (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item sx={12} sm={6} md={3}>
              <Typography className={classes.headingTitle} variant="h6">
                {config.assetForecastPropertyReport?.title}
              </Typography>
            </Grid>
            <Grid item>
              <List className={classes.list} dense disablePadding component="div" role="list">
                <ListItem key="forecastHorizon" role="listitem">
                  <ListItemText
                    primary={`Forecast Horizon: ${
                      formValues.forecastHorizon ? formValues.forecastHorizon : ''
                    }`}
                    classes={{ primary: classes.boldText }}
                  />
                </ListItem>

                {assetForecast?.map((item) => {
                  return (
                    <ListItem key={item.id} role="listitem">
                      <ListItemText primary={item.label} classes={{ primary: classes.boldText }} />
                    </ListItem>
                  );
                })}
                <ListItem />
              </List>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      {showAssetRankingReport ? (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item sx={12} sm={6} md={3}>
              <Typography className={classes.headingTitle} variant="h6">
                {config.rankingReportAndAnalysis?.title}
              </Typography>
            </Grid>
            <Grid item>
              <List className={classes.list} dense disablePadding component="div" role="list">
                <ListItem key="assetForecastPropertyReport" role="listitem">
                  <ListItemText
                    primary={`Forecast Horizon: ${
                      formValues.forecastHorizonReportAnalysis
                        ? formValues.forecastHorizonReportAnalysis
                        : ''
                    }`}
                    classes={{ primary: classes.boldText }}
                  />
                </ListItem>

                <ListItem key="Assets ranking criteria" role="listitem">
                  <ListItemText
                    primary={`Assets ranking criteria: ${assetForecastValue?.name || ''}`}
                    classes={{ primary: classes.boldText }}
                  />
                </ListItem>
                <ListItem key="Assets ranking criteria" role="listitem">
                  <ListItemText
                    primary={`Fractile Count: ${fractileCountValue?.name || ''}`}
                    classes={{ primary: classes.boldText }}
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      {/*  */}
      {formValues?.reportOnPortfolioModels ? (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item sx={12} sm={6} md={3}>
              <Typography className={classes.headingTitle} variant="h6">
                {config.portfolioPerformanceReport?.title}
              </Typography>
            </Grid>
            <Grid item>
              <List className={classes.list} dense disablePadding component="div" role="list">
                {portfolioPerformance?.map((item) => {
                  return (
                    <ListItem key={item.id} role="listitem">
                      <ListItemText primary={item.label} classes={{ primary: classes.boldText }} />
                    </ListItem>
                  );
                })}
                <ListItem />
              </List>
            </Grid>
          </Grid>
        </Grid>
      ) : null}

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item sx={12} sm={6} md={3}>
            <Typography className={classes.headingTitle} variant="h6">
              Preferences
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" style={{ marginLeft: 12 }}>
              <span>Use historical learning: </span>
              <span className={classes.boldText}>
                {formValues?.useHistoricalLearning ? 'Yes' : 'No'}
              </span>
            </Typography>
            <Typography variant="body2" style={{ marginLeft: 12 }}>
              <span>One-off run: </span>
              <span className={classes.boldText}>{formValues?.oneOffRun ? 'Yes' : 'No'}</span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item sx={12} sm={6} md={3}>
            <Typography className={classes.headingTitle} variant="h6">
              Report Description
            </Typography>
          </Grid>
          <Grid item sx={12} sm={6} md={9}>
            <Controller
              render={(props) => (
                <TextField
                  {...props}
                  className={classes.input}
                  elevation={2}
                  variant="outlined"
                  minRows={2}
                  multiline
                  fullWidth
                  label="Description"
                />
              )}
              name={`description`}
              control={control}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item style={{ marginTop: 20, marginBottom: 20 }}>
        <Button
          onClick={() => handleGenerateReports()}
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<KeyboardArrowRightIcon />}
          disabled={isSubmitting || selectedModels?.length === 0}
        >
          Generate Reports
        </Button>
      </Grid>
      {error ? (
        <Grid item xs={12}>
          <Typography
            variant="body2"
            color="error"
            dangerouslySetInnerHTML={{
              __html: error,
            }}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default GenerateReports;
