import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

import InlineInput from '../../components/InlineInput';
import FormSelect from '../../components/FormSelect';
import config from '../../config';
import { getPortfolioModelParameters } from '../../redux/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: 0,
  },
  heading: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightRegular,
  },
  button: {
    margin: theme.spacing(1),
  },
  optionHeading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const OutputReport = ({ reportVariant, reportOnPortfolioModelsValue = false }) => {
  const classes = useStyles();
  const { control, setValue } = useFormContext();
  const dispatch = useDispatch();

  const report = config[reportVariant];
  const { portfolioModelParameters } = useSelector((state) => state.portfolioModel);
  const fundRankingCriteriaOptions = portfolioModelParameters?.fund_ranking_criteria;

  // TODO: optimize if already in state
  useEffect(() => {
    dispatch(getPortfolioModelParameters());
  }, [dispatch]);

  const handleSelectAll = (report, value) => {
    config[report]?.options?.map((report) => {
      setValue(report.name, value);
      return report;
    });

    if (reportOnPortfolioModelsValue === false && reportVariant === 'assetForecastPropertyReport') {
      setValue(config.attributionReport.name, value);
    }
  };

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Paper style={{ height: '100%' }}>
          <Box
            style={{
              padding: 20,
              background: '#1E4669',
              color: 'white',
              borderRadius: '5px 5px 0 0',
            }}
          >
            <Grid container spacing={1} justifyContent="space-between" alignItems="center">
              <Grid item xs={12} sm={8}>
                <Typography className={classes.optionHeading}>{report.title}</Typography>
              </Grid>

              <Grid item xs={12} sm={4} justifyContent="flex-end" style={{ textAlign: 'right' }}>
                {reportVariant === 'rankingReportAndAnalysis' ? (
                  <FormControlLabel
                    control={
                      <Controller
                        name="rankingReportAndAnalysis"
                        defaultValue={false}
                        control={control}
                        render={(props) => (
                          <Checkbox
                            style={{ color: 'white' }}
                            onChange={(e) => {
                              props.onChange(e.target.checked);
                            }}
                            checked={props.value}
                          />
                        )}
                      />
                    }
                    label={`Select `}
                  />
                ) : (
                  <FormControlLabel
                    control={
                      <Controller
                        name={report.value}
                        defaultValue={false}
                        control={control}
                        render={(props) => (
                          <Checkbox
                            style={{ color: 'white' }}
                            onChange={(e) => {
                              props.onChange(e.target.checked);
                              handleSelectAll(report.value, !props.value);
                            }}
                            checked={props.value}
                          />
                        )}
                      />
                    }
                    label={`Select All`}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
          <Box
            style={{
              padding: 20,
              height: '100%',
            }}
          >
            <Grid container spacing={1} column>
              {reportVariant === 'assetForecastPropertyReport' ? (
                <Grid item xs={12}>
                  <InlineInput
                    control={control}
                    text="Forecast Horizon"
                    helperText="Please enter as integer."
                    name="forecastHorizon"
                  />
                </Grid>
              ) : null}

              {reportVariant === 'rankingReportAndAnalysis' ? (
                <>
                  <Grid item xs={12}>
                    <InlineInput
                      control={control}
                      text="Forecast Horizon"
                      helperText="Please enter as integer."
                      name="forecastHorizonReportAnalysis"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography variant="h6" as="h5">
                          Assets ranking criteria
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Box mb={5}>
                          <FormSelect
                            control={control}
                            name="assetsRankingCriteria"
                            data={fundRankingCriteriaOptions}
                            displayEmpty
                            emptyValue="Select Assets ranking criteria"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" as="h5">
                      Fractile Count
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box mb={5}>
                      <FormSelect
                        control={control}
                        name="fractileCount"
                        data={config.fractileCount}
                        displayEmpty
                        emptyValue="Select Fractile Count"
                      />
                    </Box>
                  </Grid>
                </>
              ) : null}
              {report.options?.map((option) => (
                <Grid item xs={12} key={option.id}>
                  <FormControlLabel
                    control={
                      <Controller
                        name={option.name}
                        defaultValue={false}
                        control={control}
                        render={(props) => (
                          <Checkbox
                            onChange={(e) => {
                              props.onChange(e.target.checked);
                            }}
                            checked={props.value}
                          />
                        )}
                      />
                    }
                    label={option.label}
                  />
                </Grid>
              ))}
              {reportOnPortfolioModelsValue === false &&
              reportVariant === 'assetForecastPropertyReport' ? (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Controller
                        name={config.attributionReport.name}
                        defaultValue={false}
                        control={control}
                        render={(props) => (
                          <Checkbox
                            onChange={(e) => {
                              props.onChange(e.target.checked);
                            }}
                            checked={props.value}
                          />
                        )}
                      />
                    }
                    label={config.attributionReport.label}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </>
  );
};

export default OutputReport;
