import React from 'react';

import Layout from '../../components/Layout';
import RecentlyOpened from '../../components/RecentlyOpened/RecentlyOpened';

const Landing = () => (
  <Layout bg>
    <RecentlyOpened />
  </Layout>
);
export default Landing;
