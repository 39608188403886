import * as React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// mui
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';

import appService from 'services/AppService';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const TranslateItem = ({ lookup, handleAddNew, addNew = false }) => {
  const queryClient = useQueryClient();
  const [isEditing, setIsEditing] = React.useState(addNew);

  const { mutateAsync: deleteLookup } = useMutation((id) => appService.deleteLookup({ id }), {
    onSuccess: () => {
      queryClient.setQueryData('ALL_LOOKUPS', (oldData) => {
        return {
          ...oldData,
          lookups: {
            data: oldData.lookups.data.filter((item) => item.id !== lookup.id),
          },
        };
      });
    },
  });

  const { mutateAsync: updateLookup } = useMutation(
    async (data) => {
      return addNew
        ? await appService.saveLookup({
            data: {
              ...data,
              type: lookup.type,
            },
          })
        : await appService.updateLookup({
            data: {
              ...data,
              type: lookup.type,
            },
            id: lookup.id,
          });
    },
    {
      onSuccess: (data) => {
        setIsEditing(false);

        queryClient.setQueryData('ALL_LOOKUPS', (oldData) => {
          return addNew
            ? {
                ...oldData,
                lookups: {
                  ...oldData.lookups,
                  data: [data, ...oldData.lookups.data],
                },
              }
            : {
                ...oldData,
                lookups: {
                  data: oldData.lookups.data.map((item) => {
                    if (item.id === data.id) {
                      return data;
                    }
                    return item;
                  }),
                },
              };
        });
        methods.reset({
          name: data.name,
          friendly_name: data.friendly_name,
        });

        addNew && handleAddNew(null);
      },
    },
  );

  const schema = yup.object().shape({
    name: yup.string().nullable(),
    friendly_name: yup.string().nullable(),
  });

  const defaultValues = {
    name: lookup.name,
    friendly_name: lookup.friendly_name,
  };

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    shouldUnregister: true,
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    updateLookup(data);
  };
  const handleSave = () => {
    methods.handleSubmit(onSubmit)();
  };

  const handleCancel = () => {
    methods.reset(defaultValues);
    setIsEditing(false);
    addNew && handleAddNew(null);
  };

  const handleDelete = async () => {
    deleteLookup(lookup.id);
  };

  return isEditing ? (
    <FormProvider {...methods}>
      <TableRow>
        <TableCell colSpan={2}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Grid container spacing={6}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    render={(props) => (
                      <TextField type={`text`} elevation={4} {...props} style={{ width: '100%' }} />
                    )}
                    error={!!methods.errors.name}
                    name="name"
                    control={methods.control}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    render={(props) => (
                      <TextField type={`text`} elevation={4} {...props} style={{ width: '100%' }} />
                    )}
                    error={!!methods.errors.friendly_name}
                    name="friendly_name"
                    control={methods.control}
                  />
                </Grid>
              </Grid>
            </Box>
          </form>
        </TableCell>
        <TableCell
          align="right"
          style={{
            width: 140,
          }}
        >
          <IconButton type="submit" aria-label="save" onClick={handleSave}>
            <CheckIcon />
          </IconButton>
          <IconButton aria-label="cancel" onClick={handleCancel}>
            <CancelIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </FormProvider>
  ) : (
    <TableRow>
      <TableCell>{lookup.name}</TableCell>
      <TableCell>{lookup.friendly_name}</TableCell>
      <TableCell
        align="right"
        style={{
          width: 140,
        }}
      >
        <IconButton
          onClick={() => setIsEditing(!isEditing)}
          aria-label="edit"
          color="primary"
          style={{
            marginRight: 5,
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton aria-label="delete" onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export const TranslateGroup = ({ lookups, type, handleAddNew, addNew = false }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="table">
        <TableHead>
          <TableRow>
            <TableCell>Backend Name</TableCell>
            <TableCell>Friendly Name</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {addNew && (
            <TranslateItem
              lookup={{ name: '', friendly_name: '', type }}
              addNew={addNew}
              handleAddNew={handleAddNew}
            />
          )}
          {lookups.map((lookup) => {
            return <TranslateItem key={lookup.id} lookup={lookup} />;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
