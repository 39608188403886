import transform from 'lodash.transform';
import isObject from 'lodash.isobject';

export const formatPortfolioModelParameters = (parameters, key = 'name') => {
  return parameters?.length
    ? parameters.map((parameter) => ({
        ...parameter,
        name: parameter[key],
      }))
    : [];
};

export const removeNullFromData = (obj) => {
  // the inner function which will be called recursivley
  return transform(obj, function (result, value, key) {
    // transform to a new object
    if (value === null) {
      return;
    }

    if (Array.isArray(value) && value.length === 0) {
      return;
    }

    if (isNaN(value) && typeof value === 'number') {
      return;
    }

    result[key] = isObject(value) ? removeNullFromData(value) : value;
  });
};

export const sortSearchResults = (results, sortBy, sortOrder) => {
  const sortFunc = (a, b) => {
    if (a[sortBy]?.toLowerCase() < b[sortBy]?.toLowerCase()) {
      return sortOrder === 'asc' ? -1 : 1;
    }

    if (a[sortBy]?.toLowerCase() > b[sortBy]?.toLowerCase()) {
      return sortOrder === 'asc' ? 1 : -1;
    }

    return 0;
  };

  return results.sort(sortFunc);
};
