import * as React from 'react';
import { Waypoint } from 'react-waypoint';
import { ParalaLineChart } from 'components/Dashboard';

export const ChartWithSelect = ({
  chartId,
  chart,
  chartDataGroupedById,
  chartProps,
  isBiAxis,
  isTripleAxis,
  yAxisLabels,
  title,
  description,
  showKeys,
  dateIndex,
  sortByName = true,
  ...props
}) => {
  const [selectedChart, setSelectedChart] = React.useState(
    sortByName
      ? chartDataGroupedById.sort((a, b) => a.name.localeCompare(b.name))[0]
      : chartDataGroupedById[0],
  );
  const [visible, setVisible] = React.useState(false);

  const { data, id, name } = selectedChart;

  const handleMenuItemClick = (id) => {
    const selectedChartData = chartDataGroupedById.find(
      (chartData) => chartData.id === id || chartData.id?.toString() === id?.toString(),
    );
    setSelectedChart(selectedChartData);
  };

  const handleWaypointEnter = () => {
    setVisible(true);
  };

  const handleWaypointLeave = () => {
    setVisible(false);
  };

  const titleReplaced = title
    ? title?.search('{REPLACE_NAME}') > -1
      ? title.replace('{REPLACE_NAME}', `<strong>${name}</strong>`)
      : title
    : '';

  const descriptionWithFundName = description
    ? description?.search('{REPLACE_NAME}')
      ? description.replace('{REPLACE_NAME}', `<strong>${name}</strong>`)
      : `${description} <strong>{name}</strong>.`
    : '';

  const selectBoxData = chartDataGroupedById.map((chartData) => {
    return {
      id: chartData.id,
      name: chartData.name,
    };
  });

  return (
    <Waypoint onEnter={handleWaypointEnter} onLeave={handleWaypointLeave}>
      {visible ? (
        <ParalaLineChart
          chartId={chartId}
          chart={chart}
          lineCharData={data}
          chartProps={chartProps}
          isBiAxis={isBiAxis}
          isTripleAxis={isTripleAxis}
          yAxisLabels={yAxisLabels}
          title={titleReplaced}
          description={descriptionWithFundName}
          showKeys={showKeys}
          dateIndex={dateIndex}
          selectBox={{
            data: sortByName
              ? selectBoxData.sort((a, b) => a.name.localeCompare(b.name))
              : selectBoxData,
            handleMenuItemClick: handleMenuItemClick,
            selectedId: id,
            secondaryText: 'Click here to select a fund',
          }}
        />
      ) : null}
    </Waypoint>
  );
};
