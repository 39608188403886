// REDUX ACTION TYPES

// Define user action types
export const USER_SIGNUP_ERROR = 'USER_SIGNUP_ERROR';
export const USER_LOGIN_IN_PROGRESS = 'USER_LOGIN_IN_PROGRESS';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_ERROR = 'USER_ERROR';

// Define data for analysis action types
export const DATA_FOR_ANALYSIS_LOAD_DATA = 'DATA_FOR_ANALYSIS_LOAD_DATA';
export const DATA_FOR_ANALYSIS_LOAD_DATA_FROM_ESTIMATOR =
  'DATA_FOR_ANALYSIS_LOAD_DATA_FROM_ESTIMATOR';
export const DATA_FOR_ANALYSIS_SAVE_DATA = 'DATA_FOR_ANALYSIS_SAVE_DATA';
export const DATA_FOR_ANALYSIS_SAVE_DATA_IN_STATE = 'DATA_FOR_ANALYSIS_SAVE_DATA_IN_STATE';
export const DATA_FOR_ANALYSIS_RESET = 'DATA_FOR_ANALYSIS_RESET';
export const DATA_FOR_ANALYSIS_SYNC = 'DATA_FOR_ANALYSIS_SYNC';
export const DATA_FOR_ANALYSIS_ADD_BENCHMARK = 'DATA_FOR_ANALYSIS_ADD_BENCHMARK';
export const DATA_FOR_ANALYSIS_REMOVE_BENCHMARK = 'DATA_FOR_ANALYSIS_REMOVE_BENCHMARK';
export const DATA_FOR_ANALYSIS_UPDATE_BENCHMARK = 'DATA_FOR_ANALYSIS_UPDATE_BENCHMARK';
export const DATA_FOR_ANALYSIS_REORDER_BENCHMARK = 'DATA_FOR_ANALYSIS_REORDER_BENCHMARK';

export const DATA_FOR_ANALYSIS_ADD_MACROFACTOR = 'DATA_FOR_ANALYSIS_ADD_MACROFACTOR';
export const DATA_FOR_ANALYSIS_REMOVE_MACROFACTOR = 'DATA_FOR_ANALYSIS_REMOVE_MACROFACTOR';
export const DATA_FOR_ANALYSIS_REORDER_MACROFACTOR = 'DATA_FOR_ANALYSIS_REORDER_MACROFACTOR';

// app
export const VALIDATE_DATA_FOR_ANALYSIS = 'VALIDATE_DATA_FOR_ANALYSIS';
export const IS_DIRTY_DATA_FOR_ANALYSIS = 'IS_DIRTY_DATA_FOR_ANALYSIS';
export const APP_DATA_FOR_ANALYSIS_RESET = 'APP_DATA_FOR_ANALYSIS_RESET';
export const IS_DIRTY_ESTIMATOR_MODEL = 'IS_DIRTY_ESTIMATOR_MODEL';
export const VALIDATE_ESTIMATOR_MODEL = 'VALIDATE_ESTIMATOR_MODEL';
export const APP_ESTIMATOR_MODEL_RESET = 'APP_ESTIMATOR_MODEL_RESET';
export const VALIDATE_PORTFOLIO_MODEL = 'VALIDATE_PORTFOLIO_MODEL';
export const IS_DIRTY_PORTFOLIO_MODEL = 'IS_DIRTY_PORTFOLIO_MODEL';
export const APP_PORTFOLIO_MODEL_RESET = 'APP_PORTFOLIO_MODEL_RESET';
export const IS_DIRTY_REPORTING_OUTPUT = 'IS_DIRTY_REPORTING_OUTPUT';
export const IS_DIRTY_RANKING_REPORTING_OUTPUT = 'IS_DIRTY_RANKING_REPORTING_OUTPUT';

export const APP_SHOW_MESSAGE = 'APP_SHOW_MESSAGE';
export const APP_HIDE_MESSAGE = 'APP_HIDE_MESSAGE';
export const APP_OPEN_PREFERENCE = 'APP_OPEN_PREFERENCE';
export const APP_CLOSE_PREFERENCE = 'APP_CLOSE_PREFERENCE';
export const APP_SAVE_PREFERENCE = 'APP_SAVE_PREFERENCE';
export const APP_UPDATE_PAGINATION = 'APP_UPDATE_PAGINATION';

export const APP_LOAD_USER_PREFERENCES = 'APP_LOAD_USER_PREFERENCES';

// ESTIMATOR MODEL
export const ESTIMATOR_MODEL_SET_RULES = 'ESTIMATOR_MODEL_SET_RULES';
export const ESTIMATOR_MODEL_LOAD_DATA = 'ESTIMATOR_MODEL_LOAD_DATA';
export const ESTIMATOR_MODEL_SMC_LOAD_DATA = 'ESTIMATOR_MODEL_SMC_LOAD_DATA';
export const ESTIMATOR_MODEL_SAVE_DATA = 'ESTIMATOR_MODEL_SAVE_DATA';
export const ESTIMATOR_MODEL_SAVE_DATA_IN_STATE = 'ESTIMATOR_MODEL_SAVE_DATA_IN_STATE';
export const ESTIMATOR_MODEL_RESET = 'ESTIMATOR_MODEL_RESET';
export const ESTIMATOR_MODEL_UPDATE_SELECTED_SIMULATED_MODELS =
  'ESTIMATOR_MODEL_UPDATE_SELECTED_SIMULATED_MODELS';
export const ESTIMATOR_MODEL_UPDATE_SIMULATED_MODELS = 'ESTIMATOR_MODEL_UPDATE_SIMULATED_MODELS';
export const ESTIMATOR_MODEL_IS_SIMULATED_MODEL_COMBINATION =
  'ESTIMATOR_MODEL_IS_SIMULATED_MODEL_COMBINATION';

export const ESTIMATOR_MODEL_UPDATE_SELECTED_SEGMENTED_MODELS =
  'ESTIMATOR_MODEL_UPDATE_SELECTED_SEGMENTED_MODELS';
export const ESTIMATOR_MODEL_UPDATE_SEGMENTED_MODELS = 'ESTIMATOR_MODEL_UPDATE_SEGMENTED_MODELS';
export const ESTIMATOR_MODEL_IS_SEGMENTED_SIMULATED_MODEL_COMBINATION =
  'ESTIMATOR_MODEL_IS_SEGMENTED_SIMULATED_MODEL_COMBINATION';

export const UPDATE_SUBSET_REGRESSION = 'UPDATE_SUBSET_REGRESSION';
export const UPDATE_SUBSET_REGRESSION_MACROFACTORS = 'UPDATE_SUBSET_REGRESSION_MACROFACTORS';
export const REFRESH_SUBSET_REGRESSION_MACROFACTORS = 'REFRESH_SUBSET_REGRESSION_MACROFACTORS';

// PORTFOLIO MODEL
export const ADD_MULTI_ASSET_CONSTRAINT = 'ADD_MULTI_ASSET_CONSTRAINT';
export const ADD_MULTI_ASSET_CONSTRAINT_WEIGHT = 'ADD_MULTI_ASSET_CONSTRAINT_WEIGHT';
export const REMOVE_MULTI_ASSET_CONSTRAINT = 'REMOVE_MULTI_ASSET_CONSTRAINT';
export const ADD_EXPOSURE_TOLERANCE = 'ADD_EXPOSURE_TOLERANCE';
export const ADD_NEUTRAL_RISK_FACTOR = 'ADD_NEUTRAL_RISK_FACTOR';

export const PORTFOLIO_MODEL_SAVE_DATA = 'PORTFOLIO_MODEL_SAVE_DATA';
export const PORTFOLIO_MODEL_LOAD_DATA = 'PORTFOLIO_MODEL_LOAD_DATA';
export const PORTFOLIO_MODEL_SAVE_DATA_IN_STATE = 'PORTFOLIO_MODEL_SAVE_DATA_IN_STATE';
export const PORTFOLIO_MODEL_RESET = 'PORTFOLIO_MODEL_RESET';
export const PORTFOLIO_MODEL_GET_PARAMETERS = 'PORTFOLIO_MODEL_GET_PARAMETERS';

//REPORTING REPORT

export const REPORTING_OUTPUT_SAVE_DATA = 'REPORTING_OUTPUT_SAVE_DATA';
export const REPORTING_OUTPUT_SAVE_DATA_IN_STATE = 'REPORTING_OUTPUT_SAVE_DATA_IN_STATE';
export const REPORTING_OUTPUT_LOAD_DATA = 'REPORTING_OUTPUT_LOAD_DATA';
export const REPORTING_OUTPUT_RESET = 'REPORTING_OUTPUT_RESET';
export const REPORTING_OUTPUT_UPDATE_SELECTED_MODELS = 'REPORTING_OUTPUT_UPDATE_SELECTED_MODELS';
export const REPORTING_OUTPUT_UPDATE_MODELS = 'REPORTING_OUTPUT_UPDATE_MODELS';

//RANKING REPORTING
export const RANKING_REPORTING_OUTPUT_SAVE_DATA = 'RANKING_REPORTING_OUTPUT_SAVE_DATA';
export const RANKING_REPORTING_OUTPUT_SAVE_DATA_IN_STATE =
  'RANKING_REPORTING_OUTPUT_SAVE_DATA_IN_STATE';
export const RANKING_REPORTING_OUTPUT_LOAD_DATA = 'RANKING_REPORTING_OUTPUT_LOAD_DATA';
export const RANKING_REPORTING_OUTPUT_RESET = 'RANKING_REPORTING_OUTPUT_RESET';
export const RANKING_REPORTING_OUTPUT_UPDATE_SELECTED_MODELS =
  'RANKING_REPORTING_OUTPUT_UPDATE_SELECTED_MODELS';
export const RANKING_REPORTING_OUTPUT_UPDATE_MODELS = 'RANKING_REPORTING_OUTPUT_UPDATE_MODELS';
