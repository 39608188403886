import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SignUpForm from '../../components/SignUpForm';

const SignUp = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);

  const { state } = useLocation();

  if (isAuthenticated === true) {
    return <Redirect to={state?.from || '/'} />;
  }

  return (
    <>
      <SignUpForm />
    </>
  );
};

export default SignUp;
