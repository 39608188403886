import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import { IconButton, Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 500,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
  paper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  input: {
    '& .MuiInput-underline:before': {
      border: 0,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 0,
    },
  },
}));

FormAutoComplete.propTypes = {
  options: PropTypes.array,
  multiple: PropTypes.bool,
};

FormAutoComplete.defaultProps = {
  options: [],
  multiple: false,
};

export default function FormAutoComplete({ options, multiple = false, name, onChange: ignored }) {
  const classes = useStyles();

  const { control, setValue } = useFormContext();

  const getValue = (id) => {
    const result = options?.filter((option) => option.id === id);

    return result[0] ? result[0] : null;
  };

  return (
    <div className={classes.root}>
      <Controller
        render={({ onChange, ...props }) => {
          let { value } = props;
          if (value?.id && !value?.name) {
            const parsedValue = getValue(value.id);
            parsedValue && setValue(name, parsedValue);
          }

          return (
            <Autocomplete
              clearOnBlur
              disableClearable
              options={options}
              multiple={multiple}
              disabled={!options?.length}
              getOptionLabel={(option) => option.name || ''}
              value={props.value || ''}
              filterSelectedOptions
              renderInput={(params) => (
                <Paper className={classes.paper} elevation={4}>
                  <IconButton className={classes.iconButton} aria-label="Search">
                    <SearchIcon />
                  </IconButton>
                  <TextField className={classes.input} {...params} placeholder="Search" />
                </Paper>
              )}
              renderOption={(option, { inputValue }) => {
                const matches = match(option.name, inputValue);
                const parts = parse(option.name, matches);

                return (
                  <div>
                    {parts.map((part, index) => (
                      <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
              onChange={(e, data) => onChange(data)}
              {...props}
            />
          );
        }}
        onChange={([, data]) => data}
        name={name}
        control={control}
      />
    </div>
  );
}
