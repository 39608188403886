import * as React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import SortIcon from '@material-ui/icons/Sort';

import { orderByOptions } from 'config';

export const OrderBy = ({ order, handleOrderChange, icon = false, height = false }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickOrder = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseOrder = () => {
    setAnchorEl(null);
  };

  const handleOnClick = (option) => {
    handleOrderChange(option);
    handleCloseOrder();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      {icon ? (
        <IconButton aria-describedby={id} onClick={handleClickOrder} size="small" color="primary">
          <SortIcon />
        </IconButton>
      ) : (
        <Button
          aria-describedby={id}
          variant="outlined"
          size="small"
          color="primary"
          onClick={handleClickOrder}
          style={{ height: height ? '54px' : 'auto' }}
        >
          Order by: {order.label}
        </Button>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseOrder}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box>
          <Typography style={{ padding: '20px 20px 0 20px' }}>Order By</Typography>
          <List component="nav" aria-label="Order By">
            {orderByOptions.map((option) => (
              <ListItem button key={option.value} onClick={() => handleOnClick(option)}>
                <ListItemText primary={option.label} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Popover>
    </>
  );
};
