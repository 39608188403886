import { useSelector } from 'react-redux';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CachedIcon from '@material-ui/icons/Cached';

import Layout from 'components/Layout';
import CollapsibleTable from 'components/CollapsibleTable';

import { useGetListJobs } from 'services/JobsService';

const Reports = () => {
  const { pagination } = useSelector((state) => state.app);
  const { isLoading, data: jobList, refetch } = useGetListJobs(pagination);

  return (
    <Layout>
      <Container maxWidth="lg" style={{ margin: 0 }}>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={6}>
            <Box display="flex">
              <Typography variant="h4" as="h1">
                Reports
              </Typography>
              <IconButton
                color="primary"
                aria-label="Refresh"
                component="span"
                onClick={() => refetch(pagination)}
              >
                <CachedIcon />
              </IconButton>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <CollapsibleTable
              jobs={jobList?.jobs || []}
              total={jobList?.total || 0}
              page={jobList?.page || 0}
              pagination={pagination}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default Reports;
