import axios from 'axios';
import isEmpty from 'lodash.isempty';
import { getAccessToken } from './firebase';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const dashboardReportService = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    // Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

const dashboardReportServiceStream = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  responseType: 'stream',
});

dashboardReportService.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.data.errors &&
      error.response.data.errors[0].code === 'INVALID_TOKEN'
    ) {
      // return AuthService.updateAccessToken().then((accessToken) => {
      //   error.config.headers.Authorization = `Bearer ${accessToken}`;
      //   return apiClient.request(error.config);
      // });
    }
    return Promise.reject(error);
  },
);

// const pyChartData = await parseCSV('pyChartData.csv');
//   const idsAndNames = await parseCSV('idsAndNames.csv');
//   const CPitPct = await parseCSV('CPitPct.csv');
//   const pyPortfolioReturns = await parseCSV('pyPortfolioReturns.csv');
//   const pyLongBetaT = await parseCSV('pyLongBetaT.csv');
//   const BetaPT = await parseCSV('BetaPT.csv');

const getIdsAndNames = async (id) => {
  const accessToken = await getAccessToken();
  try {
    const apiURL = `/jobs/${id}/artifacts`;
    const data = {
      artifactNames: ['idsAndNames.csv'],
    };
    const result = await dashboardReportService.post(apiURL, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    if (result.status !== 200 || isEmpty(result.data)) {
      throw new Error('Error fetching data');
    }

    return result.data['idsAndNames.csv'];
  } catch (error) {
    return error;
  }
};

const getByIdAndFileName = async (id, fileName, pageParam = 0) => {
  const accessToken = await getAccessToken();
  try {
    const apiURL = `/jobs/${id}/artifacts?size=50&pagination=${pageParam}`;
    const data = {
      artifactNames: [fileName],
    };
    const result = await dashboardReportService.post(apiURL, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    if (result.status !== 200) {
      throw new Error('Network response was not ok');
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error);
  }
};

const getByIdAndFileNameInfinite = async (id, fileName, pageParam = 0) => {
  const accessToken = await getAccessToken();
  try {
    const apiURL = `/jobs/${id}/artifacts?size=200&pagination=${pageParam}`;
    const data = {
      artifactNames: [fileName],
    };
    const result = await dashboardReportService.post(apiURL, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    if (result.status !== 200) {
      throw new Error('Network response was not ok');
    } else {
      return {
        data: result.data[fileName]?.data,
        nextCursor: result.data[fileName]?.next_pagination ? pageParam + 1 : undefined,
      };
    }
  } catch (error) {
    throw new Error(error);
  }
};

// const getReportsStreamData = async (id, fileName) => {
//   const accessToken = await getAccessToken();
//   try {
//     const apiURL = `/jobs/${id}/stream/artifacts`;
//     const data = {
//       artifactName: fileName,
//     };
//     const response = await dashboardReportServiceStream.post(apiURL, data, {
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//       },
//       responseType: 'stream',
//     });

//     // Create a writable stream to store the response data react client
//     const writableStream = new Stream();
//     writableStream.writable = true;
//     let responseData = '';

//     // console.log('writableStream', writableStream);

//     writableStream.write = function (chunk, encoding, done) {
//       console.log('chunk', chunk);
//       responseData += chunk.toString();
//       done();
//     }; // Pipe the readable stream to the writable stream

//     // response.data.pipe(writableStream); // Wait for the stream to finish
//     const splitText = responseData.split('}{');
//     const formattedText = splitText.map((chunk, index) => {
//       if (index === 0) {
//         return chunk + '}';
//       } else if (index === splitText.length - 1) {
//         return '{' + chunk;
//       } else {
//         return '{' + chunk + '}';
//       }
//     });

//     // Join the split chunks back into a JSON array
//     const jsonArray = `[${formattedText.join(',')}]`;
//     // console.log('JSON Array: ', JSON.parse(jsonArray));

//     // console.log('response.data', responseData);

//     return jsonArray ?? [];
//     // return null;
//   } catch (error) {
//     throw new Error(error);
//   }
// };

async function toJSON(body) {
  const reader = body.getReader(); // `ReadableStreamDefaultReader`
  const decoder = new TextDecoder();
  const chunks = [];

  async function read() {
    const { done, value } = await reader.read();

    // all chunks have been read?
    if (done) {
      return chunks;
    }

    const chunk = decoder.decode(value, { stream: true });
    chunks.push(chunk);
    return read(); // read the next chunk
  }

  return read();
}

async function fetchStreamData(response) {
  // Initialize an empty array to hold the processed data
  let result = [];

  try {
    // Fetch data from the API

    // Get the reader from the response body
    const reader = response.body.getReader();

    // Stream is UTF-8 encoded, so we need a TextDecoder to turn it into a string
    const decoder = new TextDecoder();

    // Read the stream
    while (true) {
      const { done, value } = await reader.read();

      // When the stream is complete, break the loop
      if (done) break;

      // Decode the chunk and parse it as JSON
      const chunk = decoder.decode(value, { stream: true });
      // get the data from based on Filename data from the JSON and push that to result
      // const data = chunk[fileName]?.data;
      // console.log('chunk', chunk);
      // console.log('data', data);

      // Process and add the chunk to the result
      result.push(chunk);

      // Optionally, update your component state or perform other actions
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }

  // console.log('result', result);
  // Return the complete result
  return result.concat();
}

const fetchStreamError = async (response) => {
  // decode and return the error message
  const reader = response.body.getReader();
  const decoder = new TextDecoder();
  const { value } = await reader.read();
  return decoder.decode(value, { stream: true });
};

const getReportsStreamData = async (id, fileName) => {
  const accessToken = await getAccessToken();
  try {
    const apiURL = `${baseURL}/jobs/${id}/stream/artifacts`;
    const data = {
      artifactName: fileName,
    };

    const response = await fetch(apiURL, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        responseType: 'stream',
      },
      responseType: 'stream',
    });

    // if response not 200 status throw error
    if (response.status !== 200) {
      const errorData = await fetchStreamError(response);

      throw new Error(JSON.parse(errorData).detail ?? 'Error fetching data', { cause: errorData });
    }

    const jsonData = await fetchStreamData(response);

    // merge all the data from the array
    const mergedData = jsonData.join('');

    const splitText = mergedData.split('}{');
    const formattedText = splitText.map((chunk, index) => {
      if (index === 0) {
        return chunk + '}';
      } else if (index === splitText.length - 1) {
        return '{' + chunk;
      } else {
        return '{' + chunk + '}';
      }
    });

    // Join the split chunks back into a JSON array
    const jsonArray = `[${formattedText.join(',')}]`;

    return JSON.parse(jsonArray);
  } catch (error) {
    throw new Error(error.message);
  }
};

const getById = async (id, artifactNames) => {
  const accessToken = await getAccessToken();
  try {
    const apiURL = `/jobs/${id}/artifacts`;
    const data = {
      artifactNames: [...artifactNames],
    };
    const result = await dashboardReportService.post(apiURL, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return result.data;
  } catch (error) {
    return error;
  }
};

export {
  getIdsAndNames,
  getByIdAndFileName,
  getReportsStreamData,
  getByIdAndFileNameInfinite,
  getById,
};
