import { useState } from 'react';
import groupBy from 'lodash.groupby';

// MUI

import Box from '@material-ui/core/Box';

import { ParalaLineChart } from 'components/Dashboard';
import { AssetAllocation } from './AssetAllocation';

export const UniverseLevelAttributionReports = ({
  showReports,
  showKeys,
  dateIndex,
  totalReturnData,
  totalReturnChart,
  crossSectionAverageAlphaChart,
  expectedReturnForEachRiskFactor,
  extractMacroFactorStandardisedDeviations,
  extractMacroFactorChanges,
}) => {
  return (
    <Box display="flex" flex={1} flexDirection="column">
      {showReports.totalReturnChart.visible ? (
        <Box
          style={{
            marginBottom: 30,
            paddingBottom: 30,
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        >
          <ParalaLineChart
            chart={totalReturnChart}
            lineCharData={totalReturnData}
            title="Cross-sectional average expected return"
            description="This chart shows the contributors to total expected return. Each component
is averaged over all the assets in the universe to provide a summary of the
overall opportunity set."
            showKeys={showKeys}
            dateIndex={dateIndex}
          />
        </Box>
      ) : null}
      {/* crossSectionAverageAlphaChart */}
      {showReports.crossSectionAverageAlphaChart.visible ? (
        <Box
          style={{
            marginBottom: 30,
            paddingBottom: 30,
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        >
          <ParalaLineChart
            chart={crossSectionAverageAlphaChart}
            lineCharData={totalReturnData}
            title="Cross-sectional average alpha decomposition"
            description="This chart shows the alpha contributions to total return, averaged over all the
assets in the universe. Time-varying contributions capture (alpha1 x expected
macro-factor change)."
            showKeys={showKeys}
            dateIndex={dateIndex}
          />
        </Box>
      ) : null}
      {/* crossSectionAverageAlphaChart */}
      {/* expectedReturnForEachRiskFactor */}
      {showReports.expectedReturnForEachRiskFactor.visible ? (
        <Box
          style={{
            marginBottom: 30,
            paddingBottom: 30,
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        >
          <ParalaLineChart
            chart={expectedReturnForEachRiskFactor}
            lineCharData={totalReturnData}
            title="Expected return for each risk factor"
            description=""
            showKeys={showKeys}
            dateIndex={dateIndex}
          />
        </Box>
      ) : null}
      {/* expectedReturnForEachRiskFactor */}
      {/* extractMacroFactorChanges */}
      {showReports.extractMacroFactorChanges.visible ? (
        <Box
          style={{
            marginBottom: 30,
            paddingBottom: 30,
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        >
          <ParalaLineChart
            chart={extractMacroFactorChanges}
            lineCharData={totalReturnData}
            title="Macro-factor changes"
            description="This chart shows the time series of each of the macro-factors with any filters applied."
            showKeys={showKeys}
            dateIndex={dateIndex}
          />
        </Box>
      ) : null}
      {/* extractMacroFactorChanges */}
      {/* extractMacroFactorStandardisedDeviations */}
      {showReports.extractMacroFactorStandardisedDeviations.visible ? (
        <Box
          style={{
            marginBottom: 30,
            paddingBottom: 30,
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        >
          <ParalaLineChart
            chart={extractMacroFactorStandardisedDeviations}
            lineCharData={totalReturnData}
            title="Macro-factor standardised deviations"
            description="This chart shows the time series of each of the macro-factors
normalised by the time series average and the standard deviation."
            showKeys={showKeys}
            dateIndex={dateIndex}
          />
        </Box>
      ) : null}
      {/* extractMacroFactorStandardisedDeviations */}
    </Box>
  );
};
