import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

import AlertDialog from '../AlertDialog';
import { PreventNavigationView } from './PreventNavigation.view';

PreventNavigation.propTypes = {
  dirty: PropTypes.bool,
};

export function PreventNavigation({ dirty, dirtyForm, saveToStore }) {
  const history = useHistory();
  const [location, setLocation] = useState();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [allowNavigation, setAllowNavigation] = useState(false);

  const appState = useSelector((state) => state.app);
  const isFormDirty = appState[dirtyForm]?.isFormDirty;

  useEffect(
    () => {
      if (location && allowNavigation) {
        history.push(location);
      }
    },
    [location, allowNavigation], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const launchConfirmModal = () => {
    setIsAlertOpen(true);
  };

  const handleConfirm = () => {
    saveToStore();
    setIsAlertOpen(false);
    setAllowNavigation(true);
  };

  const handleCancel = () => {
    setIsAlertOpen(false);
  };
  const handleInternalNavigation = (location) => {
    if (!allowNavigation) {
      setLocation(location.pathname);
      launchConfirmModal();
      return false;
    }
    return true;
  };

  return (
    <>
      <PreventNavigationView
        dirty={dirty || isFormDirty}
        handleInternalNavigation={handleInternalNavigation}
      />
      <AlertDialog
        isOpen={isAlertOpen}
        title="You have unsaved changes. Do you want to navigate away?"
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
    </>
  );
}

export default PreventNavigation;
