import React from 'react';
import { Droppable } from 'react-beautiful-dnd';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Macrofactor from './Macrofactor';

const useStyles = makeStyles({
  root: {
    padding: 8,
    borderRadius: 2,
    width: (props) => (props.column.id === 'macrofactors' ? `100%` : `25%`),
    display: 'flex',
    flexDirection: 'column',
  },
  macrofactorList: {
    transition: 'background-color 0.2s ease',
    backgroundColor: (props) => (props.isDraggingOver ? '#ff0000' : '#fff'),
    width: `100%`,
    paddingRight: 8,
    display: 'flex',
    height: 100,
    flexDirection: 'row',
    alignItems: 'flex-start',
    overflowX: 'scroll',
    '& > div': {
      overflowX: 'scroll',
    },
  },
  categoryList: {
    transition: 'background-color 0.2s ease',
    backgroundColor: (props) => (props.isDraggingOver ? '#ff0000' : '#fff'),

    display: 'flex',
    height: 160,
    flexDirection: 'column',
    alignItems: `center`,
    overflowY: 'scroll',
    '& > div': {
      width: '100%',
    },
  },
  columnTitle: {
    backgroundColor: 'rgb(30 70 105)',
    color: 'white',
    padding: 10,
    borderRadius: '5px 5px 0 0',
  },
});

const Column = (props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <Typography as="h4" className={classes.columnTitle}>
        {props.column.title}
      </Typography>
      <Droppable droppableId={props.column.id}>
        {(provided, snapshot) => (
          <div
            className={
              props.column.id === 'macrofactors' ? classes.macrofactorList : classes.categoryList
            }
            ref={provided.innerRef}
            {...provided.droppableProps}
            isDraggingOver={snapshot.isDraggingOver}
          >
            {props?.tasks?.map((task, index) =>
              task?.id ? (
                <Macrofactor key={task?.id} task={task} index={index} column={props?.column?.id} />
              ) : null,
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default Column;
