import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { ArtifactListByCategory } from './ArtifactListByCategory';

import jobsService from 'services/JobsService';

const ArtifactsByCategory = ({ id, open }) => {
  const [artifacts, setArtifacts] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      setIsFetching(true);
      const result = await jobsService.listArtifactsByCategory(id);
      setArtifacts(result);
      setIsFetching(false);
    };
    open && fetchData();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box margin={1}>
      <Typography variant="h6" gutterBottom component="div">
        Reports
      </Typography>
      <Box>
        {artifacts ? (
          <ArtifactListByCategory artifacts={artifacts} jobID={id} />
        ) : isFetching ? (
          <Typography variant="body2" gutterBottom component="p">
            Downloading artifacts.
          </Typography>
        ) : (
          <Typography variant="body2" gutterBottom component="p">
            There is no artifact available.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ArtifactsByCategory;
