import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import { HelpInfo } from '../HelpInfo';
import InlineInput from 'components/InlineInput';
import ReportingPreferences from 'components/ReportingPreferences';
import ReportModelSelector from 'components/ReportModelSelector';
import { updateSelectedSimulatedModels, updateSimulatedModels } from 'redux/actions';

export const SimulatedEstimationModelCombinations = () => {
  // TODO - SAVE simulated models data to DB
  // TODO - Load simulated models data to DB
  const { control, errors } = useFormContext();

  const { simulatedModelData } = useSelector((state) => state.estimatorModel);
  const { selectedModels } = simulatedModelData;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ReportModelSelector
            reportOnPortfolio={false}
            selectedModels={selectedModels}
            updateSelectedModels={updateSelectedSimulatedModels}
            updateModels={updateSimulatedModels}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" component="h5" style={{ marginBottom: 20 }}>
            Preference
          </Typography>
          <ReportingPreferences showOverWrite={true} />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Typography variant="h6" as="h5">
              Model Weighting Rule
            </Typography>
            <HelpInfo name="modelWeightingRule" isHtml />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl component="fieldset">
                <Controller
                  id="model-weighting"
                  defaultValue=""
                  name="modelWeightRuleSimulatedModels"
                  control={control}
                  render={(props) => (
                    <RadioGroup aria-label=" Model Weighting Rule" {...props}>
                      <FormControlLabel
                        value="equalWeightedCombinations"
                        control={<Radio />}
                        label="Equal Weighted Combinations"
                      />
                      <FormControlLabel
                        value="posteriorOddsWeighting"
                        control={<Radio />}
                        label="Posterior Odds Weighting"
                      />
                      <FormControlLabel
                        value="optimalPredictiveWeightingS"
                        control={<Radio />}
                        label="Optimal Predictive Weighting"
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <InlineInput
                    control={control}
                    text="Weighting Sensitivity"
                    name="weightingSensitivity"
                    placeholder="Eg. 1"
                    error={errors?.weightingSensitivity?.message}
                    leftGrid={5}
                    rightGrid={7}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
