import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: -theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  card: {
    minWidth: 170,
    margin: theme.spacing(1),
  },
  time: {
    fontSize: 14,
  },
  title: {
    marginBottom: 0,
    fontWeight: 500,
    fontSize: 20,
    textDecoration: 'none',
  },
  link: {
    textDecoration: 'none',
  },
}));

const RecentlyOpened = () => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h4" as="h1">
        View All
      </Typography>
      <div className={classes.root}>
        {config.specDetails.map((spec) => (
          <Link key={spec.id} to={spec.slug} className={classes.link}>
            <Card className={classes.card}>
              <CardContent>
                <Typography className={classes.title} variant="h6" component="h2">
                  {spec.title}
                </Typography>
              </CardContent>
            </Card>
          </Link>
        ))}
      </div>
    </>
  );
};

export default RecentlyOpened;
