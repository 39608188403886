import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import FormSelect from '../FormSelect';

import config from '../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: 0,
  },
  heading: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightRegular,
  },
  optionHeading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
  },
  button: {
    margin: theme.spacing(1),
  },
  datePicker: {
    width: 180,
    borderRadius: 4,
    border: 0,
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    backgroundColor: theme.palette.background.default,
    padding: 5,
  },
  input: {
    width: 200,
    backgroundColor: theme.palette.background.default,
    borderRadius: 4,
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      border: 0,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 0,
      },
      '&:hover fieldset': {
        border: 0,
      },
      '&.Mui-focused fieldset': {
        border: 0,
      },
    },
  },
}));

const RankingReportTab = () => {
  const [allFRH, setAllFRH] = useState(false);
  const [allReport, setAllReport] = useState(false);
  const classes = useStyles();
  const { control, setValue } = useFormContext();

  const { portfolioModelParameters } = useSelector((state) => state.portfolioModel);
  const fundRankingCriteriaOptions = portfolioModelParameters?.fund_ranking_criteria;

  const handleSelectAllFRH = (status) => {
    setAllFRH(status);

    config.forwardRankingHorizon.map((rankingHorizon) => {
      setValue(rankingHorizon.name, status);
      return rankingHorizon;
    });
  };

  const handleSelectAllReport = (status) => {
    setAllReport(status);

    config.selectReport.map((report) => {
      setValue(report.name, status);
      return report;
    });
  };

  const valuetext = (value) => {
    return value;
  };

  return (
    <Container style={{ margin: 0 }}>
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={12} sm={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6" as="h5">
                Assets ranking criteria
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box mb={5}>
                <FormSelect
                  control={control}
                  name="assetsRankingCriteria"
                  data={fundRankingCriteriaOptions}
                  displayEmpty
                  emptyValue="Select Assets ranking criteria"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6" as="h5">
                Fractile Count
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box mb={5}>
                <FormSelect
                  control={control}
                  name="fractileCount"
                  data={config.fractileCount}
                  displayEmpty
                  emptyValue="Select Fractile Count"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6" as="h5">
                Recalculation frequency
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20 }}>
              <Controller
                name="dataFreq"
                control={control}
                defaultValue={3}
                render={(props) => (
                  <Slider
                    {...props}
                    onChange={(_, value) => {
                      props.onChange(value);
                    }}
                    max={12}
                    getAriaValueText={valuetext}
                    aria-labelledby="discrete-slider-always"
                    step={1}
                    valueLabelDisplay="on"
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6}></Grid>
        <Grid item xs={12} sm={6}>
          <Paper style={{ height: '100%' }}>
            <Box
              style={{
                padding: 20,
                background: '#1E4669',
                color: 'white',
                borderRadius: '5px 5px 0 0',
              }}
            >
              <Grid container spacing={1} justifyContent="space-between" alignItems="center">
                <Grid item xs={12} sm={8}>
                  <Typography className={classes.optionHeading}>
                    Forward Ranking Horizons
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={4} justifyContent="flex-end" style={{ textAlign: 'right' }}>
                  <FormControlLabel
                    control={
                      <Controller
                        name="forwardRankingHorizons"
                        defaultValue={false}
                        control={control}
                        render={(props) => (
                          <Checkbox
                            style={{ color: 'white' }}
                            onChange={(e) => {
                              handleSelectAllFRH(!allFRH);
                            }}
                            checked={allFRH}
                          />
                        )}
                      />
                    }
                    label={`Select All`}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              style={{
                padding: 20,
                height: '100%',
              }}
            >
              <Grid container spacing={1} alignItems="flex-start">
                {config.forwardRankingHorizon.map((rankingHorizon) => (
                  <Grid item xs={12} sm={6} key={rankingHorizon.id}>
                    <FormControlLabel
                      control={
                        <Controller
                          name={rankingHorizon.name}
                          defaultValue={false}
                          control={control}
                          render={(props) => (
                            <Checkbox
                              onChange={(e) => {
                                props.onChange(e.target.checked);
                              }}
                              checked={props.value}
                            />
                          )}
                        />
                      }
                      label={rankingHorizon.label}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper style={{ height: '100%' }}>
            <Box
              style={{
                padding: 20,
                background: '#1E4669',
                color: 'white',
                borderRadius: '5px 5px 0 0',
              }}
            >
              <Grid container spacing={1} justifyContent="space-between" alignItems="center">
                <Grid item xs={12} sm={8}>
                  <Typography className={classes.optionHeading}>Select report(s)</Typography>
                </Grid>
                <Grid item xs={12} sm={4} justifyContent="flex-end" style={{ textAlign: 'right' }}>
                  <FormControlLabel
                    control={
                      <Controller
                        name="rankingReportAndAnalysis"
                        defaultValue={false}
                        control={control}
                        render={(props) => (
                          <Checkbox
                            style={{ color: 'white' }}
                            onChange={(e) => {
                              handleSelectAllReport(!allReport);
                            }}
                            checked={allReport}
                          />
                        )}
                      />
                    }
                    label={`Select All`}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              style={{
                padding: 20,
                height: '100%',
              }}
            >
              <Grid container spacing={1} alignItems="flex-start">
                {config.selectReport.map((report) => (
                  <Grid item xs={12} key={report.id}>
                    <FormControlLabel
                      control={
                        <Controller
                          name={report.name}
                          defaultValue={false}
                          control={control}
                          render={(props) => (
                            <Checkbox
                              onChange={(e) => {
                                props.onChange(e.target.checked);
                              }}
                              checked={props.value}
                            />
                          )}
                        />
                      }
                      label={report.label}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default RankingReportTab;
