import React from 'react';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import { COLORS } from './PieChart';

const CustomizedAxisTick = (props) => {
  const { x, y, stroke, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#667" transform="rotate(-35)" fontSize={11}>
        {payload.value}
      </text>
    </g>
  );
};

export const ParalaBarChart = ({ charData }) => {
  return (
    <ResponsiveContainer width="100%" height={600}>
      <BarChart
        width={500}
        height={300}
        data={charData}
        barCategoryGap={5}
        barSize={20}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 160,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" interval={0} tick={<CustomizedAxisTick />} />
        <YAxis />

        <Tooltip />
        <Bar dataKey="value" label="name" unit="%" barSize={30} fill={COLORS[0]} />
      </BarChart>
    </ResponsiveContainer>
  );
};
